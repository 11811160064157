import React from 'react';
import PropTypes from 'prop-types';
import { useField } from 'informed';
import { InputNumber as AntdInputNumber, Tooltip } from 'antd';
import classNames from 'classnames';
import { Form } from '~/components/forms';
import { isRequired } from '~/utils';
import { types } from './options';
import './InputNumber.less';

const InputNumber = React.memo(({ validate, validateOnChange, spinner, align, ...props }) => {
  const { fieldState, fieldApi, render, userProps } = useField({
    validate: validate || (props.required && isRequired) || (() => undefined),
    validateOnChange: validateOnChange || props.required || validate,
    ...props,
  });
  const {
    onChange,
    onBlur,
    label,
    initialValue,
    forwardedRef,
    className,
    block,
    type = 'integer',
    formItemProps,
    disabledReason,
    tooltipProps,
    tooltip,
    forceZero,
    disabled,
    required,
    ...rest
  } = userProps;

  let fieldError = fieldState.error;
  if (isRequired(fieldState.value) && !required) {
    fieldError = undefined;
  }

  return render(
    <Form.Item label={label} error={fieldError || (validateOnChange && validateOnChange())} required={required} {...formItemProps}>
      <Tooltip title={(disabled && disabledReason) || tooltip} placement="bottom" {...tooltipProps}>
        <AntdInputNumber
          ref={forwardedRef}
          disabled={disabled}
          value={forceZero && fieldState.value === 0 ? 0 : fieldState.value || initialValue}
          precision={types[type].precision}
          formatter={types[type].formatter}
          parser={types[type].parser}
          className={classNames(
            'input-number',
            { block: true },
            types[type].className,
            !spinner ? 'input-number-nospinner' : null,
            align === 'center' ? 'ant-input-number-input' : '',
            className
          )}
          onChange={changeValue => {
            if (types[type].restrict && !types[type].restrict(changeValue)) {
              return;
            }

            fieldApi.setValue(changeValue);

            if (onChange) {
              onChange(changeValue);
            }
          }}
          onBlur={event => {
            fieldApi.setTouched();

            if (onBlur) {
              onBlur(event);
            }
          }}
          {...rest}
        />
      </Tooltip>
    </Form.Item>
  );
});

InputNumber.defaultProps = {
  type: 'integer',
};

InputNumber.propTypes = {
  type: PropTypes.oneOf(['integer', 'number', 'currency', 'decimal', 'percentage', 'precision']),
};

export default InputNumber;
