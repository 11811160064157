import React from 'react';
import { Modal, Tabs } from 'antd';
import PubSub from 'pubsub-js';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { Link } from '~/components/general';
import { Strings } from '~/helpers/general';
import { releaseNote, about } from '~/options/general/menus';
import { CustomerService } from '~/services';

const About = React.lazy(() =>
  // eslint-disable-next-line import/no-unresolved
  import('~/layouts/general/BasicLayout/components/Logo/components/About')
);

class Logo extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isVisible: false,
      activeTab: about.idt,
      aboutToken: undefined,
      releaseNoteToken: undefined,
    };
  }

  componentDidMount() {
    this.subscribeTokens();
  }

  componentWillUnmount() {
    this.unsubscribeTokens();
  }

  subscribeTokens = () => {
    const aboutToken = PubSub.subscribe(Strings.PUBSUB_ABOUT, () => this.handlePubSub(about.idt));
    const releaseNoteToken = PubSub.subscribe(Strings.PUBSUB_RELEASE_NOTE, () => this.handlePubSub(releaseNote.idt));

    this.setState({ aboutToken, releaseNoteToken });
  };

  unsubscribeTokens = () => {
    const { aboutToken, releaseNoteToken } = this.state;

    PubSub.unsubscribe(aboutToken);
    PubSub.unsubscribe(releaseNoteToken);
  };

  handlePubSub = activeKey => {
    this.setState({ isVisible: true, activeTab: activeKey });
  };

  renderTab = menu => {
    return menu.nome;
  };

  render() {
    const { showName = true, imgProps, isMobile, dispatch, ...rest } = this.props;
    const { isVisible, activeTab } = this.state;

    return (
      <>
        <Modal
          visible={isVisible}
          centered
          onCancel={() => this.setState({ isVisible: false })}
          width={800}
          bodyStyle={{ maxHeight: '455px', overflow: 'auto' }}
          title={Strings.APP_NAME}
          footer={null}
          destroyOnClose
        >
          <Tabs tabPosition="left" activeKey={activeTab} defaultActiveKey={activeTab} onChange={activeKey => this.setState({ activeTab: activeKey })}>
            <React.Suspense fallback={null}>
              <About />
            </React.Suspense>
          </Tabs>
        </Modal>
        <Link onClick={() => this.setState({ isVisible: true })} {...rest}>
          <img src={CustomerService.getCustomer().logo} alt="" {...imgProps} />
        </Link>
        {showName && <h1>{CustomerService.getCustomer().nome.replace('REACT', '')}</h1>}
      </>
    );
  }
}

const isMobileSelector = createSelector(
  general => general,
  ({ isMobile }) => isMobile
);

const mapStateToProps = ({ general }) => ({ isMobile: isMobileSelector(general) });

export default connect(mapStateToProps)(Logo);
