import React from 'react';
import { Card, Col, Row, Alert, Modal } from 'antd';
import { UserOutlined, LockOutlined, LoginOutlined } from '@ant-design/icons';
import { Form, Input, Button, Checkbox, Link, Divider } from '~/components';
import { UserLayout } from '~/layouts';
import { CustomerService, MenuService, RememberService, StoreService, UserService } from '~/services/general';
import { isRequired } from '~/utils';
import SelecionarClienteView from './SelecionarClienteView';

class LoginPage extends React.PureComponent {
  constructor() {
    super();

    this.state = {
      isLoading: false,
      errorCode: undefined,
      empresa: '',
      clientes: [],
    };
  }

  componentDidMount() {
    this.usuarioInput.focus();
    if (document.URL.indexOf('localhost') === -1) {
      UserService.verifica_versao(response => {
        if (response.versao === 'N') {
          window.location.replace(`${process.env.REACT_APP_URL}`);
        } else this.handleRemember();
      });
    } else this.handleRemember();
  }

  handleRemember = () => {
    const remember = RememberService.getRemember();

    if (remember.usuario && remember.senha) {
      this.loginApi.submitForm();
    }
  };

  handleLogin = values => {
    this.setState({ isLoading: true, errorCode: undefined }, () => {
      const { empresa } = this.state;
      UserService.login({ ...values, empresa }, this.onLogin, this.onLogin);
    });
  };

  onLogin = response => {
    if (response.retorno && response.retorno.length === 0) {
      this.setState({ isLoading: false, errorCode: 'Usuário ou senha incorretos, verifique.' });
    } else {
      this.setState({ isLoading: false, errorCode: response.erro, clientes: response.retorno || [] });
      this.verificaLogin();
    }
  };

  esqueceuSenha = () => {
    const usuario = this.loginApi.getValue('usuario');
    this.setState({ isLoading: true, errorCode: undefined });
    UserService.esqueciMinhaSenha(
      usuario,
      response => {
        this.setState({ isLoading: false, errorCode: response.status === 1 ? undefined : 'Erro ao recuperar senha' });
        if (response.status === 1) {
          Modal.success({
            title: 'Esqueci minha senha',
            content: `Sua senha foi enviada para o e-mail: ${response.email}`,
            centered: true,
          });
        }
      },
      () => {
        this.setState({ isLoading: false, errorCode: 'Erro ao recuperar senha' });
      }
    );
  };

  onSelecionarEmpresa = record => {
    this.setState({ empresa: record.cliente }, () => {
      this.loginApi.submitForm();
    });
  };

  onSelecionarCliente = record => {
    const remember = RememberService.getRemember();
    const customer = CustomerService.getCustomer();
    const values = this.loginApi.getValues();

    UserService.setUser(record);

    if (values.lembrar === 'S') {
      RememberService.setRemember({
        ...(values.usuario === remember.usuario && remember.customer === customer.nome && remember),
        usuario: values.usuario,
        senha: values.senha,
        customer: customer.nome,
        logo_base64: customer.logo_base64,
        lembrar: 'S',
        token: remember.token,
      });
    } else {
      RememberService.resetRemember();
    }

    this.verificaLogin();
  };

  verificaLogin = () => {
    if (UserService.isLogado()) {
      const { history } = this.props;

      history.push('/home');

      StoreService.fetchReload();

      if (UserService.getUser().trocar_senha_portal === 'S') {
        MenuService.open(MenuService.getByTitle('Alterar Senha'), { modal: true });
      }
    }
  };

  renderAlert = () => {
    const { errorCode } = this.state;

    return (
      errorCode && <Alert type="error" closable message={errorCode} style={{ marginBottom: '16px' }} onClose={() => this.setState({ errorCode: undefined })} />
    );
  };

  render() {
    const { isLoading, clientes } = this.state;
    const remember = RememberService.getRemember();

    return (
      <>
        <SelecionarClienteView visible={clientes.length > 0} onOk={this.onSelecionarCliente} itens={clientes} />
        <UserLayout>
          <Row gutter={4} type="flex" align="middle" justify="center" style={{ height: '100%' }}>
            <Col flex="350px">
              <Card>
                <Card.Meta title="Login" />
                <Divider dashed />
                {this.renderAlert()}
                <Form onSubmit={this.handleLogin} getApi={api => (this.loginApi = api)}>
                  {({ formState }) => (
                    <>
                      <Input
                        disabled={isLoading}
                        prefix={<UserOutlined />}
                        size="large"
                        label="Login"
                        field="usuario"
                        type="cpfCnpj"
                        capsLock={false}
                        initialValue={remember.usuario}
                        forwardedRef={ref => (this.usuarioInput = ref)}
                        required
                        validate={value => isRequired(value) && 'Por favor insira nome de usuário!'}
                      />
                      <Input.Password
                        disabled={isLoading}
                        prefix={<LockOutlined />}
                        size="large"
                        label="Senha"
                        field="senha"
                        required
                        capsLock={false}
                        validate={value => isRequired(value) && 'Por favor insira sua senha!'}
                        initialValue={remember.senha}
                        style={{ textAlign: 'center' }}
                      />
                      <Row gutter={4}>
                        <Col md={12}>
                          <Checkbox field="lembrar" style={{ marginTop: '0' }} label="Lembrar" disabled={isLoading} initialValue={remember.lembrar} />
                        </Col>
                        <Col style={{ marginTop: '6px' }} md={12}>
                          <Link onClick={this.esqueceuSenha} disabled={isLoading || !formState.values.usuario || (formState.values.usuario || '').length < 18}>
                            Esqueci minha senha
                          </Link>
                        </Col>
                      </Row>
                      <Button htmlType="submit" block style={{ marginTop: '8px' }} size="large" type="primary" loading={isLoading} icon={<LoginOutlined />}>
                        Login
                      </Button>
                    </>
                  )}
                </Form>
              </Card>
            </Col>
          </Row>
        </UserLayout>
      </>
    );
  }
}

export default LoginPage;
