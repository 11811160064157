import { decimalPlace, stringSorter, integerSorter, currencySorter, dateSorter, affirmativeValue, dateValue } from '~/utils';
import { estados } from '~/options';

const string = {
  id: 'string',
  sorter: (a, b) => stringSorter(a, b),
};

const integer = {
  id: 'integer',
  sorter: (a, b) => integerSorter(a, b),
  align: 'center',
  render: text => (parseInt(text, 10) > 0 ? text : ''),
};

const document = {
  id: 'document',
  sorter: (a, b) => stringSorter(a, b),
  align: 'center',
};

const id = {
  id: 'id',
  sorter: (a, b) => stringSorter(a, b),
  align: 'center',
};

const affirmative = {
  id: 'affirmative',
  sorter: (a, b) => stringSorter(a, b),
  align: 'center',
  render: text => affirmativeValue(text),
};

const currency = {
  id: 'currency',
  sorter: (a, b) => currencySorter(a, b),
  align: 'right',
  render: text => (text === '' ? '' : decimalPlace(text, 2)),
};

const decimal = {
  id: 'decimal',
  sorter: (a, b) => currencySorter(a, b),
  align: 'right',
  render: text => (text === '' ? '' : decimalPlace(text, 3)),
};

const precision = {
  id: 'precision',
  sorter: (a, b) => currencySorter(a, b),
  align: 'right',
  render: text => (text === '' ? '' : decimalPlace(text, 4)),
};

const empty = {
  id: 'empty',
};

const date = {
  id: 'date',
  sorter: (a, b) => dateSorter(a, b),
  align: 'center',
  render: text => (text === null || text === '0000-00-00' || text === '0000-00-00 00:00:00' ? null : dateValue(text)),
};

const cnpj = {
  id: 'cnpj',
  sorter: (a, b) => stringSorter(a, b),
  align: 'center',
  render: text => (text ? text.replace(/[^0-9]/g, '').replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/g, '$1.$2.$3/$4-$5') : text),
};

const yearMonth = {
  id: 'yearMonth',
  sorter: (a, b) => stringSorter(a, b),
  align: 'center',
  render: text => {
    if (text.length === 4) {
      return `${text.substr(2, 2)}/20${text.substr(0, 2)}`;
    }
    if (text.length === 6) {
      return `${text.substr(4, 2)}/${text.substr(0, 4)}`;
    }
    if (text.length > 6) {
      return `${text.substr(5, 2)}/${text.substr(0, 4)}`;
    }
    return text;
  },
};

const codigoUF = {
  id: 'codigoUF',
  sorter: (a, b) => stringSorter(a, b),
  align: 'center',
  render: text => (text ? estados.find(item => item.codigo === text).label : ''),
};

export default { integer, string, document, id, affirmative, currency, decimal, precision, empty, date, cnpj, yearMonth, codigoUF };
