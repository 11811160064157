// Sergipe
const se = [
  { data: '2800100', label: 'Amparo de São Francisco' },
  { data: '2800209', label: 'Aquidabã' },
  { data: '2800308', label: 'Aracaju' },
  { data: '2800407', label: 'Arauá' },
  { data: '2800506', label: 'Areia Branca' },
  { data: '2800605', label: 'Barra dos Coqueiros' },
  { data: '2800670', label: 'Boquim' },
  { data: '2800704', label: 'Brejo Grande' },
  { data: '2801009', label: 'Campo do Brito' },
  { data: '2801108', label: 'Canhoba' },
  { data: '2801207', label: 'Canindé de São Francisco' },
  { data: '2801306', label: 'Capela' },
  { data: '2801405', label: 'Carira' },
  { data: '2801504', label: 'Carmópolis' },
  { data: '2801603', label: 'Cedro de São João' },
  { data: '2801702', label: 'Cristinápolis' },
  { data: '2801900', label: 'Cumbe' },
  { data: '2802007', label: 'Divina Pastora' },
  { data: '2802106', label: 'Estância' },
  { data: '2802205', label: 'Feira Nova' },
  { data: '2802304', label: 'Frei Paulo' },
  { data: '2802403', label: 'Gararu' },
  { data: '2802502', label: 'General Maynard' },
  { data: '2802601', label: 'Gracho Cardoso' },
  { data: '2802700', label: 'Ilha das Flores' },
  { data: '2802809', label: 'Indiaroba' },
  { data: '2802908', label: 'Itabaiana' },
  { data: '2803005', label: 'Itabaianinha' },
  { data: '2803104', label: 'Itabi' },
  { data: '2803203', label: "Itaporanga d'Ajuda" },
  { data: '2803302', label: 'Japaratuba' },
  { data: '2803401', label: 'Japoatã' },
  { data: '2803500', label: 'Lagarto' },
  { data: '2803609', label: 'Laranjeiras' },
  { data: '2803708', label: 'Macambira' },
  { data: '2803807', label: 'Malhada dos Bois' },
  { data: '2803906', label: 'Malhador' },
  { data: '2804003', label: 'Maruim' },
  { data: '2804102', label: 'Moita Bonita' },
  { data: '2804201', label: 'Monte Alegre de Sergipe' },
  { data: '2804300', label: 'Muribeca' },
  { data: '2804409', label: 'Neópolis' },
  { data: '2804458', label: 'Nossa Senhora Aparecida' },
  { data: '2804508', label: 'Nossa Senhora da Glória' },
  { data: '2804607', label: 'Nossa Senhora das Dores' },
  { data: '2804706', label: 'Nossa Senhora de Lourdes' },
  { data: '2804805', label: 'Nossa Senhora do Socorro' },
  { data: '2804904', label: 'Pacatuba' },
  { data: '2805000', label: 'Pedra Mole' },
  { data: '2805109', label: 'Pedrinhas' },
  { data: '2805208', label: 'Pinhão' },
  { data: '2805307', label: 'Pirambu' },
  { data: '2805406', label: 'Poço Redondo' },
  { data: '2805505', label: 'Poço Verde' },
  { data: '2805604', label: 'Porto da Folha' },
  { data: '2805703', label: 'Propriá' },
  { data: '2805802', label: 'Riachão do Dantas' },
  { data: '2805901', label: 'Riachuelo' },
  { data: '2806008', label: 'Ribeirópolis' },
  { data: '2806107', label: 'Rosário do Catete' },
  { data: '2806206', label: 'Salgado' },
  { data: '2806305', label: 'Santa Luzia do Itanhy' },
  { data: '2806503', label: 'Santa Rosa de Lima' },
  { data: '2806404', label: 'Santana do São Francisco' },
  { data: '2806602', label: 'Santo Amaro das Brotas' },
  { data: '2806701', label: 'São Cristóvão' },
  { data: '2806800', label: 'São Domingos' },
  { data: '2806909', label: 'São Francisco' },
  { data: '2807006', label: 'São Miguel do Aleixo' },
  { data: '2807105', label: 'Simão Dias' },
  { data: '2807204', label: 'Siriri' },
  { data: '2807303', label: 'Telha' },
  { data: '2807402', label: 'Tobias Barreto' },
  { data: '2807501', label: 'Tomar do Geru' },
  { data: '2807600', label: 'Umbaúba' },
];

export default se;
