const groupBy = (array, groupedProps, sumProps, keyFn = JSON.stringify) => {
  const map = new Map();

  for (const elem of array) {
    const grouped = pick(elem, groupedProps);
    const sum = pick(elem, sumProps);

    const key = keyFn(grouped);

    const [, currentSum] = map.get(key) || [];
    const nextSum = merge(currentSum, sum);
    map.set(key, [grouped, nextSum]);
  }

  const result = [];
  map.forEach(value => {
    const [grouped, sum] = value;
    result.push({ ...grouped, ...sum });
  });
  return result;
};

const pick = (obj, props) => {
  const newObj = {};
  if (props) {
    let index = -1;
    const arrProps = props.split(',');
    while (++index < arrProps.length) {
      const key = arrProps[index];
      newObj[key] = obj[key];
    }
  }
  return newObj;
};

const merge = (target, source) => {
  const newTarget = { ...target };

  for (let [key, value] of Object.entries(source)) {
    newTarget[key] = newTarget[key] || 0;
    if (typeof value !== 'number') {
      value = parseInt(value, 10);
    }
    newTarget[key] += value || 0;
  }
  return newTarget;
};

export default groupBy;
