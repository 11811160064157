import React from 'react';
import { ClearOutlined, PushpinOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { MenuService, RememberService } from '~/services';

class PinPage extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isFixed: false,
    };
  }

  componentDidMount() {
    this.updateIcon();
  }

  componentDidUpdate(prevProps) {
    const { tabs, clickParent } = this.props;
    const { isFixed } = this.state;

    if (prevProps.tabs.activeTab !== tabs.activeTab) {
      this.updateIcon();
    }
    if (prevProps.clickParent !== clickParent) {
      if (isFixed) {
        this.removerPage();
      } else {
        this.fixarPage();
      }
    }
  }

  updateIcon = () => {
    const { tabs } = this.props;

    this.forceUpdate(() => {
      const menuSelected = MenuService.getByTitle((tabs.data.find(item => item.id === tabs.activeTab) || {}).nome);
      this.setState({ isFixed: RememberService.getRemember().page_fixed === (menuSelected.idt || -1) });
    });
  };

  fixarPage = () => {
    const { tabs } = this.props;

    const menuSelected = MenuService.getByTitle((tabs.data.find(item => item.id === tabs.activeTab) || {}).nome);
    if (menuSelected) {
      this.setState({ isFixed: true });
      RememberService.setRemember({ ...RememberService.getRemember(), page_fixed: menuSelected.idt });
    }
  };

  removerPage = () => {
    this.setState({ isFixed: false });
    RememberService.setRemember({ ...RememberService.getRemember(), page_fixed: undefined });
  };

  render() {
    const { isFixed } = this.state;

    return isFixed ? (
      <ClearOutlined title="Remover ao Logar" onClick={this.removerPage} />
    ) : (
      <PushpinOutlined title="Fixar ao Logar" onClick={this.fixarPage} />
    );
  }
}

const mapStateToProps = ({ tabs }) => ({ tabs });
export default connect(mapStateToProps)(PinPage);
