import axios from 'axios';
import { notification } from 'antd';
import { postalValue, notifyError } from '~/utils';

const buscaCep = (value, onSuccess, onError) => {
  const cep = value.replace(/\D/g, '');
  axios({
    method: 'get',
    url: `https://viacep.com.br/ws/${cep}/json/`,
    data: { cep },
  })
    .then(({ data }) => {
      if (data.erro) {
        notification.error({
          message: 'Erro',
          description: 'CEP inválido ou serviço fora do ar.',
        });
        if (onError) {
          onError();
        }
      } else {
        const dataCep = { ...data, cep: postalValue(data.cep), logradouro: data.logradouro.toUpperCase(), bairro: data.bairro.toUpperCase() };

        if (onSuccess) {
          onSuccess(dataCep);
        }
      }
    })
    .catch(error => {
      notifyError(error);
    });
};

export default buscaCep;
