import React from 'react';
import _ from 'lodash';
import { execFilter } from './functionsCharts';
import { Table } from '..';
import { decimalPlace, groupBy } from '~/utils';

class TableDashboard extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  renderTotal = item => {
    const { data, listColunas } = this.props;

    const valorSelected = listColunas.find(itemFind => itemFind.data === item);
    let totalRodape = 0;

    if (valorSelected && !valorSelected.Type) totalRodape = data.reduce((previous, current) => parseInt(current[item], 10) + previous, 0);
    else if (valorSelected && valorSelected.Type === 'decimal(10,3)') {
      totalRodape = decimalPlace(
        data.reduce((previous, current) => parseFloat(current[item] || 0) + previous, 0),
        3
      );
    } else
      totalRodape = decimalPlace(
        data.reduce((previous, current) => parseFloat(current[item] || 0) + previous, 0),
        2
      );

    return <span key={valorSelected.label}>{`${valorSelected.label}: ${totalRodape}`}</span>;
  };

  render() {
    const { data, record, listColunas, functionAction } = this.props;
    const valores = JSON.parse(atob(record.valores));
    const filtros = JSON.parse(atob(record.filtros));

    // CRIAR COLUNAS
    const baseColumns =
      valores.colunas_tabela && valores.colunas_tabela.length > 0
        ? valores.colunas_tabela.map(itemFind => listColunas.find(item => item.data === itemFind)).filter(item => item)
        : [];
    const showColumns = baseColumns.map(item => ({
      dataIndex: item.data,
      title: item.label,
      type:
        item.Type.indexOf('int') > -1
          ? 'integer'
          : item.Type.indexOf('decimal') > -1 && item.Type.indexOf(',2') > -1
          ? 'currency'
          : item.Type.indexOf('decimal') > -1 && item.Type.indexOf(',3') > -1
          ? 'decimal'
          : 'string',
      editable: item.editable === 'S',
      functionAction: functionAction || null,
      ellipsis: true,
    }));

    // AGRUPAR DATASOURCE
    let dataSourceTable = execFilter(data, filtros);
    const colunasAgruparSoma = baseColumns
      .filter(item => item.sum_group === 'S')
      .map(item => item.Field)
      .join();

    if (valores.agrupar_tabela && valores.agrupar_tabela.length > 0) {
      dataSourceTable = groupBy(dataSourceTable, valores.agrupar_tabela.join(), colunasAgruparSoma);
    }

    const listCalcs = baseColumns.filter(itemFilter => itemFilter.calc_sub);
    if (listCalcs.length > 0) {
      dataSourceTable = _.map(dataSourceTable, item => {
        const newItem = { ...item };
        listCalcs.forEach(itemCalc => {
          const arrFields = itemCalc.calc_sub.split(',');
          newItem[itemCalc.Field] = parseFloat(newItem[arrFields[0]]) - parseFloat(newItem[arrFields[1]]);
        });

        return newItem;
      });
    }

    const rowKey = valores.agrupar_tabela && valores.agrupar_tabela.length > 0 ? valores.agrupar_tabela[0] : 'uuid';

    const props = {};
    if (valores.paginacao_tabela === 'N') {
      let scrollY = dataSourceTable.length * 40 + 4;
      if (scrollY > 314) {
        scrollY = 314;
      }
      props.scroll = { y: scrollY };
      props.footer = null;
      props.pagination = { hideOnSinglePage: true, pageSize: dataSourceTable.length };
    }

    return (
      <>
        <Table dataSource={dataSourceTable} size="small" rowKey={rowKey} {...props} removerFiltro>
          {showColumns
            .filter(item => !item.hide)
            .map(item => (
              <Table.Column key={item.dataIndex} {...item} />
            ))}
        </Table>
        {valores.totalizar_tabela && Array.isArray(valores.totalizar_tabela) && valores.totalizar_tabela.length > 0 ? (
          <div className="label-total-table">{valores.totalizar_tabela.map(item => this.renderTotal(item))}</div>
        ) : null}
      </>
    );
  }
}

export default TableDashboard;
