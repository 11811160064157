import { HttpService } from '~/helpers';
import { UserService } from '~/services';

const endpoint = 'movimento/recado_motorista';

const formatFilters = filters =>
  filters
    ? [
        { nome_campo: 'cliente', valor: UserService.getUser().codigo, tipo: 'int' },
        { nome_campo: 'placa', valor: filters.placa ? filters.placa.replace('-', '') : '', tipo: 'texto' },
        { nome_campo: 'cnpj', valor: filters.cpf_cnpj, tipo: 'texto' },
        { nome_campo: 'data', valor: filters.inicial && filters.final ? `${filters.inicial} AND ${filters.final}` : '', tipo: 'datas' },
      ]
    : [];

const formatValues = values => ({
  ...values,
  usuario: UserService.getUser().login,
  cliente: UserService.getUser().codigo,
});

const RecadoMotoristaService = {
  getAll: (values, onSuccess, onError) => {
    HttpService.call(endpoint, 'getAll', [formatFilters(values), values.excluido], onSuccess, onError);
  },
  incluir: (values, onSuccess, onError) => {
    HttpService.call(endpoint, 'incluir', [formatValues(values)], onSuccess, onError);
  },
  alterar: (values, onSuccess, onError) => {
    HttpService.call(endpoint, 'alterar', [formatValues(values)], onSuccess, onError);
  },
  excluir: (codigo, onSuccess, onError) => {
    HttpService.call(endpoint, 'excluir', [codigo, UserService.getUser().login, UserService.getUser().codigo], onSuccess, onError);
  },
  excluirSelecionados: (values, onSuccess, onError) => {
    HttpService.call(endpoint, 'excluirSelecionados', [values, UserService.getUser().login, UserService.getUser().codigo], onSuccess, onError);
  },
};

export default RecadoMotoristaService;
