// Rio de Janeiro
const rj = [
  { data: '3300100', label: 'Angra dos Reis' },
  { data: '3300159', label: 'Aperibé' },
  { data: '3300209', label: 'Araruama' },
  { data: '3300225', label: 'Areal' },
  { data: '3300233', label: 'Armação dos Búzios' },
  { data: '3300258', label: 'Arraial do Cabo' },
  { data: '3300308', label: 'Barra do Piraí' },
  { data: '3300407', label: 'Barra Mansa' },
  { data: '3300456', label: 'Belford Roxo' },
  { data: '3300506', label: 'Bom Jardim' },
  { data: '3300605', label: 'Bom Jesus do Itabapoana' },
  { data: '3300704', label: 'Cabo Frio' },
  { data: '3300803', label: 'Cachoeiras de Macacu' },
  { data: '3300902', label: 'Cambuci' },
  { data: '3301009', label: 'Campos dos Goytacazes' },
  { data: '3301108', label: 'Cantagalo' },
  { data: '3300936', label: 'Carapebus' },
  { data: '3301157', label: 'Cardoso Moreira' },
  { data: '3301207', label: 'Carmo' },
  { data: '3301306', label: 'Casimiro de Abreu' },
  { data: '3300951', label: 'Comendador Levy Gasparian' },
  { data: '3301405', label: 'Conceição de Macabu' },
  { data: '3301504', label: 'Cordeiro' },
  { data: '3301603', label: 'Duas Barras' },
  { data: '3301702', label: 'Duque de Caxias' },
  { data: '3301801', label: 'Engenheiro Paulo de Frontin' },
  { data: '3301850', label: 'Guapimirim' },
  { data: '3301876', label: 'Iguaba Grande' },
  { data: '3301900', label: 'Itaboraí' },
  { data: '3302007', label: 'Itaguaí' },
  { data: '3302056', label: 'Italva' },
  { data: '3302106', label: 'Itaocara' },
  { data: '3302205', label: 'Itaperuna' },
  { data: '3302254', label: 'Itatiaia' },
  { data: '3302270', label: 'Japeri' },
  { data: '3302304', label: 'Laje do Muriaé' },
  { data: '3302403', label: 'Macaé' },
  { data: '3302452', label: 'Macuco' },
  { data: '3302502', label: 'Magé' },
  { data: '3302601', label: 'Mangaratiba' },
  { data: '3302700', label: 'Maricá' },
  { data: '3302809', label: 'Mendes' },
  { data: '3302858', label: 'Mesquita' },
  { data: '3302908', label: 'Miguel Pereira' },
  { data: '3303005', label: 'Miracema' },
  { data: '3303104', label: 'Natividade' },
  { data: '3303203', label: 'Nilópolis' },
  { data: '3303302', label: 'Niterói' },
  { data: '3303401', label: 'Nova Friburgo' },
  { data: '3303500', label: 'Nova Iguaçu' },
  { data: '3303609', label: 'Paracambi' },
  { data: '3303708', label: 'Paraíba do Sul' },
  { data: '3303807', label: 'Parati' },
  { data: '3303856', label: 'Paty do Alferes' },
  { data: '3303906', label: 'Petrópolis' },
  { data: '3303955', label: 'Pinheiral' },
  { data: '3304003', label: 'Piraí' },
  { data: '3304102', label: 'Porciúncula' },
  { data: '3304110', label: 'Porto Real' },
  { data: '3304128', label: 'Quatis' },
  { data: '3304144', label: 'Queimados' },
  { data: '3304151', label: 'Quissamã' },
  { data: '3304201', label: 'Resende' },
  { data: '3304300', label: 'Rio Bonito' },
  { data: '3304409', label: 'Rio Claro' },
  { data: '3304508', label: 'Rio das Flores' },
  { data: '3304524', label: 'Rio das Ostras' },
  { data: '3304557', label: 'Rio de Janeiro' },
  { data: '3304607', label: 'Santa Maria Madalena' },
  { data: '3304706', label: 'Santo Antônio de Pádua' },
  { data: '3304805', label: 'São Fidélis' },
  { data: '3304755', label: 'São Francisco de Itabapoana' },
  { data: '3304904', label: 'São Gonçalo' },
  { data: '3305000', label: 'São João da Barra' },
  { data: '3305109', label: 'São João de Meriti' },
  { data: '3305133', label: 'São José de Ubá' },
  { data: '3305158', label: 'São José do Vale do Rio Preto' },
  { data: '3305208', label: 'São Pedro da Aldeia' },
  { data: '3305307', label: 'São Sebastião do Alto' },
  { data: '3305406', label: 'Sapucaia' },
  { data: '3305505', label: 'Saquarema' },
  { data: '3305554', label: 'Seropédica' },
  { data: '3305604', label: 'Silva Jardim' },
  { data: '3305703', label: 'Sumidouro' },
  { data: '3305752', label: 'Tanguá' },
  { data: '3305802', label: 'Teresópolis' },
  { data: '3305901', label: 'Trajano de Morais' },
  { data: '3306008', label: 'Três Rios' },
  { data: '3306107', label: 'Valença' },
  { data: '3306156', label: 'Varre-Sai' },
  { data: '3306206', label: 'Vassouras' },
  { data: '3306305', label: 'Volta Redonda' },
];

export default rj;
