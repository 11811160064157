// Rondônia
const ro = [
  { data: '1100015', label: "Alta Floresta D'Oeste" },
  { data: '1100379', label: 'Alto Alegre dos Parecis' },
  { data: '1100403', label: 'Alto Paraíso' },
  { data: '1100346', label: "Alvorada D'Oeste" },
  { data: '1100023', label: 'Ariquemes' },
  { data: '1100452', label: 'Buritis' },
  { data: '1100031', label: 'Cabixi' },
  { data: '1100601', label: 'Cacaulândia' },
  { data: '1100049', label: 'Cacoal' },
  { data: '1100700', label: 'Campo Novo de Rondônia' },
  { data: '1100809', label: 'Candeias do Jamari' },
  { data: '1100908', label: 'Castanheiras' },
  { data: '1100056', label: 'Cerejeiras' },
  { data: '1100924', label: 'Chupinguaia' },
  { data: '1100064', label: 'Colorado do Oeste' },
  { data: '1100072', label: 'Corumbiara' },
  { data: '1100080', label: 'Costa Marques' },
  { data: '1100940', label: 'Cujubim' },
  { data: '1100098', label: "Espigão D'Oeste" },
  { data: '1101005', label: 'Governador Jorge Teixeira' },
  { data: '1100106', label: 'Guajará-Mirim' },
  { data: '1101104', label: 'Itapuã do Oeste' },
  { data: '1100114', label: 'Jaru' },
  { data: '1100122', label: 'Ji-Paraná' },
  { data: '1100130', label: "Machadinho D'Oeste" },
  { data: '1101203', label: 'Ministro Andreazza' },
  { data: '1101302', label: 'Mirante da Serra' },
  { data: '1101401', label: 'Monte Negro' },
  { data: '1100148', label: "Nova Brasilândia D'Oeste" },
  { data: '1100338', label: 'Nova Mamoré' },
  { data: '1101435', label: 'Nova União' },
  { data: '1100502', label: 'Novo Horizonte do Oeste' },
  { data: '1100155', label: 'Ouro Preto do Oeste' },
  { data: '1101450', label: 'Parecis' },
  { data: '1100189', label: 'Pimenta Bueno' },
  { data: '1101468', label: 'Pimenteiras do Oeste' },
  { data: '1100205', label: 'Porto Velho' },
  { data: '1100254', label: 'Presidente Médici' },
  { data: '1101476', label: 'Primavera de Rondônia' },
  { data: '1100262', label: 'Rio Crespo' },
  { data: '1100288', label: 'Rolim de Moura' },
  { data: '1100296', label: "Santa Luzia D'Oeste" },
  { data: '1101484', label: "São Felipe D'Oeste" },
  { data: '1101492', label: 'São Francisco do Guaporé' },
  { data: '1100320', label: 'São Miguel do Guaporé' },
  { data: '1101500', label: 'Seringueiras' },
  { data: '1101559', label: 'Teixeirópolis' },
  { data: '1101609', label: 'Theobroma' },
  { data: '1101708', label: 'Urupá' },
  { data: '1101757', label: 'Vale do Anari' },
  { data: '1101807', label: 'Vale do Paraíso' },
  { data: '1100304', label: 'Vilhena' },
];

export default ro;
