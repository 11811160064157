import { Col, Row } from 'antd';
import _ from 'lodash';
import React from 'react';
import { Page, Table, Button, Form, ContentWrapper, Select, IconDelete } from '~/components';
import { UserService } from '~/services';
import { stringSorter } from '~/utils';
import EditExportTableInsertView from './EditExportTableInsertView';

class EditExportTableView extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      columns: [],
      itensColunas: [],
      showIncluirLayout: false,
      layouts: [],
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.getLayouts();
    }, 500);
  }

  componentDidUpdate(prevProps) {
    const { action, dataSource, columns } = this.props;
    const actionChange = action !== prevProps.action;

    if (actionChange && action) {
      if (dataSource.length > 0) {
        const otherColumns = [];
        Object.keys(dataSource[0])
          .filter(item => !columns.find(itemFind => itemFind.dataIndex === item))
          .forEach(key => {
            otherColumns.push({
              dataIndex: key,
              title: _.capitalize(_.startCase(key)),
              ellipsis: true,
              width: '80px',
              type: 'string',
            });
          });

        const newColumns = [
          ...columns
            .filter(item => typeof item.title !== typeof {})
            .map(item => ({ dataIndex: item.dataIndex, title: item.title, type: item.type || 'string', ellipsis: true, width: '80px' })),
          ...otherColumns,
        ];

        this.searchApi.setValue(
          'colunas',
          newColumns.map(item => item.dataIndex)
        );

        this.forceUpdate(() => {
          this.setState({
            columns: newColumns,
            itensColunas: newColumns.map(item => ({ data: item.dataIndex, label: item.title })).sort((a, b) => stringSorter(a.label, b.label)),
          });
        });
      }
    }
  }

  getLayouts = () => {
    UserService.getLayoutTableReact(document.title, layouts => this.setState({ layouts }));
  };

  onSaveLayout = values => {
    const valuesForm = this.searchApi.getValues();

    this.setState({ isLoading: true, showIncluirLayout: false });
    UserService.insertLayoutTableReact(
      { ...values, tipo: document.title, colunas: valuesForm.colunas.join() },
      () =>
        this.setState({ isLoading: false }, () => {
          this.getLayouts();
        }),
      this.setState({ isLoading: false })
    );
  };

  setLayout = layout => {
    const { layouts } = this.state;
    if (layout) {
      const layoutSelected = layouts.find(item => item.data === layout);
      if (layoutSelected) {
        this.searchApi.setValue('colunas', layoutSelected.colunas.split(','));
      }
    }
    this.forceUpdate();
  };

  excluirLayout = () => {
    const values = this.searchApi.getValues();

    this.setState({ isLoading: true });
    this.searchApi.setValue('layout', undefined);
    UserService.handleDeleteLayout(
      values.layout,
      () => {
        this.setState({ isLoading: false });
        this.getLayouts();
      },
      () => this.setState({ isLoading: false })
    );
  };

  render = () => {
    const { onCancel, action, dataSource, rowKey } = this.props;
    const { columns, itensColunas, showIncluirLayout, layouts, isLoading } = this.state;

    const valuesForms = this.searchApi ? this.searchApi.getValues() : {};
    const showColumns =
      valuesForms.colunas && valuesForms.colunas.length > 0 && columns.length > 0
        ? valuesForms.colunas.map(itemFind => columns.find(item => item.dataIndex === itemFind))
        : [];

    return (
      <>
        <Page.Modal
          visible={action}
          title="Exportar Dados"
          width={1000}
          onCancel={onCancel}
          forceRender
          centered
          keyboard={false}
          destroyOnClose
          footer={
            <div style={{ width: '100%', display: 'flex' }}>
              <div style={{ width: '100%', textAlign: 'left' }}>
                <Button.Save
                  disabled={!valuesForms || !valuesForms.colunas || (valuesForms && valuesForms.colunas && valuesForms.colunas.length === 0)}
                  onClick={() => this.setState({ showIncluirLayout: true })}
                >
                  Salvar Layout
                </Button.Save>
              </div>
              <div style={{ width: '100%', textAlign: 'right' }}>
                <Button onClick={onCancel}>Fechar</Button>
              </div>
            </div>
          }
        >
          <EditExportTableInsertView action={showIncluirLayout} onCancel={() => this.setState({ showIncluirLayout: false })} onOk={this.onSaveLayout} />
          <Form getApi={api => (this.searchApi = api)}>
            <ContentWrapper type="search">
              <Row gutter={4}>
                <Col md={18}>
                  <Select field="colunas" label="Colunas" maxTagCount={10} dataSource={itensColunas} mode="multiple" onChange={() => this.forceUpdate()} />
                </Col>
                <Col md={5}>
                  <Select field="layout" label="Layouts" dataSource={layouts} onChange={this.setLayout} />
                </Col>
                <Col md={1}>
                  <IconDelete
                    style={{ marginTop: '30px' }}
                    onClick={this.excluirLayout}
                    className={!valuesForms.layout ? 'icon-disabled' : ''}
                    description="Layout"
                  />
                </Col>
              </Row>
            </ContentWrapper>
          </Form>
          <Table
            dataSource={dataSource}
            size="small"
            loading={isLoading}
            rowKey={rowKey}
            scroll={{ x: showColumns.filter(item => item && !item.hide).length * 160 }}
            hideExport
          >
            {showColumns
              .filter(item => item && !item.hide)
              .map(item => (
                <Table.Column key={item.dataIndex} {...item} />
              ))}
          </Table>
        </Page.Modal>
      </>
    );
  };
}

export default EditExportTableView;
