import React from 'react';
import { Chart, Axis, Tooltip, Geom } from 'bizcharts';
import { decimalPlace } from '~/utils';

class Bar extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      autoHideXLabels: false,
    };
  }

  componentDidMount() {
    this.resize();
    window.addEventListener('resize', this.resize, { passive: true });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }

  resize = () => {
    if (!this.node) {
      return;
    }

    // eslint-disable-next-line
    const { data = [], autoLabel = true } = this.props;

    if (!autoLabel) {
      return;
    }

    const canvasWidth = this.node.parentNode.clientWidth;
    const minWidth = data.length * 30;
    const { autoHideXLabels } = this.state;

    if (canvasWidth <= minWidth) {
      if (!autoHideXLabels) {
        this.setState({ autoHideXLabels: !autoHideXLabels });
      }
    } else if (autoHideXLabels) {
      this.setState({ autoHideXLabels: !autoHideXLabels });
    }
  };

  render() {
    // eslint-disable-next-line
    const { height, title, forceFit, data, color, padding, type = 'currency' } = this.props;
    const { autoHideXLabels } = this.state;
    const scale = { x: { type: 'cat' }, y: { min: 0 } };
    const tooltip = ['x*y', (x, y) => ({ name: x.substr(0, 5), value: type === 'currency' ? decimalPlace(y, 2) : y })];

    return (
      <div style={{ height }}>
        {/* eslint-disable-next-line */}
        <div ref={node => (this.node = node)}>
          {title && <h4 style={{ marginBottom: 20 }}>{title}</h4>}
          <Chart scale={scale} height={title ? height - 41 : height} forceFit={forceFit || true} data={data} padding={padding || 'auto'}>
            <Axis name="x" title={false} label={autoHideXLabels ? false : {}} tickLine={autoHideXLabels ? false : {}} />
            <Axis name="y" min={0} />
            <Tooltip showTitle={false} crosshairs={false} />
            <Geom type="interval" position="x*y" color={color || 'rgba(24, 144, 255, 0.85)'} tooltip={tooltip} />
          </Chart>
        </div>
      </div>
    );
  }
}

export default Bar;
