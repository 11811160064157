import React from 'react';
import { decimalPlace } from '~/utils';
import { Statistic } from '..';
import { execFilter } from './functionsCharts';

class StatisticDashboard extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { data, record, listTotalizar } = this.props;
    const valores = JSON.parse(atob(record.valores));
    const filtros = JSON.parse(atob(record.filtros));
    const totalizarSelected = listTotalizar.find(item => item.data === valores.totalizar);
    const filterDataSource = execFilter(data, filtros);
    const totalCard =
      valores.totalizar === '_count_'
        ? filterDataSource.length
        : filterDataSource.reduce((previous, current) => parseFloat(current[valores.totalizar]) + previous, 0);

    return (
      <Statistic
        value={
          totalizarSelected
            ? totalizarSelected.tipo === 'decimal'
              ? decimalPlace(totalCard, 3)
              : totalizarSelected.tipo === 'integer'
              ? totalCard
              : decimalPlace(totalCard, 2)
            : 0
        }
        color={valores.color || '#ff0000'}
        precision={totalizarSelected ? (totalizarSelected.tipo === 'decimal' ? 3 : totalizarSelected.tipo === 'integer' ? 0 : 2) : 'integer'}
        prefix={totalizarSelected ? (totalizarSelected.tipo === 'decimal' ? 3 : totalizarSelected.tipo === 'integer' ? '' : 'R$ ') : ''}
        className="statistic"
      />
    );
  }
}

export default StatisticDashboard;
