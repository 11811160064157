import React from 'react';
import moment from 'moment';
import KeyboardEventHandler from 'react-keyboard-event-handler';
import { DatePicker as AntdDatePicker } from 'antd';
import classNames from 'classnames';
import { onKeyEnterTableCell } from '~/utils';
import './DatePickerCell.less';

class InputCell extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { type, onChange, onBlur, className, record, field, functionAction, defaultValue, value, enterLinha, required, ...rest } = this.props;

    let format = 'DD/MM/YYYY';
    if (rest.picker === 'month') format = 'MM/YYYY';
    else if (rest.picker === 'year') format = 'YYYY';

    return (
      <KeyboardEventHandler
        handleKeys={['enter']}
        onKeyEvent={(key, e) => {
          onKeyEnterTableCell(e, 'number', enterLinha);
        }}
      >
        <AntdDatePicker
          placeholder=""
          size="small"
          defaultValue={moment(defaultValue) || moment()}
          className={classNames('date-picker', className)}
          dropdownClassName={classNames('date-picker-dropdown')}
          format={format}
          onChange={date => {
            record[field] = date ? date.format('YYYY-MM-DD') : '';

            if (functionAction) {
              functionAction(record, field);
            }
          }}
          allowClear={!required}
          {...rest}
        />
      </KeyboardEventHandler>
    );
  }
}

export default InputCell;
