import React from 'react';
import { Row, Col, message, Divider, Modal } from 'antd';
import { FooterToolbar, Navigator, Page, Form, Input, ContentWrapper, Table, InputNumber, Button, Select, Status, IconDelete, IconUpdate } from '~/components';
import { actions, tabPanes, affirmatives } from '~/options';
import { generateUuid, notifySuccess, upsert, isDuplicated } from '~/utils';
import { VeiculoService } from '~/services';

class VeiculoPage extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      itens: [],
      selectedItem: {},
      action: actions.idle,
      activeTab: tabPanes.table.key,
      itensOdometro: [],
      motivoPlaca: '',
      placaVeiculo: '',
      recadoPlaca: '',
      movimentoPlaca: '',
    };
  }

  componentDidMount() {
    const { params } = this.props;

    if (params) {
      if (params.cliente) {
        this.searchApi.setValue('cliente', params.cliente);
      } else if (params.idt) {
        this.searchApi.setValue('idt', params.idt);
      }
      this.searchApi.submitForm();
    }

    setTimeout(() => this.searchInput.focus(), 300);
  }

  handleSearch = values => {
    this.setState({ isLoading: true, selectedItem: {} });
    VeiculoService.getAll(
      values,
      itens => this.setState({ itens, isLoading: false }),
      () => this.setState({ itens: [], isLoading: false })
    );
  };

  handleDelete = id => {
    const { recadoPlaca, placaVeiculo, movimentoPlaca, motivoPlaca } = this.state;
    if (recadoPlaca === 'S') {
      Modal.error({
        content: `Placa ${placaVeiculo} com recado cadastrado: ${motivoPlaca}.`,
        title: 'Exclusão Veículo',
        centered: true,
      });
    } else if (movimentoPlaca === 'S') {
      Modal.error({
        content: `Placa ${placaVeiculo} com movimento cadastrado, cpf vinculado ao movimento: ${motivoPlaca}.`,
        title: 'Exclusão Veículo',
        centered: true,
      });
    } else {
      this.setState({ isLoading: true });
      VeiculoService.excluir(
        id,
        () => this.onDelete(),
        () => this.onDelete(true)
      );
    }
  };

  onDelete = (error = false) => {
    this.setState({ isLoading: false, selectedItem: {} });

    if (!error) {
      const { name } = this.props;

      this.searchApi.submitForm();
      notifySuccess({ name, action: actions.remove });
    }
  };

  handleSelect = record => {
    this.setState({ selectedItem: record, itensOdometro: [] });
    this.saveApi.reset();
    if (record.idt) {
      this.saveApi.setValues(record);
      VeiculoService.getAllOdometro(
        record.idt,
        itensOdometro => this.setState({ itensOdometro }),
        () => this.setState({ itensOdometro: [] })
      );
      VeiculoService.verificar_movimento_placa({ placa: record.placa || '' }, response => {
        this.setState({ placaVeiculo: record.placa, recadoPlaca: response.recado, movimentoPlaca: response.movimento, motivoPlaca: response.motivo });
      });
    }
  };

  handleEdit = action => {
    const { itensOdometro } = this.state;
    this.setState({ action, activeTab: tabPanes.record.key, itensOdometro: action === actions.insert ? [] : itensOdometro }, () => {
      if (action === actions.insert) {
        setTimeout(() => this.editInput.focus(), 300);
      } else {
        setTimeout(() => this.descricaoInput.focus(), 300);
      }
    });

    if (action === actions.insert) {
      this.saveApi.reset();
      const { params } = this.props;
      if (params && params.cliente) {
        this.saveApi.setValues({ idt_cliente: params.cliente });
      }
    }
  };

  onEdit = (cancel = false) => {
    let { selectedItem, activeTab } = this.state;

    if (cancel) {
      this.handleSelect(selectedItem);

      if (!selectedItem.idt) {
        activeTab = tabPanes.table.key;
      }
    } else {
      this.searchApi.submitForm();

      const { action } = this.state;

      if (action === actions.insert) {
        activeTab = tabPanes.table.key;
        selectedItem = {};
      }
    }

    this.setState({ action: actions.idle, selectedItem, activeTab });
  };

  handleSave = values => {
    const { action, itensOdometro } = this.state;
    let record = {};
    let saveHandler = VeiculoService.inserir;

    if (action === actions.update) {
      const { selectedItem } = this.state;
      record = selectedItem;
      saveHandler = VeiculoService.alterar;
    }

    this.setState({ isLoading: true });
    saveHandler(
      { ...record, ...values, historico: itensOdometro },
      () => this.onSave(),
      () => this.onSave(true)
    );
  };

  onSave = (error = false) => {
    this.setState({ isLoading: false, activeTab: tabPanes.table.key });

    if (!error) {
      const { name } = this.props;
      const { action } = this.state;

      this.onEdit();
      notifySuccess({ name, action });
    }
  };

  handleSaveItem = values => {
    const { itensOdometro } = this.state;
    this.setState({ itensOdometro: upsert(itensOdometro, { uuid: generateUuid(), ...values }) });
  };

  handleTransferencia = cliente => {
    const { selectedRowKeys } = this.state;
    this.setState({ isLoading: true });
    VeiculoService.transferir(
      { cliente, placa: selectedRowKeys.join() },
      () => {
        message.success('Veículos atualizados com sucesso!', 4);
        this.searchApi.setValue('cliente', cliente);
        this.setState({ isLoading: false, selectedRowKeys: [] }, () => this.searchApi.submitForm());
      },
      () => this.setState({ isLoading: false })
    );
  };

  render = () => {
    const { menuId, tabId } = this.props;
    const { isLoading, itens, selectedItem, action, activeTab } = this.state;
    const isEdit = action !== actions.idle && !isLoading;
    const isIdle = action === actions.idle && !isLoading;

    return (
      <>
        <Page
          tabbed
          tabId={tabId}
          closable={isIdle}
          activeKey={activeTab}
          onChange={activeKey => this.setState({ activeTab: activeKey })}
          footer={action !== actions.insert && selectedItem.idt && <Status record={selectedItem} />}
        >
          <Page.TabPane key={tabPanes.table.key} tab={tabPanes.table.tab} disabled={action !== actions.idle}>
            <Form getApi={api => (this.searchApi = api)} onSubmit={this.handleSearch}>
              <ContentWrapper type="search">
                <Row gutter={2}>
                  <Col md={3} span={10}>
                    <Input field="placa" label="Placa" disabled={isLoading} forwardedRef={ref => (this.searchInput = ref)} />
                  </Col>
                  <Col md={18} span={10}>
                    <Input field="descricao" label="Descrição" disabled={isLoading} />
                  </Col>
                  <Col md={3} span={4}>
                    <Button.Search loading={isLoading} />
                  </Col>
                </Row>
              </ContentWrapper>
            </Form>
            <Table
              rowKey="idt"
              loading={isLoading}
              record={selectedItem}
              onRecordChange={this.handleSelect}
              dataSource={itens}
              onRow={{ onDoubleClick: () => this.setState({ activeTab: tabPanes.record.key }) }}
              description={item => (
                <Row>
                  <Col span={16}>
                    <span style={{ fontSize: '0.8rem' }}>{item.descricao}</span>
                  </Col>
                  <Col span={8}>
                    <span>{item.placa}</span>
                  </Col>
                </Row>
              )}
              height={window.innerHeight - 250}
            >
              <Table.Column width="15%" title="Placa" dataIndex="placa" align="center" />
              <Table.Column width="55%" title="Descrição" dataIndex="descricao" />
              <Table.Column width="10%" title="Odômetro" dataIndex="odometro" />
              <Table.Column width="10%" title="Ativo" dataIndex="ativo" type="affirmative" />
              <Table.Column
                width="5%"
                title="Ações"
                align="center"
                dataIndex="actions"
                exporter={false}
                sorter={false}
                render={(text, record) => (
                  <>
                    <IconUpdate
                      onClick={() => {
                        this.handleSelect(record);
                        this.setState({ activeTab: tabPanes.record.key, action: actions.update });
                      }}
                    />
                    <Divider type="vertical" />
                    <IconDelete
                      validate={{ value: record.idt, name: `código: ${record.idt}`, prefix: 'o' }}
                      menuId={menuId}
                      disabled={record.data_exc}
                      prefix=" o registro"
                      description={record.placa}
                      onClick={() => this.handleDelete(record.idt)}
                    />
                  </>
                )}
              />
            </Table>
          </Page.TabPane>
          <Page.TabPane key={tabPanes.record.key} tab={tabPanes.record.tab} disabled={action === actions.idle && !selectedItem.idt}>
            <Form getApi={api => (this.saveApi = api)} onSubmit={this.handleSave}>
              <ContentWrapper type="header" color={action.color}>
                <Row gutter={4}>
                  <Col md={3} span={10}>
                    <Input.Validate
                      field="placa"
                      required
                      label="Placa"
                      type="string"
                      maxLength={8}
                      forwardedRef={ref => (this.editInput = ref)}
                      disabled={!isEdit || action.id !== 'insert'}
                      validate={VeiculoService.getAllPlaca}
                      validator={isDuplicated}
                    />
                  </Col>
                  <Col md={18} span={14}>
                    <Input field="descricao" label="Descrição" disabled={!isEdit} forwardedRef={ref => (this.descricaoInput = ref)} required />
                  </Col>
                  <Col md={3} span={10}>
                    <Select field="ativo" label="Ativo" dataSource={affirmatives} initialValue={affirmatives[0].data} disabled={!isEdit} required />
                  </Col>
                  <Col md={15} span={14}>
                    <Input field="frota" label="Frota" disabled={!isEdit} />
                  </Col>
                  <Col md={3} span={8}>
                    <InputNumber field="odometro" label="Odômetro Inicial" type="integer" disabled={!isEdit} />
                  </Col>
                  <Col md={3} span={8}>
                    <InputNumber field="limite" label="Limite (R$)" type="currency" disabled={!isEdit} />
                  </Col>
                  <Col md={3} span={8}>
                    <InputNumber field="vale" label="Vale (R$)" type="currency" disabled={!isEdit} />
                  </Col>
                </Row>
              </ContentWrapper>
            </Form>
          </Page.TabPane>
        </Page>
        <FooterToolbar
          left={
            <>
              <Button.Insert menuId={menuId} disabled={!isIdle} onClick={() => this.handleEdit(actions.insert)} />
              <Button.Update menuId={menuId} disabled={!selectedItem.idt || selectedItem.data_exc || !isIdle} onClick={() => this.handleEdit(actions.update)} />
            </>
          }
          center={
            <>
              <Navigator
                disabled={!isIdle}
                record={selectedItem}
                dataSource={itens}
                dataIndex="idt"
                onClick={record => this.setState({ selectedItem: record })}
              />
            </>
          }
          right={
            <>
              <Button.Cancel disabled={isIdle || isLoading} onClick={() => this.onEdit(true)} />
              <Button.Save disabled={isIdle || isLoading} loading={action !== actions.idle && isLoading} onClick={() => this.saveApi.submitForm()} />
            </>
          }
        />
      </>
    );
  };
}

export default VeiculoPage;
