// Alagoas
const al = [
  { data: '2700102', label: 'Água Branca' },
  { data: '2700201', label: 'Anadia' },
  { data: '2700300', label: 'Arapiraca' },
  { data: '2700409', label: 'Atalaia' },
  { data: '2700508', label: 'Barra de Santo Antônio' },
  { data: '2700607', label: 'Barra de São Miguel' },
  { data: '2700706', label: 'Batalha' },
  { data: '2700805', label: 'Belém' },
  { data: '2700904', label: 'Belo Monte' },
  { data: '2701001', label: 'Boca da Mata' },
  { data: '2701100', label: 'Branquinha' },
  { data: '2701209', label: 'Cacimbinhas' },
  { data: '2701308', label: 'Cajueiro' },
  { data: '2701357', label: 'Campestre' },
  { data: '2701407', label: 'Campo Alegre' },
  { data: '2701506', label: 'Campo Grande' },
  { data: '2701605', label: 'Canapi' },
  { data: '2701704', label: 'Capela' },
  { data: '2701803', label: 'Carneiros' },
  { data: '2701902', label: 'Chã Preta' },
  { data: '2702009', label: 'Coité do Nóia' },
  { data: '2702108', label: 'Colônia Leopoldina' },
  { data: '2702207', label: 'Coqueiro Seco' },
  { data: '2702306', label: 'Coruripe' },
  { data: '2702355', label: 'Craíbas' },
  { data: '2702405', label: 'Delmiro Gouveia' },
  { data: '2702504', label: 'Dois Riachos' },
  { data: '2702553', label: 'Estrela de Alagoas' },
  { data: '2702603', label: 'Feira Grande' },
  { data: '2702702', label: 'Feliz Deserto' },
  { data: '2702801', label: 'Flexeiras' },
  { data: '2702900', label: 'Girau do Ponciano' },
  { data: '2703007', label: 'Ibateguara' },
  { data: '2703106', label: 'Igaci' },
  { data: '2703205', label: 'Igreja Nova' },
  { data: '2703304', label: 'Inhapi' },
  { data: '2703403', label: 'Jacaré dos Homens' },
  { data: '2703502', label: 'Jacuípe' },
  { data: '2703601', label: 'Japaratinga' },
  { data: '2703700', label: 'Jaramataia' },
  { data: '2703759', label: 'Jequiá da Praia' },
  { data: '2703809', label: 'Joaquim Gomes' },
  { data: '2703908', label: 'Jundiá' },
  { data: '2704005', label: 'Junqueiro' },
  { data: '2704104', label: 'Lagoa da Canoa' },
  { data: '2704203', label: 'Limoeiro de Anadia' },
  { data: '2704302', label: 'Maceió' },
  { data: '2704401', label: 'Major Isidoro' },
  { data: '2704906', label: 'Mar Vermelho' },
  { data: '2704500', label: 'Maragogi' },
  { data: '2704609', label: 'Maravilha' },
  { data: '2704708', label: 'Marechal Deodoro' },
  { data: '2704807', label: 'Maribondo' },
  { data: '2705002', label: 'Mata Grande' },
  { data: '2705101', label: 'Matriz de Camaragibe' },
  { data: '2705200', label: 'Messias' },
  { data: '2705309', label: 'Minador do Negrão' },
  { data: '2705408', label: 'Monteirópolis' },
  { data: '2705507', label: 'Murici' },
  { data: '2705606', label: 'Novo Lino' },
  { data: '2705705', label: "Olho d'Água das Flores" },
  { data: '2705804', label: "Olho d'Água do Casado" },
  { data: '2705903', label: "Olho d'Água Grande" },
  { data: '2706000', label: 'Olivença' },
  { data: '2706109', label: 'Ouro Branco' },
  { data: '2706208', label: 'Palestina' },
  { data: '2706307', label: 'Palmeira dos Índios' },
  { data: '2706406', label: 'Pão de Açúcar' },
  { data: '2706422', label: 'Pariconha' },
  { data: '2706448', label: 'Paripueira' },
  { data: '2706505', label: 'Passo de Camaragibe' },
  { data: '2706604', label: 'Paulo Jacinto' },
  { data: '2706703', label: 'Penedo' },
  { data: '2706802', label: 'Piaçabuçu' },
  { data: '2706901', label: 'Pilar' },
  { data: '2707008', label: 'Pindoba' },
  { data: '2707107', label: 'Piranhas' },
  { data: '2707206', label: 'Poço das Trincheiras' },
  { data: '2707305', label: 'Porto Calvo' },
  { data: '2707404', label: 'Porto de Pedras' },
  { data: '2707503', label: 'Porto Real do Colégio' },
  { data: '2707602', label: 'Quebrangulo' },
  { data: '2707701', label: 'Rio Largo' },
  { data: '2707800', label: 'Roteiro' },
  { data: '2707909', label: 'Santa Luzia do Norte' },
  { data: '2708006', label: 'Santana do Ipanema' },
  { data: '2708105', label: 'Santana do Mundaú' },
  { data: '2708204', label: 'São Brás' },
  { data: '2708303', label: 'São José da Laje' },
  { data: '2708402', label: 'São José da Tapera' },
  { data: '2708501', label: 'São Luís do Quitunde' },
  { data: '2708600', label: 'São Miguel dos Campos' },
  { data: '2708709', label: 'São Miguel dos Milagres' },
  { data: '2708808', label: 'São Sebastião' },
  { data: '2708907', label: 'Satuba' },
  { data: '2708956', label: 'Senador Rui Palmeira' },
  { data: '2709004', label: "Tanque d'Arca" },
  { data: '2709103', label: 'Taquarana' },
  { data: '2709152', label: 'Teotônio Vilela' },
  { data: '2709202', label: 'Traipu' },
  { data: '2709301', label: 'União dos Palmares' },
  { data: '2709400', label: 'Viçosa' },
];

export default al;
