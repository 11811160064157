import React from 'react';
import { Row, Col } from 'antd';
import { connect } from 'react-redux';
import { CustomerService } from '~/services';
import './DashboardPage.less';
import { Page } from '~/components';
import EstoqueTerceiroTableCard from './components/EstoqueTerceiroTableCard';

const RecadoRecebidoCard = React.lazy(() => import('./components/RecadoRecebidoCard'));
const AnotacaoUsuarioCard = React.lazy(() => import('./components/AnotacaoUsuarioCard'));
const DadosUsuarioCard = React.lazy(() => import('./components/DadosUsuarioCard'));
const LogVersaoCard = React.lazy(() => import('./components/LogVersaoCard'));
const PagamentosCard = React.lazy(() => import('./components/PagamentosCard'));
const EstoqueTerceiroCard = React.lazy(() => import('./components/EstoqueTerceiroCard'));
const FrequenciaCard = React.lazy(() => import('./components/FrequenciaCard'));
const PrecoCombustivelCard = React.lazy(() => import('./components/PrecoCombustivelCard'));

class DashboardPage extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
    };
  }

  componentDidMount() {
    this.setState({ isLoading: true });
  }

  render() {
    const { isLoading } = this.state;
    const { tabId, general } = this.props;
    const { isMobile } = general;

    return (
      <Page tabId={tabId} closable={!isLoading}>
        <div className="main">
          <Row gutter={4}>
            <Col md={24} span={24}>
              <React.Suspense fallback={null}>
                <RecadoRecebidoCard />
              </React.Suspense>
            </Col>
            <Col md={8} span={isMobile ? 24 : 8}>
              {CustomerService.getCustomer().posto ? (
                <React.Suspense fallback={null}>
                  <PrecoCombustivelCard />
                </React.Suspense>
              ) : null}
              <React.Suspense fallback={null}>
                <AnotacaoUsuarioCard onResponse={() => this.setState({ isLoading: false })} />
              </React.Suspense>
              <React.Suspense fallback={null}>
                <DadosUsuarioCard />
              </React.Suspense>
              <React.Suspense fallback={null}>
                <EstoqueTerceiroCard />
              </React.Suspense>
            </Col>
            <Col md={16} span={isMobile ? 24 : 16}>
              <React.Suspense fallback={null}>
                <PagamentosCard />
              </React.Suspense>
              <React.Suspense fallback={null}>
                <FrequenciaCard />
              </React.Suspense>
              <React.Suspense fallback={null}>
                <LogVersaoCard />
              </React.Suspense>
              <React.Suspense fallback={null}>
                <EstoqueTerceiroTableCard />
              </React.Suspense>
            </Col>
          </Row>
        </div>
      </Page>
    );
  }
}

const mapStateToProps = ({ empresas, general }) => ({ empresas, general });

export default connect(mapStateToProps)(DashboardPage);
