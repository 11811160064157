import React from 'react';
import classNames from 'classnames';
import ReactMarkdown from 'react-markdown/with-html';
import './Markdown.less';

class Markdown extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { className, source, ...rest } = this.props;

    return (
      <div className={classNames('markdown', className)} {...rest}>
        <ReactMarkdown source={source} escapeHtml={false} />
      </div>
    );
  }
}

export default Markdown;
