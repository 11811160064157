import React from 'react';
import { Menu, Modal, Tooltip } from 'antd';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { bindActionCreators } from 'redux';
import { actions } from '~/stores/ducks';
import { CustomerService, MenuService, UserService } from '~/services';
import './BaseMenu.less';
import AtualizarCadastroView from '~/pages/general/AtualizarCadastroView';

const menu = require('./menu.json');
const menuBenassi = require('./menu_benassi.json');
const menuPosto = require('./menu_posto.json');

class BaseMenu extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      showAtualizarCadastro: false,
    };
  }

  getPopupContainer = () => {
    const { settings } = this.props;

    if (settings.fixedHeader && settings.layout === 'topmenu') {
      return this.wrap;
    }

    return document.body;
  };

  onClick = ({ key }) => {
    const { general, setGeneral } = this.props;

    if (key === '22' && CustomerService.getCustomer().estrutura === 'BENASSI') {
      Modal.info({
        title: 'Mapa de Pedido',
        content: 'Pagina não disponível para essa empresa',
        centered: true,
      });
      return;
    }

    if (key === '23' && CustomerService.getCustomer().estrutura === 'MYSAAS') {
      Modal.info({
        title: 'Pesquisa de Preço',
        content: 'Pagina não disponível para essa empresa',
        centered: true,
      });
      return;
    }

    if (key === '22' && parseFloat(UserService.getUser().rede) === 0) {
      Modal.error({
        title: 'Mapa de Pedido',
        content: 'Cliente sem rede cadastrada!',
        centered: true,
      });
      return;
    }

    if (key === '22' && !(UserService.getUser().tabela_preco || UserService.getUser().tabela_referencial)) {
      Modal.error({
        title: 'Mapa de Pedido',
        content: 'Cliente sem tabela de preço e empresa de venda sem tabela referencial!',
        centered: true,
      });
      return;
    }

    if (key === '9') {
      this.setState({ showAtualizarCadastro: true });
    } else {
      MenuService.open(MenuService.getById(key));
    }

    if (general.isMobile) {
      setGeneral({ ...general, isCollapsed: true });
    }
  };

  renderSubMenuOrItem = item => {
    if (item.submenus) {
      return (
        <Menu.SubMenu key={item.idt} title={item.nome}>
          {item.submenus.map(submenu => this.renderSubMenuOrItem(submenu))}
        </Menu.SubMenu>
      );
    }

    return <Menu.Item key={item.idt}>{item.nome}</Menu.Item>;
  };

  render() {
    const { showAtualizarCadastro } = this.state;
    const { className, style, mode, settings } = this.props;
    const isPosto = CustomerService.getCustomer().posto;
    const isFLV = CustomerService.getCustomer().sistema === 'BENASSI';
    const menuSistema = isFLV ? menuBenassi : isPosto ? menuPosto : menu;

    return (
      <>
        <AtualizarCadastroView action={showAtualizarCadastro} onOk={() => this.setState({ showAtualizarCadastro: false })} showCancel />
        <Tooltip title={UserService.getUser().trocar_senha_portal === 'S' ? 'Altere sua senha' : undefined} color="red">
          <Menu
            className={classNames(
              className,
              { 'top-navigator-menu': mode === 'horizontal' },
              UserService.getUser().trocar_senha_portal === 'S' ? 'div-disabled' : ''
            )}
            style={style}
            mode={mode}
            subMenuCloseDelay={0.5}
            subMenuOpenDelay={0.3}
            theme={settings.theme}
            getPopupContainer={this.getPopupContainer}
            selectedKeys={[]}
            onClick={this.onClick}
          >
            {menuSistema && menuSistema.map(item => this.renderSubMenuOrItem(item))}
          </Menu>
          <div ref={ref => (this.wrap = ref)} />
        </Tooltip>
      </>
    );
  }
}

const mapStateToProps = ({ settings, general }) => ({ settings, general });
const mapDispatchToProps = dispatch => bindActionCreators(actions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(BaseMenu);
