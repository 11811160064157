// Piauí
const pi = [
  { data: '2200053', label: 'Acauã' },
  { data: '2200103', label: 'Agricolândia' },
  { data: '2200202', label: 'Água Branca' },
  { data: '2200251', label: 'Alagoinha do Piauí' },
  { data: '2200277', label: 'Alegrete do Piauí' },
  { data: '2200301', label: 'Alto Longá' },
  { data: '2200400', label: 'Altos' },
  { data: '2200459', label: 'Alvorada do Gurguéia' },
  { data: '2200509', label: 'Amarante' },
  { data: '2200608', label: 'Angical do Piauí' },
  { data: '2200707', label: 'Anísio de Abreu' },
  { data: '2200806', label: 'Antônio Almeida' },
  { data: '2200905', label: 'Aroazes' },
  { data: '2200954', label: 'Aroeiras do Itaim' },
  { data: '2201002', label: 'Arraial' },
  { data: '2201051', label: 'Assunção do Piauí' },
  { data: '2201101', label: 'Avelino Lopes' },
  { data: '2201150', label: 'Baixa Grande do Ribeiro' },
  { data: '2201176', label: "Barra D'Alcântara" },
  { data: '2201200', label: 'Barras' },
  { data: '2201309', label: 'Barreiras do Piauí' },
  { data: '2201408', label: 'Barro Duro' },
  { data: '2201507', label: 'Batalha' },
  { data: '2201556', label: 'Bela Vista do Piauí' },
  { data: '2201572', label: 'Belém do Piauí' },
  { data: '2201606', label: 'Beneditinos' },
  { data: '2201705', label: 'Bertolínia' },
  { data: '2201739', label: 'Betânia do Piauí' },
  { data: '2201770', label: 'Boa Hora' },
  { data: '2201804', label: 'Bocaina' },
  { data: '2201903', label: 'Bom Jesus' },
  { data: '2201919', label: 'Bom Princípio do Piauí' },
  { data: '2201929', label: 'Bonfim do Piauí' },
  { data: '2201945', label: 'Boqueirão do Piauí' },
  { data: '2201960', label: 'Brasileira' },
  { data: '2201988', label: 'Brejo do Piauí' },
  { data: '2202000', label: 'Buriti dos Lopes' },
  { data: '2202026', label: 'Buriti dos Montes' },
  { data: '2202059', label: 'Cabeceiras do Piauí' },
  { data: '2202075', label: 'Cajazeiras do Piauí' },
  { data: '2202083', label: 'Cajueiro da Praia' },
  { data: '2202091', label: 'Caldeirão Grande do Piauí' },
  { data: '2202109', label: 'Campinas do Piauí' },
  { data: '2202117', label: 'Campo Alegre do Fidalgo' },
  { data: '2202133', label: 'Campo Grande do Piauí' },
  { data: '2202174', label: 'Campo Largo do Piauí' },
  { data: '2202208', label: 'Campo Maior' },
  { data: '2202251', label: 'Canavieira' },
  { data: '2202307', label: 'Canto do Buriti' },
  { data: '2202406', label: 'Capitão de Campos' },
  { data: '2202455', label: 'Capitão Gervásio Oliveira' },
  { data: '2202505', label: 'Caracol' },
  { data: '2202539', label: 'Caraúbas do Piauí' },
  { data: '2202554', label: 'Caridade do Piauí' },
  { data: '2202604', label: 'Castelo do Piauí' },
  { data: '2202653', label: 'Caxingó' },
  { data: '2202703', label: 'Cocal' },
  { data: '2202711', label: 'Cocal de Telha' },
  { data: '2202729', label: 'Cocal dos Alves' },
  { data: '2202737', label: 'Coivaras' },
  { data: '2202752', label: 'Colônia do Gurguéia' },
  { data: '2202778', label: 'Colônia do Piauí' },
  { data: '2202802', label: 'Conceição do Canindé' },
  { data: '2202851', label: 'Coronel José Dias' },
  { data: '2202901', label: 'Corrente' },
  { data: '2203008', label: 'Cristalândia do Piauí' },
  { data: '2203107', label: 'Cristino Castro' },
  { data: '2203206', label: 'Curimatá' },
  { data: '2203230', label: 'Currais' },
  { data: '2203271', label: 'Curral Novo do Piauí' },
  { data: '2203255', label: 'Curralinhos' },
  { data: '2203305', label: 'Demerval Lobão' },
  { data: '2203354', label: 'Dirceu Arcoverde' },
  { data: '2203404', label: 'Dom Expedito Lopes' },
  { data: '2203453', label: 'Dom Inocêncio' },
  { data: '2203420', label: 'Domingos Mourão' },
  { data: '2203503', label: 'Elesbão Veloso' },
  { data: '2203602', label: 'Eliseu Martins' },
  { data: '2203701', label: 'Esperantina' },
  { data: '2203750', label: 'Fartura do Piauí' },
  { data: '2203800', label: 'Flores do Piauí' },
  { data: '2203859', label: 'Floresta do Piauí' },
  { data: '2203909', label: 'Floriano' },
  { data: '2204006', label: 'Francinópolis' },
  { data: '2204105', label: 'Francisco Ayres' },
  { data: '2204154', label: 'Francisco Macedo' },
  { data: '2204204', label: 'Francisco Santos' },
  { data: '2204303', label: 'Fronteiras' },
  { data: '2204352', label: 'Geminiano' },
  { data: '2204402', label: 'Gilbués' },
  { data: '2204501', label: 'Guadalupe' },
  { data: '2204550', label: 'Guaribas' },
  { data: '2204600', label: 'Hugo Napoleão' },
  { data: '2204659', label: 'Ilha Grande' },
  { data: '2204709', label: 'Inhuma' },
  { data: '2204808', label: 'Ipiranga do Piauí' },
  { data: '2204907', label: 'Isaías Coelho' },
  { data: '2205003', label: 'Itainópolis' },
  { data: '2205102', label: 'Itaueira' },
  { data: '2205151', label: 'Jacobina do Piauí' },
  { data: '2205201', label: 'Jaicós' },
  { data: '2205250', label: 'Jardim do Mulato' },
  { data: '2205276', label: 'Jatobá do Piauí' },
  { data: '2205300', label: 'Jerumenha' },
  { data: '2205359', label: 'João Costa' },
  { data: '2205409', label: 'Joaquim Pires' },
  { data: '2205458', label: 'Joca Marques' },
  { data: '2205508', label: 'José de Freitas' },
  { data: '2205516', label: 'Juazeiro do Piauí' },
  { data: '2205524', label: 'Júlio Borges' },
  { data: '2205532', label: 'Jurema' },
  { data: '2205557', label: 'Lagoa Alegre' },
  { data: '2205573', label: 'Lagoa de São Francisco' },
  { data: '2205565', label: 'Lagoa do Barro do Piauí' },
  { data: '2205581', label: 'Lagoa do Piauí' },
  { data: '2205599', label: 'Lagoa do Sítio' },
  { data: '2205540', label: 'Lagoinha do Piauí' },
  { data: '2205607', label: 'Landri Sales' },
  { data: '2205706', label: 'Luís Correia' },
  { data: '2205805', label: 'Luzilândia' },
  { data: '2205854', label: 'Madeiro' },
  { data: '2205904', label: 'Manoel Emídio' },
  { data: '2205953', label: 'Marcolândia' },
  { data: '2206001', label: 'Marcos Parente' },
  { data: '2206050', label: 'Massapê do Piauí' },
  { data: '2206100', label: 'Matias Olímpio' },
  { data: '2206209', label: 'Miguel Alves' },
  { data: '2206308', label: 'Miguel Leão' },
  { data: '2206357', label: 'Milton Brandão' },
  { data: '2206407', label: 'Monsenhor Gil' },
  { data: '2206506', label: 'Monsenhor Hipólito' },
  { data: '2206605', label: 'Monte Alegre do Piauí' },
  { data: '2206654', label: 'Morro Cabeça no Tempo' },
  { data: '2206670', label: 'Morro do Chapéu do Piauí' },
  { data: '2206696', label: 'Murici dos Portelas' },
  { data: '2206704', label: 'Nazaré do Piauí' },
  { data: '2206720', label: 'Nazária' },
  { data: '2206753', label: 'Nossa Senhora de Nazaré' },
  { data: '2206803', label: 'Nossa Senhora dos Remédios' },
  { data: '2207959', label: 'Nova Santa Rita' },
  { data: '2206902', label: 'Novo Oriente do Piauí' },
  { data: '2206951', label: 'Novo Santo Antônio' },
  { data: '2207009', label: 'Oeiras' },
  { data: '2207108', label: "Olho D'Água do Piauí" },
  { data: '2207207', label: 'Padre Marcos' },
  { data: '2207306', label: 'Paes Landim' },
  { data: '2207355', label: 'Pajeú do Piauí' },
  { data: '2207405', label: 'Palmeira do Piauí' },
  { data: '2207504', label: 'Palmeirais' },
  { data: '2207553', label: 'Paquetá' },
  { data: '2207603', label: 'Parnaguá' },
  { data: '2207702', label: 'Parnaíba' },
  { data: '2207751', label: 'Passagem Franca do Piauí' },
  { data: '2207777', label: 'Patos do Piauí' },
  { data: '2207793', label: "Pau D'Arco do Piauí" },
  { data: '2207801', label: 'Paulistana' },
  { data: '2207850', label: 'Pavussu' },
  { data: '2207900', label: 'Pedro II' },
  { data: '2207934', label: 'Pedro Laurentino' },
  { data: '2208007', label: 'Picos' },
  { data: '2208106', label: 'Pimenteiras' },
  { data: '2208205', label: 'Pio IX' },
  { data: '2208304', label: 'Piracuruca' },
  { data: '2208403', label: 'Piripiri' },
  { data: '2208502', label: 'Porto' },
  { data: '2208551', label: 'Porto Alegre do Piauí' },
  { data: '2208601', label: 'Prata do Piauí' },
  { data: '2208650', label: 'Queimada Nova' },
  { data: '2208700', label: 'Redenção do Gurguéia' },
  { data: '2208809', label: 'Regeneração' },
  { data: '2208858', label: 'Riacho Frio' },
  { data: '2208874', label: 'Ribeira do Piauí' },
  { data: '2208908', label: 'Ribeiro Gonçalves' },
  { data: '2209005', label: 'Rio Grande do Piauí' },
  { data: '2209104', label: 'Santa Cruz do Piauí' },
  { data: '2209153', label: 'Santa Cruz dos Milagres' },
  { data: '2209203', label: 'Santa Filomena' },
  { data: '2209302', label: 'Santa Luz' },
  { data: '2209377', label: 'Santa Rosa do Piauí' },
  { data: '2209351', label: 'Santana do Piauí' },
  { data: '2209401', label: 'Santo Antônio de Lisboa' },
  { data: '2209450', label: 'Santo Antônio dos Milagres' },
  { data: '2209500', label: 'Santo Inácio do Piauí' },
  { data: '2209559', label: 'São Braz do Piauí' },
  { data: '2209609', label: 'São Félix do Piauí' },
  { data: '2209658', label: 'São Francisco de Assis do Piauí' },
  { data: '2209708', label: 'São Francisco do Piauí' },
  { data: '2209757', label: 'São Gonçalo do Gurguéia' },
  { data: '2209807', label: 'São Gonçalo do Piauí' },
  { data: '2209856', label: 'São João da Canabrava' },
  { data: '2209872', label: 'São João da Fronteira' },
  { data: '2209906', label: 'São João da Serra' },
  { data: '2209955', label: 'São João da Varjota' },
  { data: '2209971', label: 'São João do Arraial' },
  { data: '2210003', label: 'São João do Piauí' },
  { data: '2210052', label: 'São José do Divino' },
  { data: '2210102', label: 'São José do Peixe' },
  { data: '2210201', label: 'São José do Piauí' },
  { data: '2210300', label: 'São Julião' },
  { data: '2210359', label: 'São Lourenço do Piauí' },
  { data: '2210375', label: 'São Luis do Piauí' },
  { data: '2210383', label: 'São Miguel da Baixa Grande' },
  { data: '2210391', label: 'São Miguel do Fidalgo' },
  { data: '2210409', label: 'São Miguel do Tapuio' },
  { data: '2210508', label: 'São Pedro do Piauí' },
  { data: '2210607', label: 'São Raimundo Nonato' },
  { data: '2210623', label: 'Sebastião Barros' },
  { data: '2210631', label: 'Sebastião Leal' },
  { data: '2210656', label: 'Sigefredo Pacheco' },
  { data: '2210706', label: 'Simões' },
  { data: '2210805', label: 'Simplício Mendes' },
  { data: '2210904', label: 'Socorro do Piauí' },
  { data: '2210938', label: 'Sussuapara' },
  { data: '2210953', label: 'Tamboril do Piauí' },
  { data: '2210979', label: 'Tanque do Piauí' },
  { data: '2211001', label: 'Teresina' },
  { data: '2211100', label: 'União' },
  { data: '2211209', label: 'Uruçuí' },
  { data: '2211308', label: 'Valença do Piauí' },
  { data: '2211357', label: 'Várzea Branca' },
  { data: '2211407', label: 'Várzea Grande' },
  { data: '2211506', label: 'Vera Mendes' },
  { data: '2211605', label: 'Vila Nova do Piauí' },
  { data: '2211704', label: 'Wall Ferraz' },
];

export default pi;
