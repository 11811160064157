import React from 'react';
import classNames from 'classnames';
import { useField } from 'informed';
import { Checkbox as AntdCheckbox, Tooltip } from 'antd';
import { Form } from '~/components/forms';
import './Checkbox.less';

const Checkbox = React.memo(props => {
  const { fieldState, fieldApi, render, userProps } = useField({ ...props });
  const { onChange, label, className, forwardedRef, block, disabledReason, tooltip, tooltipProps, initialValue, ...rest } = userProps;

  if (!fieldState.value) {
    setTimeout(() => {
      fieldApi.setValue(initialValue === 'S' ? 'S' : 'N');
    }, 300);
  }

  return render(
    <Form.Item error={fieldState.error} labelHorizontal>
      <Tooltip title={(rest.disabled && disabledReason) || tooltip} placement="bottom" color="red" {...tooltipProps}>
        <AntdCheckbox
          className={classNames(block ? 'ant-checkbox-wrapper-block' : '', className)}
          value={(fieldState.value || initialValue) === 'S' ? 'S' : 'N'}
          checked={fieldState.value === 'S'}
          ref={forwardedRef}
          onChange={event => {
            fieldApi.setValue(event.target.checked ? 'S' : 'N');

            if (onChange) {
              onChange(event);
            }
          }}
          {...rest}
        >
          {label}
        </AntdCheckbox>
      </Tooltip>
    </Form.Item>
  );
});

export default Checkbox;
