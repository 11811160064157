const paises = [
  { data: '0132', label: 'Afeganistão' },
  { data: '7560', label: 'África do Sul' },
  { data: '0175', label: 'Albânia, República da' },
  { data: '0230', label: 'Alemanha' },
  { data: '0370', label: 'Andorra' },
  { data: '0400', label: 'Angola' },
  { data: '0418', label: 'Anguilla' },
  { data: '0434', label: 'Antigua e Barbuda' },
  { data: '0477', label: 'Antilhas Holandesas' },
  { data: '0531', label: 'Arábia Saudita' },
  { data: '0590', label: 'Argélia' },
  { data: '0639', label: 'Argentina' },
  { data: '0647', label: 'Armênia, República da' },
  { data: '0655', label: 'Aruba' },
  { data: '0698', label: 'Austrália' },
  { data: '0728', label: 'Áustria' },
  { data: '0736', label: 'Azerbaijão, República do' },
  { data: '0779', label: 'Bahamas, Ilhas' },
  { data: '0809', label: 'Bahrein, Ilhas' },
  { data: '0817', label: 'Bangladesh' },
  { data: '0833', label: 'Barbados' },
  { data: '0850', label: 'Belarus' },
  { data: '0876', label: 'Bélgica' },
  { data: '0884', label: 'Belize' },
  { data: '2291', label: 'Benin' },
  { data: '0906', label: 'Bermudas' },
  { data: '0973', label: 'Bolívia' },
  { data: '0981', label: 'Bósnia-Herzegovina' },
  { data: '1015', label: 'Botsuana' },
  { data: '1058', label: 'Brasil' },
  { data: '1082', label: 'Brunei' },
  { data: '1112', label: 'Bulgária, República da' },
  { data: '0310', label: 'Burkina Faso' },
  { data: '1155', label: 'Burundi' },
  { data: '1198', label: 'Butão' },
  { data: '1279', label: 'Cabo Verde, República de' },
  { data: '1457', label: 'Camarões' },
  { data: '1414', label: 'Camboja' },
  { data: '1490', label: 'Canadá' },
  { data: '1504', label: 'Canal, Ilhas do (Jersey e Guernsey)' },
  { data: '1511', label: 'Canárias, Ilhas' },
  { data: '1546', label: 'Catar' },
  { data: '1376', label: 'Cayman, Ilhas' },
  { data: '1538', label: 'Cazaquistão, República do' },
  { data: '7889', label: 'Chade' },
  { data: '1589', label: 'Chile' },
  { data: '1600', label: 'China, República Popular da' },
  { data: '1635', label: 'Chipre' },
  { data: '5118', label: 'Christmas, Ilha (Navidad)' },
  { data: '7412', label: 'Cingapura' },
  { data: '1651', label: 'Cocos (Keeling), Ilhas' },
  { data: '1694', label: 'Colômbia' },
  { data: '1732', label: 'Comores, Ilhas' },
  { data: '8885', label: 'Congo, República Democrática do' },
  { data: '1775', label: 'Congo, República do' },
  { data: '1830', label: 'Cook, Ilhas' },
  { data: '1872', label: 'Coréia, Rep. Pop. Democrática da' },
  { data: '1902', label: 'Coréia, República da' },
  { data: '1937', label: 'Costa do Marfim' },
  { data: '1961', label: 'Costa Rica' },
  { data: '1988', label: 'Coveite' },
  { data: '1953', label: 'Croácia, República da' },
  { data: '1996', label: 'Cuba' },
  { data: '2321', label: 'Dinamarca' },
  { data: '7838', label: 'Djibuti' },
  { data: '2356', label: 'Dominica, Ilha' },
  { data: '2402', label: 'Egito' },
  { data: '6874', label: 'El Salvador' },
  { data: '2445', label: 'Emirados Árabes Unidos' },
  { data: '2399', label: 'Equador' },
  { data: '2437', label: 'Eritréia' },
  { data: '6289', label: 'Escócia' },
  { data: '2470', label: 'Eslovaca, República' },
  { data: '2461', label: 'Eslovênia, República da' },
  { data: '2453', label: 'Espanha' },
  { data: '2496', label: 'Estados Unidos' },
  { data: '2518', label: 'Estônia, República da' },
  { data: '2534', label: 'Etiópia' },
  { data: '2550', label: 'Falkland (Ilhas Malvinas)' },
  { data: '2593', label: 'Feroe, Ilhas' },
  { data: '8702', label: 'Fiji' },
  { data: '2674', label: 'Filipinas' },
  { data: '2712', label: 'Finlândia' },
  { data: '1619', label: 'Formosa (Taiwan)' },
  { data: '2755', label: 'França' },
  { data: '2810', label: 'Gabão' },
  { data: '6289', label: 'Gales, País de' },
  { data: '2852', label: 'Gâmbia' },
  { data: '2895', label: 'Gana' },
  { data: '2917', label: 'Geórgia, República da' },
  { data: '2933', label: 'Gibraltar' },
  { data: '6289', label: 'Grã-Bretanha' },
  { data: '2976', label: 'Granada' },
  { data: '3018', label: 'Grécia' },
  { data: '3050', label: 'Groenlândia' },
  { data: '3093', label: 'Guadalupe' },
  { data: '3131', label: 'Guam' },
  { data: '3174', label: 'Guatemala' },
  { data: '3379', label: 'Guiana' },
  { data: '3255', label: 'Guiana Francesa' },
  { data: '3298', label: 'Guiné' },
  { data: '3344', label: 'Guiné-Bissau' },
  { data: '3310', label: 'Guiné-Equatorial' },
  { data: '3417', label: 'Haiti' },
  { data: '5738', label: 'Holanda (Países Baixos)' },
  { data: '3450', label: 'Honduras' },
  { data: '3514', label: 'Hong Kong, Região Adm. Especial' },
  { data: '3557', label: 'Hungria, República da' },
  { data: '3573', label: 'Iêmen' },
  { data: '3611', label: 'Índia' },
  { data: '3654', label: 'Indonésia' },
  { data: '6289', label: 'Inglaterra' },
  { data: '3727', label: 'Irã, República Islâmica do' },
  { data: '3697', label: 'Iraque' },
  { data: '3751', label: 'Irlanda' },
  { data: '6289', label: 'Irlanda do Norte' },
  { data: '3794', label: 'Islândia' },
  { data: '3832', label: 'Israel' },
  { data: '3867', label: 'Itália' },
  { data: '3883', label: 'Iugoslávia, República Fed. da' },
  { data: '3913', label: 'Jamaica' },
  { data: '3999', label: 'Japão' },
  { data: '3964', label: 'Johnston, Ilhas' },
  { data: '4030', label: 'Jordânia' },
  { data: '4111', label: 'Kiribati' },
  { data: '4200', label: 'Laos, Rep. Pop. Democrática do' },
  { data: '4235', label: 'Lebuan' },
  { data: '4260', label: 'Lesoto' },
  { data: '4278', label: 'Letônia, República da' },
  { data: '4316', label: 'Líbano' },
  { data: '4340', label: 'Libéria' },
  { data: '4383', label: 'Líbia' },
  { data: '4405', label: 'Liechtenstein' },
  { data: '4421', label: 'Lituânia, República da' },
  { data: '4456', label: 'Luxemburgo' },
  { data: '4472', label: 'Macau' },
  { data: '4499', label: 'Macedônia' },
  { data: '4502', label: 'Madagascar' },
  { data: '4525', label: 'Madeira, Ilha da' },
  { data: '4553', label: 'Malásia' },
  { data: '4588', label: 'Malavi' },
  { data: '4618', label: 'Maldivas' },
  { data: '4642', label: 'Máli' },
  { data: '4677', label: 'Malta' },
  { data: '3595', label: 'Man, Ilhas' },
  { data: '4723', label: 'Marianas do Norte' },
  { data: '4740', label: 'Marrocos' },
  { data: '4766', label: 'Marshall, Ilhas' },
  { data: '4774', label: 'Martinica' },
  { data: '4855', label: 'Maurício' },
  { data: '4880', label: 'Mauritânia' },
  { data: '4936', label: 'México' },
  { data: '0930', label: 'Mianmar (Birmânia)' },
  { data: '4995', label: 'Micronésia' },
  { data: '4901', label: 'Midway, Ilhas' },
  { data: '5053', label: 'Moçambique' },
  { data: '4944', label: 'Moldávia, República da' },
  { data: '4952', label: 'Mônaco' },
  { data: '4979', label: 'Mongólia' },
  { data: '5010', label: 'Montserrat, Ilhas' },
  { data: '5070', label: 'Namíbia' },
  { data: '5088', label: 'Nauru' },
  { data: '5177', label: 'Nepal' },
  { data: '5215', label: 'Nicarágua' },
  { data: '5258', label: 'Niger' },
  { data: '5282', label: 'Nigéria' },
  { data: '5312', label: 'Niue, Ilha' },
  { data: '5355', label: 'Norfolk, Ilha' },
  { data: '5380', label: 'Noruega' },
  { data: '5428', label: 'Nova Caledônia' },
  { data: '5487', label: 'Nova Zelândia' },
  { data: '5568', label: 'Omã' },
  { data: '5738', label: 'Países Baixos (Holanda)' },
  { data: '5754', label: 'Palau' },
  { data: '5800', label: 'Panamá' },
  { data: '5452', label: 'Papua Nova Guiné' },
  { data: '5762', label: 'Paquistão' },
  { data: '5860', label: 'Paraguai' },
  { data: '5894', label: 'Peru' },
  { data: '5932', label: 'Pitcairn, Ilha' },
  { data: '5991', label: 'Polinésia Francesa' },
  { data: '6033', label: 'Polônia, República da' },
  { data: '6114', label: 'Porto Rico' },
  { data: '6076', label: 'Portugal' },
  { data: '6238', label: 'Quênia' },
  { data: '6254', label: 'Quirguiz, República' },
  { data: '6289', label: 'Reino Unido' },
  { data: '6408', label: 'República Centro-Africana' },
  { data: '6475', label: 'República Dominicana' },
  { data: '6602', label: 'Reunião, Ilha' },
  { data: '6700', label: 'Romênia' },
  { data: '6750', label: 'Ruanda' },
  { data: '6769', label: 'Rússia' },
  { data: '6858', label: 'Saara Ocidental' },
  { data: '6777', label: 'Salomão, Ilhas' },
  { data: '6904', label: 'Samoa' },
  { data: '6912', label: 'Samoa Americana' },
  { data: '6971', label: 'San Marino' },
  { data: '7102', label: 'Santa Helena' },
  { data: '7153', label: 'Santa Lúcia' },
  { data: '6955', label: 'São Cristóvão e Neves' },
  { data: '7005', label: 'São Pedro e Miquelon' },
  { data: '7200', label: 'São Tomé e Príncipe, Ilhas' },
  { data: '7056', label: 'São Vicente e Granadinas' },
  { data: '7285', label: 'Senegal' },
  { data: '7358', label: 'Serra Leoa' },
  { data: '7315', label: 'Seychelles' },
  { data: '7447', label: 'Síria, República Árabe da' },
  { data: '7480', label: 'Somália' },
  { data: '7501', label: 'Sri Lanka' },
  { data: '7544', label: 'Suazilândia' },
  { data: '7595', label: 'Sudão' },
  { data: '7641', label: 'Suécia' },
  { data: '7676', label: 'Suíça' },
  { data: '7706', label: 'Suriname' },
  { data: '7722', label: 'Tadjiquistão' },
  { data: '7765', label: 'Tailândia' },
  { data: '7803', label: 'Tanzânia, República Unida da' },
  { data: '7919', label: 'Tcheca, República' },
  { data: '7820', label: 'Território Britânico Oc. Índico' },
  { data: '7951', label: 'Timor Leste' },
  { data: '8001', label: 'Togo' },
  { data: '8109', label: 'Tonga' },
  { data: '8052', label: 'Toquelau, Ilhas' },
  { data: '8150', label: 'Trinidad e Tobago' },
  { data: '8206', label: 'Tunísia' },
  { data: '8230', label: 'Turcas e Caicos, Ilhas' },
  { data: '8249', label: 'Turcomenistão, República do' },
  { data: '8273', label: 'Turquia' },
  { data: '8281', label: 'Tuvalu' },
  { data: '8311', label: 'Ucrânia' },
  { data: '8338', label: 'Uganda' },
  { data: '8451', label: 'Uruguai' },
  { data: '8478', label: 'Uzbequistão, República do' },
  { data: '5517', label: 'Vanuatu' },
  { data: '8486', label: 'Vaticano, Estado da Cidade do' },
  { data: '8508', label: 'Venezuela' },
  { data: '8583', label: 'Vietnã' },
  { data: '8630', label: 'Virgens, Ilhas (Britânicas)' },
  { data: '8664', label: 'Virgens, Ilhas (E.U.A.)' },
  { data: '8737', label: 'Wake, Ilha' },
  { data: '8753', label: 'Wallis e Futuna, Ilhas' },
  { data: '8907', label: 'Zâmbia' },
  { data: '6653', label: 'Zimbábue' },
  { data: '8958', label: 'Zona do Canal do Panamá' },
];

export default paises;
