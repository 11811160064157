import React from 'react';
import _ from 'lodash';
import { Row, Col, Modal } from 'antd';
import { connect } from 'react-redux';
import { DeleteOutlined } from '@ant-design/icons';
import { FooterToolbar, Navigator, Page, Form, ContentWrapper, Table, Input, Button, DatePicker, Status, Select, IconDelete } from '~/components';
import { actions, tabPanes, affirmatives } from '~/options';
import { notifySuccess } from '~/utils';
import { RecadoMotoristaService } from '~/services';
import { no } from '~/options/general/affirmatives';

class RecadoMotoristaPage extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      dataItem: [],
      selectedItem: {},
      action: actions.idle,
      activeTab: tabPanes.table.key,
      selectedRowKeys: [],
    };
  }

  componentDidMount() {
    const { params } = this.props;

    if (params && params.cliente) {
      this.searchApi.setValue('cliente', params.cliente);
      this.searchApi.submitForm();
    }
  }

  handleSearch = values => {
    this.setState({ isLoading: true });
    RecadoMotoristaService.getAll(
      values,
      dataItem => {
        this.setState({ dataItem, isLoading: false });
        this.searchInput.focus();
      },
      () => {
        this.setState({ isLoading: false });
        this.searchInput.focus();
      }
    );
  };

  handleSelect = record => {
    this.setState({ selectedItem: record });
    this.saveApi.reset();
    this.saveApi.setValues(record);
  };

  handleEdit = action => {
    this.setState({ action, activeTab: tabPanes.record.key });
    setTimeout(() => this.editInput.focus(), 300);
  };

  onEdit = (cancel = false) => {
    let { selectedItem, activeTab } = this.state;

    if (cancel) {
      this.handleSelect(selectedItem);

      if (!selectedItem.id) {
        activeTab = tabPanes.table.key;
      }
    } else {
      this.searchApi.submitForm();

      const { action } = this.state;

      if (action === actions.insert) {
        activeTab = tabPanes.table.key;
        selectedItem = {};
      }
    }

    this.setState({ action: actions.idle, selectedItem, activeTab });
  };

  handleSave = values => {
    const { action } = this.state;
    let record = {};
    let saveHandler = RecadoMotoristaService.incluir;

    if (action === actions.update) {
      const { selectedItem } = this.state;
      record = selectedItem;
      saveHandler = RecadoMotoristaService.alterar;
    }

    this.setState({ isLoading: true });
    saveHandler(
      { ...record, ...values },
      () => this.onSave(),
      () => this.onSave(true)
    );
  };

  onSave = (error = false) => {
    this.setState({ isLoading: false, activeTab: tabPanes.table.key });

    if (!error) {
      const { name } = this.props;
      const { action } = this.state;

      this.onEdit();
      notifySuccess({ name, action });
    }
  };

  excluirSelecionados = () => {
    const { selectedRowKeys, dataItem } = this.state;

    Modal.confirm({
      content: 'Confirma a exclusão dos itens selecionadas?',
      title: 'EXCLUSÃO',
      centered: true,
      onOk: () => {
        RecadoMotoristaService.excluirSelecionados(
          selectedRowKeys.map(itemKey => dataItem.find(item => item.id === itemKey)),
          () => this.onDelete(),
          () => this.onDelete(true)
        );
      },
    });
  };

  handleDelete = id => {
    this.setState({ isLoading: true });
    RecadoMotoristaService.excluir(
      id,
      () => this.onDelete(),
      () => this.onDelete(true)
    );
  };

  onDelete = (error = false) => {
    this.setState({ isLoading: false, selectedRowKeys: [] });

    if (!error) {
      const { name } = this.props;

      this.searchApi.submitForm();
      notifySuccess({ name, action: actions.remove });
    }
  };

  render = () => {
    const { menuId, tabId } = this.props;
    const { isLoading, dataItem, selectedItem, action, activeTab, selectedRowKeys } = this.state;
    const isEdit = action !== actions.idle && !isLoading;
    const isIdle = action === actions.idle && !isLoading;

    const rowSelection = {
      columnWidth: '30px',
      selectedRowKeys,
      onSelectAll: selected => {
        this.setState({ selectedRowKeys: selected ? dataItem.filter(filter => filter.cliente !== '0').map(item => item.id) : [] });
      },
      onSelect: (record, selected) => {
        this.setState({ selectedRowKeys: selected ? _.uniq([...selectedRowKeys, record.id]) : selectedRowKeys.filter(item => item !== record.id) });
      },
    };

    return (
      <>
        <Page
          tabbed
          tabId={tabId}
          closable={isIdle}
          activeKey={activeTab}
          onChange={activeKey => this.setState({ activeTab: activeKey })}
          footer={action !== actions.insert && selectedItem.id && <Status record={selectedItem} />}
        >
          <Page.TabPane key={tabPanes.table.key} tab={tabPanes.table.tab} disabled={action !== actions.idle}>
            <Form getApi={api => (this.searchApi = api)} onSubmit={this.handleSearch}>
              <ContentWrapper type="search">
                <Row gutter={4}>
                  <Col md={6} span={12}>
                    <Input field="cpf_cnpj" label="CPF / CNPJ" type="cpfCnpj" allowClear disabled={isLoading} forwardedRef={ref => (this.searchInput = ref)} />
                  </Col>
                  <Col md={3} span={12}>
                    <Input field="placa" label="Placa" type="placa" allowClear disabled={isLoading} />
                  </Col>
                  <Col md={4} span={12}>
                    <DatePicker field="inicial" label="Data Inicial" disabled={isLoading} />
                  </Col>
                  <Col md={4} span={12}>
                    <DatePicker field="final" label="Data Final" disabled={isLoading} />
                  </Col>
                  <Col md={3} span={12}>
                    <Select field="excluido" label="Excluído" dataSource={affirmatives} initialValue={no.data} disabled={isLoading} />
                  </Col>
                  <Col md={4} span={12}>
                    <Button.Search disabled={isLoading} />
                  </Col>
                </Row>
              </ContentWrapper>
            </Form>
            <Table
              rowKey="id"
              loading={isLoading}
              record={selectedItem}
              onRecordChange={this.handleSelect}
              dataSource={dataItem}
              rowSelection={rowSelection}
              onRow={{ onDoubleClick: () => this.setState({ activeTab: tabPanes.record.key }) }}
              description={item => (
                <Row>
                  <Col span={16}>
                    <span style={{ fontSize: '0.8rem' }}>{item.nome}</span>
                  </Col>
                  <Col span={8}>
                    <span>{item.placa}</span>
                  </Col>
                </Row>
              )}
              height={window.innerHeight - 360}
            >
              <Table.Column width="12%" title="Data" dataIndex="id" type="date" />
              <Table.Column width="10%" title="Placa" dataIndex="placa" align="center" />
              <Table.Column width="12%" title="CPF/CNPJ" dataIndex="cnpj" align="center" />
              <Table.Column width="21%" title="Nome" dataIndex="nome" align="left" ellipsis />
              <Table.Column width="40%" title="Recado" dataIndex="recado" />
              <Table.Column
                title="Ações"
                align="center"
                dataIndex="actions"
                width="5%"
                sorter={false}
                render={(text, record) => (
                  <IconDelete
                    menuId={menuId}
                    prefix="o recado"
                    description={record.recado}
                    disabled={record.data_exc || selectedRowKeys.length > 0}
                    onClick={() => this.handleDelete(record.id)}
                  />
                )}
              />
            </Table>
          </Page.TabPane>
          <Page.TabPane key={tabPanes.record.key} tab={tabPanes.record.tab} disabled={action === actions.idle && !selectedItem.id}>
            <Form getApi={api => (this.saveApi = api)} onSubmit={this.handleSave}>
              <ContentWrapper type="header" color={action.color}>
                <Row gutter={4}>
                  <Col md={8} span={24}>
                    <Input field="nome" label="De" required disabled={!isEdit} forwardedRef={ref => (this.editInput = ref)} />
                  </Col>
                  <Col md={3} span={12}>
                    <Input field="placa" label="Placa" type="placa" allowClear disabled={!isEdit} />
                  </Col>
                  <Col md={4} span={12}>
                    <Input field="cnpj" label="CPF / CNPJ" type="cpfCnpj" allowClear disabled={!isEdit} />
                  </Col>
                  <Col md={3} span={12}>
                    <DatePicker field="data_exc" label="Exclusão" disabled />
                  </Col>
                  <Col md={6} span={12}>
                    <Input field="user_exc" label="Usuário Exclusão" type="string" disabled />
                  </Col>
                  <Col md={24} span={24}>
                    <Input.TextArea
                      field="recado"
                      label="Recado"
                      allowClear
                      disabled={!isEdit}
                      capsLock={false}
                      autoSize={{ minRows: 6, maxRows: 10 }}
                      required
                    />
                  </Col>
                </Row>
              </ContentWrapper>
            </Form>
          </Page.TabPane>
        </Page>
        <FooterToolbar
          left={
            <>
              <Button.Insert menuId={menuId} disabled={!isIdle} onClick={() => this.handleEdit(actions.insert)} />
              <Button.Update
                menuId={menuId}
                disabled={!selectedItem.id || selectedItem.data_exc || !isIdle || selectedRowKeys.length > 0}
                onClick={() => this.handleEdit(actions.update)}
              />
              <Button.Default
                icon={<DeleteOutlined />}
                label="Excluir"
                footer
                disabled={selectedRowKeys.length === 0 || isLoading}
                type="primary"
                danger
                onClick={this.excluirSelecionados}
              />
            </>
          }
          center={
            <>
              <Navigator
                disabled={!isIdle}
                record={selectedItem}
                dataSource={dataItem}
                dataIndex="id"
                onClick={record => this.setState({ selectedItem: record })}
              />
            </>
          }
          right={
            <>
              <Button.Cancel disabled={isIdle || isLoading} onClick={() => this.onEdit(true)} />
              <Button.Save disabled={isIdle || isLoading} loading={action !== actions.idle && isLoading} onClick={() => this.saveApi.submitForm()} />
            </>
          }
        />
      </>
    );
  };
}

const mapStateToProps = ({ clientes }) => ({
  clientes,
});
export default connect(mapStateToProps)(RecadoMotoristaPage);
