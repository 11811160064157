import _ from 'lodash';
import { store } from '~/stores';
import { Creators } from '~/stores/ducks/general/tabs';
import * as pages from '~/pages';
import { menus } from '~/options/general';
import { HttpService } from '~/helpers';

const flatMenu = data => {
  let flatData = [];

  _.forEach(data, item => {
    if (item.submenus) {
      flatData = _.concat(flatData, flatMenu(item.submenus));
    } else {
      flatData.push(item);
    }
  });

  return flatData;
};

const getMenuComponent = chave => pages[chave] || pages.NotFoundPage;

const menuData = require('../../layouts/general/BasicLayout/components/BaseMenu/menu.json');

const MenuService = {
  open: (menu, params) => {
    const { addTab } = Creators;

    if (menu && menu.chave) {
      store.dispatch(addTab(menu, params));
    }
  },
  getById: id => _.find(_.concat(flatMenu(menuData), menus), item => item.idt === id) || {},
  getByTitle: title => _.find(_.concat(flatMenu(menuData), menus), item => item.nome === title) || {},
  getHelpContent: (nameMenu, onSuccess, onError) => {
    HttpService.call('sistema/help', 'getHelpContent', [nameMenu], onSuccess, onError);
  },
};

export { flatMenu, getMenuComponent };
export default MenuService;
