// São Paulo
const sp = [
  { data: '3500105', label: 'Adamantina' },
  { data: '3500204', label: 'Adolfo' },
  { data: '3500303', label: 'Aguaí' },
  { data: '3500402', label: 'Águas da Prata' },
  { data: '3500501', label: 'Águas de Lindóia' },
  { data: '3500550', label: 'Águas de Santa Bárbara' },
  { data: '3500600', label: 'Águas de São Pedro' },
  { data: '3500709', label: 'Agudos' },
  { data: '3500758', label: 'Alambari' },
  { data: '3500808', label: 'Alfredo Marcondes' },
  { data: '3500907', label: 'Altair' },
  { data: '3501004', label: 'Altinópolis' },
  { data: '3501103', label: 'Alto Alegre' },
  { data: '3501152', label: 'Alumínio' },
  { data: '3501202', label: 'Álvares Florence' },
  { data: '3501301', label: 'Álvares Machado' },
  { data: '3501400', label: 'Álvaro de Carvalho' },
  { data: '3501509', label: 'Alvinlândia' },
  { data: '3501608', label: 'Americana' },
  { data: '3501707', label: 'Américo Brasiliense' },
  { data: '3501806', label: 'Américo de Campos' },
  { data: '3501905', label: 'Amparo' },
  { data: '3502002', label: 'Analândia' },
  { data: '3502101', label: 'Andradina' },
  { data: '3502200', label: 'Angatuba' },
  { data: '3502309', label: 'Anhembi' },
  { data: '3502408', label: 'Anhumas' },
  { data: '3502507', label: 'Aparecida' },
  { data: '3502606', label: "Aparecida d'Oeste" },
  { data: '3502705', label: 'Apiaí' },
  { data: '3502754', label: 'Araçariguama' },
  { data: '3502804', label: 'Araçatuba' },
  { data: '3502903', label: 'Araçoiaba da Serra' },
  { data: '3503000', label: 'Aramina' },
  { data: '3503109', label: 'Arandu' },
  { data: '3503158', label: 'Arapeí' },
  { data: '3503208', label: 'Araraquara' },
  { data: '3503307', label: 'Araras' },
  { data: '3503356', label: 'Arco-Íris' },
  { data: '3503406', label: 'Arealva' },
  { data: '3503505', label: 'Areias' },
  { data: '3503604', label: 'Areiópolis' },
  { data: '3503703', label: 'Ariranha' },
  { data: '3503802', label: 'Artur Nogueira' },
  { data: '3503901', label: 'Arujá' },
  { data: '3503950', label: 'Aspásia' },
  { data: '3504008', label: 'Assis' },
  { data: '3504107', label: 'Atibaia' },
  { data: '3504206', label: 'Auriflama' },
  { data: '3504305', label: 'Avaí' },
  { data: '3504404', label: 'Avanhandava' },
  { data: '3504503', label: 'Avaré' },
  { data: '3504602', label: 'Bady Bassitt' },
  { data: '3504701', label: 'Balbinos' },
  { data: '3504800', label: 'Bálsamo' },
  { data: '3504909', label: 'Bananal' },
  { data: '3505005', label: 'Barão de Antonina' },
  { data: '3505104', label: 'Barbosa' },
  { data: '3505203', label: 'Bariri' },
  { data: '3505302', label: 'Barra Bonita' },
  { data: '3505351', label: 'Barra do Chapéu' },
  { data: '3505401', label: 'Barra do Turvo' },
  { data: '3505500', label: 'Barretos' },
  { data: '3505609', label: 'Barrinha' },
  { data: '3505708', label: 'Barueri' },
  { data: '3505807', label: 'Bastos' },
  { data: '3505906', label: 'Batatais' },
  { data: '3506003', label: 'Bauru' },
  { data: '3506102', label: 'Bebedouro' },
  { data: '3506201', label: 'Bento de Abreu' },
  { data: '3506300', label: 'Bernardino de Campos' },
  { data: '3506359', label: 'Bertioga' },
  { data: '3506409', label: 'Bilac' },
  { data: '3506508', label: 'Birigui' },
  { data: '3506607', label: 'Biritiba-Mirim' },
  { data: '3506706', label: 'Boa Esperança do Sul' },
  { data: '3506805', label: 'Bocaina' },
  { data: '3506904', label: 'Bofete' },
  { data: '3507001', label: 'Boituva' },
  { data: '3507100', label: 'Bom Jesus dos Perdões' },
  { data: '3507159', label: 'Bom Sucesso de Itararé' },
  { data: '3507209', label: 'Borá' },
  { data: '3507308', label: 'Boracéia' },
  { data: '3507407', label: 'Borborema' },
  { data: '3507456', label: 'Borebi' },
  { data: '3507506', label: 'Botucatu' },
  { data: '3507605', label: 'Bragança Paulista' },
  { data: '3507704', label: 'Braúna' },
  { data: '3507753', label: 'Brejo Alegre' },
  { data: '3507803', label: 'Brodowski' },
  { data: '3507902', label: 'Brotas' },
  { data: '3508009', label: 'Buri' },
  { data: '3508108', label: 'Buritama' },
  { data: '3508207', label: 'Buritizal' },
  { data: '3508306', label: 'Cabrália Paulista' },
  { data: '3508405', label: 'Cabreúva' },
  { data: '3508504', label: 'Caçapava' },
  { data: '3508603', label: 'Cachoeira Paulista' },
  { data: '3508702', label: 'Caconde' },
  { data: '3508801', label: 'Cafelândia' },
  { data: '3508900', label: 'Caiabu' },
  { data: '3509007', label: 'Caieiras' },
  { data: '3509106', label: 'Caiuá' },
  { data: '3509205', label: 'Cajamar' },
  { data: '3509254', label: 'Cajati' },
  { data: '3509304', label: 'Cajobi' },
  { data: '3509403', label: 'Cajuru' },
  { data: '3509452', label: 'Campina do Monte Alegre' },
  { data: '3509502', label: 'Campinas' },
  { data: '3509601', label: 'Campo Limpo Paulista' },
  { data: '3509700', label: 'Campos do Jordão' },
  { data: '3509809', label: 'Campos Novos Paulista' },
  { data: '3509908', label: 'Cananéia' },
  { data: '3509957', label: 'Canas' },
  { data: '3510005', label: 'Cândido Mota' },
  { data: '3510104', label: 'Cândido Rodrigues' },
  { data: '3510153', label: 'Canitar' },
  { data: '3510203', label: 'Capão Bonito' },
  { data: '3510302', label: 'Capela do Alto' },
  { data: '3510401', label: 'Capivari' },
  { data: '3510500', label: 'Caraguatatuba' },
  { data: '3510609', label: 'Carapicuíba' },
  { data: '3510708', label: 'Cardoso' },
  { data: '3510807', label: 'Casa Branca' },
  { data: '3510906', label: 'Cássia dos Coqueiros' },
  { data: '3511003', label: 'Castilho' },
  { data: '3511102', label: 'Catanduva' },
  { data: '3511201', label: 'Catiguá' },
  { data: '3511300', label: 'Cedral' },
  { data: '3511409', label: 'Cerqueira César' },
  { data: '3511508', label: 'Cerquilho' },
  { data: '3511607', label: 'Cesário Lange' },
  { data: '3511706', label: 'Charqueada' },
  { data: '3557204', label: 'Chavantes' },
  { data: '3511904', label: 'Clementina' },
  { data: '3512001', label: 'Colina' },
  { data: '3512100', label: 'Colômbia' },
  { data: '3512209', label: 'Conchal' },
  { data: '3512308', label: 'Conchas' },
  { data: '3512407', label: 'Cordeirópolis' },
  { data: '3512506', label: 'Coroados' },
  { data: '3512605', label: 'Coronel Macedo' },
  { data: '3512704', label: 'Corumbataí' },
  { data: '3512803', label: 'Cosmópolis' },
  { data: '3512902', label: 'Cosmorama' },
  { data: '3513009', label: 'Cotia' },
  { data: '3513108', label: 'Cravinhos' },
  { data: '3513207', label: 'Cristais Paulista' },
  { data: '3513306', label: 'Cruzália' },
  { data: '3513405', label: 'Cruzeiro' },
  { data: '3513504', label: 'Cubatão' },
  { data: '3513603', label: 'Cunha' },
  { data: '3513702', label: 'Descalvado' },
  { data: '3513801', label: 'Diadema' },
  { data: '3513850', label: 'Dirce Reis' },
  { data: '3513900', label: 'Divinolândia' },
  { data: '3514007', label: 'Dobrada' },
  { data: '3514106', label: 'Dois Córregos' },
  { data: '3514205', label: 'Dolcinópolis' },
  { data: '3514304', label: 'Dourado' },
  { data: '3514403', label: 'Dracena' },
  { data: '3514502', label: 'Duartina' },
  { data: '3514601', label: 'Dumont' },
  { data: '3514700', label: 'Echaporã' },
  { data: '3514809', label: 'Eldorado' },
  { data: '3514908', label: 'Elias Fausto' },
  { data: '3514924', label: 'Elisiário' },
  { data: '3514957', label: 'Embaúba' },
  { data: '3515004', label: 'Embu das Artes' },
  { data: '3515103', label: 'Embu-Guaçu' },
  { data: '3515129', label: 'Emilianópolis' },
  { data: '3515152', label: 'Engenheiro Coelho' },
  { data: '3515186', label: 'Espírito Santo do Pinhal' },
  { data: '3515194', label: 'Espírito Santo do Turvo' },
  { data: '3557303', label: 'Estiva Gerbi' },
  { data: '3515301', label: 'Estrela do Norte' },
  { data: '3515202', label: "Estrela d'Oeste" },
  { data: '3515350', label: 'Euclides da Cunha Paulista' },
  { data: '3515400', label: 'Fartura' },
  { data: '3515608', label: 'Fernando Prestes' },
  { data: '3515509', label: 'Fernandópolis' },
  { data: '3515657', label: 'Fernão' },
  { data: '3515707', label: 'Ferraz de Vasconcelos' },
  { data: '3515806', label: 'Flora Rica' },
  { data: '3515905', label: 'Floreal' },
  { data: '3516002', label: 'Flórida Paulista' },
  { data: '3516101', label: 'Florínia' },
  { data: '3516200', label: 'Franca' },
  { data: '3516309', label: 'Francisco Morato' },
  { data: '3516408', label: 'Franco da Rocha' },
  { data: '3516507', label: 'Gabriel Monteiro' },
  { data: '3516606', label: 'Gália' },
  { data: '3516705', label: 'Garça' },
  { data: '3516804', label: 'Gastão Vidigal' },
  { data: '3516853', label: 'Gavião Peixoto' },
  { data: '3516903', label: 'General Salgado' },
  { data: '3517000', label: 'Getulina' },
  { data: '3517109', label: 'Glicério' },
  { data: '3517208', label: 'Guaiçara' },
  { data: '3517307', label: 'Guaimbê' },
  { data: '3517406', label: 'Guaíra' },
  { data: '3517505', label: 'Guapiaçu' },
  { data: '3517604', label: 'Guapiara' },
  { data: '3517703', label: 'Guará' },
  { data: '3517802', label: 'Guaraçaí' },
  { data: '3517901', label: 'Guaraci' },
  { data: '3518008', label: "Guarani d'Oeste" },
  { data: '3518107', label: 'Guarantã' },
  { data: '3518206', label: 'Guararapes' },
  { data: '3518305', label: 'Guararema' },
  { data: '3518404', label: 'Guaratinguetá' },
  { data: '3518503', label: 'Guareí' },
  { data: '3518602', label: 'Guariba' },
  { data: '3518701', label: 'Guarujá' },
  { data: '3518800', label: 'Guarulhos' },
  { data: '3518859', label: 'Guatapará' },
  { data: '3518909', label: 'Guzolândia' },
  { data: '3519006', label: 'Herculândia' },
  { data: '3519055', label: 'Holambra' },
  { data: '3519071', label: 'Hortolândia' },
  { data: '3519105', label: 'Iacanga' },
  { data: '3519204', label: 'Iacri' },
  { data: '3519253', label: 'Iaras' },
  { data: '3519303', label: 'Ibaté' },
  { data: '3519402', label: 'Ibirá' },
  { data: '3519501', label: 'Ibirarema' },
  { data: '3519600', label: 'Ibitinga' },
  { data: '3519709', label: 'Ibiúna' },
  { data: '3519808', label: 'Icém' },
  { data: '3519907', label: 'Iepê' },
  { data: '3520004', label: 'Igaraçu do Tietê' },
  { data: '3520103', label: 'Igarapava' },
  { data: '3520202', label: 'Igaratá' },
  { data: '3520301', label: 'Iguape' },
  { data: '3520426', label: 'Ilha Comprida' },
  { data: '3520442', label: 'Ilha Solteira' },
  { data: '3520400', label: 'Ilhabela' },
  { data: '3520509', label: 'Indaiatuba' },
  { data: '3520608', label: 'Indiana' },
  { data: '3520707', label: 'Indiaporã' },
  { data: '3520806', label: 'Inúbia Paulista' },
  { data: '3520905', label: 'Ipaussu' },
  { data: '3521002', label: 'Iperó' },
  { data: '3521101', label: 'Ipeúna' },
  { data: '3521150', label: 'Ipiguá' },
  { data: '3521200', label: 'Iporanga' },
  { data: '3521309', label: 'Ipuã' },
  { data: '3521408', label: 'Iracemápolis' },
  { data: '3521507', label: 'Irapuã' },
  { data: '3521606', label: 'Irapuru' },
  { data: '3521705', label: 'Itaberá' },
  { data: '3521804', label: 'Itaí' },
  { data: '3521903', label: 'Itajobi' },
  { data: '3522000', label: 'Itaju' },
  { data: '3522109', label: 'Itanhaém' },
  { data: '3522158', label: 'Itaóca' },
  { data: '3522208', label: 'Itapecerica da Serra' },
  { data: '3522307', label: 'Itapetininga' },
  { data: '3522406', label: 'Itapeva' },
  { data: '3522505', label: 'Itapevi' },
  { data: '3522604', label: 'Itapira' },
  { data: '3522653', label: 'Itapirapuã Paulista' },
  { data: '3522703', label: 'Itápolis' },
  { data: '3522802', label: 'Itaporanga' },
  { data: '3522901', label: 'Itapuí' },
  { data: '3523008', label: 'Itapura' },
  { data: '3523107', label: 'Itaquaquecetuba' },
  { data: '3523206', label: 'Itararé' },
  { data: '3523305', label: 'Itariri' },
  { data: '3523404', label: 'Itatiba' },
  { data: '3523503', label: 'Itatinga' },
  { data: '3523602', label: 'Itirapina' },
  { data: '3523701', label: 'Itirapuã' },
  { data: '3523800', label: 'Itobi' },
  { data: '3523909', label: 'Itu' },
  { data: '3524006', label: 'Itupeva' },
  { data: '3524105', label: 'Ituverava' },
  { data: '3524204', label: 'Jaborandi' },
  { data: '3524303', label: 'Jaboticabal' },
  { data: '3524402', label: 'Jacareí' },
  { data: '3524501', label: 'Jaci' },
  { data: '3524600', label: 'Jacupiranga' },
  { data: '3524709', label: 'Jaguariúna' },
  { data: '3524808', label: 'Jales' },
  { data: '3524907', label: 'Jambeiro' },
  { data: '3525003', label: 'Jandira' },
  { data: '3525102', label: 'Jardinópolis' },
  { data: '3525201', label: 'Jarinu' },
  { data: '3525300', label: 'Jaú' },
  { data: '3525409', label: 'Jeriquara' },
  { data: '3525508', label: 'Joanópolis' },
  { data: '3525607', label: 'João Ramalho' },
  { data: '3525706', label: 'José Bonifácio' },
  { data: '3525805', label: 'Júlio Mesquita' },
  { data: '3525854', label: 'Jumirim' },
  { data: '3525904', label: 'Jundiaí' },
  { data: '3526001', label: 'Junqueirópolis' },
  { data: '3526100', label: 'Juquiá' },
  { data: '3526209', label: 'Juquitiba' },
  { data: '3526308', label: 'Lagoinha' },
  { data: '3526407', label: 'Laranjal Paulista' },
  { data: '3526506', label: 'Lavínia' },
  { data: '3526605', label: 'Lavrinhas' },
  { data: '3526704', label: 'Leme' },
  { data: '3526803', label: 'Lençóis Paulista' },
  { data: '3526902', label: 'Limeira' },
  { data: '3527009', label: 'Lindóia' },
  { data: '3527108', label: 'Lins' },
  { data: '3527207', label: 'Lorena' },
  { data: '3527256', label: 'Lourdes' },
  { data: '3527306', label: 'Louveira' },
  { data: '3527405', label: 'Lucélia' },
  { data: '3527504', label: 'Lucianópolis' },
  { data: '3527603', label: 'Luís Antônio' },
  { data: '3527702', label: 'Luiziânia' },
  { data: '3527801', label: 'Lupércio' },
  { data: '3527900', label: 'Lutécia' },
  { data: '3528007', label: 'Macatuba' },
  { data: '3528106', label: 'Macaubal' },
  { data: '3528205', label: 'Macedônia' },
  { data: '3528304', label: 'Magda' },
  { data: '3528403', label: 'Mairinque' },
  { data: '3528502', label: 'Mairiporã' },
  { data: '3528601', label: 'Manduri' },
  { data: '3528700', label: 'Marabá Paulista' },
  { data: '3528809', label: 'Maracaí' },
  { data: '3528858', label: 'Marapoama' },
  { data: '3528908', label: 'Mariápolis' },
  { data: '3529005', label: 'Marília' },
  { data: '3529104', label: 'Marinópolis' },
  { data: '3529203', label: 'Martinópolis' },
  { data: '3529302', label: 'Matão' },
  { data: '3529401', label: 'Mauá' },
  { data: '3529500', label: 'Mendonça' },
  { data: '3529609', label: 'Meridiano' },
  { data: '3529658', label: 'Mesópolis' },
  { data: '3529708', label: 'Miguelópolis' },
  { data: '3529807', label: 'Mineiros do Tietê' },
  { data: '3530003', label: 'Mira Estrela' },
  { data: '3529906', label: 'Miracatu' },
  { data: '3530102', label: 'Mirandópolis' },
  { data: '3530201', label: 'Mirante do Paranapanema' },
  { data: '3530300', label: 'Mirassol' },
  { data: '3530409', label: 'Mirassolândia' },
  { data: '3530508', label: 'Mococa' },
  { data: '3530607', label: 'Mogi das Cruzes' },
  { data: '3530706', label: 'Mogi Guaçu' },
  { data: '3530805', label: 'Moji Mirim' },
  { data: '3530904', label: 'Mombuca' },
  { data: '3531001', label: 'Monções' },
  { data: '3531100', label: 'Mongaguá' },
  { data: '3531209', label: 'Monte Alegre do Sul' },
  { data: '3531308', label: 'Monte Alto' },
  { data: '3531407', label: 'Monte Aprazível' },
  { data: '3531506', label: 'Monte Azul Paulista' },
  { data: '3531605', label: 'Monte Castelo' },
  { data: '3531803', label: 'Monte Mor' },
  { data: '3531704', label: 'Monteiro Lobato' },
  { data: '3531902', label: 'Morro Agudo' },
  { data: '3532009', label: 'Morungaba' },
  { data: '3532058', label: 'Motuca' },
  { data: '3532108', label: 'Murutinga do Sul' },
  { data: '3532157', label: 'Nantes' },
  { data: '3532207', label: 'Narandiba' },
  { data: '3532306', label: 'Natividade da Serra' },
  { data: '3532405', label: 'Nazaré Paulista' },
  { data: '3532504', label: 'Neves Paulista' },
  { data: '3532603', label: 'Nhandeara' },
  { data: '3532702', label: 'Nipoã' },
  { data: '3532801', label: 'Nova Aliança' },
  { data: '3532827', label: 'Nova Campina' },
  { data: '3532843', label: 'Nova Canaã Paulista' },
  { data: '3532868', label: 'Nova Castilho' },
  { data: '3532900', label: 'Nova Europa' },
  { data: '3533007', label: 'Nova Granada' },
  { data: '3533106', label: 'Nova Guataporanga' },
  { data: '3533205', label: 'Nova Independência' },
  { data: '3533304', label: 'Nova Luzitânia' },
  { data: '3533403', label: 'Nova Odessa' },
  { data: '3533254', label: 'Novais' },
  { data: '3533502', label: 'Novo Horizonte' },
  { data: '3533601', label: 'Nuporanga' },
  { data: '3533700', label: 'Ocauçu' },
  { data: '3533809', label: 'Óleo' },
  { data: '3533908', label: 'Olímpia' },
  { data: '3534005', label: 'Onda Verde' },
  { data: '3534104', label: 'Oriente' },
  { data: '3534203', label: 'Orindiúva' },
  { data: '3534302', label: 'Orlândia' },
  { data: '3534401', label: 'Osasco' },
  { data: '3534500', label: 'Oscar Bressane' },
  { data: '3534609', label: 'Osvaldo Cruz' },
  { data: '3534708', label: 'Ourinhos' },
  { data: '3534807', label: 'Ouro Verde' },
  { data: '3534757', label: 'Ouroeste' },
  { data: '3534906', label: 'Pacaembu' },
  { data: '3535002', label: 'Palestina' },
  { data: '3535101', label: 'Palmares Paulista' },
  { data: '3535200', label: "Palmeira d'Oeste" },
  { data: '3535309', label: 'Palmital' },
  { data: '3535408', label: 'Panorama' },
  { data: '3535507', label: 'Paraguaçu Paulista' },
  { data: '3535606', label: 'Paraibuna' },
  { data: '3535705', label: 'Paraíso' },
  { data: '3535804', label: 'Paranapanema' },
  { data: '3535903', label: 'Paranapuã' },
  { data: '3536000', label: 'Parapuã' },
  { data: '3536109', label: 'Pardinho' },
  { data: '3536208', label: 'Pariquera-Açu' },
  { data: '3536257', label: 'Parisi' },
  { data: '3536307', label: 'Patrocínio Paulista' },
  { data: '3536406', label: 'Paulicéia' },
  { data: '3536505', label: 'Paulínia' },
  { data: '3536570', label: 'Paulistânia' },
  { data: '3536604', label: 'Paulo de Faria' },
  { data: '3536703', label: 'Pederneiras' },
  { data: '3536802', label: 'Pedra Bela' },
  { data: '3536901', label: 'Pedranópolis' },
  { data: '3537008', label: 'Pedregulho' },
  { data: '3537107', label: 'Pedreira' },
  { data: '3537156', label: 'Pedrinhas Paulista' },
  { data: '3537206', label: 'Pedro de Toledo' },
  { data: '3537305', label: 'Penápolis' },
  { data: '3537404', label: 'Pereira Barreto' },
  { data: '3537503', label: 'Pereiras' },
  { data: '3537602', label: 'Peruíbe' },
  { data: '3537701', label: 'Piacatu' },
  { data: '3537800', label: 'Piedade' },
  { data: '3537909', label: 'Pilar do Sul' },
  { data: '3538006', label: 'Pindamonhangaba' },
  { data: '3538105', label: 'Pindorama' },
  { data: '3538204', label: 'Pinhalzinho' },
  { data: '3538303', label: 'Piquerobi' },
  { data: '3538501', label: 'Piquete' },
  { data: '3538600', label: 'Piracaia' },
  { data: '3538709', label: 'Piracicaba' },
  { data: '3538808', label: 'Piraju' },
  { data: '3538907', label: 'Pirajuí' },
  { data: '3539004', label: 'Pirangi' },
  { data: '3539103', label: 'Pirapora do Bom Jesus' },
  { data: '3539202', label: 'Pirapozinho' },
  { data: '3539301', label: 'Pirassununga' },
  { data: '3539400', label: 'Piratininga' },
  { data: '3539509', label: 'Pitangueiras' },
  { data: '3539608', label: 'Planalto' },
  { data: '3539707', label: 'Platina' },
  { data: '3539806', label: 'Poá' },
  { data: '3539905', label: 'Poloni' },
  { data: '3540002', label: 'Pompéia' },
  { data: '3540101', label: 'Pongaí' },
  { data: '3540200', label: 'Pontal' },
  { data: '3540259', label: 'Pontalinda' },
  { data: '3540309', label: 'Pontes Gestal' },
  { data: '3540408', label: 'Populina' },
  { data: '3540507', label: 'Porangaba' },
  { data: '3540606', label: 'Porto Feliz' },
  { data: '3540705', label: 'Porto Ferreira' },
  { data: '3540754', label: 'Potim' },
  { data: '3540804', label: 'Potirendaba' },
  { data: '3540853', label: 'Pracinha' },
  { data: '3540903', label: 'Pradópolis' },
  { data: '3541000', label: 'Praia Grande' },
  { data: '3541059', label: 'Pratânia' },
  { data: '3541109', label: 'Presidente Alves' },
  { data: '3541208', label: 'Presidente Bernardes' },
  { data: '3541307', label: 'Presidente Epitácio' },
  { data: '3541406', label: 'Presidente Prudente' },
  { data: '3541505', label: 'Presidente Venceslau' },
  { data: '3541604', label: 'Promissão' },
  { data: '3541653', label: 'Quadra' },
  { data: '3541703', label: 'Quatá' },
  { data: '3541802', label: 'Queiroz' },
  { data: '3541901', label: 'Queluz' },
  { data: '3542008', label: 'Quintana' },
  { data: '3542107', label: 'Rafard' },
  { data: '3542206', label: 'Rancharia' },
  { data: '3542305', label: 'Redenção da Serra' },
  { data: '3542404', label: 'Regente Feijó' },
  { data: '3542503', label: 'Reginópolis' },
  { data: '3542602', label: 'Registro' },
  { data: '3542701', label: 'Restinga' },
  { data: '3542800', label: 'Ribeira' },
  { data: '3542909', label: 'Ribeirão Bonito' },
  { data: '3543006', label: 'Ribeirão Branco' },
  { data: '3543105', label: 'Ribeirão Corrente' },
  { data: '3543204', label: 'Ribeirão do Sul' },
  { data: '3543238', label: 'Ribeirão dos Índios' },
  { data: '3543253', label: 'Ribeirão Grande' },
  { data: '3543303', label: 'Ribeirão Pires' },
  { data: '3543402', label: 'Ribeirão Preto' },
  { data: '3543600', label: 'Rifaina' },
  { data: '3543709', label: 'Rincão' },
  { data: '3543808', label: 'Rinópolis' },
  { data: '3543907', label: 'Rio Claro' },
  { data: '3544004', label: 'Rio das Pedras' },
  { data: '3544103', label: 'Rio Grande da Serra' },
  { data: '3544202', label: 'Riolândia' },
  { data: '3543501', label: 'Riversul' },
  { data: '3544251', label: 'Rosana' },
  { data: '3544301', label: 'Roseira' },
  { data: '3544400', label: 'Rubiácea' },
  { data: '3544509', label: 'Rubinéia' },
  { data: '3544608', label: 'Sabino' },
  { data: '3544707', label: 'Sagres' },
  { data: '3544806', label: 'Sales' },
  { data: '3544905', label: 'Sales Oliveira' },
  { data: '3545001', label: 'Salesópolis' },
  { data: '3545100', label: 'Salmourão' },
  { data: '3545159', label: 'Saltinho' },
  { data: '3545209', label: 'Salto' },
  { data: '3545308', label: 'Salto de Pirapora' },
  { data: '3545407', label: 'Salto Grande' },
  { data: '3545506', label: 'Sandovalina' },
  { data: '3545605', label: 'Santa Adélia' },
  { data: '3545704', label: 'Santa Albertina' },
  { data: '3545803', label: "Santa Bárbara d'Oeste" },
  { data: '3546009', label: 'Santa Branca' },
  { data: '3546108', label: "Santa Clara d'Oeste" },
  { data: '3546207', label: 'Santa Cruz da Conceição' },
  { data: '3546256', label: 'Santa Cruz da Esperança' },
  { data: '3546306', label: 'Santa Cruz das Palmeiras' },
  { data: '3546405', label: 'Santa Cruz do Rio Pardo' },
  { data: '3546504', label: 'Santa Ernestina' },
  { data: '3546603', label: 'Santa Fé do Sul' },
  { data: '3546702', label: 'Santa Gertrudes' },
  { data: '3546801', label: 'Santa Isabel' },
  { data: '3546900', label: 'Santa Lúcia' },
  { data: '3547007', label: 'Santa Maria da Serra' },
  { data: '3547106', label: 'Santa Mercedes' },
  { data: '3547502', label: 'Santa Rita do Passa Quatro' },
  { data: '3547403', label: "Santa Rita d'Oeste" },
  { data: '3547601', label: 'Santa Rosa de Viterbo' },
  { data: '3547650', label: 'Santa Salete' },
  { data: '3547205', label: 'Santana da Ponte Pensa' },
  { data: '3547304', label: 'Santana de Parnaíba' },
  { data: '3547700', label: 'Santo Anastácio' },
  { data: '3547809', label: 'Santo André' },
  { data: '3547908', label: 'Santo Antônio da Alegria' },
  { data: '3548005', label: 'Santo Antônio de Posse' },
  { data: '3548054', label: 'Santo Antônio do Aracanguá' },
  { data: '3548104', label: 'Santo Antônio do Jardim' },
  { data: '3548203', label: 'Santo Antônio do Pinhal' },
  { data: '3548302', label: 'Santo Expedito' },
  { data: '3548401', label: 'Santópolis do Aguapeí' },
  { data: '3548500', label: 'Santos' },
  { data: '3548609', label: 'São Bento do Sapucaí' },
  { data: '3548708', label: 'São Bernardo do Campo' },
  { data: '3548807', label: 'São Caetano do Sul' },
  { data: '3548906', label: 'São Carlos' },
  { data: '3549003', label: 'São Francisco' },
  { data: '3549102', label: 'São João da Boa Vista' },
  { data: '3549201', label: 'São João das Duas Pontes' },
  { data: '3549250', label: 'São João de Iracema' },
  { data: '3549300', label: "São João do Pau d'Alho" },
  { data: '3549409', label: 'São Joaquim da Barra' },
  { data: '3549508', label: 'São José da Bela Vista' },
  { data: '3549607', label: 'São José do Barreiro' },
  { data: '3549706', label: 'São José do Rio Pardo' },
  { data: '3549805', label: 'São José do Rio Preto' },
  { data: '3549904', label: 'São José dos Campos' },
  { data: '3549953', label: 'São Lourenço da Serra' },
  { data: '3550001', label: 'São Luís do Paraitinga' },
  { data: '3550100', label: 'São Manuel' },
  { data: '3550209', label: 'São Miguel Arcanjo' },
  { data: '3550308', label: 'São Paulo' },
  { data: '3550407', label: 'São Pedro' },
  { data: '3550506', label: 'São Pedro do Turvo' },
  { data: '3550605', label: 'São Roque' },
  { data: '3550704', label: 'São Sebastião' },
  { data: '3550803', label: 'São Sebastião da Grama' },
  { data: '3550902', label: 'São Simão' },
  { data: '3551009', label: 'São Vicente' },
  { data: '3551108', label: 'Sarapuí' },
  { data: '3551207', label: 'Sarutaiá' },
  { data: '3551306', label: 'Sebastianópolis do Sul' },
  { data: '3551405', label: 'Serra Azul' },
  { data: '3551603', label: 'Serra Negra' },
  { data: '3551504', label: 'Serrana' },
  { data: '3551702', label: 'Sertãozinho' },
  { data: '3551801', label: 'Sete Barras' },
  { data: '3551900', label: 'Severínia' },
  { data: '3552007', label: 'Silveiras' },
  { data: '3552106', label: 'Socorro' },
  { data: '3552205', label: 'Sorocaba' },
  { data: '3552304', label: 'Sud Mennucci' },
  { data: '3552403', label: 'Sumaré' },
  { data: '3552551', label: 'Suzanápolis' },
  { data: '3552502', label: 'Suzano' },
  { data: '3552601', label: 'Tabapuã' },
  { data: '3552700', label: 'Tabatinga' },
  { data: '3552809', label: 'Taboão da Serra' },
  { data: '3552908', label: 'Taciba' },
  { data: '3553005', label: 'Taguaí' },
  { data: '3553104', label: 'Taiaçu' },
  { data: '3553203', label: 'Taiúva' },
  { data: '3553302', label: 'Tambaú' },
  { data: '3553401', label: 'Tanabi' },
  { data: '3553500', label: 'Tapiraí' },
  { data: '3553609', label: 'Tapiratiba' },
  { data: '3553658', label: 'Taquaral' },
  { data: '3553708', label: 'Taquaritinga' },
  { data: '3553807', label: 'Taquarituba' },
  { data: '3553856', label: 'Taquarivaí' },
  { data: '3553906', label: 'Tarabai' },
  { data: '3553955', label: 'Tarumã' },
  { data: '3554003', label: 'Tatuí' },
  { data: '3554102', label: 'Taubaté' },
  { data: '3554201', label: 'Tejupá' },
  { data: '3554300', label: 'Teodoro Sampaio' },
  { data: '3554409', label: 'Terra Roxa' },
  { data: '3554508', label: 'Tietê' },
  { data: '3554607', label: 'Timburi' },
  { data: '3554656', label: 'Torre de Pedra' },
  { data: '3554706', label: 'Torrinha' },
  { data: '3554755', label: 'Trabiju' },
  { data: '3554805', label: 'Tremembé' },
  { data: '3554904', label: 'Três Fronteiras' },
  { data: '3554953', label: 'Tuiuti' },
  { data: '3555000', label: 'Tupã' },
  { data: '3555109', label: 'Tupi Paulista' },
  { data: '3555208', label: 'Turiúba' },
  { data: '3555307', label: 'Turmalina' },
  { data: '3555356', label: 'Ubarana' },
  { data: '3555406', label: 'Ubatuba' },
  { data: '3555505', label: 'Ubirajara' },
  { data: '3555604', label: 'Uchoa' },
  { data: '3555703', label: 'União Paulista' },
  { data: '3555802', label: 'Urânia' },
  { data: '3555901', label: 'Uru' },
  { data: '3556008', label: 'Urupês' },
  { data: '3556107', label: 'Valentim Gentil' },
  { data: '3556206', label: 'Valinhos' },
  { data: '3556305', label: 'Valparaíso' },
  { data: '3556354', label: 'Vargem' },
  { data: '3556404', label: 'Vargem Grande do Sul' },
  { data: '3556453', label: 'Vargem Grande Paulista' },
  { data: '3556503', label: 'Várzea Paulista' },
  { data: '3556602', label: 'Vera Cruz' },
  { data: '3556701', label: 'Vinhedo' },
  { data: '3556800', label: 'Viradouro' },
  { data: '3556909', label: 'Vista Alegre do Alto' },
  { data: '3556958', label: 'Vitória Brasil' },
  { data: '3557006', label: 'Votorantim' },
  { data: '3557105', label: 'Votuporanga' },
  { data: '3557154', label: 'Zacarias' },
];

export default sp;
