import { HttpService } from '~/helpers';
import { UserService } from '~/services';

const endpoint = 'movimento/recado_posto';

const formatFilters = filters =>
  filters
    ? [
        { nome_campo: 'a.funcionario', valor: filters.funcionario, tipo: 'int' },
        { nome_campo: 'a.cnpj', valor: filters.cnpj, tipo: 'texto' },
        { nome_campo: 'a.data', valor: filters.inicial && filters.final ? `${filters.inicial} AND ${filters.final}` : '', tipo: 'datas' },
      ]
    : [];

const formatValues = values => ({
  ...values,
  usuario: UserService.getUser().login,
  cliente: UserService.getUser().codigo,
});

const RecadoPostoService = {
  buscar: (values, onSuccess, onError) => {
    HttpService.call(endpoint, 'buscar', [UserService.getUser().codigo, formatFilters(values)], onSuccess, onError);
  },
  getRecado: (codigo, onSuccess, onError) => {
    HttpService.call(endpoint, 'getRecado', [codigo, UserService.getUser().codigo], onSuccess, onError);
  },
  salvar: (values, onSuccess, onError) => {
    HttpService.call(endpoint, 'salvar', [formatValues(values)], onSuccess, onError);
  },
  excluir: (codigo, onSuccess, onError) => {
    HttpService.call(endpoint, 'excluir', [codigo, UserService.getUser().codigo], onSuccess, onError);
  },
};

export default RecadoPostoService;
