import ac from './ac';
import al from './al';
import am from './am';
import ap from './ap';
import ba from './ba';
import ce from './ce';
import df from './df';
import es from './es';
import go from './go';
import ma from './ma';
import mg from './mg';
import ms from './ms';
import mt from './mt';
import pa from './pa';
import pb from './pb';
import pe from './pe';
import pi from './pi';
import pr from './pr';
import rj from './rj';
import rn from './rn';
import ro from './ro';
import rr from './rr';
import rs from './rs';
import sc from './sc';
import se from './se';
import sp from './sp';
import to from './to';

export default {
  ac,
  al,
  am,
  ap,
  ba,
  ce,
  df,
  es,
  go,
  ma,
  mg,
  ms,
  mt,
  pa,
  pb,
  pe,
  pi,
  pr,
  rj,
  rn,
  ro,
  rr,
  rs,
  sc,
  se,
  sp,
  to,
};
