import React from 'react';
import moment from 'moment';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { FilePdfTwoTone } from '@ant-design/icons';
import { colors } from '~/options/general';
import { decimalPlace } from '~/utils';
import ExportPDFView from './ExportPDFView';

class ExportPDF extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      showTitulo: false,
    };
  }

  getHeaders = () => {
    const { columns } = this.props;
    const headers = [];

    if (columns) {
      columns.forEach(element => {
        if (element.dataIndex && element.exporter !== false) {
          headers.push({ label: element.title, key: element.dataIndex, type: element.type || '' });
        }
      });
    }

    return headers;
  };

  exportPDF = values => {
    const { dataSource, selectedSorter, columns } = this.props;

    let newDataSource = dataSource;
    let labelGrupo = '';
    if (!values.agrupamento) {
      if (selectedSorter && selectedSorter.column) {
        newDataSource = dataSource.sort(selectedSorter.column.sorter);
        if (selectedSorter.order === 'descend') {
          newDataSource.reverse();
        }
      }
    } else {
      labelGrupo = columns.find(item => item.dataIndex === values.agrupamento).title;
      const columnAgrupamentoSelected = columns.find(item => item.dataIndex === values.agrupamento);
      const typeOrder = columnAgrupamentoSelected && columnAgrupamentoSelected.type ? columnAgrupamentoSelected.type : 'string';

      if (typeOrder === 'currency' || typeOrder === 'integer' || typeOrder === 'decimal') {
        newDataSource = dataSource.sort((a, b) => parseFloat(a[values.agrupamento]) - parseFloat(b[values.agrupamento]));
      } else if (typeOrder === 'date') {
        newDataSource = dataSource
          .map(itemMap => ({
            ...itemMap,
            valores: { ...itemMap, [values.agrupamento]: (itemMap[values.agrupamento] || '99999999').replace('-', '').replace('-', '') },
          }))
          .sort((a, b) => parseFloat(a[values.agrupamento]) - parseFloat(b[values.agrupamento]));
      } else {
        newDataSource = dataSource.sort((a, b) => parseFloat(a[values.agrupamento]) - parseFloat(b[values.agrupamento]));
      }
    }

    const unit = 'pt';
    const marginLeft = 40;
    // eslint-disable-next-line new-cap
    const doc = new jsPDF(values.orientacao, unit, values.size);

    const title = values.titulo;
    const columnsRel = this.getHeaders();
    const keyColumns = columnsRel.map(item => item.key);
    const headers = [columnsRel.map(item => item.label)];

    columnsRel.forEach(coluna => {
      if (coluna.type === 'decimal' || coluna.type === 'currency') {
        newDataSource = newDataSource.map(item => ({ ...item, [coluna.key]: decimalPlace(item[coluna.key], coluna.type === 'decimal' ? 3 : 2) }));
      }
    });

    const dataPDF = [];
    let agrupamento = '';
    newDataSource.forEach(item => {
      const obj = [];
      if (values.agrupamento && agrupamento !== item[values.agrupamento]) {
        agrupamento = item[values.agrupamento];
        dataPDF.push([`   ${labelGrupo}: ${item[values.agrupamento]}`]);
      }

      keyColumns.forEach(key => {
        obj.push(item[key]);
      });

      dataPDF.push(obj);
    });

    const marginTop = 50;
    // if (empresas.selected.logo) {
    //   marginTop = 90;
    //   doc.addImage(`data:image/png;base64,${empresas.selected.logo}`, 'PNG', 10, 10, 50, 50);
    // }

    const content = {
      startY: marginTop,
      head: headers,
      body: dataPDF,
    };

    doc.setFontSize(8);
    doc.text(moment().format('DD/MM/YYYY HH:mm:ss'), 480, 10);
    // doc.text(empresas.selected.label, 480, 20);
    doc.setFontSize(15);
    doc.text(title, marginLeft, marginTop - 10);
    doc.autoTable(content);
    doc.save(`${title}-${moment().format('DDMMYYYY-HHmmss')}.pdf`);
  };

  render() {
    const { dataSource, className, columns } = this.props;
    const { showTitulo } = this.state;

    return (
      <>
        <ExportPDFView action={showTitulo} onOk={this.exportPDF} onCancel={() => this.setState({ showTitulo: false })} columns={columns} />
        <FilePdfTwoTone
          twoToneColor={colors.dustRed.color}
          title=".pdf"
          disabled={dataSource.length === 0}
          className={className}
          onClick={() => this.setState({ showTitulo: true })}
        />
      </>
    );
  }
}

export default ExportPDF;
