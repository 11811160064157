import React from 'react';
import moment from 'moment';
import _ from 'lodash';
import { Button, Col, Modal, Row } from 'antd';
import { Bar, ContentWrapper, Form, Select } from '~/components';
import './TableChartBarView.less';

class SuporteChartStatusView extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      options: [],
      datas: [],
    };
  }

  componentDidUpdate(prevProps) {
    const { action, datas, options } = this.props;

    if (action !== prevProps.action && action) {
      this.forceUpdate(() => {
        this.setState(
          {
            datas: datas.map(item => ({ data: item.dataIndex, label: item.title })),
            options: [{ data: 'quantidade_data', label: 'Quantidade' }, ...options.map(item => ({ data: item.dataIndex, label: item.title }))],
          },
          () => {
            this.searchApi.setValues({
              data: datas[0].dataIndex,
              option: 'quantidade_data',
            });
            this.forceUpdate();
          }
        );
      });
    }
  }

  render() {
    const { datas, options } = this.state;
    const { itens, action, onCancel } = this.props;

    let dataSource = [];
    const valuesForm = this.searchApi ? this.searchApi.getValues() : {};
    if (action && this.searchApi && valuesForm.data && valuesForm.option) {
      dataSource = _.groupBy(itens, item => item[valuesForm.data]);
      dataSource = _.map(dataSource, item => ({
        x: moment(item[0][valuesForm.data]).format('DD/MM/YYYY'),
        y: valuesForm.option === 'quantidade_data' ? item.length : item.reduce((previous, current) => parseFloat(current[valuesForm.option]) + previous, 0),
      }));
    }

    return (
      <Modal
        visible={action}
        title="Gráfico Barra"
        width={1000}
        onCancel={onCancel}
        centered
        destroyOnClose
        maskClosable={false}
        footer={
          <div style={{ textAlign: 'center' }}>
            <Button key="back" onClick={onCancel}>
              Fechar
            </Button>
          </div>
        }
      >
        <Form getApi={api => (this.searchApi = api)}>
          <ContentWrapper type="search">
            <Row gutter={4}>
              <Col md={6}>
                <Select field="data" label="Data" dataSource={datas} required onChange={() => this.forceUpdate()} />
              </Col>
              <Col md={6}>
                <Select field="option" label="Somatória" dataSource={options} required onChange={() => this.forceUpdate()} />
              </Col>
            </Row>
          </ContentWrapper>
        </Form>
        <Bar height={250} data={dataSource} type={valuesForm.option === 'quantidade_data' ? 'integer' : 'currency'} />
      </Modal>
    );
  }
}

export default SuporteChartStatusView;
