import { HttpService } from '~/helpers';
import { UserService, CustomerService } from '~/services';

const endpoint = 'movimento/frequencia';

const formatValues = values => ({
  ...values,
  usuario: UserService.getUser().login,
  cliente: UserService.getUser().codigo,
});

const FrequenciaService = {
  getAll: (values, onSuccess, onError) => {
    HttpService.call(endpoint, 'getAll', [formatValues(values)], onSuccess, onError);
  },
  getAllItens: (codigo, onSuccess, onError) => {
    HttpService.call(endpoint, 'getAllItens', [codigo], onSuccess, onError);
  },
  getAllPagamentos: (codigo, onSuccess, onError) => {
    HttpService.call(endpoint, 'getAllPagamentos', [codigo], onSuccess, onError);
  },
  getAllResumo: (grupo, onSuccess, onError) => {
    HttpService.call(
      endpoint,
      'getAllResumo',
      [UserService.getUser().codigo, grupo, CustomerService.getCustomer().estrutura === 'MYSAAS' ? '' : UserService.getUser().filial || ''],
      onSuccess,
      onError
    );
  },
  consultar_cupom: (obj, onSuccess, onError) => {
    HttpService.call(endpoint, 'consultar_cupom', [obj], onSuccess, onError);
  },
};

export default FrequenciaService;
