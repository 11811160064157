// Roraima
const rr = [
  { data: '1400050', label: 'Alto Alegre' },
  { data: '1400027', label: 'Amajari' },
  { data: '1400100', label: 'Boa Vista' },
  { data: '1400159', label: 'Bonfim' },
  { data: '1400175', label: 'Cantá' },
  { data: '1400209', label: 'Caracaraí' },
  { data: '1400233', label: 'Caroebe' },
  { data: '1400282', label: 'Iracema' },
  { data: '1400308', label: 'Mucajaí' },
  { data: '1400407', label: 'Normandia' },
  { data: '1400456', label: 'Pacaraima' },
  { data: '1400472', label: 'Rorainópolis' },
  { data: '1400506', label: 'São João da Baliza' },
  { data: '1400605', label: 'São Luiz' },
  { data: '1400704', label: 'Uiramutã' },
];

export default rr;
