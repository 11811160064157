import html2canvas from 'html2canvas';
import { UserService } from '~/services';

const notifyErrorImage = (descricao, stackTrace, page = '', callback = null) => {
  html2canvas(document.body).then(canvas => {
    const base64image = canvas.toDataURL('image/png');
    UserService.inserir_erro_sistema(descricao, stackTrace, page || document.title, base64image, callback);
  });
};

export default notifyErrorImage;
