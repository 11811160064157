import { produtosGrupos, produtosSubGrupos, produtosGrupoSeparacao } from './cadastros';

const rootSaga = sagaMiddleware => {
  // cadastros
  sagaMiddleware.run(produtosGrupos);
  sagaMiddleware.run(produtosSubGrupos);
  sagaMiddleware.run(produtosGrupoSeparacao);
};

export default rootSaga;
