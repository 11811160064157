// Goiás
const go = [
  { data: '5200050', label: 'Abadia de Goiás' },
  { data: '5200100', label: 'Abadiânia' },
  { data: '5200134', label: 'Acreúna' },
  { data: '5200159', label: 'Adelândia' },
  { data: '5200175', label: 'Água Fria de Goiás' },
  { data: '5200209', label: 'Água Limpa' },
  { data: '5200258', label: 'Águas Lindas de Goiás' },
  { data: '5200308', label: 'Alexânia' },
  { data: '5200506', label: 'Aloândia' },
  { data: '5200555', label: 'Alto Horizonte' },
  { data: '5200605', label: 'Alto Paraíso de Goiás' },
  { data: '5200803', label: 'Alvorada do Norte' },
  { data: '5200829', label: 'Amaralina' },
  { data: '5200852', label: 'Americano do Brasil' },
  { data: '5200902', label: 'Amorinópolis' },
  { data: '5201108', label: 'Anápolis' },
  { data: '5201207', label: 'Anhanguera' },
  { data: '5201306', label: 'Anicuns' },
  { data: '5201405', label: 'Aparecida de Goiânia' },
  { data: '5201454', label: 'Aparecida do Rio Doce' },
  { data: '5201504', label: 'Aporé' },
  { data: '5201603', label: 'Araçu' },
  { data: '5201702', label: 'Aragarças' },
  { data: '5201801', label: 'Aragoiânia' },
  { data: '5202155', label: 'Araguapaz' },
  { data: '5202353', label: 'Arenópolis' },
  { data: '5202502', label: 'Aruanã' },
  { data: '5202601', label: 'Aurilândia' },
  { data: '5202809', label: 'Avelinópolis' },
  { data: '5203104', label: 'Baliza' },
  { data: '5203203', label: 'Barro Alto' },
  { data: '5203302', label: 'Bela Vista de Goiás' },
  { data: '5203401', label: 'Bom Jardim de Goiás' },
  { data: '5203500', label: 'Bom Jesus de Goiás' },
  { data: '5203559', label: 'Bonfinópolis' },
  { data: '5203575', label: 'Bonópolis' },
  { data: '5203609', label: 'Brazabrantes' },
  { data: '5203807', label: 'Britânia' },
  { data: '5203906', label: 'Buriti Alegre' },
  { data: '5203939', label: 'Buriti de Goiás' },
  { data: '5203962', label: 'Buritinópolis' },
  { data: '5204003', label: 'Cabeceiras' },
  { data: '5204102', label: 'Cachoeira Alta' },
  { data: '5204201', label: 'Cachoeira de Goiás' },
  { data: '5204250', label: 'Cachoeira Dourada' },
  { data: '5204300', label: 'Caçu' },
  { data: '5204409', label: 'Caiapônia' },
  { data: '5204508', label: 'Caldas Novas' },
  { data: '5204557', label: 'Caldazinha' },
  { data: '5204607', label: 'Campestre de Goiás' },
  { data: '5204656', label: 'Campinaçu' },
  { data: '5204706', label: 'Campinorte' },
  { data: '5204805', label: 'Campo Alegre de Goiás' },
  { data: '5204854', label: 'Campo Limpo de Goiás' },
  { data: '5204904', label: 'Campos Belos' },
  { data: '5204953', label: 'Campos Verdes' },
  { data: '5205000', label: 'Carmo do Rio Verde' },
  { data: '5205059', label: 'Castelândia' },
  { data: '5205109', label: 'Catalão' },
  { data: '5205208', label: 'Caturaí' },
  { data: '5205307', label: 'Cavalcante' },
  { data: '5205406', label: 'Ceres' },
  { data: '5205455', label: 'Cezarina' },
  { data: '5205471', label: 'Chapadão do Céu' },
  { data: '5205497', label: 'Cidade Ocidental' },
  { data: '5205513', label: 'Cocalzinho de Goiás' },
  { data: '5205521', label: 'Colinas do Sul' },
  { data: '5205703', label: 'Córrego do Ouro' },
  { data: '5205802', label: 'Corumbá de Goiás' },
  { data: '5205901', label: 'Corumbaíba' },
  { data: '5206206', label: 'Cristalina' },
  { data: '5206305', label: 'Cristianópolis' },
  { data: '5206404', label: 'Crixás' },
  { data: '5206503', label: 'Cromínia' },
  { data: '5206602', label: 'Cumari' },
  { data: '5206701', label: 'Damianópolis' },
  { data: '5206800', label: 'Damolândia' },
  { data: '5206909', label: 'Davinópolis' },
  { data: '5207105', label: 'Diorama' },
  { data: '5208301', label: 'Divinópolis de Goiás' },
  { data: '5207253', label: 'Doverlândia' },
  { data: '5207352', label: 'Edealina' },
  { data: '5207402', label: 'Edéia' },
  { data: '5207501', label: 'Estrela do Norte' },
  { data: '5207535', label: 'Faina' },
  { data: '5207600', label: 'Fazenda Nova' },
  { data: '5207808', label: 'Firminópolis' },
  { data: '5207907', label: 'Flores de Goiás' },
  { data: '5208004', label: 'Formosa' },
  { data: '5208103', label: 'Formoso' },
  { data: '5208152', label: 'Gameleira de Goiás' },
  { data: '5208400', label: 'Goianápolis' },
  { data: '5208509', label: 'Goiandira' },
  { data: '5208608', label: 'Goianésia' },
  { data: '5208707', label: 'Goiânia' },
  { data: '5208806', label: 'Goianira' },
  { data: '5208905', label: 'Goiás' },
  { data: '5209101', label: 'Goiatuba' },
  { data: '5209150', label: 'Gouvelândia' },
  { data: '5209200', label: 'Guapó' },
  { data: '5209291', label: 'Guaraíta' },
  { data: '5209408', label: 'Guarani de Goiás' },
  { data: '5209457', label: 'Guarinos' },
  { data: '5209606', label: 'Heitoraí' },
  { data: '5209705', label: 'Hidrolândia' },
  { data: '5209804', label: 'Hidrolina' },
  { data: '5209903', label: 'Iaciara' },
  { data: '5209937', label: 'Inaciolândia' },
  { data: '5209952', label: 'Indiara' },
  { data: '5210000', label: 'Inhumas' },
  { data: '5210109', label: 'Ipameri' },
  { data: '5210158', label: 'Ipiranga de Goiás' },
  { data: '5210208', label: 'Iporá' },
  { data: '5210307', label: 'Israelândia' },
  { data: '5210406', label: 'Itaberaí' },
  { data: '5210562', label: 'Itaguari' },
  { data: '5210604', label: 'Itaguaru' },
  { data: '5210802', label: 'Itajá' },
  { data: '5210901', label: 'Itapaci' },
  { data: '5211008', label: 'Itapirapuã' },
  { data: '5211206', label: 'Itapuranga' },
  { data: '5211305', label: 'Itarumã' },
  { data: '5211404', label: 'Itauçu' },
  { data: '5211503', label: 'Itumbiara' },
  { data: '5211602', label: 'Ivolândia' },
  { data: '5211701', label: 'Jandaia' },
  { data: '5211800', label: 'Jaraguá' },
  { data: '5211909', label: 'Jataí' },
  { data: '5212006', label: 'Jaupaci' },
  { data: '5212055', label: 'Jesúpolis' },
  { data: '5212105', label: 'Joviânia' },
  { data: '5212204', label: 'Jussara' },
  { data: '5212253', label: 'Lagoa Santa' },
  { data: '5212303', label: 'Leopoldo de Bulhões' },
  { data: '5212501', label: 'Luziânia' },
  { data: '5212600', label: 'Mairipotaba' },
  { data: '5212709', label: 'Mambaí' },
  { data: '5212808', label: 'Mara Rosa' },
  { data: '5212907', label: 'Marzagão' },
  { data: '5212956', label: 'Matrinchã' },
  { data: '5213004', label: 'Maurilândia' },
  { data: '5213053', label: 'Mimoso de Goiás' },
  { data: '5213087', label: 'Minaçu' },
  { data: '5213103', label: 'Mineiros' },
  { data: '5213400', label: 'Moiporá' },
  { data: '5213509', label: 'Monte Alegre de Goiás' },
  { data: '5213707', label: 'Montes Claros de Goiás' },
  { data: '5213756', label: 'Montividiu' },
  { data: '5213772', label: 'Montividiu do Norte' },
  { data: '5213806', label: 'Morrinhos' },
  { data: '5213855', label: 'Morro Agudo de Goiás' },
  { data: '5213905', label: 'Mossâmedes' },
  { data: '5214002', label: 'Mozarlândia' },
  { data: '5214051', label: 'Mundo Novo' },
  { data: '5214101', label: 'Mutunópolis' },
  { data: '5214408', label: 'Nazário' },
  { data: '5214507', label: 'Nerópolis' },
  { data: '5214606', label: 'Niquelândia' },
  { data: '5214705', label: 'Nova América' },
  { data: '5214804', label: 'Nova Aurora' },
  { data: '5214838', label: 'Nova Crixás' },
  { data: '5214861', label: 'Nova Glória' },
  { data: '5214879', label: 'Nova Iguaçu de Goiás' },
  { data: '5214903', label: 'Nova Roma' },
  { data: '5215009', label: 'Nova Veneza' },
  { data: '5215207', label: 'Novo Brasil' },
  { data: '5215231', label: 'Novo Gama' },
  { data: '5215256', label: 'Novo Planalto' },
  { data: '5215306', label: 'Orizona' },
  { data: '5215405', label: 'Ouro Verde de Goiás' },
  { data: '5215504', label: 'Ouvidor' },
  { data: '5215603', label: 'Padre Bernardo' },
  { data: '5215652', label: 'Palestina de Goiás' },
  { data: '5215702', label: 'Palmeiras de Goiás' },
  { data: '5215801', label: 'Palmelo' },
  { data: '5215900', label: 'Palminópolis' },
  { data: '5216007', label: 'Panamá' },
  { data: '5216304', label: 'Paranaiguara' },
  { data: '5216403', label: 'Paraúna' },
  { data: '5216452', label: 'Perolândia' },
  { data: '5216809', label: 'Petrolina de Goiás' },
  { data: '5216908', label: 'Pilar de Goiás' },
  { data: '5217104', label: 'Piracanjuba' },
  { data: '5217203', label: 'Piranhas' },
  { data: '5217302', label: 'Pirenópolis' },
  { data: '5217401', label: 'Pires do Rio' },
  { data: '5217609', label: 'Planaltina' },
  { data: '5217708', label: 'Pontalina' },
  { data: '5218003', label: 'Porangatu' },
  { data: '5218052', label: 'Porteirão' },
  { data: '5218102', label: 'Portelândia' },
  { data: '5218300', label: 'Posse' },
  { data: '5218391', label: 'Professor Jamil' },
  { data: '5218508', label: 'Quirinópolis' },
  { data: '5218607', label: 'Rialma' },
  { data: '5218706', label: 'Rianápolis' },
  { data: '5218789', label: 'Rio Quente' },
  { data: '5218805', label: 'Rio Verde' },
  { data: '5218904', label: 'Rubiataba' },
  { data: '5219001', label: 'Sanclerlândia' },
  { data: '5219100', label: 'Santa Bárbara de Goiás' },
  { data: '5219209', label: 'Santa Cruz de Goiás' },
  { data: '5219258', label: 'Santa Fé de Goiás' },
  { data: '5219308', label: 'Santa Helena de Goiás' },
  { data: '5219357', label: 'Santa Isabel' },
  { data: '5219407', label: 'Santa Rita do Araguaia' },
  { data: '5219456', label: 'Santa Rita do Novo Destino' },
  { data: '5219506', label: 'Santa Rosa de Goiás' },
  { data: '5219605', label: 'Santa Tereza de Goiás' },
  { data: '5219704', label: 'Santa Terezinha de Goiás' },
  { data: '5219712', label: 'Santo Antônio da Barra' },
  { data: '5219738', label: 'Santo Antônio de Goiás' },
  { data: '5219753', label: 'Santo Antônio do Descoberto' },
  { data: '5219803', label: 'São Domingos' },
  { data: '5219902', label: 'São Francisco de Goiás' },
  { data: '5220058', label: 'São João da Paraúna' },
  { data: '5220009', label: "São João d'Aliança" },
  { data: '5220108', label: 'São Luís de Montes Belos' },
  { data: '5220157', label: 'São Luíz do Norte' },
  { data: '5220207', label: 'São Miguel do Araguaia' },
  { data: '5220264', label: 'São Miguel do Passa Quatro' },
  { data: '5220280', label: 'São Patrício' },
  { data: '5220405', label: 'São Simão' },
  { data: '5220454', label: 'Senador Canedo' },
  { data: '5220504', label: 'Serranópolis' },
  { data: '5220603', label: 'Silvânia' },
  { data: '5220686', label: 'Simolândia' },
  { data: '5220702', label: "Sítio d'Abadia" },
  { data: '5221007', label: 'Taquaral de Goiás' },
  { data: '5221080', label: 'Teresina de Goiás' },
  { data: '5221197', label: 'Terezópolis de Goiás' },
  { data: '5221304', label: 'Três Ranchos' },
  { data: '5221403', label: 'Trindade' },
  { data: '5221452', label: 'Trombas' },
  { data: '5221502', label: 'Turvânia' },
  { data: '5221551', label: 'Turvelândia' },
  { data: '5221577', label: 'Uirapuru' },
  { data: '5221601', label: 'Uruaçu' },
  { data: '5221700', label: 'Uruana' },
  { data: '5221809', label: 'Urutaí' },
  { data: '5221858', label: 'Valparaíso de Goiás' },
  { data: '5221908', label: 'Varjão' },
  { data: '5222005', label: 'Vianópolis' },
  { data: '5222054', label: 'Vicentinópolis' },
  { data: '5222203', label: 'Vila Boa' },
  { data: '5222302', label: 'Vila Propício' },
];

export default go;
