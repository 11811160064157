import React from 'react';
import { Modal } from 'antd';
import { QuestionOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { Loading, Markdown } from '~/components';
import { MenuService } from '~/services';

class HelpIcon extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      isVisible: false,
      source: '',
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const { isVisible } = this.state;
    const { tabs } = this.props;
    const actionChange = isVisible !== prevState.isVisible;

    if (actionChange && isVisible) {
      this.forceUpdate(() => {
        const titlePage = (tabs.data.find(item => item.id === tabs.activeTab) || {}).nome || '';

        if (titlePage) {
          this.setState({ isLoading: true });
          MenuService.getHelpContent(
            titlePage,
            response => this.setState({ source: response.markdown, isLoading: false }),
            () => this.setState({ source: '', isLoading: false })
          );
        }
      });
    }
  }

  render() {
    const { isVisible, isLoading, source } = this.state;
    const { tabs } = this.props;

    const titlePage = (tabs.data.find(item => item.id === tabs.activeTab) || {}).nome || '';

    return (
      <>
        <Modal
          visible={isVisible}
          centered
          onCancel={() => this.setState({ isVisible: false })}
          width={1200}
          bodyStyle={{ maxHeight: '550px', overflow: 'auto' }}
          title={`Ajuda - ${titlePage}`}
          footer={null}
          destroyOnClose
          className="modal-help"
        >
          {isLoading ? <Loading /> : <Markdown source={source} />}
        </Modal>
        <QuestionOutlined title="Ajuda" onClick={() => this.setState({ isVisible: true })} />
      </>
    );
  }
}

const mapStateToProps = ({ tabs }) => ({ tabs });
export default connect(mapStateToProps)(HelpIcon);
