import React from 'react';
import { Tooltip } from 'antd';
import PubSub from 'pubsub-js';
import { SettingOutlined } from '@ant-design/icons';
import { Strings } from '~/helpers/general';

class Settings extends React.PureComponent {
  onClick = () => {
    const { onClick } = this.props;

    PubSub.publish(Strings.PUBSUB_SETTINGS);

    if (onClick) {
      onClick();
    }
  };

  render() {
    const { className } = this.props;

    return (
      <Tooltip title="Preferências">
        <div className={className} onClick={this.onClick} onKeyPress={this.onClick} role="button">
          <SettingOutlined style={{ fontSize: '16px' }} />
        </div>
      </Tooltip>
    );
  }
}

export default Settings;
