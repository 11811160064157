// FUNÇÕES / VALIDADORES USADOS EM OUTROS ARQUIVOS
const regexEmail = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/m;

const parserCEP = value => {
  return value.replace(/[^0-9]/g, '').replace(/^([0-9]{2})([0-9]{3})([0-9]{3})$/g, '$1.$2-$3');
};

const parserCNPJ = value => {
  return value.replace(/[^0-9]/g, '').replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/g, '$1.$2.$3/$4-$5');
};

// FUNÇÕES USADAS APENAS NESTE ARQUIVO
const isCNPJ = input => {
  const value = (input || '').replace(/[^0-9]/g, '');
  if (value === '') {
    return undefined;
  }

  if (value.length < 14 || /^(.)\1*$/.test(value)) {
    return 'CNPJ inválido!';
  }

  const verifyingDigit = verifyingLength => {
    const numbers = value.substring(0, verifyingLength);
    let rest = verifyingLength - 7;
    let sum = 0;
    let result = 0;

    for (let index = verifyingLength; index >= 1; index -= 1) {
      sum += numbers.charAt(verifyingLength - index) * rest;
      rest -= 1;

      if (rest < 2) {
        rest = 9;
      }
    }

    result = 11 - (sum % 11);

    return result > 9 ? 0 : result;
  };

  const length = value.length - 2;
  const digits = value.substring(length);
  const firstVerifyingDigit = parseInt(digits.charAt(0), 10);
  const secondVerifyingDigit = parseInt(digits.charAt(1), 10);
  const validaDigitos = verifyingDigit(length) !== firstVerifyingDigit || verifyingDigit(length + 1) !== secondVerifyingDigit;

  return validaDigitos ? 'CNPJ inválido!' : undefined;
};

const isCPF = input => {
  const value = (input || '').replace(/[^0-9]/g, '');
  if (value === '') {
    return undefined;
  }

  if (value.length < 11 || /^(.)\1*$/.test(value)) {
    return 'CPF inválido!';
  }

  let Soma = 0;
  let Resto;

  for (let i = 1; i <= 9; i += 1) {
    Soma += parseInt(value.substring(i - 1, i), 10) * (11 - i);
  }

  Resto = (Soma * 10) % 11;
  if (Resto === 10 || Resto === 11) {
    Resto = 0;
  }
  if (Resto !== parseInt(value.substring(9, 10), 10)) {
    return 'CPF inválido!';
  }

  Soma = 0;
  for (let i = 1; i <= 10; i += 1) {
    Soma += parseInt(value.substring(i - 1, i), 10) * (12 - i);
  }
  Resto = (Soma * 10) % 11;

  if (Resto === 10 || Resto === 11) {
    Resto = 0;
  }
  if (Resto !== parseInt(value.substring(10, 11), 10)) {
    return 'CPF inválido!';
  }

  return undefined;
};

const calculaDigitoBlocoBoleto = bloco => {
  let npeso = 1;
  let soma = 0;

  for (let i = bloco.length; i > 0; i -= 1) {
    npeso = npeso === 1 ? 2 : 1;
    let nvalor = parseInt(bloco.substr(i - 1, 1), 10) * npeso;
    if (nvalor > 9) nvalor = parseInt(nvalor.toString().substr(0, 1), 10) + parseInt(nvalor.toString().substr(1, 1), 10);
    soma += nvalor;
  }
  const digito = 10 - (soma % 10);
  return digito === 10 ? '0' : digito.toString();
};

// FUNÇÕES PARA OS COMPONENTES
const id = {
  id: 'id',
};

const string = {
  id: 'string',
  restrict: value => !/[^A-Za-z0-9\u00C0-\u00FF &._()-\\]/g.test(value),
  parser: value => value.replace(/[^A-Za-z0-9\u00C0-\u00FF &._()-\\]/g, ''),
};

const password = {
  id: 'password',
  parser: value => value,
};

const textArea = {
  id: 'textArea',
  restrict: value => !/[^A-Za-z0-9\u00C0-\u00FF $!#%&._()-\\r\n\\]/g.test(value),
  parser: value => value.replace(/[^A-Za-z0-9\u00C0-\u00FF $!#%&._()-\\r\n\\]/g, ''),
};

const justificativa = {
  id: 'justificativa',
  length: 200,
  parser: value => value,
};

const integer = {
  id: 'integer',
  length: 10,
  restrict: value => !/[^0-9]/g.test(value),
  parser: value => (value || '').replace(/[^0-9]/g, ''),
};

const onlyInteger = {
  id: 'onlyInteger',
  restrict: value => !/[^0-9]/g.test(value),
  parser: value => value.replace(/[^0-9]/g, ''),
};

const integerDot = {
  id: 'integerDot',
  restrict: value => !/[^0-9.]/g.test(value),
  parser: value => (value || '').replace(/[^0-9.]/g, ''),
};

const integerDash = {
  id: 'integerDash',
  restrict: value => !/[^0-9-]/g.test(value),
  parser: value => (value && value !== '' ? value.toString().replace(/[^0-9-]/g, '') : ''),
};

const integerBar = {
  id: 'integerBar',
  restrict: value => !/[^0-9/]/g.test(value),
  parser: value => (value || '').replace(/[^0-9/]/g, ''),
};

// CPF PARCIAL
const integerDotDash = {
  id: 'integerDotDash',
  restrict: value => !/[^0-9.-]/g.test(value),
  parser: value =>
    value
      .replace(/[^0-9.-]/g, '')
      .replace(/--/g, '-')
      .replace(/\.\./g, '.'),
};

// CNPJ PARCIAL
const integerDotDashBar = {
  id: 'integerDotDashBar',
  restrict: value => !/[^0-9./-]/g.test(value),
  parser: value =>
    value
      .replace(/[^0-9./-]/g, '')
      .replace(/--/g, '-')
      .replace(/\.\./g, '.')
      .replace(/\/\//g, '/'),
};

// LISTA DE NÚMEROS EX. 123,456,789
const integerList = {
  id: 'integerList',
  restrict: value => !/[^0-9,]/g.test(value),
  parser: value =>
    value
      .replace(/[^0-9,]/g, '')
      .replace(/^,/g, '')
      .replace(/,,/g, ','),
};

// STRING DE NÚMEROS
const integerString = {
  id: 'integerString',
  restrict: value => !/[^0-9]/g.test(value),
  parser: value => value.replace(/[^0-9]/g, ''),
};

const letters = {
  id: 'letters',
  restrict: value => !/[^A-Za-z]/g.test(value),
  parser: value => value.replace(/[^A-Za-z]/g, ''),
};

const alphaNumeric = {
  id: 'alphaNumeric',
  restrict: value => !/[^A-Za-z0-9\u00C0-\u00FF '"&.()-\\]/g.test(value),
  parser: value => value.replace(/[^A-Za-z0-9\u00C0-\u00FF '"&.()-\\]/g, ''),
};

const alfaNumerico = {
  id: 'alfaNumerico',
  restrict: value => !/[^A-Za-z0-9]/g.test(value),
  parser: value => value.replace(/[^A-Za-z0-9]/g, ''),
};

const textoAlfaNumerico = {
  id: 'textoAlfaNumerico',
  restrict: value => !/[^A-Za-z0-9 ]/g.test(value),
  parser: value => value.replace(/[^A-Za-z0-9 ]/g, ''),
};

const time = {
  id: 'time',
  length: 5,
  restrict: value => !/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/g.test(value),
  parser: value => {
    const newValue = value.replace(/[^0-9]/, '');
    return newValue
      .replace(/^[^0-2]/g, '')
      .replace(/^([0-1]{1})([^0-9])/g, '$1')
      .replace(/^(2{1})([^0-3])/g, '$1')
      .replace(/^(.{2})([^0-5])/g, '$1')
      .replace(/^(.{3})([^0-9])/g, '$1')
      .replace(/^([0-2][0-9])([0-5][0-9])$/g, '$1:$2');
  },
  validate: value => {
    if (value && (value.length < 5 || value.length > 5 || !/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/g.test(value))) {
      return 'Hora inválida!';
    }
    return undefined;
  },
};

const hhmmss = {
  id: 'hhmmss',
  length: 8,
  restrict: value => !/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]$/g.test(value),
  parser: value => {
    return value.replace(/[^0-9:]/, '');
  },
  /*
  validate: value => {
    if (
      value &&
      ((value.length === 5 && !/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/g.test(value)) ||
        value.length === 8 ||
        !/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]$/g.test(value))
    ) {
      return 'Hora inválida!';
    }
    return undefined;
  },
  */
};

const postal = {
  id: 'postal',
  length: 10,
  restrict: value => /^(\d{1,2}|\d{2}[.]{0,1}|\d{2}[.]{0,1}\d{1,3}|\d{2}[.]{0,1}\d{3}[-]{0,1}|\d{2}[.]{0,1}\d{3}[-]{0,1}\d{1,3})$/g.test(value) || value === '',
  parser: value => parserCEP(value),
  validate: value => {
    if (value && value.replace(/[^0-9]/g, '').length > 0 && value.replace(/[^0-9]/g, '').length < 8) {
      return 'CEP inválido!';
    }
    return undefined;
  },
};

const placa = {
  id: 'placa',
  length: 8,
  parser: value =>
    (value.substring(0, 3).replace(/[^A-Za-z]/g, '') + value.substring(3).replace(/[^A-Za-z0-9]/g, '')).replace(/(^[A-Za-z0-9]{3})([A-Za-z0-9])/, '$1-$2'),
};

const cest = {
  id: 'cest',
  length: 9,
  restrict: value => !/[^0-9.]/g.test(value),
  parser: value => value.replace(/[^0-9]/g, '').replace(/^(\d{2})(\d{3})(\d{2})$/g, '$1.$2.$3'),
  validate: value => {
    if (value && value.length < 9 && value.length > 0) {
      return 'Código inválido!';
    }
    return undefined;
  },
};

const ncm = {
  id: 'ncm',
  length: 10,
  restrict: value => !/[^0-9.\u00C0-\u00FF]/g.test(value),
  parser: value => value.replace(/[^0-9]/g, '').replace(/^(\d{4})(\d{2})(\d{2})$/g, '$1.$2.$3'),
};

const cnpj = {
  id: 'cnpj',
  length: 18,
  restrict: value => /[0-9/.-]/g.test(value) || value === '',
  parser: value => parserCNPJ(value),
  validate: value => isCNPJ(value),
};

const cpf = {
  id: 'cpf',
  length: 14,
  restrict: value => /[0-9.-]/g.test(value) || value === '',
  parser: value => value.replace(/[^0-9]/g, '').replace(/^(\d{3})(\d{3})(\d{3})(\d{2})$/g, '$1.$2.$3-$4'),
  validate: value => isCPF(value),
};

const cpfCnpj = {
  id: 'cpfCnpj',
  length: 18,
  restrict: value => /[0-9/.-]/g.test(value) || value === '',
  parser: value => {
    if (value.length < 12) {
      return value.replace(/[^0-9]/g, '').replace(/^(\d{3})(\d{3})(\d{3})(\d{2})$/g, '$1.$2.$3-$4');
    }
    if (value.length > 11) {
      return value.replace(/[^0-9]/g, '').replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/g, '$1.$2.$3/$4-$5');
    }
    return value.replace(/[^0-9]/g, '');
  },
  validate: value => {
    if (value && value.length > 0 && value.length !== 14 && value.length !== 18) {
      return 'Documento inválido!';
    }
    if (value && value.length === 14) {
      return isCPF(value);
    }
    if (value && value.length === 18) {
      return isCNPJ(value);
    }
    return undefined;
  },
};

const phone = {
  id: 'phone',
  length: 15,
  restrict: value => /^[0-9 ()-]{0,}$/g.test(value),
  parser: value => {
    let newValue = value.replace(/^0|[^0-9-]/g, '');
    if ((newValue.length >= 11 && newValue.includes('-')) || (newValue.length >= 10 && !newValue.includes('-'))) {
      newValue = value.replace(/^0|[^0-9]/g, '');
      let regex = /(\d{2})(\d{4})(\d{4})/g;
      if (newValue.length === 11) {
        regex = /(\d{2})(\d{5})(\d{4})/g;
      }
      return newValue.replace(regex, '($1) $2-$3');
    }
    return newValue;
  },
  validate: value => {
    if (value && value.replace(/[^0-9]/g, '').length < 10) {
      return 'Telefone inválido!';
    }
    return undefined;
  },
};

const email = {
  id: 'email',
  restrict: value => !/[^a-z0-9@&._;-]/g.test(value),
  parser: value => value.toLowerCase().replace(/[^a-z0-9@&._;-]/g, ''),
  validate: value => {
    if (value && (/[^a-z0-9@&._;-]/g.test(value) || /[;]{2,}/g.test(value))) {
      return 'Email inválido!';
    }
    return undefined;
  },
};

// DIGITADO: 23795904059000000683408002901505387390000009990
// LEITOR: 23793873900000099905904090000006830800290150
// FORMATADO: 23795.90405 90000.006834 08002.901505 3 87390000009990
const fatura = {
  id: 'fatura',
  restrict: value => /^[\d., ]{0,}$/g.test(value),
  onBlur: value => {
    const text = value.replace(/[^0-9. ]/, '');

    const isBoleto = text.substr(0, 1) !== '8';
    let retorno = text.length > 54 && isBoleto ? text.substr(0, 54) : text;
    const isLeitora = text.indexOf(' ') === -1 && text.indexOf('.') === -1 && (text.length === 44 || text.length === 48);

    if (isBoleto) {
      if (isLeitora) {
        retorno = text
          .split(' ')
          .join('')
          .split('.')
          .join('');

        retorno =
          text.substr(0, 4) +
          text.substr(19, 5) +
          calculaDigitoBlocoBoleto(text.substr(0, 4) + text.substr(19, 5)) +
          text.substr(24, 10) +
          calculaDigitoBlocoBoleto(text.substr(24, 10)) +
          text.substr(34, 10) +
          calculaDigitoBlocoBoleto(text.substr(34, 10)) +
          text.substr(4, 15);
        retorno = `${retorno.substr(0, 5)}.${retorno.substr(5, 5)} ${retorno.substr(10, 5)}.${retorno.substr(15, 6)} ${retorno.substr(21, 5)}.${retorno.substr(
          26,
          6
        )} ${retorno.substr(32, 1)} ${retorno.substr(33, 14)}`;
      } else if (!isLeitora) {
        if (text.length === 47 && value.indexOf('.') === -1 && value.indexOf(' ') === -1) {
          retorno = `${text.substr(0, 5)}.${text.substr(5, 5)} ${text.substr(10, 5)}.${text.substr(15, 6)} ${text.substr(21, 5)}.${text.substr(
            26,
            6
          )} ${text.substr(32, 1)} ${text.substr(33, 14)}`;
        }
      }
    } else if (isLeitora) {
      retorno = `${retorno.substr(0, 11)} ${retorno.substr(11, 1)} ${retorno.substr(12, 11)} ${retorno.substr(23, 1)} ${retorno.substr(
        24,
        11
      )} ${retorno.substr(35, 1)} ${retorno.substr(36, 11)} ${retorno.substr(47, 1)}`;
    } else if (
      text.length === 11 ||
      text.length === 13 ||
      text.length === 25 ||
      text.length === 27 ||
      text.length === 39 ||
      text.length === 41 ||
      text.length === 53
    ) {
      retorno += ' ';
    }

    return retorno;
  },
  onChange: value => {
    const text = value.replace(/[^0-9. ]/, '');

    const isBoleto = text.substr(0, 1) !== '8';
    let retorno = text.length > 54 && isBoleto ? text.substr(0, 54) : text;

    if (isBoleto) {
      if (text.length === 5 || text.length === 17 || text.length === 30) {
        retorno += '.';
      } else if (text.length === 11 || text.length === 24 || text.length === 37 || text.length === 39) {
        retorno += ' ';
      } else if (text.length === 47 && value.indexOf('.') === -1 && value.indexOf(' ') === -1) {
        retorno = `${text.substr(0, 5)}.${text.substr(5, 5)} ${text.substr(10, 5)}.${text.substr(15, 6)} ${text.substr(21, 5)}.${text.substr(
          26,
          6
        )} ${text.substr(32, 1)} ${text.substr(33, 14)}`;
      }
    } else if (
      text.length === 11 ||
      text.length === 13 ||
      text.length === 25 ||
      text.length === 27 ||
      text.length === 39 ||
      text.length === 41 ||
      text.length === 53
    ) {
      retorno += ' ';
    }

    return retorno;
  },
  validate: value => {
    const calculaDigitoMOD11 = bloco => {
      let npeso = 2;
      let soma = 0;

      for (let i = bloco.length; i > 0; i -= 1) {
        const nvalor = parseInt(bloco.substr(i - 1, 1), 10) * npeso;
        soma += nvalor;
        npeso = npeso === 9 ? 2 : npeso + 1;
      }
      const digito = 11 - (soma % 11);
      return digito > 9 ? '1' : digito.toString();
    };

    let errorMessage = false;

    if (value) {
      const isBoleto = value.substr(0, 1) !== '8';
      const strFatura = value
        .split(' ')
        .join('')
        .split('.')
        .join('');

      if (value.length < 54 && value) errorMessage = 'Complete a linha digitável!';

      if (isBoleto) {
        if (strFatura.length > 9 && calculaDigitoBlocoBoleto(strFatura.substr(0, 9)) !== strFatura.substr(9, 1)) errorMessage = 'Bloco 1 inválido!';
        if (strFatura.length > 20 && calculaDigitoBlocoBoleto(strFatura.substr(10, 10)) !== strFatura.substr(20, 1)) errorMessage = 'Bloco 2 inválido!';
        if (strFatura.length > 31 && calculaDigitoBlocoBoleto(strFatura.substr(21, 10)) !== strFatura.substr(31, 1)) errorMessage = 'Bloco 3 inválido!';
        if (
          strFatura.length === 47 &&
          calculaDigitoMOD11(
            strFatura.substr(0, 4) + strFatura.substr(33, 14) + strFatura.substr(4, 5) + strFatura.substr(10, 10) + strFatura.substr(21, 10)
          ) !== strFatura.substr(32, 1)
        )
          errorMessage = 'Boleto inválido!';
      }
    }
    return errorMessage || undefined;
  },
};

export default {
  integer,
  onlyInteger,
  string,
  password,
  textArea,
  justificativa,
  id,
  postal,
  cnpj,
  cpf,
  cpfCnpj,
  phone,
  email,
  cest,
  integerDot,
  integerDash,
  integerBar,
  ncm,
  integerDotDash,
  integerDotDashBar,
  integerList,
  integerString,
  fatura,
  placa,
  alphaNumeric,
  alfaNumerico,
  textoAlfaNumerico,
  letters,
  time,
  hhmmss,
  regexEmail,
  parserCEP,
  parserCNPJ,
};
