import { UserService, MenuService, StoreService, CustomerService, RememberService, FileService } from './general';

import { MotoristaService, VeiculoService } from './cadastros';

import {
  FrequenciaService,
  PedidoService,
  MapaPedidoService,
  RecadoMotoristaService,
  RecadoPostoService,
  PesquisaPrecoService,
  ContentoresEmbalagensService,
} from './movimento';

import { ChequeService, ContaPagarService } from './financeiro';

import { RelatorioService } from './relatorio';

import { DashboardService } from './sistema';

export {
  // general
  UserService,
  MenuService,
  StoreService,
  CustomerService,
  RememberService,
  FileService,
  // cadastros
  MotoristaService,
  VeiculoService,
  // movimento
  FrequenciaService,
  PedidoService,
  MapaPedidoService,
  RecadoMotoristaService,
  RecadoPostoService,
  PesquisaPrecoService,
  ContentoresEmbalagensService,
  // financeiro
  ChequeService,
  ContaPagarService,
  // relatorio
  RelatorioService,
  // sistema
  DashboardService,
};
