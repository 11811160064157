import React from 'react';
import Keyboard from 'react-simple-keyboard';
import classNames from 'classnames';
import { CloseCircleOutlined, RightCircleOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { Page } from '~/components';
import { types } from '~/components/forms/InputNumber/options';
import './KeyboardNumericView.less';

class KeyboardNumericView extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      numericText: '',
    };
  }

  componentDidUpdate(prevProps) {
    const { config } = this.props;
    const valueChange = JSON.stringify(config || {}) !== JSON.stringify(prevProps.config || {});

    if (valueChange) {
      this.forceUpdate(() => {
        this.keyboard.clearInput();
        this.setState({ numericText: (config || {}).initialValue || '' });
      });
    }
  }

  handleSave = () => {
    const { numericText } = this.state;
    const { config } = this.props;

    if (config && config.onOk) {
      config.onOk(numericText);
    }
  };

  handleCancel = () => {
    const { config } = this.props;
    if (config && config.onOk) {
      config.onOk(config.initialValue || '');
    }
  };

  onKeyPressButton = value => {
    if (value === '{erase}') {
      this.setState({ numericText: '' });
      this.keyboard.clearInput();
    }
  };

  render() {
    const { config } = this.props;
    const { numericText } = this.state;
    const type = types[config && config.type ? config.type : 'integer'];

    return (
      <Page.Modal
        visible={!!config}
        width={700}
        forceRender
        centered
        destroyOnClose
        className="modal-keyboard-numeric"
        footer={
          <div className="modal-keyboard-footer" style={{ width: '100%', textAlign: 'center' }}>
            <Button
              key="cancelar"
              icon={<CloseCircleOutlined />}
              size="large"
              className="btn-large-normal-keyboard button-keyboard-cancelar"
              danger
              onClick={this.handleCancel}
            >
              CANCELAR
            </Button>
            <Button
              key="submit"
              type="primary"
              icon={<RightCircleOutlined />}
              size="large"
              className="btn-large-normal-keyboard button-keyboard-proximo"
              onClick={this.handleSave}
            >
              CONFIRMAR
            </Button>
          </div>
        }
      >
        <div className="box-keyboard-titulo">{config ? config.titulo || '' : ''}</div>
        <div className="box-keyboard-valor">{`${type.formatter(numericText || '0')}`}</div>

        <Keyboard
          keyboardRef={r => (this.keyboard = r)}
          layout={{ default: ['1 2 3', '4 5 6', '7 8 9', '{erase} 0 ,'] }}
          display={{ '{bksp}': 'APAGAR', '{erase}': 'LIMPAR' }}
          buttonTheme={[
            {
              class: 'button-keyboard-limpar',
              buttons: '{erase}',
            },
            {
              class: classNames('button-keyboard-apagar', type.id === 'integer' ? 'icon-disabled' : ''),
              buttons: ',',
            },
            {
              class: 'button-keyboard-linha',
              buttons: '{erase} 0 ,',
            },
          ]}
          theme={'hg-theme-default keyboardNumeric'}
          onChange={input => this.setState({ numericText: input.replace('APAGAR', '').replace(',', '.') })}
          inputName="myKeyboard"
          onKeyPress={button => this.onKeyPressButton(button)}
        />
      </Page.Modal>
    );
  }
}

export default KeyboardNumericView;
