// Minas Gerais
const mg = [
  
    { data: '3100104', label: 'Abadia dos Dourados' },
    { data: '3100203', label: 'Abaeté' },
    { data: '3100302', label: 'Abre Campo' },
    { data: '3100401', label: 'Acaiaca' },
    { data: '3100500', label: 'Açucena' },
    { data: '3100609', label: 'Água Boa' },
    { data: '3100708', label: 'Água Comprida' },
    { data: '3100807', label: 'Aguanil' },
    { data: '3100906', label: 'Águas Formosas' },
    { data: '3101003', label: 'Águas Vermelhas' },
    { data: '3101102', label: 'Aimorés' },
    { data: '3101201', label: 'Aiuruoca' },
    { data: '3101300', label: 'Alagoa' },
    { data: '3101409', label: 'Albertina' },
    { data: '3101508', label: 'Além Paraíba' },
    { data: '3101607', label: 'Alfenas' },
    { data: '3101631', label: 'Alfredo Vasconcelos' },
    { data: '3101706', label: 'Almenara' },
    { data: '3101805', label: 'Alpercata' },
    { data: '3101904', label: 'Alpinópolis' },
    { data: '3102001', label: 'Alterosa' },
    { data: '3102050', label: 'Alto Caparaó' },
    { data: '3153509', label: 'Alto Jequitibá' },
    { data: '3102100', label: 'Alto Rio Doce' },
    { data: '3102209', label: 'Alvarenga' },
    { data: '3102308', label: 'Alvinópolis' },
    { data: '3102407', label: 'Alvorada de Minas' },
    { data: '3102506', label: 'Amparo do Serra' },
    { data: '3102605', label: 'Andradas' },
    { data: '3102803', label: 'Andrelândia' },
    { data: '3102852', label: 'Angelândia' },
    { data: '3102902', label: 'Antônio Carlos' },
    { data: '3103009', label: 'Antônio Dias' },
    { data: '3103108', label: 'Antônio Prado de Minas' },
    { data: '3103207', label: 'Araçaí' },
    { data: '3103306', label: 'Aracitaba' },
    { data: '3103405', label: 'Araçuaí' },
    { data: '3103504', label: 'Araguari' },
    { data: '3103603', label: 'Arantina' },
    { data: '3103702', label: 'Araponga' },
    { data: '3103751', label: 'Araporã' },
    { data: '3103801', label: 'Arapuá' },
    { data: '3103900', label: 'Araújos' },
    { data: '3104007', label: 'Araxá' },
    { data: '3104106', label: 'Arceburgo' },
    { data: '3104205', label: 'Arcos' },
    { data: '3104304', label: 'Areado' },
    { data: '3104403', label: 'Argirita' },
    { data: '3104452', label: 'Aricanduva' },
    { data: '3104502', label: 'Arinos' },
    { data: '3104601', label: 'Astolfo Dutra' },
    { data: '3104700', label: 'Ataléia' },
    { data: '3104809', label: 'Augusto de Lima' },
    { data: '3104908', label: 'Baependi' },
    { data: '3105004', label: 'Baldim' },
    { data: '3105103', label: 'Bambuí' },
    { data: '3105202', label: 'Bandeira' },
    { data: '3105301', label: 'Bandeira do Sul' },
    { data: '3105400', label: 'Barão de Cocais' },
    { data: '3105509', label: 'Barão de Monte Alto' },
    { data: '3105608', label: 'Barbacena' },
    { data: '3105707', label: 'Barra Longa' },
    { data: '3105905', label: 'Barroso' },
    { data: '3106002', label: 'Bela Vista de Minas' },
    { data: '3106101', label: 'Belmiro Braga' },
    { data: '3106200', label: 'Belo Horizonte' },
    { data: '3106309', label: 'Belo Oriente' },
    { data: '3106408', label: 'Belo Vale' },
    { data: '3106507', label: 'Berilo' },
    { data: '3106655', label: 'Berizal' },
    { data: '3106606', label: 'Bertópolis' },
    { data: '3106705', label: 'Betim' },
    { data: '3106804', label: 'Bias Fortes' },
    { data: '3106903', label: 'Bicas' },
    { data: '3107000', label: 'Biquinhas' },
    { data: '3107109', label: 'Boa Esperança' },
    { data: '3107208', label: 'Bocaina de Minas' },
    { data: '3107307', label: 'Bocaiúva' },
    { data: '3107406', label: 'Bom Despacho' },
    { data: '3107505', label: 'Bom Jardim de Minas' },
    { data: '3107604', label: 'Bom Jesus da Penha' },
    { data: '3107703', label: 'Bom Jesus do Amparo' },
    { data: '3107802', label: 'Bom Jesus do Galho' },
    { data: '3107901', label: 'Bom Repouso' },
    { data: '3108008', label: 'Bom Sucesso' },
    { data: '3108107', label: 'Bonfim' },
    { data: '3108206', label: 'Bonfinópolis de Minas' },
    { data: '3108255', label: 'Bonito de Minas' },
    { data: '3108305', label: 'Borda da Mata' },
    { data: '3108404', label: 'Botelhos' },
    { data: '3108503', label: 'Botumirim' },
    { data: '3108701', label: 'Brás Pires' },
    { data: '3108552', label: 'Brasilândia de Minas' },
    { data: '3108602', label: 'Brasília de Minas' },
    { data: '3108909', label: 'Brasópolis' },
    { data: '3108800', label: 'Braúnas' },
    { data: '3109006', label: 'Brumadinho' },
    { data: '3109105', label: 'Bueno Brandão' },
    { data: '3109204', label: 'Buenópolis' },
    { data: '3109253', label: 'Bugre' },
    { data: '3109303', label: 'Buritis' },
    { data: '3109402', label: 'Buritizeiro' },
    { data: '3109451', label: 'Cabeceira Grande' },
    { data: '3109501', label: 'Cabo Verde' },
    { data: '3109600', label: 'Cachoeira da Prata' },
    { data: '3109709', label: 'Cachoeira de Minas' },
    { data: '3102704', label: 'Cachoeira de Pajeú' },
    { data: '3109808', label: 'Cachoeira Dourada' },
    { data: '3109907', label: 'Caetanópolis' },
    { data: '3110004', label: 'Caeté' },
    { data: '3110103', label: 'Caiana' },
    { data: '3110202', label: 'Cajuri' },
    { data: '3110301', label: 'Caldas' },
    { data: '3110400', label: 'Camacho' },
    { data: '3110509', label: 'Camanducaia' },
    { data: '3110608', label: 'Cambuí' },
    { data: '3110707', label: 'Cambuquira' },
    { data: '3110806', label: 'Campanário' },
    { data: '3110905', label: 'Campanha' },
    { data: '3111002', label: 'Campestre' },
    { data: '3111101', label: 'Campina Verde' },
    { data: '3111150', label: 'Campo Azul' },
    { data: '3111200', label: 'Campo Belo' },
    { data: '3111309', label: 'Campo do Meio' },
    { data: '3111408', label: 'Campo Florido' },
    { data: '3111507', label: 'Campos Altos' },
    { data: '3111606', label: 'Campos Gerais' },
    { data: '3111903', label: 'Cana Verde' },
    { data: '3111705', label: 'Canaã' },
    { data: '3111804', label: 'Canápolis' },
    { data: '3112000', label: 'Candeias' },
    { data: '3112059', label: 'Cantagalo' },
    { data: '3112109', label: 'Caparaó' },
    { data: '3112208', label: 'Capela Nova' },
    { data: '3112307', label: 'Capelinha' },
    { data: '3112406', label: 'Capetinga' },
    { data: '3112505', label: 'Capim Branco' },
    { data: '3112604', label: 'Capinópolis' },
    { data: '3112653', label: 'Capitão Andrade' },
    { data: '3112703', label: 'Capitão Enéas' },
    { data: '3112802', label: 'Capitólio' },
    { data: '3112901', label: 'Caputira' },
    { data: '3113008', label: 'Caraí' },
    { data: '3113107', label: 'Caranaíba' },
    { data: '3113206', label: 'Carandaí' },
    { data: '3113305', label: 'Carangola' },
    { data: '3113404', label: 'Caratinga' },
    { data: '3113503', label: 'Carbonita' },
    { data: '3113602', label: 'Careaçu' },
    { data: '3113701', label: 'Carlos Chagas' },
    { data: '3113800', label: 'Carmésia' },
    { data: '3113909', label: 'Carmo da Cachoeira' },
    { data: '3114006', label: 'Carmo da Mata' },
    { data: '3114105', label: 'Carmo de Minas' },
    { data: '3114204', label: 'Carmo do Cajuru' },
    { data: '3114303', label: 'Carmo do Paranaíba' },
    { data: '3114402', label: 'Carmo do Rio Claro' },
    { data: '3114501', label: 'Carmópolis de Minas' },
    { data: '3114550', label: 'Carneirinho' },
    { data: '3114600', label: 'Carrancas' },
    { data: '3114709', label: 'Carvalhópolis' },
    { data: '3114808', label: 'Carvalhos' },
    { data: '3114907', label: 'Casa Grande' },
    { data: '3115003', label: 'Cascalho Rico' },
    { data: '3115102', label: 'Cássia' },
    { data: '3115300', label: 'Cataguases' },
    { data: '3115359', label: 'Catas Altas' },
    { data: '3115409', label: 'Catas Altas da Noruega' },
    { data: '3115458', label: 'Catuji' },
    { data: '3115474', label: 'Catuti' },
    { data: '3115508', label: 'Caxambu' },
    { data: '3115607', label: 'Cedro do Abaeté' },
    { data: '3115706', label: 'Central de Minas' },
    { data: '3115805', label: 'Centralina' },
    { data: '3115904', label: 'Chácara' },
    { data: '3116001', label: 'Chalé' },
    { data: '3116100', label: 'Chapada do Norte' },
    { data: '3116159', label: 'Chapada Gaúcha' },
    { data: '3116209', label: 'Chiador' },
    { data: '3116308', label: 'Cipotânea' },
    { data: '3116407', label: 'Claraval' },
    { data: '3116506', label: 'Claro dos Poções' },
    { data: '3116605', label: 'Cláudio' },
    { data: '3116704', label: 'Coimbra' },
    { data: '3116803', label: 'Coluna' },
    { data: '3116902', label: 'Comendador Gomes' },
    { data: '3117009', label: 'Comercinho' },
    { data: '3117108', label: 'Conceição da Aparecida' },
    { data: '3115201', label: 'Conceição da Barra de Minas' },
    { data: '3117306', label: 'Conceição das Alagoas' },
    { data: '3117207', label: 'Conceição das Pedras' },
    { data: '3117405', label: 'Conceição de Ipanema' },
    { data: '3117504', label: 'Conceição do Mato Dentro' },
    { data: '3117603', label: 'Conceição do Pará' },
    { data: '3117702', label: 'Conceição do Rio Verde' },
    { data: '3117801', label: 'Conceição dos Ouros' },
    { data: '3117836', label: 'Cônego Marinho' },
    { data: '3117876', label: 'Confins' },
    { data: '3117900', label: 'Congonhal' },
    { data: '3118007', label: 'Congonhas' },
    { data: '3118106', label: 'Congonhas do Norte' },
    { data: '3118205', label: 'Conquista' },
    { data: '3118304', label: 'Conselheiro Lafaiete' },
    { data: '3118403', label: 'Conselheiro Pena' },
    { data: '3118502', label: 'Consolação' },
    { data: '3118601', label: 'Contagem' },
    { data: '3118700', label: 'Coqueiral' },
    { data: '3118809', label: 'Coração de Jesus' },
    { data: '3118908', label: 'Cordisburgo' },
    { data: '3119005', label: 'Cordislândia' },
    { data: '3119104', label: 'Corinto' },
    { data: '3119203', label: 'Coroaci' },
    { data: '3119302', label: 'Coromandel' },
    { data: '3119401', label: 'Coronel Fabriciano' },
    { data: '3119500', label: 'Coronel Murta' },
    { data: '3119609', label: 'Coronel Pacheco' },
    { data: '3119708', label: 'Coronel Xavier Chaves' },
    { data: '3119807', label: 'Córrego Danta' },
    { data: '3119906', label: 'Córrego do Bom Jesus' },
    { data: '3119955', label: 'Córrego Fundo' },
    { data: '3120003', label: 'Córrego Novo' },
    { data: '3120102', label: 'Couto de Magalhães de Minas' },
    { data: '3120151', label: 'Crisólita' },
    { data: '3120201', label: 'Cristais' },
    { data: '3120300', label: 'Cristália' },
    { data: '3120409', label: 'Cristiano Otoni' },
    { data: '3120508', label: 'Cristina' },
    { data: '3120607', label: 'Crucilândia' },
    { data: '3120706', label: 'Cruzeiro da Fortaleza' },
    { data: '3120805', label: 'Cruzília' },
    { data: '3120839', label: 'Cuparaque' },
    { data: '3120870', label: 'Curral de Dentro' },
    { data: '3120904', label: 'Curvelo' },
    { data: '3121001', label: 'Datas' },
    { data: '3121100', label: 'Delfim Moreira' },
    { data: '3121209', label: 'Delfinópolis' },
    { data: '3121258', label: 'Delta' },
    { data: '3121308', label: 'Descoberto' },
    { data: '3121407', label: 'Desterro de Entre Rios' },
    { data: '3121506', label: 'Desterro do Melo' },
    { data: '3121605', label: 'Diamantina' },
    { data: '3121704', label: 'Diogo de Vasconcelos' },
    { data: '3121803', label: 'Dionísio' },
    { data: '3121902', label: 'Divinésia' },
    { data: '3122009', label: 'Divino' },
    { data: '3122108', label: 'Divino das Laranjeiras' },
    { data: '3122207', label: 'Divinolândia de Minas' },
    { data: '3122306', label: 'Divinópolis' },
    { data: '3122355', label: 'Divisa Alegre' },
    { data: '3122405', label: 'Divisa Nova' },
    { data: '3122454', label: 'Divisópolis' },
    { data: '3122470', label: 'Dom Bosco' },
    { data: '3122504', label: 'Dom Cavati' },
    { data: '3122603', label: 'Dom Joaquim' },
    { data: '3122702', label: 'Dom Silvério' },
    { data: '3122801', label: 'Dom Viçoso' },
    { data: '3122900', label: 'Dona Eusébia' },
    { data: '3123007', label: 'Dores de Campos' },
    { data: '3123106', label: 'Dores de Guanhães' },
    { data: '3123205', label: 'Dores do Indaiá' },
    { data: '3123304', label: 'Dores do Turvo' },
    { data: '3123403', label: 'Doresópolis' },
    { data: '3123502', label: 'Douradoquara' },
    { data: '3123528', label: 'Durandé' },
    { data: '3123601', label: 'Elói Mendes' },
    { data: '3123700', label: 'Engenheiro Caldas' },
    { data: '3123809', label: 'Engenheiro Navarro' },
    { data: '3123858', label: 'Entre Folhas' },
    { data: '3123908', label: 'Entre Rios de Minas' },
    { data: '3124005', label: 'Ervália' },
    { data: '3124104', label: 'Esmeraldas' },
    { data: '3124203', label: 'Espera Feliz' },
    { data: '3124302', label: 'Espinosa' },
    { data: '3124401', label: 'Espírito Santo do Dourado' },
    { data: '3124500', label: 'Estiva' },
    { data: '3124609', label: 'Estrela Dalva' },
    { data: '3124708', label: 'Estrela do Indaiá' },
    { data: '3124807', label: 'Estrela do Sul' },
    { data: '3124906', label: 'Eugenópolis' },
    { data: '3125002', label: 'Ewbank da Câmara' },
    { data: '3125101', label: 'Extrema' },
    { data: '3125200', label: 'Fama' },
    { data: '3125309', label: 'Faria Lemos' },
    { data: '3125408', label: 'Felício dos Santos' },
    { data: '3125606', label: 'Felisburgo' },
    { data: '3125705', label: 'Felixlândia' },
    { data: '3125804', label: 'Fernandes Tourinho' },
    { data: '3125903', label: 'Ferros' },
    { data: '3125952', label: 'Fervedouro' },
    { data: '3126000', label: 'Florestal' },
    { data: '3126109', label: 'Formiga' },
    { data: '3126208', label: 'Formoso' },
    { data: '3126307', label: 'Fortaleza de Minas' },
    { data: '3126406', label: 'Fortuna de Minas' },
    { data: '3126505', label: 'Francisco Badaró' },
    { data: '3126604', label: 'Francisco Dumont' },
    { data: '3126703', label: 'Francisco Sá' },
    { data: '3126752', label: 'Franciscópolis' },
    { data: '3126802', label: 'Frei Gaspar' },
    { data: '3126901', label: 'Frei Inocêncio' },
    { data: '3126950', label: 'Frei Lagonegro' },
    { data: '3127008', label: 'Fronteira' },
    { data: '3127057', label: 'Fronteira dos Vales' },
    { data: '3127073', label: 'Fruta de Leite' },
    { data: '3127107', label: 'Frutal' },
    { data: '3127206', label: 'Funilândia' },
    { data: '3127305', label: 'Galiléia' },
    { data: '3127339', label: 'Gameleiras' },
    { data: '3127354', label: 'Glaucilândia' },
    { data: '3127370', label: 'Goiabeira' },
    { data: '3127388', label: 'Goianá' },
    { data: '3127404', label: 'Gonçalves' },
    { data: '3127503', label: 'Gonzaga' },
    { data: '3127602', label: 'Gouveia' },
    { data: '3127701', label: 'Governador Valadares' },
    { data: '3127800', label: 'Grão Mogol' },
    { data: '3127909', label: 'Grupiara' },
    { data: '3128006', label: 'Guanhães' },
    { data: '3128105', label: 'Guapé' },
    { data: '3128204', label: 'Guaraciaba' },
    { data: '3128253', label: 'Guaraciama' },
    { data: '3128303', label: 'Guaranésia' },
    { data: '3128402', label: 'Guarani' },
    { data: '3128501', label: 'Guarará' },
    { data: '3128600', label: 'Guarda-Mor' },
    { data: '3128709', label: 'Guaxupé' },
    { data: '3128808', label: 'Guidoval' },
    { data: '3128907', label: 'Guimarânia' },
    { data: '3129004', label: 'Guiricema' },
    { data: '3129103', label: 'Gurinhatã' },
    { data: '3129202', label: 'Heliodora' },
    { data: '3129301', label: 'Iapu' },
    { data: '3129400', label: 'Ibertioga' },
    { data: '3129509', label: 'Ibiá' },
    { data: '3129608', label: 'Ibiaí' },
    { data: '3129657', label: 'Ibiracatu' },
    { data: '3129707', label: 'Ibiraci' },
    { data: '3129806', label: 'Ibirité' },
    { data: '3129905', label: 'Ibitiúra de Minas' },
    { data: '3130002', label: 'Ibituruna' },
    { data: '3130051', label: 'Icaraí de Minas' },
    { data: '3130101', label: 'Igarapé' },
    { data: '3130200', label: 'Igaratinga' },
    { data: '3130309', label: 'Iguatama' },
    { data: '3130408', label: 'Ijaci' },
    { data: '3130507', label: 'Ilicínea' },
    { data: '3130556', label: 'Imbé de Minas' },
    { data: '3130606', label: 'Inconfidentes' },
    { data: '3130655', label: 'Indaiabira' },
    { data: '3130705', label: 'Indianópolis' },
    { data: '3130804', label: 'Ingaí' },
    { data: '3130903', label: 'Inhapim' },
    { data: '3131000', label: 'Inhaúma' },
    { data: '3131109', label: 'Inimutaba' },
    { data: '3131158', label: 'Ipaba' },
    { data: '3131208', label: 'Ipanema' },
    { data: '3131307', label: 'Ipatinga' },
    { data: '3131406', label: 'Ipiaçu' },
    { data: '3131505', label: 'Ipuiúna' },
    { data: '3131604', label: 'Iraí de Minas' },
    { data: '3131703', label: 'Itabira' },
    { data: '3131802', label: 'Itabirinha' },
    { data: '3131901', label: 'Itabirito' },
    { data: '3132008', label: 'Itacambira' },
    { data: '3132107', label: 'Itacarambi' },
    { data: '3132206', label: 'Itaguara' },
    { data: '3132305', label: 'Itaipé' },
    { data: '3132404', label: 'Itajubá' },
    { data: '3132503', label: 'Itamarandiba' },
    { data: '3132602', label: 'Itamarati de Minas' },
    { data: '3132701', label: 'Itambacuri' },
    { data: '3132800', label: 'Itambé do Mato Dentro' },
    { data: '3132909', label: 'Itamogi' },
    { data: '3133006', label: 'Itamonte' },
    { data: '3133105', label: 'Itanhandu' },
    { data: '3133204', label: 'Itanhomi' },
    { data: '3133303', label: 'Itaobim' },
    { data: '3133402', label: 'Itapagipe' },
    { data: '3133501', label: 'Itapecerica' },
    { data: '3133600', label: 'Itapeva' },
    { data: '3133709', label: 'Itatiaiuçu' },
    { data: '3133758', label: 'Itaú de Minas' },
    { data: '3133808', label: 'Itaúna' },
    { data: '3133907', label: 'Itaverava' },
    { data: '3134004', label: 'Itinga' },
    { data: '3134103', label: 'Itueta' },
    { data: '3134202', label: 'Ituiutaba' },
    { data: '3134301', label: 'Itumirim' },
    { data: '3134400', label: 'Iturama' },
    { data: '3134509', label: 'Itutinga' },
    { data: '3134608', label: 'Jaboticatubas' },
    { data: '3134707', label: 'Jacinto' },
    { data: '3134806', label: 'Jacuí' },
    { data: '3134905', label: 'Jacutinga' },
    { data: '3135001', label: 'Jaguaraçu' },
    { data: '3135050', label: 'Jaíba' },
    { data: '3135076', label: 'Jampruca' },
    { data: '3135100', label: 'Janaúba' },
    { data: '3135209', label: 'Januária' },
    { data: '3135308', label: 'Japaraíba' },
    { data: '3135357', label: 'Japonvar' },
    { data: '3135407', label: 'Jeceaba' },
    { data: '3135456', label: 'Jenipapo de Minas' },
    { data: '3135506', label: 'Jequeri' },
    { data: '3135605', label: 'Jequitaí' },
    { data: '3135704', label: 'Jequitibá' },
    { data: '3135803', label: 'Jequitinhonha' },
    { data: '3135902', label: 'Jesuânia' },
    { data: '3136009', label: 'Joaíma' },
    { data: '3136108', label: 'Joanésia' },
    { data: '3136207', label: 'João Monlevade' },
    { data: '3136306', label: 'João Pinheiro' },
    { data: '3136405', label: 'Joaquim Felício' },
    { data: '3136504', label: 'Jordânia' },
    { data: '3136520', label: 'José Gonçalves de Minas' },
    { data: '3136553', label: 'José Raydan' },
    { data: '3136579', label: 'Josenópolis' },
    { data: '3136652', label: 'Juatuba' },
    { data: '3136702', label: 'Juiz de Fora' },
    { data: '3136801', label: 'Juramento' },
    { data: '3136900', label: 'Juruaia' },
    { data: '3136959', label: 'Juvenília' },
    { data: '3137007', label: 'Ladainha' },
    { data: '3137106', label: 'Lagamar' },
    { data: '3137205', label: 'Lagoa da Prata' },
    { data: '3137304', label: 'Lagoa dos Patos' },
    { data: '3137403', label: 'Lagoa Dourada' },
    { data: '3137502', label: 'Lagoa Formosa' },
    { data: '3137536', label: 'Lagoa Grande' },
    { data: '3137601', label: 'Lagoa Santa' },
    { data: '3137700', label: 'Lajinha' },
    { data: '3137809', label: 'Lambari' },
    { data: '3137908', label: 'Lamim' },
    { data: '3138005', label: 'Laranjal' },
    { data: '3138104', label: 'Lassance' },
    { data: '3138203', label: 'Lavras' },
    { data: '3138302', label: 'Leandro Ferreira' },
    { data: '3138351', label: 'Leme do Prado' },
    { data: '3138401', label: 'Leopoldina' },
    { data: '3138500', label: 'Liberdade' },
    { data: '3138609', label: 'Lima Duarte' },
    { data: '3138625', label: 'Limeira do Oeste' },
    { data: '3138658', label: 'Lontra' },
    { data: '3138674', label: 'Luisburgo' },
    { data: '3138682', label: 'Luislândia' },
    { data: '3138708', label: 'Luminárias' },
    { data: '3138807', label: 'Luz' },
    { data: '3138906', label: 'Machacalis' },
    { data: '3139003', label: 'Machado' },
    { data: '3139102', label: 'Madre de Deus de Minas' },
    { data: '3139201', label: 'Malacacheta' },
    { data: '3139250', label: 'Mamonas' },
    { data: '3139300', label: 'Manga' },
    { data: '3139409', label: 'Manhuaçu' },
    { data: '3139508', label: 'Manhumirim' },
    { data: '3139607', label: 'Mantena' },
    { data: '3139805', label: 'Mar de Espanha' },
    { data: '3139706', label: 'Maravilhas' },
    { data: '3139904', label: 'Maria da Fé' },
    { data: '3140001', label: 'Mariana' },
    { data: '3140100', label: 'Marilac' },
    { data: '3140159', label: 'Mário Campos' },
    { data: '3140209', label: 'Maripá de Minas' },
    { data: '3140308', label: 'Marliéria' },
    { data: '3140407', label: 'Marmelópolis' },
    { data: '3140506', label: 'Martinho Campos' },
    { data: '3140530', label: 'Martins Soares' },
    { data: '3140555', label: 'Mata Verde' },
    { data: '3140605', label: 'Materlândia' },
    { data: '3140704', label: 'Mateus Leme' },
    { data: '3171501', label: 'Mathias Lobato' },
    { data: '3140803', label: 'Matias Barbosa' },
    { data: '3140852', label: 'Matias Cardoso' },
    { data: '3140902', label: 'Matipó' },
    { data: '3141009', label: 'Mato Verde' },
    { data: '3141108', label: 'Matozinhos' },
    { data: '3141207', label: 'Matutina' },
    { data: '3141306', label: 'Medeiros' },
    { data: '3141405', label: 'Medina' },
    { data: '3141504', label: 'Mendes Pimentel' },
    { data: '3141603', label: 'Mercês' },
    { data: '3141702', label: 'Mesquita' },
    { data: '3141801', label: 'Minas Novas' },
    { data: '3141900', label: 'Minduri' },
    { data: '3142007', label: 'Mirabela' },
    { data: '3142106', label: 'Miradouro' },
    { data: '3142205', label: 'Miraí' },
    { data: '3142254', label: 'Miravânia' },
    { data: '3142304', label: 'Moeda' },
    { data: '3142403', label: 'Moema' },
    { data: '3142502', label: 'Monjolos' },
    { data: '3142601', label: 'Monsenhor Paulo' },
    { data: '3142700', label: 'Montalvânia' },
    { data: '3142809', label: 'Monte Alegre de Minas' },
    { data: '3142908', label: 'Monte Azul' },
    { data: '3143005', label: 'Monte Belo' },
    { data: '3143104', label: 'Monte Carmelo' },
    { data: '3143153', label: 'Monte Formoso' },
    { data: '3143203', label: 'Monte Santo de Minas' },
    { data: '3143401', label: 'Monte Sião' },
    { data: '3143302', label: 'Montes Claros' },
    { data: '3143450', label: 'Montezuma' },
    { data: '3143500', label: 'Morada Nova de Minas' },
    { data: '3143609', label: 'Morro da Garça' },
    { data: '3143708', label: 'Morro do Pilar' },
    { data: '3143807', label: 'Munhoz' },
    { data: '3143906', label: 'Muriaé' },
    { data: '3144003', label: 'Mutum' },
    { data: '3144102', label: 'Muzambinho' },
    { data: '3144201', label: 'Nacip Raydan' },
    { data: '3144300', label: 'Nanuque' },
    { data: '3144359', label: 'Naque' },
    { data: '3144375', label: 'Natalândia' },
    { data: '3144409', label: 'Natércia' },
    { data: '3144508', label: 'Nazareno' },
    { data: '3144607', label: 'Nepomuceno' },
    { data: '3144656', label: 'Ninheira' },
    { data: '3144672', label: 'Nova Belém' },
    { data: '3144706', label: 'Nova Era' },
    { data: '3144805', label: 'Nova Lima' },
    { data: '3144904', label: 'Nova Módica' },
    { data: '3145000', label: 'Nova Ponte' },
    { data: '3145059', label: 'Nova Porteirinha' },
    { data: '3145109', label: 'Nova Resende' },
    { data: '3145208', label: 'Nova Serrana' },
    { data: '3136603', label: 'Nova União' },
    { data: '3145307', label: 'Novo Cruzeiro' },
    { data: '3145356', label: 'Novo Oriente de Minas' },
    { data: '3145372', label: 'Novorizonte' },
    { data: '3145406', label: 'Olaria' },
    { data: '3145455', label: "Olhos-d'Água" },
    { data: '3145505', label: 'Olímpio Noronha' },
    { data: '3145604', label: 'Oliveira' },
    { data: '3145703', label: 'Oliveira Fortes' },
    { data: '3145802', label: 'Onça de Pitangui' },
    { data: '3145851', label: 'Oratórios' },
    { data: '3145877', label: 'Orizânia' },
    { data: '3145901', label: 'Ouro Branco' },
    { data: '3146008', label: 'Ouro Fino' },
    { data: '3146107', label: 'Ouro Preto' },
    { data: '3146206', label: 'Ouro Verde de Minas' },
    { data: '3146255', label: 'Padre Carvalho' },
    { data: '3146305', label: 'Padre Paraíso' },
    { data: '3146552', label: 'Pai Pedro' },
    { data: '3146404', label: 'Paineiras' },
    { data: '3146503', label: 'Pains' },
    { data: '3146602', label: 'Paiva' },
    { data: '3146701', label: 'Palma' },
    { data: '3146750', label: 'Palmópolis' },
    { data: '3146909', label: 'Papagaios' },
    { data: '3147105', label: 'Pará de Minas' },
    { data: '3147006', label: 'Paracatu' },
    { data: '3147204', label: 'Paraguaçu' },
    { data: '3147303', label: 'Paraisópolis' },
    { data: '3147402', label: 'Paraopeba' },
    { data: '3147600', label: 'Passa Quatro' },
    { data: '3147709', label: 'Passa Tempo' },
    { data: '3147501', label: 'Passabém' },
    { data: '3147808', label: 'Passa-Vinte' },
    { data: '3147907', label: 'Passos' },
    { data: '3147956', label: 'Patis' },
    { data: '3148004', label: 'Patos de Minas' },
    { data: '3148103', label: 'Patrocínio' },
    { data: '3148202', label: 'Patrocínio do Muriaé' },
    { data: '3148301', label: 'Paula Cândido' },
    { data: '3148400', label: 'Paulistas' },
    { data: '3148509', label: 'Pavão' },
    { data: '3148608', label: 'Peçanha' },
    { data: '3148707', label: 'Pedra Azul' },
    { data: '3148756', label: 'Pedra Bonita' },
    { data: '3148806', label: 'Pedra do Anta' },
    { data: '3148905', label: 'Pedra do Indaiá' },
    { data: '3149002', label: 'Pedra Dourada' },
    { data: '3149101', label: 'Pedralva' },
    { data: '3149150', label: 'Pedras de Maria da Cruz' },
    { data: '3149200', label: 'Pedrinópolis' },
    { data: '3149309', label: 'Pedro Leopoldo' },
    { data: '3149408', label: 'Pedro Teixeira' },
    { data: '3149507', label: 'Pequeri' },
    { data: '3149606', label: 'Pequi' },
    { data: '3149705', label: 'Perdigão' },
    { data: '3149804', label: 'Perdizes' },
    { data: '3149903', label: 'Perdões' },
    { data: '3149952', label: 'Periquito' },
    { data: '3150000', label: 'Pescador' },
    { data: '3150109', label: 'Piau' },
    { data: '3150158', label: 'Piedade de Caratinga' },
    { data: '3150208', label: 'Piedade de Ponte Nova' },
    { data: '3150307', label: 'Piedade do Rio Grande' },
    { data: '3150406', label: 'Piedade dos Gerais' },
    { data: '3150505', label: 'Pimenta' },
    { data: '3150539', label: "Pingo-d'Água" },
    { data: '3150570', label: 'Pintópolis' },
    { data: '3150604', label: 'Piracema' },
    { data: '3150703', label: 'Pirajuba' },
    { data: '3150802', label: 'Piranga' },
    { data: '3150901', label: 'Piranguçu' },
    { data: '3151008', label: 'Piranguinho' },
    { data: '3151107', label: 'Pirapetinga' },
    { data: '3151206', label: 'Pirapora' },
    { data: '3151305', label: 'Piraúba' },
    { data: '3151404', label: 'Pitangui' },
    { data: '3151503', label: 'Piumhi' },
    { data: '3151602', label: 'Planura' },
    { data: '3151701', label: 'Poço Fundo' },
    { data: '3151800', label: 'Poços de Caldas' },
    { data: '3151909', label: 'Pocrane' },
    { data: '3152006', label: 'Pompéu' },
    { data: '3152105', label: 'Ponte Nova' },
    { data: '3152131', label: 'Ponto Chique' },
    { data: '3152170', label: 'Ponto dos Volantes' },
    { data: '3152204', label: 'Porteirinha' },
    { data: '3152303', label: 'Porto Firme' },
    { data: '3152402', label: 'Poté' },
    { data: '3152501', label: 'Pouso Alegre' },
    { data: '3152600', label: 'Pouso Alto' },
    { data: '3152709', label: 'Prados' },
    { data: '3152808', label: 'Prata' },
    { data: '3152907', label: 'Pratápolis' },
    { data: '3153004', label: 'Pratinha' },
    { data: '3153103', label: 'Presidente Bernardes' },
    { data: '3153202', label: 'Presidente Juscelino' },
    { data: '3153301', label: 'Presidente Kubitschek' },
    { data: '3153400', label: 'Presidente Olegário' },
    { data: '3153608', label: 'Prudente de Morais' },
    { data: '3153707', label: 'Quartel Geral' },
    { data: '3153806', label: 'Queluzito' },
    { data: '3153905', label: 'Raposos' },
    { data: '3154002', label: 'Raul Soares' },
    { data: '3154101', label: 'Recreio' },
    { data: '3154150', label: 'Reduto' },
    { data: '3154200', label: 'Resende Costa' },
    { data: '3154309', label: 'Resplendor' },
    { data: '3154408', label: 'Ressaquinha' },
    { data: '3154457', label: 'Riachinho' },
    { data: '3154507', label: 'Riacho dos Machados' },
    { data: '3154606', label: 'Ribeirão das Neves' },
    { data: '3154705', label: 'Ribeirão Vermelho' },
    { data: '3154804', label: 'Rio Acima' },
    { data: '3154903', label: 'Rio Casca' },
    { data: '3155108', label: 'Rio do Prado' },
    { data: '3155009', label: 'Rio Doce' },
    { data: '3155207', label: 'Rio Espera' },
    { data: '3155306', label: 'Rio Manso' },
    { data: '3155405', label: 'Rio Novo' },
    { data: '3155504', label: 'Rio Paranaíba' },
    { data: '3155603', label: 'Rio Pardo de Minas' },
    { data: '3155702', label: 'Rio Piracicaba' },
    { data: '3155801', label: 'Rio Pomba' },
    { data: '3155900', label: 'Rio Preto' },
    { data: '3156007', label: 'Rio Vermelho' },
    { data: '3156106', label: 'Ritápolis' },
    { data: '3156205', label: 'Rochedo de Minas' },
    { data: '3156304', label: 'Rodeiro' },
    { data: '3156403', label: 'Romaria' },
    { data: '3156452', label: 'Rosário da Limeira' },
    { data: '3156502', label: 'Rubelita' },
    { data: '3156601', label: 'Rubim' },
    { data: '3156700', label: 'Sabará' },
    { data: '3156809', label: 'Sabinópolis' },
    { data: '3156908', label: 'Sacramento' },
    { data: '3157005', label: 'Salinas' },
    { data: '3157104', label: 'Salto da Divisa' },
    { data: '3157203', label: 'Santa Bárbara' },
    { data: '3157252', label: 'Santa Bárbara do Leste' },
    { data: '3157278', label: 'Santa Bárbara do Monte Verde' },
    { data: '3157302', label: 'Santa Bárbara do Tugúrio' },
    { data: '3157336', label: 'Santa Cruz de Minas' },
    { data: '3157377', label: 'Santa Cruz de Salinas' },
    { data: '3157401', label: 'Santa Cruz do Escalvado' },
    { data: '3157500', label: 'Santa Efigênia de Minas' },
    { data: '3157609', label: 'Santa Fé de Minas' },
    { data: '3157658', label: 'Santa Helena de Minas' },
    { data: '3157708', label: 'Santa Juliana' },
    { data: '3157807', label: 'Santa Luzia' },
    { data: '3157906', label: 'Santa Margarida' },
    { data: '3158003', label: 'Santa Maria de Itabira' },
    { data: '3158102', label: 'Santa Maria do Salto' },
    { data: '3158201', label: 'Santa Maria do Suaçuí' },
    { data: '3159209', label: 'Santa Rita de Caldas' },
    { data: '3159407', label: 'Santa Rita de Ibitipoca' },
    { data: '3159308', label: 'Santa Rita de Jacutinga' },
    { data: '3159357', label: 'Santa Rita de Minas' },
    { data: '3159506', label: 'Santa Rita do Itueto' },
    { data: '3159605', label: 'Santa Rita do Sapucaí' },
    { data: '3159704', label: 'Santa Rosa da Serra' },
    { data: '3159803', label: 'Santa Vitória' },
    { data: '3158300', label: 'Santana da Vargem' },
    { data: '3158409', label: 'Santana de Cataguases' },
    { data: '3158508', label: 'Santana de Pirapama' },
    { data: '3158607', label: 'Santana do Deserto' },
    { data: '3158706', label: 'Santana do Garambéu' },
    { data: '3158805', label: 'Santana do Jacaré' },
    { data: '3158904', label: 'Santana do Manhuaçu' },
    { data: '3158953', label: 'Santana do Paraíso' },
    { data: '3159001', label: 'Santana do Riacho' },
    { data: '3159100', label: 'Santana dos Montes' },
    { data: '3159902', label: 'Santo Antônio do Amparo' },
    { data: '3160009', label: 'Santo Antônio do Aventureiro' },
    { data: '3160108', label: 'Santo Antônio do Grama' },
    { data: '3160207', label: 'Santo Antônio do Itambé' },
    { data: '3160306', label: 'Santo Antônio do Jacinto' },
    { data: '3160405', label: 'Santo Antônio do Monte' },
    { data: '3160454', label: 'Santo Antônio do Retiro' },
    { data: '3160504', label: 'Santo Antônio do Rio Abaixo' },
    { data: '3160603', label: 'Santo Hipólito' },
    { data: '3160702', label: 'Santos Dumont' },
    { data: '3160801', label: 'São Bento Abade' },
    { data: '3160900', label: 'São Brás do Suaçuí' },
    { data: '3160959', label: 'São Domingos das Dores' },
    { data: '3161007', label: 'São Domingos do Prata' },
    { data: '3161056', label: 'São Félix de Minas' },
    { data: '3161106', label: 'São Francisco' },
    { data: '3161205', label: 'São Francisco de Paula' },
    { data: '3161304', label: 'São Francisco de Sales' },
    { data: '3161403', label: 'São Francisco do Glória' },
    { data: '3161502', label: 'São Geraldo' },
    { data: '3161601', label: 'São Geraldo da Piedade' },
    { data: '3161650', label: 'São Geraldo do Baixio' },
    { data: '3161700', label: 'São Gonçalo do Abaeté' },
    { data: '3161809', label: 'São Gonçalo do Pará' },
    { data: '3161908', label: 'São Gonçalo do Rio Abaixo' },
    { data: '3125507', label: 'São Gonçalo do Rio Preto' },
    { data: '3162005', label: 'São Gonçalo do Sapucaí' },
    { data: '3162104', label: 'São Gotardo' },
    { data: '3162203', label: 'São João Batista do Glória' },
    { data: '3162252', label: 'São João da Lagoa' },
    { data: '3162302', label: 'São João da Mata' },
    { data: '3162401', label: 'São João da Ponte' },
    { data: '3162450', label: 'São João das Missões' },
    { data: '3162500', label: 'São João del Rei' },
    { data: '3162559', label: 'São João do Manhuaçu' },
    { data: '3162575', label: 'São João do Manteninha' },
    { data: '3162609', label: 'São João do Oriente' },
    { data: '3162658', label: 'São João do Pacuí' },
    { data: '3162708', label: 'São João do Paraíso' },
    { data: '3162807', label: 'São João Evangelista' },
    { data: '3162906', label: 'São João Nepomuceno' },
    { data: '3162922', label: 'São Joaquim de Bicas' },
    { data: '3162948', label: 'São José da Barra' },
    { data: '3162955', label: 'São José da Lapa' },
    { data: '3163003', label: 'São José da Safira' },
    { data: '3163102', label: 'São José da Varginha' },
    { data: '3163201', label: 'São José do Alegre' },
    { data: '3163300', label: 'São José do Divino' },
    { data: '3163409', label: 'São José do Goiabal' },
    { data: '3163508', label: 'São José do Jacuri' },
    { data: '3163607', label: 'São José do Mantimento' },
    { data: '3163706', label: 'São Lourenço' },
    { data: '3163805', label: 'São Miguel do Anta' },
    { data: '3163904', label: 'São Pedro da União' },
    { data: '3164100', label: 'São Pedro do Suaçuí' },
    { data: '3164001', label: 'São Pedro dos Ferros' },
    { data: '3164209', label: 'São Romão' },
    { data: '3164308', label: 'São Roque de Minas' },
    { data: '3164407', label: 'São Sebastião da Bela Vista' },
    { data: '3164431', label: 'São Sebastião da Vargem Alegre' },
    { data: '3164472', label: 'São Sebastião do Anta' },
    { data: '3164506', label: 'São Sebastião do Maranhão' },
    { data: '3164605', label: 'São Sebastião do Oeste' },
    { data: '3164704', label: 'São Sebastião do Paraíso' },
    { data: '3164803', label: 'São Sebastião do Rio Preto' },
    { data: '3164902', label: 'São Sebastião do Rio Verde' },
    { data: '3165206', label: 'São Thomé das Letras' },
    { data: '3165008', label: 'São Tiago' },
    { data: '3165107', label: 'São Tomás de Aquino' },
    { data: '3165305', label: 'São Vicente de Minas' },
    { data: '3165404', label: 'Sapucaí-Mirim' },
    { data: '3165503', label: 'Sardoá' },
    { data: '3165537', label: 'Sarzedo' },
    { data: '3165560', label: 'Sem-Peixe' },
    { data: '3165578', label: 'Senador Amaral' },
    { data: '3165602', label: 'Senador Cortes' },
    { data: '3165701', label: 'Senador Firmino' },
    { data: '3165800', label: 'Senador José Bento' },
    { data: '3165909', label: 'Senador Modestino Gonçalves' },
    { data: '3166006', label: 'Senhora de Oliveira' },
    { data: '3166105', label: 'Senhora do Porto' },
    { data: '3166204', label: 'Senhora dos Remédios' },
    { data: '3166303', label: 'Sericita' },
    { data: '3166402', label: 'Seritinga' },
    { data: '3166501', label: 'Serra Azul de Minas' },
    { data: '3166600', label: 'Serra da Saudade' },
    { data: '3166808', label: 'Serra do Salitre' },
    { data: '3166709', label: 'Serra dos Aimorés' },
    { data: '3166907', label: 'Serrania' },
    { data: '3166956', label: 'Serranópolis de Minas' },
    { data: '3167004', label: 'Serranos' },
    { data: '3167103', label: 'Serro' },
    { data: '3167202', label: 'Sete Lagoas' },
    { data: '3165552', label: 'Setubinha' },
    { data: '3167301', label: 'Silveirânia' },
    { data: '3167400', label: 'Silvianópolis' },
    { data: '3167509', label: 'Simão Pereira' },
    { data: '3167608', label: 'Simonésia' },
    { data: '3167707', label: 'Sobrália' },
    { data: '3167806', label: 'Soledade de Minas' },
    { data: '3167905', label: 'Tabuleiro' },
    { data: '3168002', label: 'Taiobeiras' },
    { data: '3168051', label: 'Taparuba' },
    { data: '3168101', label: 'Tapira' },
    { data: '3168200', label: 'Tapiraí' },
    { data: '3168309', label: 'Taquaraçu de Minas' },
    { data: '3168408', label: 'Tarumirim' },
    { data: '3168507', label: 'Teixeiras' },
    { data: '3168606', label: 'Teófilo Otoni' },
    { data: '3168705', label: 'Timóteo' },
    { data: '3168804', label: 'Tiradentes' },
    { data: '3168903', label: 'Tiros' },
    { data: '3169000', label: 'Tocantins' },
    { data: '3169059', label: 'Tocos do Moji' },
    { data: '3169109', label: 'Toledo' },
    { data: '3169208', label: 'Tombos' },
    { data: '3169307', label: 'Três Corações' },
    { data: '3169356', label: 'Três Marias' },
    { data: '3169406', label: 'Três Pontas' },
    { data: '3169505', label: 'Tumiritinga' },
    { data: '3169604', label: 'Tupaciguara' },
    { data: '3169703', label: 'Turmalina' },
    { data: '3169802', label: 'Turvolândia' },
    { data: '3169901', label: 'Ubá' },
    { data: '3170008', label: 'Ubaí' },
    { data: '3170057', label: 'Ubaporanga' },
    { data: '3170107', label: 'Uberaba' },
    { data: '3170206', label: 'Uberlândia' },
    { data: '3170305', label: 'Umburatiba' },
    { data: '3170404', label: 'Unaí' },
    { data: '3170438', label: 'União de Minas' },
    { data: '3170479', label: 'Uruana de Minas' },
    { data: '3170503', label: 'Urucânia' },
    { data: '3170529', label: 'Urucuia' },
    { data: '3170578', label: 'Vargem Alegre' },
    { data: '3170602', label: 'Vargem Bonita' },
    { data: '3170651', label: 'Vargem Grande do Rio Pardo' },
    { data: '3170701', label: 'Varginha' },
    { data: '3170750', label: 'Varjão de Minas' },
    { data: '3170800', label: 'Várzea da Palma' },
    { data: '3170909', label: 'Varzelândia' },
    { data: '3171006', label: 'Vazante' },
    { data: '3171030', label: 'Verdelândia' },
    { data: '3171071', label: 'Veredinha' },
    { data: '3171105', label: 'Veríssimo' },
    { data: '3171154', label: 'Vermelho Novo' },
    { data: '3171204', label: 'Vespasiano' },
    { data: '3171303', label: 'Viçosa' },
    { data: '3171402', label: 'Vieiras' },
    { data: '3171600', label: 'Virgem da Lapa' },
    { data: '3171709', label: 'Virgínia' },
    { data: '3171808', label: 'Virginópolis' },
    { data: '3171907', label: 'Virgolândia' },
    { data: '3172004', label: 'Visconde do Rio Branco' },
    { data: '3172103', label: 'Volta Grande' },
    { data: '3172202', label: 'Wenceslau Braz' },

];

export default mg;
