import React from 'react';
import { Row, Col } from 'antd';
import { FooterToolbar, Navigator, Page, Form, Input, ContentWrapper, Table, Button, DatePicker, SelectCombo, Select, Status } from '~/components';
import { actions } from '~/options';
import { RecadoPostoService, UserService } from '~/services';
import { notifySuccess } from '~/utils';

class RecadoPostoPage extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      itens: [],
      selectedItem: {},
      action: actions.idle,
      funcionarios: [],
      funcoes: [],
    };
  }

  componentDidMount() {
    this.searchApi.submitForm();
    setTimeout(() => this.searchInput.focus(), 300);

    UserService.fetchFuncionario(funcionarios => this.setState({ funcionarios }));

    UserService.fetchFuncoinarioFuncao(funcoes => this.setState({ funcoes }));
  }

  handleSearch = values => {
    this.setState({ isLoading: true });
    RecadoPostoService.buscar(values, this.onSearch, () => this.onSearch());
  };

  onSearch = (itens = []) => {
    this.setState({ itens, isLoading: false });
  };

  handleSelect = record => {
    this.setState({ selectedItem: record });
    this.saveApi.reset();
    this.saveApi.setValues(record);
  };

  handleEdit = action => {
    this.setState({ action });
    setTimeout(() => this.editInput.focus(), 300);

    if (action === actions.insert) this.saveApi.reset();
  };

  onEdit = (cancel = false) => {
    let { selectedItem } = this.state;

    if (cancel) {
      this.handleSelect(selectedItem);
    } else {
      this.searchApi.submitForm();

      const { action } = this.state;

      if (action === actions.insert) {
        selectedItem = {};
      }
    }

    this.setState({ action: actions.idle, selectedItem });
  };

  handleSave = values => {
    this.setState({ isLoading: true });
    RecadoPostoService.salvar(
      values,
      () => this.onSave(),
      () => this.onSave(true)
    );
  };

  onSave = (error = false) => {
    this.setState({ isLoading: false });

    if (!error) {
      const { name } = this.props;
      const { action } = this.state;

      this.onEdit();

      notifySuccess({ name, action });
    }
  };

  render = () => {
    const { tabId, menuId } = this.props;
    const { isLoading, itens, selectedItem, action, funcionarios, funcoes } = this.state;
    const isEdit = action !== actions.idle && !isLoading;
    const isIdle = action === actions.idle && !isLoading;

    return (
      <>
        <Page tabId={tabId} footer={action !== actions.insert && selectedItem.idt && <Status record={selectedItem} />} closable={isIdle}>
          <Form getApi={api => (this.searchApi = api)} onSubmit={this.handleSearch}>
            <ContentWrapper type="search">
              <Row gutter={4}>
                <Col md={10} span={24}>
                  <SelectCombo
                    field="funcionario"
                    label="Funcionário"
                    dataSource={funcionarios}
                    allowClear
                    disabled={isLoading}
                    forwardedRef={ref => (this.searchInput = ref)}
                  />
                </Col>
                <Col md={3} span={12}>
                  <DatePicker field="inicial" label="Período Inicial" allowClear disabled={isLoading} />
                </Col>
                <Col md={3} span={12}>
                  <DatePicker field="final" label="Período Final" allowClear disabled={isLoading} />
                </Col>
                <Col md={5} span={20}>
                  <Input field="cnpj" label="CPF/CNPJ" type="cpfCnpj" allowClear disabled={isLoading} />
                </Col>
                <Col md={3} span={4}>
                  <Button.Search disabled={isLoading} />
                </Col>
              </Row>
            </ContentWrapper>
          </Form>
          <Table
            rowKey="idt"
            loading={isLoading}
            record={selectedItem}
            onRecordChange={this.handleSelect}
            dataSource={itens}
            description={item => (
              <Row>
                <Col span={16}>
                  <span style={{ fontSize: '0.8rem' }}>{item.nome_funcionario}</span>
                </Col>
                <Col span={8}>
                  <span>{item.data_hora}</span>
                </Col>
              </Row>
            )}
            height={window.innerHeight - 460}
          >
            <Table.Column width="28%" title="Funcionário" dataIndex="nome_funcionario" />
            <Table.Column width="32%" title="Recado" dataIndex="recado" />
            <Table.Column width="19%" title="Contato" dataIndex="contato" />
            <Table.Column width="21%" title="Dia/Hora" align="center" dataIndex="data_hora" />
          </Table>
          <Form getApi={api => (this.saveApi = api)} onSubmit={this.handleSave}>
            <ContentWrapper type="header" color={action.color}>
              <Row gutter={4}>
                <Col md={4} span={12}>
                  <Input field="de" label="De" type="string" forwardedRef={ref => (this.editInput = ref)} allowClear disabled={!isEdit} />
                </Col>
                <Col md={5} span={12}>
                  <Input field="email" label="Email" type="email" allowClear disabled={!isEdit} />
                </Col>
                <Col md={8} span={24}>
                  <SelectCombo field="funcionario" label="Funcionário" dataSource={funcionarios} allowClear disabled={!isEdit} />
                </Col>
                <Col md={4} span={12}>
                  <Select field="funcao" label="Função" dataSource={funcoes} allowClear disabled={!isEdit} />
                </Col>
                <Col md={3} span={12}>
                  <DatePicker field="data_expira" label="Expira" today allowClear disabled={!isEdit} />
                </Col>
                <Col md={24} span={24}>
                  <Input.TextArea field="recado" label="Mensagem" type="string" required capsLock={false} disabled={!isEdit} />
                </Col>
                <Col md={24} span={24}>
                  <Input.TextArea field="resposta" label="Resposta" type="string" capsLock={false} disabled />
                </Col>
              </Row>
            </ContentWrapper>
          </Form>
        </Page>
        <FooterToolbar
          left={
            <>
              <Button.Insert menuId={menuId} disabled={!isIdle} perfilAcess="visualizar" onClick={() => this.handleEdit(actions.insert)} />
            </>
          }
          center={
            <>
              <Navigator
                disabled={!isIdle}
                record={selectedItem}
                dataSource={itens}
                dataIndex="idt"
                onClick={record => this.setState({ selectedItem: record })}
              />
            </>
          }
          right={
            <>
              <Button.Cancel disabled={isIdle || isLoading} onClick={() => this.onEdit(true)} />
              <Button.Save disabled={isIdle || isLoading} loading={action !== actions.idle && isLoading} onClick={() => this.saveApi.submitForm()} />
            </>
          }
        />
      </>
    );
  };
}

export default RecadoPostoPage;
