// Amapá
const ap = [
  { data: '1600105', label: 'Amapá' },
  { data: '1600204', label: 'Calçoene' },
  { data: '1600212', label: 'Cutias' },
  { data: '1600238', label: 'Ferreira Gomes' },
  { data: '1600253', label: 'Itaubal' },
  { data: '1600279', label: 'Laranjal do Jari' },
  { data: '1600303', label: 'Macapá' },
  { data: '1600402', label: 'Mazagão' },
  { data: '1600501', label: 'Oiapoque' },
  { data: '1600154', label: 'Pedra Branca do Amapari' },
  { data: '1600535', label: 'Porto Grande' },
  { data: '1600550', label: 'Pracuúba' },
  { data: '1600600', label: 'Santana' },
  { data: '1600055', label: 'Serra do Navio' },
  { data: '1600709', label: 'Tartarugalzinho' },
  { data: '1600808', label: 'Vitória do Jari' },
];

export default ap;
