import React from 'react';
import { Upload } from 'antd';
import { LoadingOutlined, SendOutlined } from '@ant-design/icons';
import { CustomerService } from '~/services';

import './UploadDragger.less';

class UploadDragger extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      baseUpload: `${process.env.REACT_APP_URL}/arquivo/upload_react.php?diretorio=${
        props.diretorio
      }&cliente=${CustomerService.getCustomer().nome.toLowerCase()}`,
    };
  }

  onComplete = info => {
    const { completeUpload, defaultFileList } = this.props;

    if (completeUpload && info.file.status === 'done')
      completeUpload(
        [...defaultFileList, ...info.fileList.filter(itemFilter => !defaultFileList.find(itemDefault => itemDefault.uid === itemFilter.uid))].map(item =>
          item.idt
            ? item
            : {
                ...item,
                url: `${process.env.REACT_APP_URL.replace(':3000', '')}/arquivo/${
                  info.fileList.find(itemFind => itemFind.uid === item.uid).response
                    ? info.fileList.find(itemFind => itemFind.uid === item.uid).response.url
                    : ''
                }`,
              }
        )
      );

    this.setState({ isLoading: false });
  };

  render = () => {
    const { isLoading, baseUpload } = this.state;
    const { loading, diretorio, onComplete, defaultFileList, ...rest } = this.props;

    return (
      <div className="clearfix">
        <Upload.Dragger
          multiple
          listType="picture"
          className="upload-list-inline"
          fileList={defaultFileList}
          disabled={isLoading || loading}
          beforeUpload={() => {
            this.setState({ isLoading: true });
          }}
          onChange={info => {
            this.onComplete(info);
          }}
          action={baseUpload}
          {...rest}
        >
          <p className="ant-upload-hint">
            {isLoading ? <LoadingOutlined style={{ marginRight: '8px' }} /> : <SendOutlined style={{ marginRight: '8px' }} />}
            Clique ou arraste o arquivo para esta área para fazer upload
          </p>
        </Upload.Dragger>
      </div>
    );
  };
}

export default UploadDragger;
