import { HttpService } from '~/helpers';
import { UserService } from '~/services';

const endpoint = 'financeiro/cheque';

const formatFilters = filters =>
  filters
    ? [
        { nome_campo: 'cheque.cliente', valor: UserService.getUser().codigo, tipo: 'int' },
        { nome_campo: 'cheque.origem', valor: filters.origem, tipo: 'int' },
        { nome_campo: 'cheque.placa', valor: filters.placa ? filters.placa.replace('-', '') : '', tipo: 'texto' },
        { nome_campo: 'cheque.vencimento', valor: filters.inicial && filters.final ? `${filters.inicial} AND ${filters.final}` : '', tipo: 'datas' },
        { nome_campo: 'cheque.numero', valor: filters.numero, tipo: 'int' },
        { nome_campo: 'banco.banco', valor: filters.banco, tipo: 'int' },
        { nome_campo: 'banco.conta', valor: filters.conta, tipo: 'int' },
      ]
    : [];

const formatValues = values => ({
  ...values,
  usuario: UserService.getUser().login,
  cliente: UserService.getUser().codigo,
});

const ChequeService = {
  getAll: (values, onSuccess, onError) => {
    HttpService.call(endpoint, 'getAll', [formatFilters(values)], onSuccess, onError);
  },
  getAllTrocados: (values, onSuccess, onError) => {
    HttpService.call(endpoint, 'getAllTrocados', [formatValues(values), formatFilters(values)], onSuccess, onError);
  },
  getAllImagem: (idtImagem, onSuccess, onError) => {
    HttpService.call(endpoint, 'getAllImagem', [idtImagem], onSuccess, onError);
  },
};

export default ChequeService;
