import React from 'react';
import PubSub from 'pubsub-js';
import { Layout } from 'antd';
import { connect } from 'react-redux';
import Animate from 'rc-animate';
import { TopNavigator, GlobalHeader } from './components';
import { MenuService, RememberService, UserService } from '~/services';
import './Header.less';
import AtualizarCadastroView from '~/pages/general/AtualizarCadastroView';

let dash = false;
let pageDev = false;
let pageRecados = false;
let pageAtualiza = false;

class Header extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isVisible: true,
      itensRecados: [],
      showAtualizarCadastro: false,
    };
  }

  static getDerivedStateFromProps({ settings }, { isVisible }) {
    if (!settings.autoHideHeader && !isVisible) {
      return { isVisible: true };
    }

    return null;
  }

  componentDidMount() {
    document.addEventListener('scroll', this.handleScroll, { passive: true });

    UserService.getAllRecados(
      UserService.getUser().codigo,
      UserService.getUser().filial,
      itensRecados => this.setState({ itensRecados }),
      () => {}
    );
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this.handleScroll);
  }

  handleSubscription = subscribe => {
    let { token } = this.state;

    if (subscribe) {
      token = PubSub.subscribe('logout_service', () => {
        this.onLogout();
      });
    } else {
      PubSub.unsubscribe(token);
    }
  };

  onLogout = () => {
    const { history } = this.props;

    UserService.logout(() => history.push(`/${process.env.REACT_APP_VERSION}/login`));
  };

  handleScroll = () => {
    const { settings } = this.props;

    if (!settings.autoHideHeader) {
      return;
    }

    const { isVisible, isTicking, oldScrollTop } = this.state;
    const scrollTop = document.body.scrollTop + document.documentElement.scrollTop;

    if (!isTicking) {
      this.setState({ isTicking: true });

      requestAnimationFrame(() => {
        if (oldScrollTop > scrollTop) {
          this.setState({ isVisible: true });
        } else if (scrollTop > 300 && isVisible) {
          this.setState({ isVisible: false });
        } else if (scrollTop < 300 && !isVisible) {
          this.setState({ isVisible: true });
        }

        this.setState({ isTicking: false, oldScrollTop: scrollTop });
      });
    }
  };

  getHeaderWidth = () => {
    const { settings, general } = this.props;

    if (general.isMobile || !settings.fixedHeader || settings.layout === 'topmenu') {
      return '100%';
    }

    return general.isCollapsed ? 'calc(100% - 80px)' : 'calc(100% - 256px)';
  };

  render() {
    const { settings, general } = this.props;
    const { isVisible, itensRecados, showAtualizarCadastro } = this.state;

    setTimeout(() => {
      // Open DASHBOARD
      const menuDash = '51';
      if (!dash && MenuService.getById(menuDash) && UserService.getUser().trocar_senha_portal !== 'S') {
        dash = true;
        MenuService.open(MenuService.getById(menuDash), {});
      }

      // Open - RECADOS
      const menuRecado = '7';
      if (!pageRecados && MenuService.getById(menuRecado) && itensRecados.length > 0 && UserService.getUser().trocar_senha_portal !== 'S') {
        pageRecados = true;
        MenuService.open(MenuService.getById(menuRecado), {});
      }

      // Open - FIXED
      const idPageDev = RememberService.getRemember().page_fixed;
      if (!pageDev && idPageDev && MenuService.getById(idPageDev) && UserService.getUser().trocar_senha_portal !== 'S') {
        pageDev = true;
        const menuSelected = MenuService.getById(idPageDev);
        MenuService.open(menuSelected, {});
      }

      // Open ATUALZIAR CADASTRO
      if (!pageAtualiza && !showAtualizarCadastro && UserService.getUser().atualizar_cadastro_portal === 'S') {
        pageAtualiza = true;
        this.setState({ showAtualizarCadastro: true });
      }
    }, 500);

    return (
      <>
        <AtualizarCadastroView action={showAtualizarCadastro} onOk={() => this.setState({ showAtualizarCadastro: false })} />
        <Animate component="" transitionName="fade">
          {isVisible ? (
            <Layout.Header
              style={{ padding: 0, zIndex: 2, width: this.getHeaderWidth() }}
              className={settings.fixedHeader ? 'header-fixed-header' : 'no-header-fixed-header'}
            >
              {settings.layout === 'topmenu' && !general.isMobile ? <TopNavigator /> : <GlobalHeader />}
            </Layout.Header>
          ) : null}
        </Animate>
      </>
    );
  }
}

const mapStateToProps = ({ settings, general, menus }) => ({ settings, general, menus });

export default connect(mapStateToProps)(Header);
