import React from 'react';
import { IndexBar, List, PullToRefresh, SwipeAction } from 'antd-mobile';
import classNames from 'classnames';
import { groupBy } from 'lodash';
import { Loading } from '..';
import './ListMobile.less';

class ListMobile extends React.PureComponent {
  render() {
    const {
      loading,
      dataSource,
      onRecordChange,
      description,
      rowKey,
      dataIndex,
      height = window.innerHeight - 176,
      dataIndexBar,
      handleDelete,
      rowColor,
      pullToRefresh,
      className,
      leftActions = [],
    } = this.props;
    const itensSource = dataIndexBar ? dataSource.map(item => ({ ...item, index: item[dataIndexBar].substring(0, 1) })) : [];
    const groups = Object.keys(groupBy(itensSource, item => item.index)).map(item => ({
      title: item,
      items: dataSource.filter(itemFilter => itemFilter[dataIndexBar].substring(0, 1) === item),
    }));

    const execColor = item => {
      if (rowColor) {
        for (let index = 0; index < rowColor.length; index += 1) {
          const itemRowColor = rowColor[index];
          if (itemRowColor.predicate(item)) {
            return itemRowColor.color.className;
          }
        }
      }
      return undefined;
    };

    const descriptionDefault = item => {
      return <span>{item[rowKey]}</span>;
    };

    return (
      <PullToRefresh pullingText="Atualizar..." canReleaseText="Atualizar..." refreshingText="Buscando..." completeText="Buscando..." onRefresh={pullToRefresh}>
        {dataSource.length === 0 && !loading ? (
          <div style={{ height: `${height}px` }} className="div-not-found">
            {'Nenhum registro encontrado'}
          </div>
        ) : loading ? (
          <Loading style={{ marginTop: '8px', height: `${height}px` }} />
        ) : (
          <div className="div-list">
            {dataIndexBar ? (
              <IndexBar className={classNames('index-bar-list-mobile', className || '')} style={{ height: `${height}px` }}>
                {groups.map(group => (
                  <IndexBar.Panel index={group.title} title={group.title} key={group.title}>
                    <List className={className || ''}>
                      {group.items.map(item => (
                        <SwipeAction
                          key={item[rowKey]}
                          closeOnAction={false}
                          closeOnTouchOutside={false}
                          rightActions={
                            handleDelete
                              ? [
                                  {
                                    key: 'delete',
                                    text: 'Excluir',
                                    color: 'danger',
                                    onClick: () => {
                                      handleDelete(item);
                                    },
                                  },
                                ]
                              : []
                          }
                          leftActions={[...leftActions.map(itemLA => ({ ...itemLA, onClick: () => itemLA.onClick(item) }))]}
                        >
                          <List.Item
                            key={item[rowKey]}
                            prefix={dataIndex ? item[dataIndex] : undefined}
                            description={description ? description(item) : itemDesc => <span>{itemDesc[rowKey]}</span>}
                            arrow={false}
                            onClick={() => (onRecordChange ? onRecordChange(item) : '')}
                            className={execColor(item)}
                          />
                        </SwipeAction>
                      ))}
                    </List>
                  </IndexBar.Panel>
                ))}
              </IndexBar>
            ) : (
              <List className={className || ''} style={{ height: height && dataSource.length > 0 && !loading ? `${height}px` : 'auto' }}>
                {dataSource.map(item => (
                  <SwipeAction
                    key={item[rowKey]}
                    rightActions={
                      handleDelete
                        ? [
                            {
                              key: 'delete',
                              text: 'Excluir',
                              color: 'danger',
                              onClick: () => {
                                handleDelete(item);
                              },
                            },
                          ]
                        : []
                    }
                    leftActions={[...leftActions.map(itemLA => ({ ...itemLA, onClick: () => itemLA.onClick(item) }))]}
                  >
                    <List.Item
                      key={item[rowKey]}
                      prefix={dataIndex ? item[dataIndex] : undefined}
                      description={description ? description(item) : descriptionDefault(item)}
                      arrow={false}
                      onClick={() => (onRecordChange ? onRecordChange(item) : '')}
                      className={execColor(item)}
                    />
                  </SwipeAction>
                ))}
              </List>
            )}
          </div>
        )}
      </PullToRefresh>
    );
  }
}
export default ListMobile;
