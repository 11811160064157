import React from 'react';
import { connect } from 'react-redux';
import { Upload as AntdUpload, message, Dropdown, Menu, Card, Avatar, Row, Col } from 'antd';
import { UpSquareOutlined, DeleteOutlined, LoadingOutlined, InboxOutlined, EditOutlined } from '@ant-design/icons';
import { FileService } from '~/services';
import { uploadPaths } from '~/options';
import './UploadAnexo.less';
import UploadAnexoDescricaoView from './UploadAnexoDescricaoView';

class UploadAnexo extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      fileList: [],
      fileEdit: undefined,
    };
  }

  componentDidMount() {
    const { record } = this.props;
    if (record) {
      this.handleSearch();
    }
  }

  componentDidUpdate(prevProps) {
    const { record, action } = this.props;
    const recordChange = record !== prevProps.record;
    const actionChange = action !== prevProps.action;

    if (recordChange || actionChange) {
      this.handleSearch();
    }
  }

  handleSearch = () => {
    const { table, record } = this.props;

    if (record) {
      this.setState({ isLoading: true });

      FileService.search({ tabela: uploadPaths[table] ? uploadPaths[table].table : table, idt_registro: record }, this.onSearch, () => this.onSearch());
    }
  };

  onSearch = (fileList = []) => {
    this.setState({ isLoading: false, fileList });
  };

  onUploadAnexo = ({ file }) => {
    const { fileSize = 10, table, record, empresas } = this.props;
    const sizeLimit = uploadPaths[table] ? uploadPaths[table].fileSize : fileSize;

    if (file.size > sizeLimit * 1024 * 1024) {
      return message.error(
        <span>
          Arquivo
          <b>{file.name}</b>
          maior que o permitido.
        </span>,
        5
      );
    }

    const formData = new FormData();
    formData.append('file', file);

    FileService.insertAttachment(file.name, table, formData, ({ data }) =>
      FileService.insert(
        {
          idt_registro: record,
          origem: empresas.selected.idt,
          tabela: uploadPaths[table] ? uploadPaths[table].table : table,
          nome_arquivo: file.name,
          url: `https://erp.mysaas.com.br/arquivo/${data.url}`,
        },
        (message.success(
          <span>
            Arquivo
            <b>{file.name}</b>
            enviado com sucesso.
          </span>,
          3
        ),
        this.handleSearch),
        () => {
          this.onSearch();
        }
      )
    );
  };

  handleDelete = file => {
    FileService.delete(
      file.idt,
      (message.success(
        <span>
          Arquivo
          <b>{file.nome_arquivo}</b>
          removido com sucesso.
        </span>,
        3
      ),
      this.handleSearch)
    );
  };

  handleEditDescricao = file => {
    this.setState({ fileEdit: file });
  };

  handleSaveDescricao = file => {
    this.setState({ isLoading: true });
    FileService.saveDescricao(
      file,
      () => {
        this.setState({ isLoading: false });
        this.handleSearch();
      },
      this.setState({ isLoading: false })
    );
  };

  openAnexo = ({ url }) => {
    window.open(url, '_blank');
  };

  renderUploads = () => {
    const { fileList } = this.state;

    return (
      <Row gutter={4}>
        {fileList.map(file => (
          <Col md={8} key={file.uid || file.idt}>
            <Dropdown
              overlay={
                // eslint-disable-next-line react/jsx-wrap-multilines
                <Menu>
                  <Menu.Item onClick={() => this.handleEditDescricao(file)}>
                    <EditOutlined style={{ color: 'blue' }} />
                    Descrição
                  </Menu.Item>
                  <Menu.Item onClick={() => this.handleDelete(file)}>
                    <DeleteOutlined style={{ color: 'red' }} />
                    Excluir
                  </Menu.Item>
                </Menu>
              }
              trigger={['contextMenu']}
            >
              <Card hoverable size="small" onClick={() => this.openAnexo(file)}>
                <Card.Meta
                  className="card-meta-upload-anexo"
                  description={file.descricao}
                  title={file.nome_arquivo}
                  avatar={<Avatar icon={<UpSquareOutlined />} src={file.url} />}
                />
              </Card>
            </Dropdown>
          </Col>
        ))}
      </Row>
    );
  };

  render() {
    const { isLoading, fileEdit } = this.state;
    const { loading, disabled, record, fileSize = 10, table, ...rest } = this.props;
    const sizeLimit = uploadPaths[table] ? uploadPaths[table].fileSize : fileSize;
    const hidden = !record;

    return (
      <div hidden={hidden}>
        <UploadAnexoDescricaoView action={fileEdit} onCancel={() => this.setState({ fileEdit: undefined })} onOk={this.handleSaveDescricao} />
        <Row gutter={4}>
          <Col md={24}>
            <AntdUpload.Dragger
              name="name"
              multiple
              fileList={[]}
              showUploadList
              className="upload"
              disabled={disabled || isLoading || loading}
              customRequest={this.onUploadAnexo}
              {...rest}
            >
              <p className="ant-upload-hint">
                {isLoading || loading ? <LoadingOutlined style={{ marginRight: '8px' }} /> : <InboxOutlined style={{ marginRight: '8px' }} />}
                {`Clique ou arraste o arquivo para fazer upload. Tamanho máximo: ${sizeLimit}MB.`}
              </p>
            </AntdUpload.Dragger>
          </Col>
        </Row>
        {this.renderUploads()}
      </div>
    );
  }
}

const mapStateToProps = ({ empresas }) => ({ empresas });
export default connect(mapStateToProps)(UploadAnexo);
