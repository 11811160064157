import React from 'react';
import KeyboardEventHandler from 'react-keyboard-event-handler';
import { InputNumber as AntdInputNumber } from 'antd';
import classNames from 'classnames';
import { types } from './options';
import './InputNumberCell.less';
import { onKeyEnterTableCell } from '~/utils';

class InputNumberCell extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      value: props.defaultValue,
    };
  }

  render() {
    const { type, onChange, onBlur, className, record, field, functionAction, defaultValue, forceZero, enterLinha, ...rest } = this.props;
    const { value } = this.state;

    return (
      <KeyboardEventHandler
        handleKeys={['enter']}
        onKeyEvent={(key, e) => {
          onKeyEnterTableCell(e, 'number', enterLinha);
        }}
      >
        <AntdInputNumber
          size="small"
          value={forceZero && defaultValue === 0 ? 0 : defaultValue}
          defaultValue={defaultValue}
          precision={types[type].precision}
          formatter={types[type].formatter}
          parser={types[type].parser}
          className={classNames('input-number', { block: true }, className, types[type].className)}
          onChange={changeValue => {
            if (types[type].restrict && !types[type].restrict(changeValue)) {
              return;
            }

            this.setState({ value: changeValue });

            if (onChange) {
              onChange(changeValue);
            }
          }}
          onBlur={event => {
            if (record[field] !== value) {
              if (functionAction) functionAction({ ...record, [field]: value }, field);
            }

            if (onBlur) {
              onBlur(event);
            }
          }}
          {...rest}
        />
      </KeyboardEventHandler>
    );
  }
}

export default InputNumberCell;
