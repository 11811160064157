import axios from 'axios';
import { message, Modal } from 'antd';
import { HttpService } from '~/helpers';
import { UserService, CustomerService } from '~/services';
import { store } from '~/stores';
import { openWindowWithPost } from '~/utils';

const endpoint = 'relatorio/relatorio';

const formatValues = values => ({
  ...values,
  usuario: UserService.getUser().login,
});

const RelatorioService = {
  getChamaRelatorio: (values, nomeServlet, tituloRelatorio, onSucess, onError) => {
    const customer = CustomerService.getCustomer();
    const user = UserService.getUser();
    let newNomeServlet = nomeServlet;
    if (customer.estrutura === 'MYSAAS' && nomeServlet === 'ServletPedidos') {
      newNomeServlet = 'ServletPedido';
    }

    values.push({ nome_campo: 'nome_servlet', valor: newNomeServlet });
    values.push({ nome_campo: 'titulo_relatorio', valor: tituloRelatorio });
    values.push({ nome_campo: 'banco_dados', valor: customer.database });
    values.push({ nome_campo: 'hiperlink', valor: `'${process.env.REACT_APP_URL}'` });
    values.push({ nome_campo: 'hiperlink_real', valor: `'${process.env.REACT_APP_URL}'` });
    values.push({ nome_campo: 'strserver', valor: customer.server_relatorio });
    values.push({ nome_campo: 'strhost', valor: customer.endereco_banco });
    values.push({ nome_campo: 'perfil', valor: user.perfil });
    values.push({ nome_campo: 'usuario', valor: UserService.getUser().login });
    values.push({ nome_campo: 'logo_origem', valor: customer.logo_origem });
    values.push({ nome_campo: 'empresa', valor: customer.nome.toLowerCase() });

    const retorno = {
      id_tmp: user.idtmp,
      servlet: newNomeServlet,
      str_server: customer.server_relatorio,
      str_host: customer.endereco_banco,
      str_host_externo: customer.host_externo,
      servidor_externo: customer.endereco_banco_externo,
      usuario: UserService.getUser().login,
      email: 'N',
      titulo: tituloRelatorio,
      relatorio_grafico: 'N',
      exporta_excel: (values.find(item => item.nome_campo === 'exporta_excel') || {}).valor || 'N',
      exporta_csv: (values.find(item => item.nome_campo === 'exporta_csv') || {}).valor || 'N',
      estrutura: customer.estrutura,
    };

    HttpService.call(
      endpoint,
      'getChamaRelatorio',
      [values, retorno],
      response => {
        if (onSucess) onSucess();
        const context = customer.estrutura === 'BENASSI' ? 'relatorios_benassi_erp' : 'relatorios_mysaas';
        if (customer.server_relatorio.indexOf('localhost') > -1 && !values.find(item => item.nome_campo === 'exporta_csv' && item.valor === 'S')) {
          // const urlRel = `http://localhost:8080/${context}/CarregadorServlets?acao=${response.servlet}&PAR_FILTRO=${response.id_tmp}&SERVIDOR=${response.str_host}`;
          const urlRel = `${customer.server_relatorio}/${context}/CarregadorServlets?acao=${response.servlet}&PAR_FILTRO=${response.id_tmp}&SERVIDOR=${response.str_host}`;
          window.open(urlRel, '_blank');
        } else {
          window.open(`${process.env.REACT_APP_URL}/${response.arquivo}`, '_blank');
        }
      },
      onError,
      { innerHtml: true }
    );
  },
  getVarsRelatorio: () => {
    const values = [];
    const customer = CustomerService.getCustomer();
    const user = UserService.getUser();
    const empresa = store.getState().empresas;

    values.push({ nome_campo: 'banco_dados', valor: customer.database });
    values.push({ nome_campo: 'banco_dados_nota', valor: empresa.selected.database_nfe });
    values.push({ nome_campo: 'hiperlink', valor: `'${process.env.REACT_APP_URL}'` });
    values.push({ nome_campo: 'hiperlink_real', valor: `'${process.env.REACT_APP_URL}'` });
    values.push({ nome_campo: 'strserver', valor: customer.server_relatorio });
    values.push({ nome_campo: 'strhost', valor: customer.endereco_banco });
    values.push({ nome_campo: 'perfil', valor: user.perfil });
    values.push({ nome_campo: 'usuario', valor: UserService.getUser().login });
    values.push({ nome_campo: 'logo_origem', valor: empresa.selected.logo_origem });
    values.push({ nome_campo: 'empresa', valor: customer.nome.toLowerCase() });

    values.push({ nome_campo: 'retorna_excel', valor: 'N' });
    values.push({ nome_campo: 'retorna_pdf', valor: 'S' });
    values.push({ nome_campo: 'webnfe3', valor: 'S' });
    values.push({ nome_campo: 'email', valor: 'S' });

    return values;
  },
  getChamaRelatorioLocal: (values, relatorio, onSuccess = null, onError = null) => {
    const customer = CustomerService.getCustomer();
    const params = { relatorio, endereco_banco: customer.endereco_banco, banco_dados: customer.database, versao_mysql: '5' };
    values.forEach(item => {
      params[item.nome_campo] = item.valor;
    });

    message.success(`Impressão enviada - ${relatorio.replace('_', ' ')}`, 5);

    axios({
      method: 'post',
      baseURL: `http://127.0.0.1:8085/`,
      responseType: 'json',
      responseEncoding: 'utf8',
      params,
    })
      .then(response => {
        if (response.status !== 200) {
          Modal.error({
            title: 'Erro ao executar AutoImp',
            content: `${response.status} - ${response.statusText}`,
            centered: true,
          });
        } else if (response.status === 200 && onSuccess) {
          onSuccess('');
        }
      })
      .catch(error => {
        // só liberar código quando tiver Access-Control-Allow-Origin no header do autoimp novo
        // let mensagemErro = '';
        // if (error.message === 'Network Error') mensagemErro = 'Verifique se está instalado e executando corretamente';
        // Modal.error({
        //   title: 'Erro ao executar AutoImp',
        //   content: mensagemErro || error.message,
        //   centered: true,
        // });

        if (onError) {
          onError(error);
        }
      });
  },
  fetchRelatorios: (onSuccess, onError) => {
    HttpService.call(endpoint, 'fetchRelatorios', [UserService.getUser().perfil, UserService.getUser().login], onSuccess, onError);
  },
  getMacros: (onSuccess, onError) => {
    HttpService.call('sistema/macro', 'getMacros', ['', UserService.getUser().login], onSuccess, onError);
  },
  getMacrosDados: (onSuccess, onError) => {
    HttpService.call('sistema/macro', 'getMacrosDados', ['', UserService.getUser().login], onSuccess, onError);
  },
  getGeraBoleto: (values, onSuccess, onError) => {
    HttpService.call(
      endpoint,
      'getGeraBoleto',
      [formatValues({ ...values, tipo: 'pdf', origem: values.origem })],
      response => {
        const customer = CustomerService.getCustomer();

        if (onSuccess) onSuccess();
        openWindowWithPost(
          response.service,
          {
            boletos: response.boletos,
            remessa: response.remessa || '',
          },
          customer.server_relatorio
        );
      },
      onSuccess,
      onError
    );
  },
  getGeraRemessa: (values, onSuccess) => {
    HttpService.call(endpoint, 'getGeraRemessa', [formatValues({ ...values, banco: values.banco.data })], response => {
      onSuccess();

      openWindowWithPost(
        response.service,
        {
          boletos: JSON.stringify(response.boletos),
          remessa: JSON.stringify({ ...response.remessa, ...values, banco: values.banco.codigoBanco, boletos: response.boletos } || ''),
        },
        CustomerService.getCustomer().server_relatorio
      );
    });
  },
  getVisualizarCheque: (record, tipo, onSuccess, onError) => {
    HttpService.call(endpoint, 'getVisualizarCheque', [record, tipo], onSuccess, onError);
  },
  getChamaRelatorioMobile: (values, dadosEmpresa, obj, onSuccess, onError) => {
    HttpService.call(endpoint, 'getChamaRelatorioMobile', [formatValues(values), dadosEmpresa, obj], onSuccess, onError);
  },
  getKeyFiltro: (chave, ar, onSuccess, onError) => {
    HttpService.call(endpoint, 'getKeyFiltro', [chave, ar], onSuccess, onError);
  },
  enviar: (emailTo, url, nomeRel, observacao, logo, onSuccess, onError) => {
    HttpService.call(
      endpoint,
      'enviar',
      [emailTo, url, UserService.getUser().email, UserService.getUser().login, nomeRel, observacao, CustomerService.getCustomer().nome, logo],
      onSuccess,
      onError
    );
  },
  altera_campo: (relatorios, campos, nome, onSuccess, onError) => {
    HttpService.call(endpoint, 'altera_campo', [relatorios, campos, nome], onSuccess, onError);
  },
  getGrafico: (url, onSuccess, onError) => {
    HttpService.call(endpoint, 'getGrafico', [url], onSuccess, onError);
  },
  exporta_csv: (server, obj, onSuccess, onError) => {
    HttpService.call(endpoint, 'exporta_csv', [server, obj], onSuccess, onError);
  },
  listasCampos: onSuccess => {
    HttpService.call(endpoint, 'listasCampos', [], onSuccess);
  },
};

export default RelatorioService;
