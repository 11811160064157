import React from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { useField } from 'informed';
import { DatePicker as AntdDatePicker, Tooltip } from 'antd';
import classNames from 'classnames';
import { SizeMe } from 'react-sizeme';
import moment from 'moment';
import { isRequired, momentValue } from '~/utils';
import { Form } from '~/components/forms';
import './DatePicker.less';

const DatePicker = React.memo(({ required, validate, validateOnChange, today, ...props }) => {
  props.initialValue = today ? moment().format('YYYYMMDD') : props.initialValue;

  const { fieldState, fieldApi, render, userProps } = useField({
    validate: validate || (required && isRequired) || (() => undefined),
    validateOnChange: validateOnChange || required || validate,
    ...props,
  });
  const {
    onChange,
    onBlur,
    label,
    initialValue,
    forwardedRef,
    allowClear,
    formItemProps,
    className,
    block,
    dropdownClassName,
    popupStyle,
    locale,
    disabledReason,
    tooltipProps,
    isMobile,
    ...rest
  } = userProps;

  let formato = 'DD/MM/YYYY';
  if (props.picker === 'month') {
    formato = 'MM/YYYY';
  }

  return render(
    <Form.Item label={label} error={fieldState.error} required={required} {...formItemProps}>
      <Tooltip title={rest.disabled && disabledReason} placement="bottom" color="red" {...tooltipProps}>
        <SizeMe>
          {({ size }) => (
            <AntdDatePicker
              placeholder=""
              value={
                props.picker === 'month'
                  ? fieldState.value && fieldState.value !== ''
                    ? moment(`20${fieldState.value}`, 'YYYYMM')
                    : undefined
                  : momentValue(fieldState.value || initialValue)
              }
              picker={props.picker}
              ref={forwardedRef}
              className={classNames('date-picker', { block: block === true }, className)}
              dropdownClassName={classNames('date-picker-dropdown', { block: block === true }, dropdownClassName)}
              format={formato}
              onChange={(date, dateString) => {
                let dataFormatada = '';
                if (props.picker === 'month') {
                  dataFormatada = `${dateString.substring(5)}${dateString.substring(0, 2)}`;
                } else {
                  dataFormatada = `${dateString.substring(6)}${dateString.substring(3, 5)}${dateString.substring(0, 2)}`;
                }

                fieldApi.setValue(dataFormatada);

                if (onChange) {
                  onChange(date, dateString);
                }
              }}
              onBlur={event => {
                if (event.target.value && event.target.value.indexOf('/') === -1) {
                  const valorMoment = moment(event.target.value, 'DDMMYYYY').format('YYYYMMDD');
                  if (valorMoment !== 'Invalid date') {
                    fieldApi.setValue(valorMoment);
                  } else {
                    fieldApi.setValue(undefined);
                  }
                }

                fieldApi.setTouched();

                if (onBlur) {
                  onBlur(event);
                }
              }}
              allowClear={!required && !rest.disabled}
              popupStyle={{
                ...(block && size.width > 280 && { width: size.width }),
                ...popupStyle,
              }}
              inputReadOnly={isMobile}
              {...rest}
            />
          )}
        </SizeMe>
      </Tooltip>
    </Form.Item>
  );
});

const isMobileSelector = createSelector(
  general => general,
  ({ isMobile }) => isMobile
);

const mapStateToProps = ({ general }) => ({ isMobile: isMobileSelector(general) });

export default connect(mapStateToProps)(DatePicker);
