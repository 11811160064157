import React from 'react';
import _ from 'lodash';
import { decimalPlace } from '~/utils';
import { execFilter, groupByXY } from './functionsCharts';
import Pie from '../charts/Pie';

class PieDashboard extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { data, record, listTotalizar } = this.props;
    const valores = JSON.parse(atob(record.valores));
    const filtros = JSON.parse(atob(record.filtros));
    const totalizarSelected = listTotalizar.find(item => item.data === valores.agrupamento_pie);

    const filterDataSource = execFilter(data, filtros);
    let dataSourcePie = groupByXY(filterDataSource, valores.analise_pie, valores.agrupamento_pie);
    if (dataSourcePie.length > 9) {
      const outRankeds = _.slice(dataSourcePie, 9);
      dataSourcePie = _.slice(dataSourcePie, 0, 9);
      dataSourcePie.push({
        x: `OUTROS (${outRankeds.length})`,
        y: _.reduce(outRankeds, (previous, current) => current.y + previous, 0),
      });
    }

    return (
      <Pie
        data={dataSourcePie}
        key={record.idt}
        height={250}
        lineWidth={5}
        hasLegend
        valueFormat={totalizarSelected && totalizarSelected.tipo === 'integer' ? undefined : decimalPlace}
        total={decimalPlace(
          dataSourcePie.reduce((previous, current) => current.y + previous, 0),
          totalizarSelected && totalizarSelected.tipo === 'integer' ? 0 : 2
        )}
        subTitle="Total"
        className="pie-table-dashboard"
      />
    );
  }
}

export default PieDashboard;
