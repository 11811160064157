import { store } from '~/stores';
import { actions } from '~/stores/ducks';

const StoreService = {
  fetchProdutosGrupos: () => {
    const { fetchProdutosGrupos } = actions;

    store.dispatch(fetchProdutosGrupos());
  },
  fetchProdutosSubGrupos: () => {
    const { fetchProdutosSubGrupos } = actions;

    store.dispatch(fetchProdutosSubGrupos());
  },
  fetchProdutosGrupoSeparacao: () => {
    const { fetchProdutosGrupoSeparacao } = actions;

    store.dispatch(fetchProdutosGrupoSeparacao());
  },
  // Os loadings são independentes e devem ser tratados individualmente
  // Ex.: SyncIcon.jsx
  fetchReload: () => {
    StoreService.fetchProdutosGrupos();
    StoreService.fetchProdutosSubGrupos();
    StoreService.fetchProdutosGrupoSeparacao();
  },
};

export default StoreService;
