import { createActions, createReducer } from 'reduxsauce';

// types
const { Types, Creators } = createActions({
  fetchProdutosSubGrupos: [],
  fetchProdutosSubGruposSuccess: ['data'],
  fetchProdutosSubGruposFailure: [],
  resetProdutosSubGrupos: [],
});

const INITIAL_STATE = {
  loading: false,
  data: [],
};

// reducers
const fetch = (state = INITIAL_STATE) => ({ ...state, loading: true });

const fetchSuccess = (state, { data }) => ({ data, loading: false });

const fetchFailure = (state = INITIAL_STATE) => ({ ...state, loading: false });

const reset = () => INITIAL_STATE;

// Hookup reducers to types
const Reducers = createReducer(INITIAL_STATE, {
  [Types.FETCH_PRODUTOS_SUB_GRUPOS]: fetch,
  [Types.FETCH_PRODUTOS_SUB_GRUPOS_SUCCESS]: fetchSuccess,
  [Types.FETCH_PRODUTOS_SUB_GRUPOS_FAILURE]: fetchFailure,
  [Types.RESET_PRODUTOS_SUB_GRUPOS]: reset,
});

export { Reducers, Types, Creators };
export default Reducers;
