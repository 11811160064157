import React from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import { Table as AntdTable, Divider, Row, Col, Input, Space, Button } from 'antd';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { BarChartOutlined, PieChartTwoTone, SearchOutlined } from '@ant-design/icons';
import { backValue, filterColumns, momentValue, wordCounter } from '~/utils';
import { Export, Legend, ExportEdit, ExportPDF } from './components';
import { types as typesNumber } from '~/components/forms/InputNumber/options';
import { types, display } from './options';
import { getRowColor } from './utils';
import { DatePickerCell, InputCell, InputNumberCell, ListMobile } from '~/components';
import { RememberService } from '~/services';
import { TableChartBarView, TableChartPieView } from './views';
import './Table.less';

const { Column } = AntdTable;

class Table extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      record: {},
      pageSize: RememberService.getRemember().paginacao || 10,
      selectedSorter: undefined,
      showBarChart: false,
      showPieChart: false,
      filters: {},
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const { record, dataSource } = this.props;

    if (JSON.stringify(record) !== JSON.stringify(prevState.record) || dataSource !== prevProps.dataSource) {
      this.onClick(record);
    }
  }

  onClick = newRecord => {
    const { record } = this.state;
    const { onRecordChange, rowKey, dataSource } = this.props;
    const newRecordSource = dataSource.find(item => item[rowKey] === record[rowKey] || -1);

    if (newRecord && record[rowKey] !== newRecord[rowKey] && newRecordSource) {
      this.setState({ record: newRecord });

      if (onRecordChange) {
        onRecordChange(newRecord);
      }
    }
  };

  onExpand = (expanded, record) => {
    const { rowKey, dataSource, onExpand } = this.props;

    this.onClick(
      record,
      dataSource.findIndex(item => item[rowKey] === record[rowKey])
    );

    if (onExpand) {
      onExpand(expanded, record);
    }
  };

  getColumns = expanded => {
    const { columns: propsColumns, children, isMobile, forceWidthMobile } = this.props;
    const columns = propsColumns || React.Children.map(children, ({ props }) => props);

    let newColumns = columns.filter(({ hide }) => !hide);
    if (expanded) {
      if (!isMobile) {
        newColumns = newColumns.filter(({ onDesktop }) => onDesktop === display.hide.id);
      } else {
        newColumns = newColumns.filter(({ onMobile }) => !onMobile || onMobile === display.expanded.id);
      }
    } else if (isMobile) {
      const mobileColumns = newColumns
        .filter(({ onMobile, title }) => onMobile === display.show.id || title === 'Ações' || title === 'Código' || title === '' || title === 'Excluir')
        .map(item => ({ ...item, width: forceWidthMobile ? item.width : undefined }));
      newColumns = mobileColumns.length > 0 ? mobileColumns : [{ type: types.empty.id, dataIndex: new Date().toISOString() }];
    } else {
      newColumns = newColumns.filter(({ onDesktop }) => onDesktop !== display.hide.id);
    }

    return newColumns.map(item => ({
      ...item,
      index: columns.findIndex(column => column.dataIndex === item.dataIndex),
    }));
  };

  rowClassName = (record, index) => {
    const { record: stateRecord } = this.state;
    const { rowClassName, rowKey, selecionar, rowColor = [] } = this.props;
    const rowColorClassName = rowColor.length > 0 && getRowColor(record, rowColor).className;

    return classNames(
      'table-row',
      { odd: !rowColorClassName && index % 2 !== 0 },
      { selected: stateRecord[rowKey] === record[rowKey] && (selecionar || selecionar === undefined) },
      rowColorClassName,
      { 'color-table-row': rowColorClassName },
      rowClassName
    );
  };

  expandedRowRender = (record, index, indent, expanded) => {
    const { expandedRowRender } = this.props;

    return (
      <Row gutter={4} className="table-expanded">
        {[
          ...this.getColumns(true),
          ...(expandedRowRender
            ? [
                {
                  dataIndex: 'expanded',
                  title: 'Expandido',
                  render: () => expandedRowRender(record, index, indent, expanded),
                },
              ]
            : []),
        ].map(({ dataIndex, title, type = types.string.id, render: propsRender }) => {
          const render = propsRender || types[type].render;

          return (
            <Col span={24} key={dataIndex} className="table-expanded-item">
              <span className="table-expanded-title">{title}</span>
              <span className="table-expanded-content">{render ? render(record[dataIndex], record) : record[dataIndex]}</span>
            </Col>
          );
        })}
      </Row>
    );
  };

  convertValue = (selectedKeys, type) => {
    return selectedKeys.map(item => {
      if (type === 'decimal') return typesNumber.decimal.parser(item);
      if (type === 'currency') return typesNumber.currency.parser(item);
      if (type === 'date' && item.length === 10) return backValue(item, 'date', 'YYYY-MM-DD');
      return item;
    });
  };

  formatterValue = (value, type) => {
    if (value) {
      if (type === 'date' && value.length === 10) return momentValue(value).format('DD/MM/YYYY');
    }
    return value;
  };

  getColumnSearchProps = (dataIndex, title, type) =>
    title === '' || !dataIndex || title === 'Ações' || type === 'decimal' || type === 'currency'
      ? {}
      : {
          filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
              <Input
                ref={node => {
                  this.searchInput = node;
                }}
                placeholder={`Filtrar ${title}`}
                value={this.formatterValue(selectedKeys[0], type)}
                onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                onPressEnter={() => {
                  setSelectedKeys(this.convertValue(selectedKeys, type));
                  this.handleSearch(selectedKeys, confirm, dataIndex);
                }}
                style={{ marginBottom: 8, display: 'block' }}
              />
              <Space>
                <Button
                  type="primary"
                  onClick={() => {
                    setSelectedKeys(this.convertValue(selectedKeys, type));
                    this.handleSearch(selectedKeys, confirm, dataIndex);
                  }}
                  icon={<SearchOutlined />}
                  size="small"
                  style={{ width: 90 }}
                >
                  Filtrar
                </Button>
                <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                  Limpar
                </Button>
              </Space>
            </div>
          ),
          filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
          onFilter: (value, record) =>
            record[dataIndex]
              ? record[dataIndex]
                  .toString()
                  .toLowerCase()
                  .includes((value || '').toLowerCase())
              : '',
          onFilterDropdownVisibleChange: visible => {
            if (visible) {
              setTimeout(() => this.searchInput.select(), 100);
            }
          },
        };

  handleSearch = (selectedKeys, confirm) => {
    confirm();
  };

  handleReset = clearFilters => {
    clearFilters();
  };

  getColumnFilterProps = (dataIndex, title, type) => {
    return type === 'decimal' || type === 'currency'
      ? {
          filters: [
            {
              text: 'Menor que 0',
              value: 'menor',
            },
            {
              text: 'Igual a 0',
              value: 'igual',
            },
            {
              text: 'Maior que 0',
              value: 'maior',
            },
          ],
          onFilter: (value, record) => {
            if (value === 'menor') {
              return parseFloat(record[dataIndex]) < 0;
            }
            if (value === 'igual') {
              return parseFloat(record[dataIndex]) === 0;
            }
            if (value === 'maior') {
              return parseFloat(record[dataIndex]) > 0;
            }
            return true;
          },
        }
      : {};
  };

  renderFooter = () => {
    const { dataSource, rowKey, rowColor = [], footer, extraFooter, extra, hideExport } = this.props;
    const { selectedSorter, filters } = this.state;

    const dataSourceFilter = filterColumns(dataSource, filters);

    return footer === null ? null : (
      <div className="table-footer">
        <div className="table-footer-left">
          <>
            {wordCounter(dataSourceFilter.length, 'registro')}
            {extra ? (
              <>
                <Divider type="vertical" />
                {extra}
              </>
            ) : null}
          </>
        </div>
        <div className="table-footer-right">
          {rowColor.length > 0 && (
            <>
              <Legend colors={rowColor} placement="left" />
              <Divider type="vertical" />
            </>
          )}
          <Export
            dataSource={dataSourceFilter}
            selectedSorter={selectedSorter}
            columns={this.getColumns()}
            className={dataSourceFilter.length === 0 ? 'icon-disabled' : ''}
          />
          <Divider type="vertical" />
          <ExportPDF
            dataSource={dataSourceFilter}
            selectedSorter={selectedSorter}
            columns={this.getColumns()}
            className={dataSourceFilter.length === 0 ? 'icon-disabled' : ''}
          />
          <Divider type="vertical" />
          <ExportEdit
            rowKey={rowKey}
            dataSource={dataSourceFilter}
            columns={this.getColumns()}
            className={dataSourceFilter.length === 0 || hideExport ? 'icon-disabled' : ''}
          />
          {this.renderIconPieChart(dataSourceFilter)}
          {this.renderIconBarChart(dataSourceFilter)}
          {extraFooter ? (
            <>
              <Divider type="vertical" />
              {extraFooter}
            </>
          ) : null}
        </div>
      </div>
    );
  };

  renderIconPieChart = dataSource => {
    const { showPieChart } = this.state;
    const columns = this.getColumns().filter(item => !item.type || item.type === 'string');
    const grupos = [];
    const analises = [];

    columns.forEach(itemColumn => {
      const dataGroup = Object.keys(_.groupBy(dataSource, item => item[itemColumn.dataIndex]));
      if (dataGroup.length > 1 && dataGroup.length < 10) grupos.push(itemColumn);
    });

    this.getColumns().forEach(itemColumn => {
      const dataGroup = Object.keys(_.groupBy(dataSource, item => item[itemColumn.dataIndex]));
      if (dataGroup.length > 1 && dataGroup.length < 10) analises.push(itemColumn);
    });

    return (
      <>
        {dataSource.length > 0 && grupos.length > 0 ? (
          <TableChartPieView
            action={showPieChart}
            onCancel={() => this.setState({ showPieChart: false })}
            itens={dataSource}
            grupos={grupos}
            analises={analises}
          />
        ) : null}
        <Divider type="vertical" />
        <PieChartTwoTone
          title="Gráficos Pizza"
          className={dataSource.length === 0 || grupos.length === 0 ? 'icon-disabled' : ''}
          onClick={() => this.setState({ showPieChart: true })}
        />
      </>
    );
  };

  renderIconBarChart = dataSource => {
    const { showBarChart } = this.state;
    const columns = this.getColumns();
    const columnsNumbers = columns.filter(item => item.type === 'currency' || item.type === 'decimal');
    const columnsDate = columns.filter(item => item.type === 'date');
    const newColumnsDate = [];

    columnsDate.forEach(itemColumn => {
      if (Object.keys(_.groupBy(dataSource, item => item[itemColumn.dataIndex])).length > 1) newColumnsDate.push(itemColumn);
    });

    return (
      <>
        {dataSource.length > 0 && newColumnsDate.length > 0 ? (
          <TableChartBarView
            action={showBarChart}
            onCancel={() => this.setState({ showBarChart: false })}
            itens={dataSource}
            options={columnsNumbers}
            datas={newColumnsDate}
          />
        ) : null}
        <Divider type="vertical" />
        <BarChartOutlined
          title="Gráficos Barra"
          className={dataSource.length === 0 || newColumnsDate.length === 0 ? 'icon-disabled' : ''}
          onClick={() => this.setState({ showBarChart: true })}
        />
      </>
    );
  };

  renderChild = ({ dataIndex, type = types.string.id, editable, className, title, removerFiltro, ...rest }) => {
    return (
      <Column
        key={dataIndex}
        dataIndex={dataIndex}
        title={title}
        sorter={(a, b) => types[type].sorter(a[dataIndex], b[dataIndex])}
        align={types[type].align}
        className={className || ''}
        render={editable ? (text, record) => this.renderEditable({ type, record, dataIndex, ...rest }) : types[type].render}
        {...(title && removerFiltro === false ? this.getColumnSearchProps(dataIndex, title, type) : {})}
        {...(title && removerFiltro === false ? this.getColumnFilterProps(dataIndex, title, type) : {})}
        {...rest}
      />
    );
  };

  // eslint-disable-next-line consistent-return
  renderEditable = ({ type, record, disabled, dataIndex, maxLength, functionAction, forceZero, enterLinha, index, disabledCell, required, capsLock }) => {
    if (type === 'currency' || type === 'decimal' || type === 'integer')
      return (
        <InputNumberCell
          disabled={disabled || (disabledCell ? disabledCell(record) : false)}
          type={type}
          forceZero={forceZero}
          field={dataIndex}
          name={index}
          functionAction={functionAction}
          record={record}
          defaultValue={record[dataIndex] === null || record[dataIndex] === undefined ? '' : record[dataIndex]}
          maxLength={maxLength || null}
          enterLinha={enterLinha}
          index={index}
        />
      );
    if (type === 'string')
      return (
        <InputCell
          disabled={disabled}
          type={type}
          field={dataIndex}
          name={index}
          functionAction={functionAction}
          record={record}
          defaultValue={record[dataIndex]}
          maxLength={maxLength}
          enterLinha={enterLinha}
          index={index}
          capsLock={capsLock}
        />
      );
    if (type === 'date')
      return (
        <DatePickerCell
          disabled={disabled}
          type={type}
          field={dataIndex}
          name={index}
          functionAction={functionAction}
          record={record}
          defaultValue={(record[dataIndex] || '').toString()}
          enterLinha={enterLinha}
          index={index}
          required={required}
        />
      );
  };

  onShowSizeChange = (current, pageSize) => {
    this.setState({ pageSize });
  };

  onChangeTable = (pagination, filters, sorter) => {
    this.setState({ selectedSorter: sorter, filters });
  };

  render() {
    const { pageSize } = this.state;
    const {
      className,
      rowClassName,
      pagination,
      onRow = {},
      isMobile,
      expandedRowRender,
      enterLinha = false,
      removerFiltro = false,
      onDoubleClick,
      footer,
      ...rest
    } = this.props;
    const { onClick, ...onRowProps } = onRow;
    const newPagination = pagination || { pageSize: pageSize || 10, showSizeChanger: true };
    const newPaginationState = pageSize !== 10 && !(pagination && pagination.hideOnSinglePage) ? { pageSize, showSizeChanger: true } : {};
    const heigthTable =
      pagination && pagination.hideOnSinglePage && rest.scroll && rest.scroll.y
        ? { height: `${rest.scroll.y + (rest.showHeader === false ? 0 : 38) + (footer === null ? 0 : 22)}px` }
        : {};

    return isMobile ? (
      <ListMobile {...this.props} />
    ) : (
      <div style={heigthTable}>
        <AntdTable
          bordered
          size={isMobile ? 'small' : 'middle'}
          pagination={{
            showLessItems: isMobile,
            onShowSizeChange: this.onShowSizeChange,
            ...{ ...newPagination, ...newPaginationState },
          }}
          className={classNames('table-main', className)}
          rowClassName={this.rowClassName}
          footer={() => this.renderFooter()}
          onRow={(record, index) => ({
            onClick: () => this.onClick(record, index),
            onDoubleClick: onDoubleClick ? () => onDoubleClick(record, index) : () => {},
            ...onRowProps,
          })}
          onExpand={this.onExpand}
          expandedRowRender={this.getColumns(true).length > 0 ? this.expandedRowRender : expandedRowRender}
          onChange={this.onChangeTable}
          {...rest}
        >
          {this.getColumns().map(props => this.renderChild({ ...props, enterLinha, removerFiltro: props.removerFiltro ? props.removerFiltro : removerFiltro }))}
        </AntdTable>
      </div>
    );
  }
}

Table.Column = Column;

const isMobileSelector = createSelector(
  general => general,
  ({ isMobile }) => isMobile
);

const mapStateToProps = ({ general }) => ({ isMobile: isMobileSelector(general) });

export { Column };
export default connect(mapStateToProps)(Table);
