import React from 'react';
import { CheckCircleTwoTone, ClockCircleTwoTone, StopTwoTone, ExclamationCircleTwoTone } from '@ant-design/icons';
import { Tooltip } from 'antd';
import './IconOperacaoDestinada.less';

const IconOperacaoNota = React.memo(({ record }) => {
  if (record.operacao === 'X' || record.operacao_download === 'X')
    return (
      <Tooltip title={`${'Erro ao altera dados da nota - '}${record.descricao}`}>
        <ExclamationCircleTwoTone className="icon" twoToneColor="#ff0000" />
      </Tooltip>
    );
  if (record.operacao === 'J')
    return (
      <Tooltip title={`${'Conhecida - '}${record.descricao}`}>
        <CheckCircleTwoTone className="icon" twoToneColor="#33cc33" />
      </Tooltip>
    );
  if (record.operacao === 'J1')
    return (
      <Tooltip title={`${'Confirmação - '}${record.descricao}`}>
        <CheckCircleTwoTone className="icon" twoToneColor="#33cc33" />
      </Tooltip>
    );
  if (record.operacao === 'J2')
    return (
      <Tooltip title={`${'Desconhecimento - '}${record.descricao}`}>
        <CheckCircleTwoTone className="icon" twoToneColor="#ff0000" />
      </Tooltip>
    );
  if (record.operacao === 'J3')
    return (
      <Tooltip title={`${'Operação Não Realizada - '}${record.descricao}`}>
        <CheckCircleTwoTone className="icon" twoToneColor="#ffff00" />
      </Tooltip>
    );
  if (record.operacao === 'J4')
    return (
      <Tooltip title={`${'Ciência - Não Confirmada - '}${record.descricao}`}>
        <CheckCircleTwoTone className="icon" twoToneColor="#3399ff" />
      </Tooltip>
    );
  if (record.operacao === 'E')
    return (
      <Tooltip title={`${'Cancelada - '}${record.descricao}`}>
        <StopTwoTone className="icon" twoToneColor="#cc0000" />
      </Tooltip>
    );
  if (record.operacao === 'M')
    return (
      <Tooltip title={`${'Nota sem reconhecimento - '}${record.descricao}`}>
        <CheckCircleTwoTone className="icon" twoToneColor="#000000" />
      </Tooltip>
    );
  return (
    <Tooltip title={`${'Nota sem reconhecimento - '}${record.descricao}`}>
      <ClockCircleTwoTone className="icon" twoToneColor="#0000ff" />
    </Tooltip>
  );
});

export default IconOperacaoNota;
