// Rio Grande do Norte
const rn = [
  { data: '2400109', label: 'Acari' },
  { data: '2400208', label: 'Açu' },
  { data: '2400307', label: 'Afonso Bezerra' },
  { data: '2400406', label: 'Água Nova' },
  { data: '2400505', label: 'Alexandria' },
  { data: '2400604', label: 'Almino Afonso' },
  { data: '2400703', label: 'Alto do Rodrigues' },
  { data: '2400802', label: 'Angicos' },
  { data: '2400901', label: 'Antônio Martins' },
  { data: '2401008', label: 'Apodi' },
  { data: '2401107', label: 'Areia Branca' },
  { data: '2401206', label: 'Arês' },
  { data: '2401305', label: 'Augusto Severo' },
  { data: '2401404', label: 'Baía Formosa' },
  { data: '2401453', label: 'Baraúna' },
  { data: '2401503', label: 'Barcelona' },
  { data: '2401602', label: 'Bento Fernandes' },
  { data: '2401651', label: 'Bodó' },
  { data: '2401701', label: 'Bom Jesus' },
  { data: '2401800', label: 'Brejinho' },
  { data: '2401859', label: 'Caiçara do Norte' },
  { data: '2401909', label: 'Caiçara do Rio do Vento' },
  { data: '2402006', label: 'Caicó' },
  { data: '2402105', label: 'Campo Redondo' },
  { data: '2402204', label: 'Canguaretama' },
  { data: '2402303', label: 'Caraúbas' },
  { data: '2402402', label: 'Carnaúba dos Dantas' },
  { data: '2402501', label: 'Carnaubais' },
  { data: '2402600', label: 'Ceará-Mirim' },
  { data: '2402709', label: 'Cerro Corá' },
  { data: '2402808', label: 'Coronel Ezequiel' },
  { data: '2402907', label: 'Coronel João Pessoa' },
  { data: '2403004', label: 'Cruzeta' },
  { data: '2403103', label: 'Currais Novos' },
  { data: '2403202', label: 'Doutor Severiano' },
  { data: '2403301', label: 'Encanto' },
  { data: '2403400', label: 'Equador' },
  { data: '2403509', label: 'Espírito Santo' },
  { data: '2403608', label: 'Extremoz' },
  { data: '2403707', label: 'Felipe Guerra' },
  { data: '2403756', label: 'Fernando Pedroza' },
  { data: '2403806', label: 'Florânia' },
  { data: '2403905', label: 'Francisco Dantas' },
  { data: '2404002', label: 'Frutuoso Gomes' },
  { data: '2404101', label: 'Galinhos' },
  { data: '2404200', label: 'Goianinha' },
  { data: '2404309', label: 'Governador Dix-Sept Rosado' },
  { data: '2404408', label: 'Grossos' },
  { data: '2404507', label: 'Guamaré' },
  { data: '2404606', label: 'Ielmo Marinho' },
  { data: '2404705', label: 'Ipanguaçu' },
  { data: '2404804', label: 'Ipueira' },
  { data: '2404853', label: 'Itajá' },
  { data: '2404903', label: 'Itaú' },
  { data: '2405009', label: 'Jaçanã' },
  { data: '2405108', label: 'Jandaíra' },
  { data: '2405207', label: 'Janduís' },
  { data: '2405306', label: 'Januário Cicco' },
  { data: '2405405', label: 'Japi' },
  { data: '2405504', label: 'Jardim de Angicos' },
  { data: '2405603', label: 'Jardim de Piranhas' },
  { data: '2405702', label: 'Jardim do Seridó' },
  { data: '2405801', label: 'João Câmara' },
  { data: '2405900', label: 'João Dias' },
  { data: '2406007', label: 'José da Penha' },
  { data: '2406106', label: 'Jucurutu' },
  { data: '2406155', label: 'Jundiá' },
  { data: '2406205', label: "Lagoa d'Anta" },
  { data: '2406304', label: 'Lagoa de Pedras' },
  { data: '2406403', label: 'Lagoa de Velhos' },
  { data: '2406502', label: 'Lagoa Nova' },
  { data: '2406601', label: 'Lagoa Salgada' },
  { data: '2406700', label: 'Lajes' },
  { data: '2406809', label: 'Lajes Pintadas' },
  { data: '2406908', label: 'Lucrécia' },
  { data: '2407005', label: 'Luís Gomes' },
  { data: '2407104', label: 'Macaíba' },
  { data: '2407203', label: 'Macau' },
  { data: '2407252', label: 'Major Sales' },
  { data: '2407302', label: 'Marcelino Vieira' },
  { data: '2407401', label: 'Martins' },
  { data: '2407500', label: 'Maxaranguape' },
  { data: '2407609', label: 'Messias Targino' },
  { data: '2407708', label: 'Montanhas' },
  { data: '2407807', label: 'Monte Alegre' },
  { data: '2407906', label: 'Monte das Gameleiras' },
  { data: '2408003', label: 'Mossoró' },
  { data: '2408102', label: 'Natal' },
  { data: '2408201', label: 'Nísia Floresta' },
  { data: '2408300', label: 'Nova Cruz' },
  { data: '2408409', label: "Olho-d'Água do Borges" },
  { data: '2408508', label: 'Ouro Branco' },
  { data: '2408607', label: 'Paraná' },
  { data: '2408706', label: 'Paraú' },
  { data: '2408805', label: 'Parazinho' },
  { data: '2408904', label: 'Parelhas' },
  { data: '2403251', label: 'Parnamirim' },
  { data: '2409100', label: 'Passa e Fica' },
  { data: '2409209', label: 'Passagem' },
  { data: '2409308', label: 'Patu' },
  { data: '2409407', label: 'Pau dos Ferros' },
  { data: '2409506', label: 'Pedra Grande' },
  { data: '2409605', label: 'Pedra Preta' },
  { data: '2409704', label: 'Pedro Avelino' },
  { data: '2409803', label: 'Pedro Velho' },
  { data: '2409902', label: 'Pendências' },
  { data: '2410009', label: 'Pilões' },
  { data: '2410108', label: 'Poço Branco' },
  { data: '2410207', label: 'Portalegre' },
  { data: '2410256', label: 'Porto do Mangue' },
  { data: '2410306', label: 'Serra Caiada***' },
  { data: '2410405', label: 'Pureza' },
  { data: '2410504', label: 'Rafael Fernandes' },
  { data: '2410603', label: 'Rafael Godeiro' },
  { data: '2410702', label: 'Riacho da Cruz' },
  { data: '2410801', label: 'Riacho de Santana' },
  { data: '2410900', label: 'Riachuelo' },
  { data: '2408953', label: 'Rio do Fogo' },
  { data: '2411007', label: 'Rodolfo Fernandes' },
  { data: '2411106', label: 'Ruy Barbosa' },
  { data: '2411205', label: 'Santa Cruz' },
  { data: '2409332', label: 'Santa Maria' },
  { data: '2411403', label: 'Santana do Matos' },
  { data: '2411429', label: 'Santana do Seridó' },
  { data: '2411502', label: 'Santo Antônio' },
  { data: '2411601', label: 'São Bento do Norte' },
  { data: '2411700', label: 'São Bento do Trairí' },
  { data: '2411809', label: 'São Fernando' },
  { data: '2411908', label: 'São Francisco do Oeste' },
  { data: '2412005', label: 'São Gonçalo do Amarante' },
  { data: '2412104', label: 'São João do Sabugi' },
  { data: '2412203', label: 'São José de Mipibu' },
  { data: '2412302', label: 'São José do Campestre' },
  { data: '2412401', label: 'São José do Seridó' },
  { data: '2412500', label: 'São Miguel' },
  { data: '2412559', label: 'São Miguel do Gostoso' },
  { data: '2412609', label: 'São Paulo do Potengi' },
  { data: '2412708', label: 'São Pedro' },
  { data: '2412807', label: 'São Rafael' },
  { data: '2412906', label: 'São Tomé' },
  { data: '2413003', label: 'São Vicente' },
  { data: '2413102', label: 'Senador Elói de Souza' },
  { data: '2413201', label: 'Senador Georgino Avelino' },
  { data: '2413300', label: 'Serra de São Bento' },
  { data: '2413359', label: 'Serra do Mel' },
  { data: '2413409', label: 'Serra Negra do Norte' },
  { data: '2413508', label: 'Serrinha' },
  { data: '2413557', label: 'Serrinha dos Pintos' },
  { data: '2413607', label: 'Severiano Melo' },
  { data: '2413706', label: 'Sítio Novo' },
  { data: '2413805', label: 'Taboleiro Grande' },
  { data: '2413904', label: 'Taipu' },
  { data: '2414001', label: 'Tangará' },
  { data: '2414100', label: 'Tenente Ananias' },
  { data: '2414159', label: 'Tenente Laurentino Cruz' },
  { data: '2411056', label: 'Tibau' },
  { data: '2414209', label: 'Tibau do Sul' },
  { data: '2414308', label: 'Timbaúba dos Batistas' },
  { data: '2414407', label: 'Touros' },
  { data: '2414456', label: 'Triunfo Potiguar' },
  { data: '2414506', label: 'Umarizal' },
  { data: '2414605', label: 'Upanema' },
  { data: '2414704', label: 'Várzea' },
  { data: '2414753', label: 'Venha-Ver' },
  { data: '2414803', label: 'Vera Cruz' },
  { data: '2414902', label: 'Viçosa' },
  { data: '2415008', label: 'Vila Flor' },
];

export default rn;
