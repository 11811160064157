import React from 'react';
import { Row, Col, Button } from 'antd';
import { connect } from 'react-redux';
import { ContentWrapper, Form, Page } from '~/components';
import { affirmatives } from '~/options';
import { RememberService } from '~/services';
import { Input, InputNumber, Select } from '~/components/forms';

class ParamPageFormView extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidUpdate(prevProps) {
    const { action, menu, general } = this.props;

    if (action !== prevProps.action && action) {
      if (this.saveApi) {
        const remember = RememberService.getRemember() || {};
        this.saveApi.setValues(remember[general.isMobile ? menu.chave_mobile : menu.chave]);
      }
    }
  }

  handleSave = values => {
    const { onOk, onCancel } = this.props;

    if (onOk) {
      onOk(values);
      onCancel();
    }
  };

  renderItemForm = item => {
    let Component = Select;
    if (item.tipo === 'Input') {
      Component = Input;
    } else if (item.tipo === 'InputNumber') {
      Component = InputNumber;
    }
    const props = {};

    props.field = item.uuid;
    props.label = item.label;
    props.initialValue = item.initialValue;

    if (item.tipo !== 'Input' && item.tipo !== 'InputNumber') {
      if (item.tipo === 'affirmatives') {
        props.dataSource = affirmatives;
      } else {
        props.dataSource = item.dataSource;
      }
    }

    return (
      <Col key={item.uuid} md={item.tamanho || 24} span={item.tamanho || 24}>
        <Component key={item.field} {...props} />
      </Col>
    );
  };

  render() {
    const { onCancel, action, menu, paramPage } = this.props;

    return (
      <>
        <Page.Modal
          visible={action}
          title={`Parâmetros - ${menu.nome}`}
          onCancel={onCancel}
          forceRender
          centered
          width={450}
          bodyStyle={{ overflow: 'auto' }}
          destroyOnClose
          footer={[
            <div key="divFooter" style={{ textAlign: 'right' }}>
              <Button key="back" onClick={onCancel}>
                Fechar
              </Button>
              <Button key="submit" type="primary" onClick={() => this.saveApi.submitForm()}>
                Salvar
              </Button>
            </div>,
          ]}
        >
          <Form getApi={api => (this.saveApi = api)} onSubmit={this.handleSave}>
            <ContentWrapper type="search">
              <Row gutter={4}>{paramPage.map(item => this.renderItemForm(item))}</Row>
            </ContentWrapper>
          </Form>
        </Page.Modal>
      </>
    );
  }
}

const mapStateToProps = ({ general }) => ({
  general,
});
export default connect(mapStateToProps)(ParamPageFormView);
