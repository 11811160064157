import React from 'react';
import { Menu, Dropdown } from 'antd';
import { createSelector } from 'reselect';
import { connect } from 'react-redux';
import { FileTextOutlined, FilePdfOutlined, FileExcelOutlined, DownOutlined } from '@ant-design/icons';
import Button from '..';

const menuDefault = [
  { key: 'pdf', label: 'PDF', color: '#ff4d4d', icon: <FilePdfOutlined /> },
  { key: 'csv', label: 'CSV', color: '#006600', icon: <FileTextOutlined /> },
  { key: 'excel', label: 'EXCEL', color: '#0033cc', icon: <FileExcelOutlined /> },
];

class Print extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      selectedItem: props.menu ? props.menu[0] : menuDefault[0],
    };
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { selectedItem } = this.state;
    if (nextProps.options) {
      const menuMap = nextProps.menu || menuDefault;
      this.setState({ selectedItem: menuMap.find(item => item.key === selectedItem.key) });
    }
  }

  handleMenuClick = e => {
    const { menu } = this.props;
    const menuMap = menu || menuDefault;
    this.setState({ selectedItem: menuMap.find(item => item.key === e.item.props.eventKey) });
  };

  render() {
    const { noLabel, label, options, loading, onPrint, menu, disabled, block, isMobile, dispatch, ...rest } = this.props;
    const { selectedItem } = this.state;

    const menuMap = menu || menuDefault;

    const styleDisabled = {
      color: '#bbbbbb',
      borderColor: '#d9d9d9',
      cursor: 'not-allowed !important',
    };

    const styleEnabled = {
      color: selectedItem.color || menuDefault[0].color,
      borderColor: selectedItem.color || menuDefault[0].color,
    };

    return options === undefined ? (
      <Button
        style={disabled ? styleDisabled : styleEnabled}
        icon={menuDefault[0].icon}
        loading={loading}
        onClick={() => {
          if (onPrint) onPrint();
        }}
        disabled={disabled}
        {...rest}
      >
        {noLabel || isMobile ? true : label || 'Imprimir'}
      </Button>
    ) : (
      <Dropdown.Button
        placement="topRight"
        style={{ width: block ? '100%' : null, marginTop: block ? '22px' : null }}
        icon={<DownOutlined />}
        overlay={
          <Menu onClick={this.handleMenuClick}>
            {menuMap.map(item => (
              <Menu.Item color={item.color} key={item.key} value={item.itemClick} disabled={item.disabled}>
                {item.icon}
                {item.label}
              </Menu.Item>
            ))}
          </Menu>
        }
        buttonsRender={([leftButton, rightButton]) => [
          React.cloneElement(leftButton, {
            icon: selectedItem.icon,
            loading,
            disabled: selectedItem.disabled || disabled,
            style: { ...(selectedItem.disabled || disabled ? styleDisabled : styleEnabled), width: block ? '100%' : null },
          }),
          React.cloneElement(rightButton, {
            disabled: disabled !== undefined ? disabled : !menuMap.find(item => !item.disabled),
            style: { ...((disabled !== undefined ? disabled : !menuMap.find(item => !item.disabled)) ? styleDisabled : styleEnabled) },
          }),
        ]}
        onClick={() => {
          if (options && menu) {
            if (selectedItem.itemClick) selectedItem.itemClick();
          } else if (onPrint) onPrint(selectedItem.key);
        }}
        {...rest}
      >
        {noLabel || isMobile ? true : selectedItem.label || menuDefault[0].label}
      </Dropdown.Button>
    );
  }
}

const isMobileSelector = createSelector(
  general => general,
  ({ isMobile }) => isMobile
);

const mapStateToProps = ({ general }) => ({ isMobile: isMobileSelector(general) });

export default connect(mapStateToProps)(Print);
