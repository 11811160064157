import React from 'react';
import { Row, Col } from 'antd';
import { Page, Form, Input, ContentWrapper, Button } from '~/components';

class UploadAnexoDescricaoView extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidUpdate(prevProps) {
    const { action } = this.props;
    const actionChange = action && action.idt !== (prevProps.action ? prevProps.action.idt : '');

    if (actionChange) {
      if (this.saveApi) {
        this.saveApi.reset();
        this.saveApi.setValue('descricao', action.descricao);
        this.editInput.focus();
      }
    }
  }

  handleSave = values => {
    const { action, onOk, onCancel } = this.props;

    onOk({ ...action, descricao: values.descricao || '' });
    onCancel();
  };

  render = () => {
    const { onCancel, action } = this.props;

    return (
      <>
        <Page.Modal
          visible={action && action.idt}
          title="Observações anexo"
          width={400}
          onCancel={onCancel}
          forceRender
          centered
          closable
          destroyOnClose
          footer={
            <div style={{ textAlign: 'right' }}>
              <Button key="back" onClick={onCancel}>
                Fechar
              </Button>
              <Button key="submit" type="primary" onClick={() => this.saveApi.submitForm()}>
                Salvar
              </Button>
            </div>
          }
        >
          <Form getApi={api => (this.saveApi = api)} onSubmit={this.handleSave}>
            <ContentWrapper type="header">
              <Row gutter={4}>
                <Col md={24}>
                  <Input.TextArea field="descricao" label="Descrição" type="string" forwardedRef={ref => (this.editInput = ref)} allowClear />
                </Col>
              </Row>
            </ContentWrapper>
          </Form>
        </Page.Modal>
      </>
    );
  };
}

export default UploadAnexoDescricaoView;
