import React from 'react';
import _ from 'lodash';
import { Button, Col, Modal, Row } from 'antd';
import { ContentWrapper, Form, Pie, Select } from '~/components';
import './TableChartPieView.less';

class SuporteChartStatusView extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidUpdate(prevProps) {
    const { action, grupos } = this.props;

    if (action !== prevProps.action && action) {
      this.forceUpdate(() => {
        this.searchApi.setValues({
          grupo: grupos[0].dataIndex,
        });
        this.forceUpdate();
      });
    }
  }

  render() {
    const { action, onCancel, itens, grupos, analises } = this.props;

    let dataSource = [];
    const valuesForm = this.searchApi ? this.searchApi.getValues() : {};
    if (action && this.searchApi && valuesForm.grupo) {
      dataSource = _.groupBy(itens, item => item[valuesForm.grupo]);
      dataSource = _.map(dataSource, item => ({
        x: (item[0][valuesForm.grupo] || '').substr(0, 40),
        y: valuesForm.analise
          ? itens
              .filter(itemFilter => itemFilter[valuesForm.grupo] === item[0][valuesForm.grupo])
              .reduce((previous, current) => parseFloat(current[valuesForm.analise || 0]) + previous, 0) || 0
          : item.length,
      }));
      dataSource = _.orderBy(dataSource, item => item.y);
    }

    return (
      <Modal
        visible={action}
        title="Gráfico Pizza"
        width={900}
        onCancel={onCancel}
        centered
        destroyOnClose
        maskClosable={false}
        footer={
          <div style={{ textAlign: 'center' }}>
            <Button key="back" onClick={onCancel}>
              Fechar
            </Button>
          </div>
        }
      >
        <Form getApi={api => (this.searchApi = api)}>
          <ContentWrapper type="search">
            <Row gutter={4}>
              <Col md={6}>
                <Select
                  field="grupo"
                  label="Agrupamento"
                  dataSource={grupos.map(item => ({ data: item.dataIndex, label: item.title }))}
                  required
                  onChange={() => this.forceUpdate()}
                />
              </Col>
              <Col md={6}>
                <Select
                  field="analise"
                  label="Analises"
                  dataSource={analises.map(item => ({ data: item.dataIndex, label: item.title }))}
                  required
                  onChange={() => this.forceUpdate()}
                />
              </Col>
            </Row>
          </ContentWrapper>
        </Form>
        <Pie
          data={dataSource}
          height={250}
          lineWidth={5}
          hasLegend
          total={() => dataSource.reduce((previous, current) => current.y + previous, 0)}
          subTitle="Total"
          className="pie-table"
        />
      </Modal>
    );
  }
}

export default SuporteChartStatusView;
