// Bahia
const ba = [
  { data: '2900108', label: 'Abaíra' },
  { data: '2900207', label: 'Abaré' },
  { data: '2900306', label: 'Acajutiba' },
  { data: '2900355', label: 'Adustina' },
  { data: '2900405', label: 'Água Fria' },
  { data: '2900603', label: 'Aiquara' },
  { data: '2900702', label: 'Alagoinhas' },
  { data: '2900801', label: 'Alcobaça' },
  { data: '2900900', label: 'Almadina' },
  { data: '2901007', label: 'Amargosa' },
  { data: '2901106', label: 'Amélia Rodrigues' },
  { data: '2901155', label: 'América Dourada' },
  { data: '2901205', label: 'Anagé' },
  { data: '2901304', label: 'Andaraí' },
  { data: '2901353', label: 'Andorinha' },
  { data: '2901403', label: 'Angical' },
  { data: '2901502', label: 'Anguera' },
  { data: '2901601', label: 'Antas' },
  { data: '2901700', label: 'Antônio Cardoso' },
  { data: '2901809', label: 'Antônio Gonçalves' },
  { data: '2901908', label: 'Aporá' },
  { data: '2901957', label: 'Apuarema' },
  { data: '2902054', label: 'Araças' },
  { data: '2902005', label: 'Aracatu' },
  { data: '2902104', label: 'Araci' },
  { data: '2902203', label: 'Aramari' },
  { data: '2902252', label: 'Arataca' },
  { data: '2902302', label: 'Aratuípe' },
  { data: '2902401', label: 'Aurelino Leal' },
  { data: '2902500', label: 'Baianópolis' },
  { data: '2902609', label: 'Baixa Grande' },
  { data: '2902658', label: 'Banzaê' },
  { data: '2902708', label: 'Barra' },
  { data: '2902807', label: 'Barra da Estiva' },
  { data: '2902906', label: 'Barra do Choça' },
  { data: '2903003', label: 'Barra do Mendes' },
  { data: '2903102', label: 'Barra do Rocha' },
  { data: '2903201', label: 'Barreiras' },
  { data: '2903235', label: 'Barro Alto' },
  { data: '2903300', label: 'Barro Preto' },
  { data: '2903276', label: 'Barrocas' },
  { data: '2903409', label: 'Belmonte' },
  { data: '2903508', label: 'Belo Campo' },
  { data: '2903607', label: 'Biritinga' },
  { data: '2903706', label: 'Boa Nova' },
  { data: '2903805', label: 'Boa Vista do Tupim' },
  { data: '2903904', label: 'Bom Jesus da Lapa' },
  { data: '2903953', label: 'Bom Jesus da Serra' },
  { data: '2904001', label: 'Boninal' },
  { data: '2904050', label: 'Bonito' },
  { data: '2904100', label: 'Boquira' },
  { data: '2904209', label: 'Botuporã' },
  { data: '2904308', label: 'Brejões' },
  { data: '2904407', label: 'Brejolândia' },
  { data: '2904506', label: 'Brotas de Macaúbas' },
  { data: '2904605', label: 'Brumado' },
  { data: '2904704', label: 'Buerarema' },
  { data: '2904753', label: 'Buritirama' },
  { data: '2904803', label: 'Caatiba' },
  { data: '2904852', label: 'Cabaceiras do Paraguaçu' },
  { data: '2904902', label: 'Cachoeira' },
  { data: '2905008', label: 'Caculé' },
  { data: '2905107', label: 'Caém' },
  { data: '2905156', label: 'Caetanos' },
  { data: '2905206', label: 'Caetité' },
  { data: '2905305', label: 'Cafarnaum' },
  { data: '2905404', label: 'Cairu' },
  { data: '2905503', label: 'Caldeirão Grande' },
  { data: '2905602', label: 'Camacan' },
  { data: '2905701', label: 'Camaçari' },
  { data: '2905800', label: 'Camamu' },
  { data: '2905909', label: 'Campo Alegre de Lourdes' },
  { data: '2906006', label: 'Campo Formoso' },
  { data: '2906105', label: 'Canápolis' },
  { data: '2906204', label: 'Canarana' },
  { data: '2906303', label: 'Canavieiras' },
  { data: '2906402', label: 'Candeal' },
  { data: '2906501', label: 'Candeias' },
  { data: '2906600', label: 'Candiba' },
  { data: '2906709', label: 'Cândido Sales' },
  { data: '2906808', label: 'Cansanção' },
  { data: '2906824', label: 'Canudos' },
  { data: '2906857', label: 'Capela do Alto Alegre' },
  { data: '2906873', label: 'Capim Grosso' },
  { data: '2906899', label: 'Caraíbas' },
  { data: '2906907', label: 'Caravelas' },
  { data: '2907004', label: 'Cardeal da Silva' },
  { data: '2907103', label: 'Carinhanha' },
  { data: '2907202', label: 'Casa Nova' },
  { data: '2907301', label: 'Castro Alves' },
  { data: '2907400', label: 'Catolândia' },
  { data: '2907509', label: 'Catu' },
  { data: '2907558', label: 'Caturama' },
  { data: '2907608', label: 'Central' },
  { data: '2907707', label: 'Chorrochó' },
  { data: '2907806', label: 'Cícero Dantas' },
  { data: '2907905', label: 'Cipó' },
  { data: '2908002', label: 'Coaraci' },
  { data: '2908101', label: 'Cocos' },
  { data: '2908200', label: 'Conceição da Feira' },
  { data: '2908309', label: 'Conceição do Almeida' },
  { data: '2908408', label: 'Conceição do Coité' },
  { data: '2908507', label: 'Conceição do Jacuípe' },
  { data: '2908606', label: 'Conde' },
  { data: '2908705', label: 'Condeúba' },
  { data: '2908804', label: 'Contendas do Sincorá' },
  { data: '2908903', label: 'Coração de Maria' },
  { data: '2909000', label: 'Cordeiros' },
  { data: '2909109', label: 'Coribe' },
  { data: '2909208', label: 'Coronel João Sá' },
  { data: '2909307', label: 'Correntina' },
  { data: '2909406', label: 'Cotegipe' },
  { data: '2909505', label: 'Cravolândia' },
  { data: '2909604', label: 'Crisópolis' },
  { data: '2909703', label: 'Cristópolis' },
  { data: '2909802', label: 'Cruz das Almas' },
  { data: '2909901', label: 'Curaçá' },
  { data: '2910008', label: 'Dário Meira' },
  { data: '2910057', label: "Dias d'Ávila" },
  { data: '2910107', label: 'Dom Basílio' },
  { data: '2910206', label: 'Dom Macedo Costa' },
  { data: '2910305', label: 'Elísio Medrado' },
  { data: '2910404', label: 'Encruzilhada' },
  { data: '2910503', label: 'Entre Rios' },
  { data: '2900504', label: 'Érico Cardoso' },
  { data: '2910602', label: 'Esplanada' },
  { data: '2910701', label: 'Euclides da Cunha' },
  { data: '2910727', label: 'Eunápolis' },
  { data: '2910750', label: 'Fátima' },
  { data: '2910776', label: 'Feira da Mata' },
  { data: '2910800', label: 'Feira de Santana' },
  { data: '2910859', label: 'Filadélfia' },
  { data: '2910909', label: 'Firmino Alves' },
  { data: '2911006', label: 'Floresta Azul' },
  { data: '2911105', label: 'Formosa do Rio Preto' },
  { data: '2911204', label: 'Gandu' },
  { data: '2911253', label: 'Gavião' },
  { data: '2911303', label: 'Gentio do Ouro' },
  { data: '2911402', label: 'Glória' },
  { data: '2911501', label: 'Gongogi' },
  { data: '2911600', label: 'Governador Mangabeira' },
  { data: '2911659', label: 'Guajeru' },
  { data: '2911709', label: 'Guanambi' },
  { data: '2911808', label: 'Guaratinga' },
  { data: '2911857', label: 'Heliópolis' },
  { data: '2911907', label: 'Iaçu' },
  { data: '2912004', label: 'Ibiassucê' },
  { data: '2912103', label: 'Ibicaraí' },
  { data: '2912202', label: 'Ibicoara' },
  { data: '2912301', label: 'Ibicuí' },
  { data: '2912400', label: 'Ibipeba' },
  { data: '2912509', label: 'Ibipitanga' },
  { data: '2912608', label: 'Ibiquera' },
  { data: '2912707', label: 'Ibirapitanga' },
  { data: '2912806', label: 'Ibirapuã' },
  { data: '2912905', label: 'Ibirataia' },
  { data: '2913002', label: 'Ibitiara' },
  { data: '2913101', label: 'Ibititá' },
  { data: '2913200', label: 'Ibotirama' },
  { data: '2913309', label: 'Ichu' },
  { data: '2913408', label: 'Igaporã' },
  { data: '2913457', label: 'Igrapiúna' },
  { data: '2913507', label: 'Iguaí' },
  { data: '2913606', label: 'Ilhéus' },
  { data: '2913705', label: 'Inhambupe' },
  { data: '2913804', label: 'Ipecaetá' },
  { data: '2913903', label: 'Ipiaú' },
  { data: '2914000', label: 'Ipirá' },
  { data: '2914109', label: 'Ipupiara' },
  { data: '2914208', label: 'Irajuba' },
  { data: '2914307', label: 'Iramaia' },
  { data: '2914406', label: 'Iraquara' },
  { data: '2914505', label: 'Irará' },
  { data: '2914604', label: 'Irecê' },
  { data: '2914653', label: 'Itabela' },
  { data: '2914703', label: 'Itaberaba' },
  { data: '2914802', label: 'Itabuna' },
  { data: '2914901', label: 'Itacaré' },
  { data: '2915007', label: 'Itaeté' },
  { data: '2915106', label: 'Itagi' },
  { data: '2915205', label: 'Itagibá' },
  { data: '2915304', label: 'Itagimirim' },
  { data: '2915353', label: 'Itaguaçu da Bahia' },
  { data: '2915403', label: 'Itaju do Colônia' },
  { data: '2915502', label: 'Itajuípe' },
  { data: '2915601', label: 'Itamaraju' },
  { data: '2915700', label: 'Itamari' },
  { data: '2915809', label: 'Itambé' },
  { data: '2915908', label: 'Itanagra' },
  { data: '2916005', label: 'Itanhém' },
  { data: '2916104', label: 'Itaparica' },
  { data: '2916203', label: 'Itapé' },
  { data: '2916302', label: 'Itapebi' },
  { data: '2916401', label: 'Itapetinga' },
  { data: '2916500', label: 'Itapicuru' },
  { data: '2916609', label: 'Itapitanga' },
  { data: '2916708', label: 'Itaquara' },
  { data: '2916807', label: 'Itarantim' },
  { data: '2916856', label: 'Itatim' },
  { data: '2916906', label: 'Itiruçu' },
  { data: '2917003', label: 'Itiúba' },
  { data: '2917102', label: 'Itororó' },
  { data: '2917201', label: 'Ituaçu' },
  { data: '2917300', label: 'Ituberá' },
  { data: '2917334', label: 'Iuiú' },
  { data: '2917359', label: 'Jaborandi' },
  { data: '2917409', label: 'Jacaraci' },
  { data: '2917508', label: 'Jacobina' },
  { data: '2917607', label: 'Jaguaquara' },
  { data: '2917706', label: 'Jaguarari' },
  { data: '2917805', label: 'Jaguaripe' },
  { data: '2917904', label: 'Jandaíra' },
  { data: '2918001', label: 'Jequié' },
  { data: '2918100', label: 'Jeremoabo' },
  { data: '2918209', label: 'Jiquiriçá' },
  { data: '2918308', label: 'Jitaúna' },
  { data: '2918357', label: 'João Dourado' },
  { data: '2918407', label: 'Juazeiro' },
  { data: '2918456', label: 'Jucuruçu' },
  { data: '2918506', label: 'Jussara' },
  { data: '2918555', label: 'Jussari' },
  { data: '2918605', label: 'Jussiape' },
  { data: '2918704', label: 'Lafaiete Coutinho' },
  { data: '2918753', label: 'Lagoa Real' },
  { data: '2918803', label: 'Laje' },
  { data: '2918902', label: 'Lajedão' },
  { data: '2919009', label: 'Lajedinho' },
  { data: '2919058', label: 'Lajedo do Tabocal' },
  { data: '2919108', label: 'Lamarão' },
  { data: '2919157', label: 'Lapão' },
  { data: '2919207', label: 'Lauro de Freitas' },
  { data: '2919306', label: 'Lençóis' },
  { data: '2919405', label: 'Licínio de Almeida' },
  { data: '2919504', label: 'Livramento de Nossa Senhora' },
  { data: '2919553', label: 'Luís Eduardo Magalhães' },
  { data: '2919603', label: 'Macajuba' },
  { data: '2919702', label: 'Macarani' },
  { data: '2919801', label: 'Macaúbas' },
  { data: '2919900', label: 'Macururé' },
  { data: '2919926', label: 'Madre de Deus' },
  { data: '2919959', label: 'Maetinga' },
  { data: '2920007', label: 'Maiquinique' },
  { data: '2920106', label: 'Mairi' },
  { data: '2920205', label: 'Malhada' },
  { data: '2920304', label: 'Malhada de Pedras' },
  { data: '2920403', label: 'Manoel Vitorino' },
  { data: '2920452', label: 'Mansidão' },
  { data: '2920502', label: 'Maracás' },
  { data: '2920601', label: 'Maragogipe' },
  { data: '2920700', label: 'Maraú' },
  { data: '2920809', label: 'Marcionílio Souza' },
  { data: '2920908', label: 'Mascote' },
  { data: '2921005', label: 'Mata de São João' },
  { data: '2921054', label: 'Matina' },
  { data: '2921104', label: 'Medeiros Neto' },
  { data: '2921203', label: 'Miguel Calmon' },
  { data: '2921302', label: 'Milagres' },
  { data: '2921401', label: 'Mirangaba' },
  { data: '2921450', label: 'Mirante' },
  { data: '2921500', label: 'Monte Santo' },
  { data: '2921609', label: 'Morpará' },
  { data: '2921708', label: 'Morro do Chapéu' },
  { data: '2921807', label: 'Mortugaba' },
  { data: '2921906', label: 'Mucugê' },
  { data: '2922003', label: 'Mucuri' },
  { data: '2922052', label: 'Mulungu do Morro' },
  { data: '2922102', label: 'Mundo Novo' },
  { data: '2922201', label: 'Muniz Ferreira' },
  { data: '2922250', label: 'Muquém de São Francisco' },
  { data: '2922300', label: 'Muritiba' },
  { data: '2922409', label: 'Mutuípe' },
  { data: '2922508', label: 'Nazaré' },
  { data: '2922607', label: 'Nilo Peçanha' },
  { data: '2922656', label: 'Nordestina' },
  { data: '2922706', label: 'Nova Canaã' },
  { data: '2922730', label: 'Nova Fátima' },
  { data: '2922755', label: 'Nova Ibiá' },
  { data: '2922805', label: 'Nova Itarana' },
  { data: '2922854', label: 'Nova Redenção' },
  { data: '2922904', label: 'Nova Soure' },
  { data: '2923001', label: 'Nova Viçosa' },
  { data: '2923035', label: 'Novo Horizonte' },
  { data: '2923050', label: 'Novo Triunfo' },
  { data: '2923100', label: 'Olindina' },
  { data: '2923209', label: 'Oliveira dos Brejinhos' },
  { data: '2923308', label: 'Ouriçangas' },
  { data: '2923357', label: 'Ourolândia' },
  { data: '2923407', label: 'Palmas de Monte Alto' },
  { data: '2923506', label: 'Palmeiras' },
  { data: '2923605', label: 'Paramirim' },
  { data: '2923704', label: 'Paratinga' },
  { data: '2923803', label: 'Paripiranga' },
  { data: '2923902', label: 'Pau Brasil' },
  { data: '2924009', label: 'Paulo Afonso' },
  { data: '2924058', label: 'Pé de Serra' },
  { data: '2924108', label: 'Pedrão' },
  { data: '2924207', label: 'Pedro Alexandre' },
  { data: '2924306', label: 'Piatã' },
  { data: '2924405', label: 'Pilão Arcado' },
  { data: '2924504', label: 'Pindaí' },
  { data: '2924603', label: 'Pindobaçu' },
  { data: '2924652', label: 'Pintadas' },
  { data: '2924678', label: 'Piraí do Norte' },
  { data: '2924702', label: 'Piripá' },
  { data: '2924801', label: 'Piritiba' },
  { data: '2924900', label: 'Planaltino' },
  { data: '2925006', label: 'Planalto' },
  { data: '2925105', label: 'Poções' },
  { data: '2925204', label: 'Pojuca' },
  { data: '2925253', label: 'Ponto Novo' },
  { data: '2925303', label: 'Porto Seguro' },
  { data: '2925402', label: 'Potiraguá' },
  { data: '2925501', label: 'Prado' },
  { data: '2925600', label: 'Presidente Dutra' },
  { data: '2925709', label: 'Presidente Jânio Quadros' },
  { data: '2925758', label: 'Presidente Tancredo Neves' },
  { data: '2925808', label: 'Queimadas' },
  { data: '2925907', label: 'Quijingue' },
  { data: '2925931', label: 'Quixabeira' },
  { data: '2925956', label: 'Rafael Jambeiro' },
  { data: '2926004', label: 'Remanso' },
  { data: '2926103', label: 'Retirolândia' },
  { data: '2926202', label: 'Riachão das Neves' },
  { data: '2926301', label: 'Riachão do Jacuípe' },
  { data: '2926400', label: 'Riacho de Santana' },
  { data: '2926509', label: 'Ribeira do Amparo' },
  { data: '2926608', label: 'Ribeira do Pombal' },
  { data: '2926657', label: 'Ribeirão do Largo' },
  { data: '2926707', label: 'Rio de Contas' },
  { data: '2926806', label: 'Rio do Antônio' },
  { data: '2926905', label: 'Rio do Pires' },
  { data: '2927002', label: 'Rio Real' },
  { data: '2927101', label: 'Rodelas' },
  { data: '2927200', label: 'Ruy Barbosa' },
  { data: '2927309', label: 'Salinas da Margarida' },
  { data: '2927408', label: 'Salvador' },
  { data: '2927507', label: 'Santa Bárbara' },
  { data: '2927606', label: 'Santa Brígida' },
  { data: '2927705', label: 'Santa Cruz Cabrália' },
  { data: '2927804', label: 'Santa Cruz da Vitória' },
  { data: '2927903', label: 'Santa Inês' },
  { data: '2928059', label: 'Santa Luzia' },
  { data: '2928109', label: 'Santa Maria da Vitória' },
  { data: '2928406', label: 'Santa Rita de Cássia' },
  { data: '2928505', label: 'Santa Teresinha' },
  { data: '2928000', label: 'Santaluz' },
  { data: '2928208', label: 'Santana' },
  { data: '2928307', label: 'Santanópolis' },
  { data: '2928604', label: 'Santo Amaro' },
  { data: '2928703', label: 'Santo Antônio de Jesus' },
  { data: '2928802', label: 'Santo Estêvão' },
  { data: '2928901', label: 'São Desidério' },
  { data: '2928950', label: 'São Domingos' },
  { data: '2929107', label: 'São Felipe' },
  { data: '2929008', label: 'São Félix' },
  { data: '2929057', label: 'São Félix do Coribe' },
  { data: '2929206', label: 'São Francisco do Conde' },
  { data: '2929255', label: 'São Gabriel' },
  { data: '2929305', label: 'São Gonçalo dos Campos' },
  { data: '2929354', label: 'São José da Vitória' },
  { data: '2929370', label: 'São José do Jacuípe' },
  { data: '2929404', label: 'São Miguel das Matas' },
  { data: '2929503', label: 'São Sebastião do Passé' },
  { data: '2929602', label: 'Sapeaçu' },
  { data: '2929701', label: 'Sátiro Dias' },
  { data: '2929750', label: 'Saubara' },
  { data: '2929800', label: 'Saúde' },
  { data: '2929909', label: 'Seabra' },
  { data: '2930006', label: 'Sebastião Laranjeiras' },
  { data: '2930105', label: 'Senhor do Bonfim' },
  { data: '2930204', label: 'Sento Sé' },
  { data: '2930154', label: 'Serra do Ramalho' },
  { data: '2930303', label: 'Serra Dourada' },
  { data: '2930402', label: 'Serra Preta' },
  { data: '2930501', label: 'Serrinha' },
  { data: '2930600', label: 'Serrolândia' },
  { data: '2930709', label: 'Simões Filho' },
  { data: '2930758', label: 'Sítio do Mato' },
  { data: '2930766', label: 'Sítio do Quinto' },
  { data: '2930774', label: 'Sobradinho' },
  { data: '2930808', label: 'Souto Soares' },
  { data: '2930907', label: 'Tabocas do Brejo Velho' },
  { data: '2931004', label: 'Tanhaçu' },
  { data: '2931053', label: 'Tanque Novo' },
  { data: '2931103', label: 'Tanquinho' },
  { data: '2931202', label: 'Taperoá' },
  { data: '2931301', label: 'Tapiramutá' },
  { data: '2931350', label: 'Teixeira de Freitas' },
  { data: '2931400', label: 'Teodoro Sampaio' },
  { data: '2931509', label: 'Teofilândia' },
  { data: '2931608', label: 'Teolândia' },
  { data: '2931707', label: 'Terra Nova' },
  { data: '2931806', label: 'Tremedal' },
  { data: '2931905', label: 'Tucano' },
  { data: '2932002', label: 'Uauá' },
  { data: '2932101', label: 'Ubaíra' },
  { data: '2932200', label: 'Ubaitaba' },
  { data: '2932309', label: 'Ubatã' },
  { data: '2932408', label: 'Uibaí' },
  { data: '2932457', label: 'Umburanas' },
  { data: '2932507', label: 'Una' },
  { data: '2932606', label: 'Urandi' },
  { data: '2932705', label: 'Uruçuca' },
  { data: '2932804', label: 'Utinga' },
  { data: '2932903', label: 'Valença' },
  { data: '2933000', label: 'Valente' },
  { data: '2933059', label: 'Várzea da Roça' },
  { data: '2933109', label: 'Várzea do Poço' },
  { data: '2933158', label: 'Várzea Nova' },
  { data: '2933174', label: 'Varzedo' },
  { data: '2933208', label: 'Vera Cruz' },
  { data: '2933257', label: 'Vereda' },
  { data: '2933307', label: 'Vitória da Conquista' },
  { data: '2933406', label: 'Wagner' },
  { data: '2933455', label: 'Wanderley' },
  { data: '2933505', label: 'Wenceslau Guimarães' },
  { data: '2933604', label: 'Xique-Xique' },
];

export default ba;
