import React from 'react';
import { Modal as AntdModal } from 'antd';

const Modal = React.memo(({ title, extraHeader, ...rest }) => (
  <AntdModal
    title={
      <div style={{ display: 'flex', width: '100%' }}>
        <div style={{ width: extraHeader ? '80%' : '100%' }}>{title}</div>
        {extraHeader ? <div style={{ width: '20%', textAlign: 'right', paddingRight: '24px' }}>{extraHeader || null}</div> : null}
      </div>
    }
    centered
    maskClosable={false}
    closable={false}
    {...rest}
  />
));

export default Modal;
