import React from 'react';
import { Layout, Row, Col } from 'antd';
import classNames from 'classnames';
import { Footer } from '~/layouts';
import { logo } from '~/assets';
import './UserLayout.less';

// eslint-disable-next-line import/no-unresolved
const BackTop = React.lazy(() => import('~/layouts/components/BackTop'));

const UserLayout = React.memo(({ children, showHeader = true }) => (
  <>
    <Layout className="user-layout">
      <Layout.Content className="user-layout-content">
        <Col
          className={classNames('user-layout-content-wrapper', {
            'user-layout-content-header-fix': showHeader,
          })}
        >
          {showHeader && (
            <Row gutter={4} type="flex" justify="center">
              <Col className="user-layout-content-header-wrapper">
                <div className="user-layout-content-header">
                  <img alt="logo" className="user-layout-content-header-logo" src={logo} />
                </div>
              </Col>
            </Row>
          )}
          {children}
        </Col>
      </Layout.Content>
      <Footer />
    </Layout>
    <React.Suspense fallback={null}>
      <BackTop />
    </React.Suspense>
  </>
));

export default UserLayout;
