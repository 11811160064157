import React from 'react';
import { LoadingEmpty } from '~/components';
import './ImageBox.less';

const ImageBox = React.memo(({ sourceImage, loading }) => (
  <div className="div-externa">
    {sourceImage ? <img width="256px" height="256px" alt="logo_produto" src={sourceImage} /> : <LoadingEmpty loading={loading} />}
  </div>
));

export default ImageBox;
