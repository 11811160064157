import React from 'react';
import classNames from 'classnames';
import { Tooltip } from 'antd';
import { useField } from 'informed';
import { Form } from '~/components/forms';
import { isRequired, complexValidator } from '~/utils';
import { Password, Validate, TextArea } from './components';
import { components, types } from './options';
import './Input.less';

let timeout;

const Input = React.memo(({ validate, validateOnChange, type = types.string.id, labelHorizontal, align, ...props }) => {
  const { fieldState, fieldApi, render, userProps } = useField({
    validate: validate || complexValidator(props.required && isRequired, types[type].validate) || (() => undefined),
    validateOnChange: validateOnChange || props.required || validate || types[type].validate,
    ...props,
  });
  const {
    onChange,
    onBlur,
    label,
    initialValue,
    forwardedRef,
    style,
    component = components.input.id,
    formItemProps,
    disabledReason,
    tooltipProps,
    tooltip,
    required,
    capsLock = true,
    className,
    ...rest
  } = userProps;
  const { Component } = components[component];

  let fieldError = fieldState.error;
  if (isRequired(fieldState.value) && !required) {
    fieldError = undefined;
  }

  const formatarFatura = value => {
    if (timeout) {
      fieldApi.setValue(value);
      clearTimeout(timeout);
      timeout = null;
    }
    timeout = setTimeout(() => {
      if (value && (value.length === 47 || value.length === 44 || value.length === 48)) fieldApi.setValue(types[type].onBlur(value));
      else if (fieldState.value && value.length > fieldState.value.length) fieldApi.setValue(types[type].onChange(value));
      else fieldApi.setValue(value);
    }, 200);
  };

  return render(
    <Form.Item label={label} error={!rest.disabled && fieldError} required={required} {...formItemProps} labelHorizontal={labelHorizontal}>
      <Tooltip title={(rest.disabled && disabledReason) || tooltip} placement="bottom" color="red" {...tooltipProps}>
        <Component
          autoComplete="none"
          ref={forwardedRef}
          value={!fieldState.value && initialValue ? initialValue : fieldState.value || ''}
          onChange={event => {
            const value = types[type].parser && event.target.value.length > 0 ? types[type].parser(event.target.value) : event.target.value;

            if (types[type].validate) {
              fieldState.error = types[type].validate(value);
            }

            if (type === 'fatura') {
              formatarFatura(value);
            } else {
              fieldApi.setValue(value);
            }

            if (onChange) {
              onChange(event);
            }
          }}
          onBlur={event => {
            if (capsLock && type !== 'email' && fieldState.value && fieldState.value !== (fieldState.value.toString() || '').toUpperCase()) {
              fieldApi.setValue((fieldState.value.toString() || '').toUpperCase());
            }

            fieldApi.setTouched();

            if (onBlur) {
              if (types[type].validate) {
                if (!types[type].validate(event.target.value)) {
                  onBlur(event);
                }
              } else {
                onBlur(event);
              }
            }
          }}
          suffix={rest.suffix || undefined}
          prefix={rest.prefix || undefined}
          allowClear
          maxLength={types[type].length ? types[type].length : undefined}
          {...rest}
          className={classNames(
            className,
            type === 'integer' || type === 'postal' || align === 'center' ? 'input-align-center' : '',
            capsLock && type !== 'email' ? 'ant-input-uppercase' : ''
          )}
          onPressEnter={() => {}}
        />
      </Tooltip>
    </Form.Item>
  );
});

Input.Password = Password;
Input.Validate = Validate;
Input.TextArea = TextArea;

export { Password, Validate, TextArea };
export default Input;
