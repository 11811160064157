import React from 'react';
import moment from 'moment';
import { Row, Col, Modal } from 'antd';
import { FilePdfTwoTone, FileTextTwoTone } from '@ant-design/icons';
import classNames from 'classnames';
import { Input, Form, Button, Table, FooterToolbar, Page, ContentWrapper, DatePicker, Select, Link, Statistic } from '~/components';
import { CustomerService, FrequenciaService, RelatorioService, UserService } from '~/services';
import { decimalValue, openWindowWithPost } from '~/utils';
import FrequenciaPageDetalheView from './FrequenciaPageDetalheView';

class FrequenciaPage extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      itens: [],
      selectedItem: {},
      tipoDetalhe: '',
      showDetalhe: false,
      empresas: [],
    };
  }

  componentDidMount() {
    UserService.fetchEmpresas(empresas => this.setState({ empresas }));

    this.searchInput.focus();
  }

  handleSearch = values => {
    if (moment(values.data_final).diff(values.data_inicial, 'days') > 31) {
      Modal.error({
        title: 'Frequência',
        content: 'Não é possível buscar um período maior que o selecionado',
        centered: true,
      });
      return;
    }

    this.setState({ isLoading: true });
    FrequenciaService.getAll(
      values,
      itens => this.setState({ itens, isLoading: false }),
      () => this.setState({ isLoading: false })
    );
  };

  imprimirCupom = (record, tipo = 'pdf') => {
    this.setState({ isLoading: true });

    FrequenciaService.consultar_cupom(
      { ...record, retorno: tipo },
      response => {
        this.setState({ isLoading: false });

        if (response.toString().indexOf(' encontrado') === -1) {
          window.open(`${process.env.REACT_APP_URL}/${response.toString()}`, tipo === 'pdf' ? '_blank' : 'Download');
        } else {
          Modal.error({ content: response.toString(), title: 'Consulta Cupom', centered: true });
        }
      },
      () => this.setState({ isLoading: false })
    );
  };

  imprimirNota = record => {
    const values = [];
    values.push({ nome_campo: 'codigo', valor: record.idt_nfe });
    values.push({ nome_campo: 'chave', valor: record.chave_nfe });
    values.push({ nome_campo: 'webnfe3', valor: 'S' });
    values.push({
      nome_campo: 'periodo',
      valor: `20${record.chave_nfe.substr(2, 4)}`,
    });

    RelatorioService.getChamaRelatorio(values, 'ServletDanfe', 'Nota Fiscal');
  };

  baixarXML = (record, idt, tipo = 'PDF') => {
    openWindowWithPost('arquivo/exportar/exportar_nota.php', {
      server: CustomerService.getCustomer().endereco_banco,
      db_erp: CustomerService.getCustomer().database,
      banco_nota: record.database_nfe,
      idt,
      tipo,
    });
  };

  render() {
    const { tabId } = this.props;
    const { isLoading, itens, selectedItem, tipoDetalhe, showDetalhe, empresas } = this.state;

    return (
      <>
        <FrequenciaPageDetalheView record={selectedItem} action={showDetalhe} tipo={tipoDetalhe} onCancel={() => this.setState({ showDetalhe: false })} />
        <Page tabId={tabId}>
          <Form getApi={api => (this.searchApi = api)} onSubmit={this.handleSearch}>
            <ContentWrapper type="search">
              <Row gutter={4}>
                <Col md={3} span={12}>
                  <DatePicker field="data_inicial" label="Data Inicial" required today disabled={isLoading} />
                </Col>
                <Col md={3} span={12}>
                  <DatePicker field="data_final" label="Data Final" required today disabled={isLoading} />
                </Col>
                <Col md={3} span={12}>
                  <Input field="placa" label="Placa" disabled={isLoading} type="placa" allowClear forwardedRef={ref => (this.searchInput = ref)} />
                </Col>
                <Col md={12} span={20}>
                  <Select field="origem" label="Empresa" dataSource={empresas} allowClear disabled={isLoading} />
                </Col>
                <Col md={3} span={4}>
                  <Button.Search loading={isLoading} />
                </Col>
              </Row>
            </ContentWrapper>
          </Form>
          <Table
            dataSource={itens}
            rowKey="idt"
            loading={isLoading}
            record={selectedItem}
            onRecordChange={record => this.setState({ selectedItem: record })}
            description={item => (
              <Row>
                <Col span={16}>
                  <span style={{ fontSize: '0.8rem' }}>{item.nome_estabelecimento}</span>
                </Col>
                <Col span={8}>
                  <span>{item.placa}</span>
                </Col>
              </Row>
            )}
            height={window.innerHeight - 390}
          >
            <Table.Column title="Emissão" dataIndex="emissao" type="date" onMobile="show" width="12%" />
            <Table.Column
              title="NFC-e"
              dataIndex="nfce"
              aligon="center"
              width="8%"
              render={(text, record) => (
                <Link align="center" onClick={() => this.setState({ selectedItem: record, showDetalhe: true })}>
                  {text || ''}
                </Link>
              )}
            />
            <Table.Column title="NF-e" dataIndex="nfe" width="8%" type="integer" />
            <Table.Column title="Empresa" dataIndex="nome_estabelecimento" width="24%" />
            <Table.Column title="Placa" dataIndex="placa" width="7%" align="center" />
            <Table.Column title="Valor" dataIndex="valor" type="currency" width="8%" />
            <Table.Column title="Litros" dataIndex="litros" type="decimal" width="7%" />
            <Table.Column title="Motorista" dataIndex="motorista" width="10%" />
            <Table.Column title="Odômetro" dataIndex="odometro" width="8%" />
            <Table.Column
              title="Ações"
              dataIndex="actions"
              width="8%"
              align="center"
              exporter={false}
              sorter={false}
              render={(text, record) => (
                <>
                  <FilePdfTwoTone
                    onClick={() => this.imprimirCupom(record)}
                    title="PDF NFC-e"
                    twoToneColor="#cc0000"
                    className={classNames(!record.chave_nfce ? 'icon-disabled' : '', 'icon-table-margin')}
                  />
                  <FileTextTwoTone
                    onClick={() => this.imprimirCupom(record, 'xml')}
                    title="XML NFC-e"
                    twoToneColor="#00cc00"
                    className={classNames(!record.chave_nfce ? 'icon-disabled' : '', 'icon-table-margin')}
                  />
                  <FilePdfTwoTone
                    onClick={() => this.imprimirNota(record)}
                    title="PDF NF-e"
                    twoToneColor="#cc0000"
                    className={classNames(!record.chave_nfe ? 'icon-disabled' : '', 'icon-table-margin')}
                  />
                  <FileTextTwoTone
                    onClick={() => this.baixarXML(record, record.idt_nfe, 'XML')}
                    title="XML NF-e"
                    twoToneColor="#00cc00"
                    className={classNames(!record.chave_nfe ? 'icon-disabled' : '', 'icon-table-margin')}
                  />
                </>
              )}
            />
          </Table>
          <ContentWrapper type="header">
            <Row>
              <Col md={6} span={4} />
              <Col md={6} span={10}>
                <Statistic
                  style={{ textAlign: 'center' }}
                  title="Litros"
                  value={itens.reduce((previous, current) => parseFloat(current.litros) + previous, 0)}
                  color="#000099"
                  precision={3}
                  prefix=""
                />
              </Col>
              <Col md={6} span={10}>
                <Statistic
                  style={{ textAlign: 'center' }}
                  title="Valor Total (R$)"
                  value={decimalValue(
                    itens.reduce((previous, current) => parseFloat(current.valor) + previous, 0),
                    2
                  )}
                  color="#3f8600"
                />
              </Col>
            </Row>
          </ContentWrapper>
        </Page>
        <FooterToolbar left={<></>} center={<></>} right={<></>} />
      </>
    );
  }
}

export default FrequenciaPage;
