import React from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { actions } from '~/options/general';
import Button from '..';

const Insert = React.memo(({ menuId, children, perfilAcess, ...rest }) => (
  <Button icon={<PlusOutlined />} type="primary" onMobile="icon" {...rest}>
    {children || actions.insert.name}
  </Button>
));

export default Insert;
