import React from 'react';
import { CheckCircleTwoTone, StopTwoTone } from '@ant-design/icons';
import { Tooltip } from 'antd';
import './IconOperacaoDestinada.less';

const IconOperacaoNota = React.memo(({ record }) => {
  if (record.operacao_efetuada === '1')
    return (
      <Tooltip title={`CONFIRMADA`}>
        <CheckCircleTwoTone className="icon" twoToneColor="#33cc33" />
      </Tooltip>
    );
  if (record.operacao_efetuada === '2')
    return (
      <Tooltip title={`DESCONHECIMENTO`}>
        <CheckCircleTwoTone className="icon" twoToneColor="#bfbfbf" />
      </Tooltip>
    );
  if (record.operacao_efetuada === '3')
    return (
      <Tooltip title={`OPERAÇÃO NÃO REALIZADA`}>
        <CheckCircleTwoTone className="icon" twoToneColor="#ff0000" />
      </Tooltip>
    );
  if (record.operacao_efetuada === '4')
    return (
      <Tooltip title={`CIÊNCIA - NÃO CONFIRMADA`}>
        <StopTwoTone className="icon" twoToneColor="#cc9900" />
      </Tooltip>
    );
  return null;
});

export default IconOperacaoNota;
