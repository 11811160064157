import React from 'react';
import { Col as ColAntd } from 'antd';

const Col = React.memo(({ md, hidden, children }) => (
  <ColAntd md={md} flex={md} hidden={hidden}>
    {children}
  </ColAntd>
));

export default Col;
