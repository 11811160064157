import React from 'react';
import { Row, Col } from 'antd';
import { FilePdfOutlined, FileTextOutlined, FileExcelOutlined } from '@ant-design/icons';
import { FooterToolbar, Page, Form, ContentWrapper, Button, DatePicker, Select, Input } from '~/components';
import { RelatorioService, UserService } from '~/services';

let TIPO = 'pdf';
let EMAIL = false;

class RelatorioPage extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      relatorios: [],
      empresas: [],
    };
  }

  componentDidMount() {
    setTimeout(() => {
      if (this.editInput) this.editInput.focus();
    }, 300);

    UserService.fetchEmpresas(empresas => this.setState({ empresas }));

    this.setState({ isLoading: true });

    RelatorioService.fetchRelatorios(
      relatorios => this.setState({ relatorios, isLoading: false }),
      () => this.setState({ relatorios: [], isLoading: false })
    );
  }

  handlePrint = (tipo = 'pdf', email = false) => {
    TIPO = tipo;
    EMAIL = email;
    this.saveApi.submitForm();
  };

  gerarRelatorio = valuesForms => {
    const { relatorios } = this.state;
    const relatorioSelected = relatorios.find(item => item.data === valuesForms.relatorio);
    const newValuesForms = {
      ...valuesForms,
      origem: valuesForms.origem && valuesForms.origem.length > 0 ? valuesForms.origem.join() : '',
    };

    const values = [];
    Object.entries(newValuesForms).forEach(([key, value = '']) => {
      if (value && Array.isArray(value)) {
        values.push({ nome_campo: key, valor: value.length > 0 ? (parseInt(value[0], 10) > 0 ? value.join() : `'${value.join("','")}'`) : '' });
      } else if (value && value !== '') {
        values.push({ nome_campo: key, valor: value.length === 10 ? value.replace('-', '').replace('-', '') : value });
      }
    });

    values.push({ nome_campo: 'cliente', valor: UserService.getUser().codigo });
    values.push({ nome_campo: 'titulo_relatorio', valor: relatorioSelected.label });
    values.push({ nome_campo: 'exporta_excel', valor: TIPO === 'excel' ? 'S' : 'N' });
    values.push({ nome_campo: 'exporta_csv', valor: TIPO === 'csv' || relatorioSelected.label === 'Listagem de Venda Detalhada' ? 'S' : 'N' });
    values.push({ nome_campo: 'retorna_pdf', valor: EMAIL ? 'S' : 'N' });
    values.push({ nome_campo: 'permissao', valor: 'S' });

    this.setState({ isLoading: true });
    RelatorioService.getChamaRelatorio(
      values,
      relatorioSelected.servlet,
      relatorioSelected.label,
      () => {
        this.setState({ isLoading: false });
      },
      () => {
        this.setState({ isLoading: false });
      }
    );
  };

  render = () => {
    const { tabId } = this.props;
    const { isLoading, relatorios, empresas } = this.state;
    const valuesForm = this.saveApi ? this.saveApi.getValues() : {};
    const isDisabled = !valuesForm.relatorio || isLoading;

    return (
      <>
        <Page tabId={tabId} closable>
          <Form getApi={api => (this.saveApi = api)} onSubmit={this.gerarRelatorio}>
            <ContentWrapper>
              <Row gutter={4}>
                <Col md={24} span={24}>
                  <Select field="relatorio" label="Relatório" dataSource={relatorios} required disabled={isLoading} onChange={() => this.forceUpdate()} />
                </Col>
              </Row>
            </ContentWrapper>
            <ContentWrapper type="search">
              <Row gutter={4}>
                <Col md={24} span={24}>
                  <Select
                    field="origem"
                    label="Empresa"
                    dataSource={empresas}
                    forwardedRef={ref => (this.searchInput = ref)}
                    disabled={isDisabled}
                    mode="multiple"
                    maxTagCount={2}
                  />
                </Col>
                <Col md={3} span={12}>
                  <DatePicker field="data_inicial" label="Data Inicial" today forwardedRef={ref => (this.editInput = ref)} disabled={isDisabled} required />
                </Col>
                <Col md={3} span={12}>
                  <DatePicker field="data_final" label="Data Final" today disabled={isDisabled} required />
                </Col>
                <Col md={4} span={12}>
                  <Input label="Placa" field="placa" type="placa" allowClear disabled={isDisabled} />
                </Col>
              </Row>
            </ContentWrapper>
          </Form>
        </Page>
        <FooterToolbar
          left={<></>}
          right={
            <>
              <Button.Print
                options
                loading={isLoading}
                disabled={!valuesForm.relatorio}
                menu={[
                  { key: 'pdf', label: 'Gerar Relatório', color: '#ff4d4d', icon: <FilePdfOutlined />, itemClick: () => this.handlePrint('pdf', false) },
                  { key: 'csv', label: 'Gerar CSV', color: '#006600', icon: <FileTextOutlined />, itemClick: () => this.handlePrint('csv', false) },
                  { key: 'excel', label: 'Gerar EXCEL', color: '#0033cc', icon: <FileExcelOutlined />, itemClick: () => this.handlePrint('excel', false) },
                ]}
              />
            </>
          }
        />
      </>
    );
  };
}

export default RelatorioPage;
