import { HttpService } from '~/helpers';
import { UserService } from '~/services';

const endpoint = 'cadastro/veiculo';

const formatFilters = filters =>
  filters
    ? [
        { nome_campo: 'a.idt_cliente', valor: UserService.getUser().codigo, tipo: 'int' },
        { nome_campo: 'a.descricao', valor: filters.descricao, tipo: 'texto' },
        { nome_campo: 'a.placa', valor: filters.placa, tipo: 'texto' },
      ]
    : [];

const formatValues = values => ({
  ...values,
  usuario: UserService.getUser().login,
});

const VeiculoService = {
  fetch: (onSuccess, onError) => {
    HttpService.call('sistema/combo', 'combo_cad_veiculo', [], onSuccess, onError);
  },
  getAll: (avancada, onSuccess, onError) => {
    HttpService.call(endpoint, 'getAll', [formatFilters(avancada)], onSuccess, onError);
  },
  getAllPlaca: (placa, onSuccess) => {
    HttpService.call(endpoint, 'getAllPlaca', [placa, UserService.getUser().codigo], response => onSuccess(response.length === 0));
  },
  inserir: (values, onSuccess, onError) => {
    HttpService.call(endpoint, 'inserir', [formatValues(values)], onSuccess, onError);
  },
  alterar: (values, onSuccess, onError) => {
    HttpService.call(endpoint, 'alterar', [formatValues(values)], onSuccess, onError);
  },
  excluir: (codigo, onSuccess, onError) => {
    HttpService.call(endpoint, 'excluir', [codigo, UserService.getUser().login], onSuccess, onError);
  },
  getAllOdometro: (veiculo, onSuccess, onError) => {
    HttpService.call(endpoint, 'getAllOdometro', [veiculo], onSuccess, onError);
  },
  verificar_movimento_placa: (values, onSuccess) => {
    HttpService.call(endpoint, 'verificar_movimento_placa', [formatValues(values)], onSuccess);
  },
};

export default VeiculoService;
