import React from 'react';
import { Modal } from 'antd';
import { DeleteTwoTone } from '@ant-design/icons';
import { Input } from '~/components';
import { actions } from '~/options/general';
import { hasAccess } from '~/utils/validators';
import { accessWarning } from '~/components/utils';

let campoCodigo = false;
let campoJustificativa = '';
const validarJustificativa = value => {
  if (!value || value.trim() === '' || /(.)\1{4,}/.test(value)) {
    campoJustificativa = '';
    return false;
  }

  campoJustificativa = value;
  return true;
};
const limparCampos = () => {
  campoJustificativa = '';
  campoCodigo = false;
};

const Delete = React.memo(
  ({ menuId, onClick: propsOnClick, onCancel, prefix, description, descricaoCustomizada, disabled, validate = {}, capsLock, ...rest }) => (
    <DeleteTwoTone
      title={actions.remove.name}
      twoToneColor={actions.remove.color.color}
      onCancel={onCancel}
      className={disabled ? 'icon-disabled' : ''}
      onClick={() => {
        const justificar = hasAccess(menuId, { perfil: 'justificar_excluir' }, false);
        if (!menuId || hasAccess(menuId, actions.remove, false)) {
          const modal = Modal.confirm({
            title: actions.remove.name,
            icon: <DeleteTwoTone twoToneColor={actions.remove.color.color} />,
            centered: true,
            onOk: () => {
              propsOnClick(campoJustificativa);
              limparCampos();
            },
            okText: actions.remove.name,
            okType: 'danger',
            maskClosable: true,
            destroyOnClose: true,
            okButtonProps: { disabled: validate.value },
            onCancel,
            content: (
              <>
                {`Tem certeza de que deseja ${actions.remove.name.toLowerCase()}${` ${prefix || ''}`}`}
                {description ? <b>{` ${description}`}</b> : ' o registro'}
                {`? Esta operação não poderá ser desfeita.`}
                {descricaoCustomizada ? <div style={{ margin: '10px 0px', fontWeight: 'bold' }}>{`${descricaoCustomizada}`}</div> : null}
                {validate.value && (
                  <>
                    {justificar ? (
                      <>
                        <div style={{ margin: '16px 0 8px' }}>
                          <span style={{ color: '#ff0000' }}>*</span>
                          &nbsp;Informe a justificativa:
                        </div>
                        <Input.TextArea
                          autoFocus
                          field="justificativa"
                          type="justificativa"
                          allowClear={false}
                          autoSize={{ minRows: 4, maxRows: 4 }}
                          onChange={event => {
                            validarJustificativa(event.target.value);
                            modal.update({
                              okButtonProps: { disabled: campoCodigo === false || validarJustificativa(event.target.value) === false },
                            });
                          }}
                        />
                        <div style={{ fontSize: '11px', marginBottom: '30px' }}>A jusfiticativa é obrigatória e não aceita repetições (ex. aaaa).</div>
                      </>
                    ) : null}

                    <div style={{ margin: '16px 0 8px' }}>
                      {`Digite ${validate.prefix && `${validate.prefix} `}`}
                      {validate.name ? <b>{` ${validate.name}`}</b> : ' a validação'}
                      {' para confirmar.'}
                    </div>
                    <Input
                      autoFocus={!justificar}
                      formItemProps={{ style: { marginBottom: 0 }, colon: false }}
                      type={validate.type || 'id'}
                      placeholder={validate.value}
                      validate={() => undefined}
                      align="center"
                      onChange={({ target }) => {
                        campoCodigo = target.value === validate.value;
                        modal.update({
                          okButtonProps: { disabled: justificar ? campoCodigo === false || campoJustificativa === '' : target.value !== validate.value },
                        });
                      }}
                      capsLock={capsLock !== false}
                    />
                  </>
                )}
              </>
            ),
          });
        } else accessWarning({ title: 'Excluir' });
      }}
      {...rest}
    />
  )
);

export default Delete;
