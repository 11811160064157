// Amazonas
const am = [
  { data: '1300029', label: 'Alvarães' },
  { data: '1300060', label: 'Amaturá' },
  { data: '1300086', label: 'Anamã' },
  { data: '1300102', label: 'Anori' },
  { data: '1300144', label: 'Apuí' },
  { data: '1300201', label: 'Atalaia do Norte' },
  { data: '1300300', label: 'Autazes' },
  { data: '1300409', label: 'Barcelos' },
  { data: '1300508', label: 'Barreirinha' },
  { data: '1300607', label: 'Benjamin Constant' },
  { data: '1300631', label: 'Beruri' },
  { data: '1300680', label: 'Boa Vista do Ramos' },
  { data: '1300706', label: 'Boca do Acre' },
  { data: '1300805', label: 'Borba' },
  { data: '1300839', label: 'Caapiranga' },
  { data: '1300904', label: 'Canutama' },
  { data: '1301001', label: 'Carauari' },
  { data: '1301100', label: 'Careiro' },
  { data: '1301159', label: 'Careiro da Várzea' },
  { data: '1301209', label: 'Coari' },
  { data: '1301308', label: 'Codajás' },
  { data: '1301407', label: 'Eirunepé' },
  { data: '1301506', label: 'Envira' },
  { data: '1301605', label: 'Fonte Boa' },
  { data: '1301654', label: 'Guajará' },
  { data: '1301704', label: 'Humaitá' },
  { data: '1301803', label: 'Ipixuna' },
  { data: '1301852', label: 'Iranduba' },
  { data: '1301902', label: 'Itacoatiara' },
  { data: '1301951', label: 'Itamarati' },
  { data: '1302009', label: 'Itapiranga' },
  { data: '1302108', label: 'Japurá' },
  { data: '1302207', label: 'Juruá' },
  { data: '1302306', label: 'Jutaí' },
  { data: '1302405', label: 'Lábrea' },
  { data: '1302504', label: 'Manacapuru' },
  { data: '1302553', label: 'Manaquiri' },
  { data: '1302603', label: 'Manaus' },
  { data: '1302702', label: 'Manicoré' },
  { data: '1302801', label: 'Maraã' },
  { data: '1302900', label: 'Maués' },
  { data: '1303007', label: 'Nhamundá' },
  { data: '1303106', label: 'Nova Olinda do Norte' },
  { data: '1303205', label: 'Novo Airão' },
  { data: '1303304', label: 'Novo Aripuanã' },
  { data: '1303403', label: 'Parintins' },
  { data: '1303502', label: 'Pauini' },
  { data: '1303536', label: 'Presidente Figueiredo' },
  { data: '1303569', label: 'Rio Preto da Eva' },
  { data: '1303601', label: 'Santa Isabel do Rio Negro' },
  { data: '1303700', label: 'Santo Antônio do Içá' },
  { data: '1303809', label: 'São Gabriel da Cachoeira' },
  { data: '1303908', label: 'São Paulo de Olivença' },
  { data: '1303957', label: 'São Sebastião do Uatumã' },
  { data: '1304005', label: 'Silves' },
  { data: '1304062', label: 'Tabatinga' },
  { data: '1304104', label: 'Tapauá' },
  { data: '1304203', label: 'Tefé' },
  { data: '1304237', label: 'Tonantins' },
  { data: '1304260', label: 'Uarini' },
  { data: '1304302', label: 'Urucará' },
  { data: '1304401', label: 'Urucurituba' },
];

export default am;
