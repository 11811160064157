import React from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { SearchOutlined, UpOutlined, DownOutlined } from '@ant-design/icons';
import { Dropdown, Menu, Tooltip } from 'antd';
import { actions } from '~/options/general';
import Button from '..';

const Search = React.memo(
  ({
    children,
    expanded,
    noLabel,
    style = { marginBottom: '10px', marginTop: '22px' },
    onExpand,
    loading,
    className,
    icon,
    disabled,
    disabledReason,
    tooltip,
    general,
  }) => {
    if (expanded === undefined) {
      return (
        <Button
          type="primary"
          icon={icon || <SearchOutlined />}
          htmlType="submit"
          block
          style={style}
          loading={loading}
          className={className}
          disabled={disabled}
          disabledReason={disabledReason}
        >
          {noLabel || general.isMobile ? true : children || actions.search.name}
        </Button>
      );
    }
    if ((disabled && disabledReason !== undefined) || tooltip) {
      return (
        <Tooltip title={(disabled && disabledReason) || tooltip} color="red" placement="top" className="top_button-tooltip">
          <Dropdown.Button
            style={{ ...{ marginBottom: '10px', marginTop: '21px' }, overflow: 'hidden' }}
            icon={expanded ? <UpOutlined /> : <DownOutlined />}
            type="primary"
            trigger="click"
            disabled={disabled || loading}
            className={classNames(loading ? 'button-loading' : '', className)}
            overlay={<Menu selectable={false} />}
            buttonsRender={([leftButton, rightButton]) => [
              React.cloneElement(leftButton, {
                icon: icon || <SearchOutlined />,
                loading,
                htmlType: 'submit',
              }),
              React.cloneElement(rightButton, {
                onClick: () => onExpand(!expanded),
              }),
            ]}
          >
            {noLabel || general.isMobile ? true : children || actions.search.name}
          </Dropdown.Button>
        </Tooltip>
      );
    }
    return (
      <Dropdown.Button
        style={{ ...{ marginBottom: '10px', marginTop: '21px' }, overflow: 'hidden' }}
        icon={expanded ? <UpOutlined /> : <DownOutlined />}
        type="primary"
        trigger="click"
        disabled={disabled || loading}
        className={classNames(loading ? 'button-loading' : '', className)}
        overlay={<Menu selectable={false} />}
        buttonsRender={([leftButton, rightButton]) => [
          React.cloneElement(leftButton, {
            icon: icon || <SearchOutlined />,
            loading,
            htmlType: 'submit',
          }),
          React.cloneElement(rightButton, {
            onClick: () => onExpand(!expanded),
          }),
        ]}
      >
        {noLabel || general.isMobile ? true : children || actions.search.name}
      </Dropdown.Button>
    );
  }
);

const mapStateToProps = ({ general }) => ({ general });
export default connect(mapStateToProps)(Search);
