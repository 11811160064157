import React from 'react';
import { EditOutlined } from '@ant-design/icons';
import Button from '../..';
import { actions } from '~/options/general';
import './Update.less';

const Update = React.memo(({ children, menuId, perfilAcess, ...rest }) => (
  <Button icon={<EditOutlined />} type="primary" className="update-button" onMobile="icon" {...rest}>
    {children || actions.update.name}
  </Button>
));

export default Update;
