// Mato Grosso do Sul
const ms = [
  { data: '5000203', label: 'Água Clara' },
  { data: '5000252', label: 'Alcinópolis' },
  { data: '5000609', label: 'Amambaí' },
  { data: '5000708', label: 'Anastácio' },
  { data: '5000807', label: 'Anaurilândia' },
  { data: '5000856', label: 'Angélica' },
  { data: '5000906', label: 'Antônio João' },
  { data: '5001003', label: 'Aparecida do Taboado' },
  { data: '5001102', label: 'Aquidauana' },
  { data: '5001243', label: 'Aral Moreira' },
  { data: '5001508', label: 'Bandeirantes' },
  { data: '5001904', label: 'Bataguassu' },
  { data: '5002001', label: 'Batayporã' },
  { data: '5002100', label: 'Bela Vista' },
  { data: '5002159', label: 'Bodoquena' },
  { data: '5002209', label: 'Bonito' },
  { data: '5002308', label: 'Brasilândia' },
  { data: '5002407', label: 'Caarapó' },
  { data: '5002605', label: 'Camapuã' },
  { data: '5002704', label: 'Campo Grande' },
  { data: '5002803', label: 'Caracol' },
  { data: '5002902', label: 'Cassilândia' },
  { data: '5002951', label: 'Chapadão do Sul' },
  { data: '5003108', label: 'Corguinho' },
  { data: '5003157', label: 'Coronel Sapucaia' },
  { data: '5003207', label: 'Corumbá' },
  { data: '5003256', label: 'Costa Rica' },
  { data: '5003306', label: 'Coxim' },
  { data: '5003454', label: 'Deodápolis' },
  { data: '5003488', label: 'Dois Irmãos do Buriti' },
  { data: '5003504', label: 'Douradina' },
  { data: '5003702', label: 'Dourados' },
  { data: '5003751', label: 'Eldorado' },
  { data: '5003801', label: 'Fátima do Sul' },
  { data: '5003900', label: 'Figueirão' },
  { data: '5004007', label: 'Glória de Dourados' },
  { data: '5004106', label: 'Guia Lopes da Laguna' },
  { data: '5004304', label: 'Iguatemi' },
  { data: '5004403', label: 'Inocência' },
  { data: '5004502', label: 'Itaporã' },
  { data: '5004601', label: 'Itaquiraí' },
  { data: '5004700', label: 'Ivinhema' },
  { data: '5004809', label: 'Japorã' },
  { data: '5004908', label: 'Jaraguari' },
  { data: '5005004', label: 'Jardim' },
  { data: '5005103', label: 'Jateí' },
  { data: '5005152', label: 'Juti' },
  { data: '5005202', label: 'Ladário' },
  { data: '5005251', label: 'Laguna Carapã' },
  { data: '5005400', label: 'Maracaju' },
  { data: '5005608', label: 'Miranda' },
  { data: '5005681', label: 'Mundo Novo' },
  { data: '5005707', label: 'Naviraí' },
  { data: '5005806', label: 'Nioaque' },
  { data: '5006002', label: 'Nova Alvorada do Sul' },
  { data: '5006200', label: 'Nova Andradina' },
  { data: '5006259', label: 'Novo Horizonte do Sul' },
  { data: '5006275', label: 'Paraíso das Águas' },
  { data: '5006309', label: 'Paranaíba' },
  { data: '5006358', label: 'Paranhos' },
  { data: '5006408', label: 'Pedro Gomes' },
  { data: '5006606', label: 'Ponta Porã' },
  { data: '5006903', label: 'Porto Murtinho' },
  { data: '5007109', label: 'Ribas do Rio Pardo' },
  { data: '5007208', label: 'Rio Brilhante' },
  { data: '5007307', label: 'Rio Negro' },
  { data: '5007406', label: 'Rio Verde de Mato Grosso' },
  { data: '5007505', label: 'Rochedo' },
  { data: '5007554', label: 'Santa Rita do Pardo' },
  { data: '5007695', label: 'São Gabriel do Oeste' },
  { data: '5007802', label: 'Selvíria' },
  { data: '5007703', label: 'Sete Quedas' },
  { data: '5007901', label: 'Sidrolândia' },
  { data: '5007935', label: 'Sonora' },
  { data: '5007950', label: 'Tacuru' },
  { data: '5007976', label: 'Taquarussu' },
  { data: '5008008', label: 'Terenos' },
  { data: '5008305', label: 'Três Lagoas' },
  { data: '5008404', label: 'Vicentina' },
];

export default ms;
