import { stringSorter } from '../sorters';

const onKeyEnterTableCell = (event, type, enterLinha) => {
  if (!event.target || !event.target.form) return;

  const elements = Array.from(event.target.form.elements).filter(
    item => item.type === 'text' && !item.disabled && item.className !== 'ant-select-selection-search-input'
  );

  if (enterLinha) {
    elements.sort((a, b) => stringSorter(a.name, b.name));
  }
  const moveNext = elements.findIndex(item => item === event.target);

  if (moveNext > -1 && elements[moveNext + 1]) {
    elements[moveNext + 1].focus();
    elements[moveNext + 1].select();
  } else {
    event.target.blur();
  }
};

export default onKeyEnterTableCell;
