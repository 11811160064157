import React from 'react';
import { Collapse, UploadAnexo } from '~/components';

const Anexo = React.memo(({ record, table, color, disabled, badge = 0 }) => (
  <Collapse type="form" color={color || '#f6ffed'} style={{ display: 'block' }}>
    <Collapse.Panel header="Anexos" badge={badge} key="anexos" disabled={disabled || !record} className="no-background" style={{ padding: '0' }}>
      <UploadAnexo disabled={disabled || !record} record={record} table={table} />
    </Collapse.Panel>
  </Collapse>
));

export default Anexo;
