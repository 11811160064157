// Pará
const pa = [
  { data: '1500107', label: 'Abaetetuba' },
  { data: '1500131', label: 'Abel Figueiredo' },
  { data: '1500206', label: 'Acará' },
  { data: '1500305', label: 'Afuá' },
  { data: '1500347', label: 'Água Azul do Norte' },
  { data: '1500404', label: 'Alenquer' },
  { data: '1500503', label: 'Almeirim' },
  { data: '1500602', label: 'Altamira' },
  { data: '1500701', label: 'Anajás' },
  { data: '1500800', label: 'Ananindeua' },
  { data: '1500859', label: 'Anapu' },
  { data: '1500909', label: 'Augusto Corrêa' },
  { data: '1500958', label: 'Aurora do Pará' },
  { data: '1501006', label: 'Aveiro' },
  { data: '1501105', label: 'Bagre' },
  { data: '1501204', label: 'Baião' },
  { data: '1501253', label: 'Bannach' },
  { data: '1501303', label: 'Barcarena' },
  { data: '1501402', label: 'Belém' },
  { data: '1501451', label: 'Belterra' },
  { data: '1501501', label: 'Benevides' },
  { data: '1501576', label: 'Bom Jesus do Tocantins' },
  { data: '1501600', label: 'Bonito' },
  { data: '1501709', label: 'Bragança' },
  { data: '1501725', label: 'Brasil Novo' },
  { data: '1501758', label: 'Brejo Grande do Araguaia' },
  { data: '1501782', label: 'Breu Branco' },
  { data: '1501808', label: 'Breves' },
  { data: '1501907', label: 'Bujaru' },
  { data: '1502004', label: 'Cachoeira do Arari' },
  { data: '1501956', label: 'Cachoeira do Piriá' },
  { data: '1502103', label: 'Cametá' },
  { data: '1502152', label: 'Canaã dos Carajás' },
  { data: '1502202', label: 'Capanema' },
  { data: '1502301', label: 'Capitão Poço' },
  { data: '1502400', label: 'Castanhal' },
  { data: '1502509', label: 'Chaves' },
  { data: '1502608', label: 'Colares' },
  { data: '1502707', label: 'Conceição do Araguaia' },
  { data: '1502756', label: 'Concórdia do Pará' },
  { data: '1502764', label: 'Cumaru do Norte' },
  { data: '1502772', label: 'Curionópolis' },
  { data: '1502806', label: 'Curralinho' },
  { data: '1502855', label: 'Curuá' },
  { data: '1502905', label: 'Curuçá' },
  { data: '1502939', label: 'Dom Eliseu' },
  { data: '1502954', label: 'Eldorado dos Carajás' },
  { data: '1503002', label: 'Faro' },
  { data: '1503044', label: 'Floresta do Araguaia' },
  { data: '1503077', label: 'Garrafão do Norte' },
  { data: '1503093', label: 'Goianésia do Pará' },
  { data: '1503101', label: 'Gurupá' },
  { data: '1503200', label: 'Igarapé-Açu' },
  { data: '1503309', label: 'Igarapé-Miri' },
  { data: '1503408', label: 'Inhangapi' },
  { data: '1503457', label: 'Ipixuna do Pará' },
  { data: '1503507', label: 'Irituia' },
  { data: '1503606', label: 'Itaituba' },
  { data: '1503705', label: 'Itupiranga' },
  { data: '1503754', label: 'Jacareacanga' },
  { data: '1503804', label: 'Jacundá' },
  { data: '1503903', label: 'Juruti' },
  { data: '1504000', label: 'Limoeiro do Ajuru' },
  { data: '1504059', label: 'Mãe do Rio' },
  { data: '1504109', label: 'Magalhães Barata' },
  { data: '1504208', label: 'Marabá' },
  { data: '1504307', label: 'Maracanã' },
  { data: '1504406', label: 'Marapanim' },
  { data: '1504422', label: 'Marituba' },
  { data: '1504455', label: 'Medicilândia' },
  { data: '1504505', label: 'Melgaço' },
  { data: '1504604', label: 'Mocajuba' },
  { data: '1504703', label: 'Moju' },
  { data: '1504752', label: 'Mojuí dos Campos' },
  { data: '1504802', label: 'Monte Alegre' },
  { data: '1504901', label: 'Muaná' },
  { data: '1504950', label: 'Nova Esperança do Piriá' },
  { data: '1504976', label: 'Nova Ipixuna' },
  { data: '1505007', label: 'Nova Timboteua' },
  { data: '1505031', label: 'Novo Progresso' },
  { data: '1505064', label: 'Novo Repartimento' },
  { data: '1505106', label: 'Óbidos' },
  { data: '1505205', label: 'Oeiras do Pará' },
  { data: '1505304', label: 'Oriximiná' },
  { data: '1505403', label: 'Ourém' },
  { data: '1505437', label: 'Ourilândia do Norte' },
  { data: '1505486', label: 'Pacajá' },
  { data: '1505494', label: 'Palestina do Pará' },
  { data: '1505502', label: 'Paragominas' },
  { data: '1505536', label: 'Parauapebas' },
  { data: '1505551', label: "Pau D'Arco" },
  { data: '1505601', label: 'Peixe-Boi' },
  { data: '1505635', label: 'Piçarra' },
  { data: '1505650', label: 'Placas' },
  { data: '1505700', label: 'Ponta de Pedras' },
  { data: '1505809', label: 'Portel' },
  { data: '1505908', label: 'Porto de Moz' },
  { data: '1506005', label: 'Prainha' },
  { data: '1506104', label: 'Primavera' },
  { data: '1506112', label: 'Quatipuru' },
  { data: '1506138', label: 'Redenção' },
  { data: '1506161', label: 'Rio Maria' },
  { data: '1506187', label: 'Rondon do Pará' },
  { data: '1506195', label: 'Rurópolis' },
  { data: '1506203', label: 'Salinópolis' },
  { data: '1506302', label: 'Salvaterra' },
  { data: '1506351', label: 'Santa Bárbara do Pará' },
  { data: '1506401', label: 'Santa Cruz do Arari' },
  { data: '1506500', label: 'Santa Izabel do Pará' },
  { data: '1506559', label: 'Santa Luzia do Pará' },
  { data: '1506583', label: 'Santa Maria das Barreiras' },
  { data: '1506609', label: 'Santa Maria do Pará' },
  { data: '1506708', label: 'Santana do Araguaia' },
  { data: '1506807', label: 'Santarém' },
  { data: '1506906', label: 'Santarém Novo' },
  { data: '1507003', label: 'Santo Antônio do Tauá' },
  { data: '1507102', label: 'São Caetano de Odivelas' },
  { data: '1507151', label: 'São Domingos do Araguaia' },
  { data: '1507201', label: 'São Domingos do Capim' },
  { data: '1507300', label: 'São Félix do Xingu' },
  { data: '1507409', label: 'São Francisco do Pará' },
  { data: '1507458', label: 'São Geraldo do Araguaia' },
  { data: '1507466', label: 'São João da Ponta' },
  { data: '1507474', label: 'São João de Pirabas' },
  { data: '1507508', label: 'São João do Araguaia' },
  { data: '1507607', label: 'São Miguel do Guamá' },
  { data: '1507706', label: 'São Sebastião da Boa Vista' },
  { data: '1507755', label: 'Sapucaia' },
  { data: '1507805', label: 'Senador José Porfírio' },
  { data: '1507904', label: 'Soure' },
  { data: '1507953', label: 'Tailândia' },
  { data: '1507961', label: 'Terra Alta' },
  { data: '1507979', label: 'Terra Santa' },
  { data: '1508001', label: 'Tomé-Açu' },
  { data: '1508035', label: 'Tracuateua' },
  { data: '1508050', label: 'Trairão' },
  { data: '1508084', label: 'Tucumã' },
  { data: '1508100', label: 'Tucuruí' },
  { data: '1508126', label: 'Ulianópolis' },
  { data: '1508159', label: 'Uruará' },
  { data: '1508209', label: 'Vigia' },
  { data: '1508308', label: 'Viseu' },
  { data: '1508357', label: 'Vitória do Xingu' },
  { data: '1508407', label: 'Xinguara' },
];

export default pa;
