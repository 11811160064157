import React from 'react';
import { notification, Modal, Input } from 'antd';
import html2canvas from 'html2canvas';
import httpStatus from 'http-status-codes';
import { UserService } from '~/services';
import notifyErrorImage from './notifyErrorImage';

const errors = {
  [httpStatus.BAD_REQUEST]: 'O servidor rejeitou a solicitação!',
  [httpStatus.NOT_FOUND]: 'O endereço solicitado não existe!',
  [httpStatus.INTERNAL_SERVER_ERROR]: 'O servidor está reportando um erro!',
};

const notifyError = (error, showNotification = true) => {
  if (error.response) {
    const mensagem = error.response.str.replace('Uncaught Exception: ', '');
    let mensagemErro;
    if (mensagem.includes('Ocorreu um erro ao efetuar operação no banco de dados:') || (mensagem.includes('Erro: ') && mensagem.includes('QUERY'))) {
      mensagemErro = mensagem;
      if (process.env.NODE_ENV === 'production') {
        html2canvas(document.body).then(canvas => {
          const base64image = canvas.toDataURL('image/png');
          UserService.inserir_erro_imagem(mensagemErro, base64image);
        });
      }
    } else if ((mensagem.indexOf(': ') > -1 || mensagem.indexOf('.php:') > -1) && mensagem.indexOf(' in ') > -1) {
      const arrayErros = mensagem.split(' in ');
      if (arrayErros.length > 0) {
        [mensagemErro] = arrayErros;
      }
    } else mensagemErro = mensagem;

    Modal.warning({
      title: 'Atenção',
      okText: 'Fechar',
      content: (
        <Input.TextArea
          value={error.innerHtml ? <div dangerouslySetInnerHTML={{ __html: mensagemErro }} /> : mensagemErro}
          autoSize={{ minRows: 1, maxRows: 20 }}
          style={{ backgroundColor: '#fefefe', color: '#9b9b9d' }}
          disabled
        />
      ),
      centered: true,
      width: '800px',
    });
  } else if (showNotification) {
    if (process.env.NODE_ENV === 'production') {
      notifyErrorImage('ERRO DE SOLICITAÇÃO', JSON.stringify(error));
    }

    notification.error({
      message: 'Erro de solicitação',
      description: errors[error.status] || errors[httpStatus.NOT_FOUND],
    });
  }
};

export { errors };
export default notifyError;
