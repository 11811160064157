// Rio Grande do Sul
const rs = [
  { data: '4300034', label: 'Aceguá' },
  { data: '4300059', label: 'Água Santa' },
  { data: '4300109', label: 'Agudo' },
  { data: '4300208', label: 'Ajuricaba' },
  { data: '4300307', label: 'Alecrim' },
  { data: '4300406', label: 'Alegrete' },
  { data: '4300455', label: 'Alegria' },
  { data: '4300471', label: 'Almirante Tamandaré do Sul' },
  { data: '4300505', label: 'Alpestre' },
  { data: '4300554', label: 'Alto Alegre' },
  { data: '4300570', label: 'Alto Feliz' },
  { data: '4300604', label: 'Alvorada' },
  { data: '4300638', label: 'Amaral Ferrador' },
  { data: '4300646', label: 'Ametista do Sul' },
  { data: '4300661', label: 'André da Rocha' },
  { data: '4300703', label: 'Anta Gorda' },
  { data: '4300802', label: 'Antônio Prado' },
  { data: '4300851', label: 'Arambaré' },
  { data: '4300877', label: 'Araricá' },
  { data: '4300901', label: 'Aratiba' },
  { data: '4301008', label: 'Arroio do Meio' },
  { data: '4301073', label: 'Arroio do Padre' },
  { data: '4301057', label: 'Arroio do Sal' },
  { data: '4301206', label: 'Arroio do Tigre' },
  { data: '4301107', label: 'Arroio dos Ratos' },
  { data: '4301305', label: 'Arroio Grande' },
  { data: '4301404', label: 'Arvorezinha' },
  { data: '4314548', label: 'Pinto Bandeira' },
  { data: '4301503', label: 'Augusto Pestana' },
  { data: '4301552', label: 'Áurea' },
  { data: '4301602', label: 'Bagé' },
  { data: '4301636', label: 'Balneário Pinhal' },
  { data: '4301651', label: 'Barão' },
  { data: '4301701', label: 'Barão de Cotegipe' },
  { data: '4301750', label: 'Barão do Triunfo' },
  { data: '4301859', label: 'Barra do Guarita' },
  { data: '4301875', label: 'Barra do Quaraí' },
  { data: '4301909', label: 'Barra do Ribeiro' },
  { data: '4301925', label: 'Barra do Rio Azul' },
  { data: '4301958', label: 'Barra Funda' },
  { data: '4301800', label: 'Barracão' },
  { data: '4302006', label: 'Barros Cassal' },
  { data: '4302055', label: 'Benjamin Constant do Sul' },
  { data: '4302105', label: 'Bento Gonçalves' },
  { data: '4302154', label: 'Boa Vista das Missões' },
  { data: '4302204', label: 'Boa Vista do Buricá' },
  { data: '4302220', label: 'Boa Vista do Cadeado' },
  { data: '4302238', label: 'Boa Vista do Incra' },
  { data: '4302253', label: 'Boa Vista do Sul' },
  { data: '4302303', label: 'Bom Jesus' },
  { data: '4302352', label: 'Bom Princípio' },
  { data: '4302378', label: 'Bom Progresso' },
  { data: '4302402', label: 'Bom Retiro do Sul' },
  { data: '4302451', label: 'Boqueirão do Leão' },
  { data: '4302501', label: 'Bossoroca' },
  { data: '4302584', label: 'Bozano' },
  { data: '4302600', label: 'Braga' },
  { data: '4302659', label: 'Brochier' },
  { data: '4302709', label: 'Butiá' },
  { data: '4302808', label: 'Caçapava do Sul' },
  { data: '4302907', label: 'Cacequi' },
  { data: '4303004', label: 'Cachoeira do Sul' },
  { data: '4303103', label: 'Cachoeirinha' },
  { data: '4303202', label: 'Cacique Doble' },
  { data: '4303301', label: 'Caibaté' },
  { data: '4303400', label: 'Caiçara' },
  { data: '4303509', label: 'Camaquã' },
  { data: '4303558', label: 'Camargo' },
  { data: '4303608', label: 'Cambará do Sul' },
  { data: '4303673', label: 'Campestre da Serra' },
  { data: '4303707', label: 'Campina das Missões' },
  { data: '4303806', label: 'Campinas do Sul' },
  { data: '4303905', label: 'Campo Bom' },
  { data: '4304002', label: 'Campo Novo' },
  { data: '4304101', label: 'Campos Borges' },
  { data: '4304200', label: 'Candelária' },
  { data: '4304309', label: 'Cândido Godói' },
  { data: '4304358', label: 'Candiota' },
  { data: '4304408', label: 'Canela' },
  { data: '4304507', label: 'Canguçu' },
  { data: '4304606', label: 'Canoas' },
  { data: '4304614', label: 'Canudos do Vale' },
  { data: '4304622', label: 'Capão Bonito do Sul' },
  { data: '4304630', label: 'Capão da Canoa' },
  { data: '4304655', label: 'Capão do Cipó' },
  { data: '4304663', label: 'Capão do Leão' },
  { data: '4304689', label: 'Capela de Santana' },
  { data: '4304697', label: 'Capitão' },
  { data: '4304671', label: 'Capivari do Sul' },
  { data: '4304713', label: 'Caraá' },
  { data: '4304705', label: 'Carazinho' },
  { data: '4304804', label: 'Carlos Barbosa' },
  { data: '4304853', label: 'Carlos Gomes' },
  { data: '4304903', label: 'Casca' },
  { data: '4304952', label: 'Caseiros' },
  { data: '4305009', label: 'Catuípe' },
  { data: '4305108', label: 'Caxias do Sul' },
  { data: '4305116', label: 'Centenário' },
  { data: '4305124', label: 'Cerrito' },
  { data: '4305132', label: 'Cerro Branco' },
  { data: '4305157', label: 'Cerro Grande' },
  { data: '4305173', label: 'Cerro Grande do Sul' },
  { data: '4305207', label: 'Cerro Largo' },
  { data: '4305306', label: 'Chapada' },
  { data: '4305355', label: 'Charqueadas' },
  { data: '4305371', label: 'Charrua' },
  { data: '4305405', label: 'Chiapetta' },
  { data: '4305439', label: 'Chuí' },
  { data: '4305447', label: 'Chuvisca' },
  { data: '4305454', label: 'Cidreira' },
  { data: '4305504', label: 'Ciríaco' },
  { data: '4305587', label: 'Colinas' },
  { data: '4305603', label: 'Colorado' },
  { data: '4305702', label: 'Condor' },
  { data: '4305801', label: 'Constantina' },
  { data: '4305835', label: 'Coqueiro Baixo' },
  { data: '4305850', label: 'Coqueiros do Sul' },
  { data: '4305871', label: 'Coronel Barros' },
  { data: '4305900', label: 'Coronel Bicaco' },
  { data: '4305934', label: 'Coronel Pilar' },
  { data: '4305959', label: 'Cotiporã' },
  { data: '4305975', label: 'Coxilha' },
  { data: '4306007', label: 'Crissiumal' },
  { data: '4306056', label: 'Cristal' },
  { data: '4306072', label: 'Cristal do Sul' },
  { data: '4306106', label: 'Cruz Alta' },
  { data: '4306130', label: 'Cruzaltense' },
  { data: '4306205', label: 'Cruzeiro do Sul' },
  { data: '4306304', label: 'David Canabarro' },
  { data: '4306320', label: 'Derrubadas' },
  { data: '4306353', label: 'Dezesseis de Novembro' },
  { data: '4306379', label: 'Dilermando de Aguiar' },
  { data: '4306403', label: 'Dois Irmãos' },
  { data: '4306429', label: 'Dois Irmãos das Missões' },
  { data: '4306452', label: 'Dois Lajeados' },
  { data: '4306502', label: 'Dom Feliciano' },
  { data: '4306601', label: 'Dom Pedrito' },
  { data: '4306551', label: 'Dom Pedro de Alcântara' },
  { data: '4306700', label: 'Dona Francisca' },
  { data: '4306734', label: 'Doutor Maurício Cardoso' },
  { data: '4306759', label: 'Doutor Ricardo' },
  { data: '4306767', label: 'Eldorado do Sul' },
  { data: '4306809', label: 'Encantado' },
  { data: '4306908', label: 'Encruzilhada do Sul' },
  { data: '4306924', label: 'Engenho Velho' },
  { data: '4306957', label: 'Entre Rios do Sul' },
  { data: '4306932', label: 'Entre-Ijuís' },
  { data: '4306973', label: 'Erebango' },
  { data: '4307005', label: 'Erechim' },
  { data: '4307054', label: 'Ernestina' },
  { data: '4307203', label: 'Erval Grande' },
  { data: '4307302', label: 'Erval Seco' },
  { data: '4307401', label: 'Esmeralda' },
  { data: '4307450', label: 'Esperança do Sul' },
  { data: '4307500', label: 'Espumoso' },
  { data: '4307559', label: 'Estação' },
  { data: '4307609', label: 'Estância Velha' },
  { data: '4307708', label: 'Esteio' },
  { data: '4307807', label: 'Estrela' },
  { data: '4307815', label: 'Estrela Velha' },
  { data: '4307831', label: 'Eugênio de Castro' },
  { data: '4307864', label: 'Fagundes Varela' },
  { data: '4307906', label: 'Farroupilha' },
  { data: '4308003', label: 'Faxinal do Soturno' },
  { data: '4308052', label: 'Faxinalzinho' },
  { data: '4308078', label: 'Fazenda Vilanova' },
  { data: '4308102', label: 'Feliz' },
  { data: '4308201', label: 'Flores da Cunha' },
  { data: '4308250', label: 'Floriano Peixoto' },
  { data: '4308300', label: 'Fontoura Xavier' },
  { data: '4308409', label: 'Formigueiro' },
  { data: '4308433', label: 'Forquetinha' },
  { data: '4308458', label: 'Fortaleza dos Valos' },
  { data: '4308508', label: 'Frederico Westphalen' },
  { data: '4308607', label: 'Garibaldi' },
  { data: '4308656', label: 'Garruchos' },
  { data: '4308706', label: 'Gaurama' },
  { data: '4308805', label: 'General Câmara' },
  { data: '4308854', label: 'Gentil' },
  { data: '4308904', label: 'Getúlio Vargas' },
  { data: '4309001', label: 'Giruá' },
  { data: '4309050', label: 'Glorinha' },
  { data: '4309100', label: 'Gramado' },
  { data: '4309126', label: 'Gramado dos Loureiros' },
  { data: '4309159', label: 'Gramado Xavier' },
  { data: '4309209', label: 'Gravataí' },
  { data: '4309258', label: 'Guabiju' },
  { data: '4309308', label: 'Guaíba' },
  { data: '4309407', label: 'Guaporé' },
  { data: '4309506', label: 'Guarani das Missões' },
  { data: '4309555', label: 'Harmonia' },
  { data: '4307104', label: 'Herval' },
  { data: '4309571', label: 'Herveiras' },
  { data: '4309605', label: 'Horizontina' },
  { data: '4309654', label: 'Hulha Negra' },
  { data: '4309704', label: 'Humaitá' },
  { data: '4309753', label: 'Ibarama' },
  { data: '4309803', label: 'Ibiaçá' },
  { data: '4309902', label: 'Ibiraiaras' },
  { data: '4309951', label: 'Ibirapuitã' },
  { data: '4310009', label: 'Ibirubá' },
  { data: '4310108', label: 'Igrejinha' },
  { data: '4310207', label: 'Ijuí' },
  { data: '4310306', label: 'Ilópolis' },
  { data: '4310330', label: 'Imbé' },
  { data: '4310363', label: 'Imigrante' },
  { data: '4310405', label: 'Independência' },
  { data: '4310413', label: 'Inhacorá' },
  { data: '4310439', label: 'Ipê' },
  { data: '4310462', label: 'Ipiranga do Sul' },
  { data: '4310504', label: 'Iraí' },
  { data: '4310538', label: 'Itaara' },
  { data: '4310553', label: 'Itacurubi' },
  { data: '4310579', label: 'Itapuca' },
  { data: '4310603', label: 'Itaqui' },
  { data: '4310652', label: 'Itati' },
  { data: '4310702', label: 'Itatiba do Sul' },
  { data: '4310751', label: 'Ivorá' },
  { data: '4310801', label: 'Ivoti' },
  { data: '4310850', label: 'Jaboticaba' },
  { data: '4310876', label: 'Jacuizinho' },
  { data: '4310900', label: 'Jacutinga' },
  { data: '4311007', label: 'Jaguarão' },
  { data: '4311106', label: 'Jaguari' },
  { data: '4311122', label: 'Jaquirana' },
  { data: '4311130', label: 'Jari' },
  { data: '4311155', label: 'Jóia' },
  { data: '4311205', label: 'Júlio de Castilhos' },
  { data: '4311239', label: 'Lagoa Bonita do Sul' },
  { data: '4311270', label: 'Lagoa dos Três Cantos' },
  { data: '4311304', label: 'Lagoa Vermelha' },
  { data: '4311254', label: 'Lagoão' },
  { data: '4311403', label: 'Lajeado' },
  { data: '4311429', label: 'Lajeado do Bugre' },
  { data: '4311502', label: 'Lavras do Sul' },
  { data: '4311601', label: 'Liberato Salzano' },
  { data: '4311627', label: 'Lindolfo Collor' },
  { data: '4311643', label: 'Linha Nova' },
  { data: '4311718', label: 'Maçambara' },
  { data: '4311700', label: 'Machadinho' },
  { data: '4311734', label: 'Mampituba' },
  { data: '4311759', label: 'Manoel Viana' },
  { data: '4311775', label: 'Maquiné' },
  { data: '4311791', label: 'Maratá' },
  { data: '4311809', label: 'Marau' },
  { data: '4311908', label: 'Marcelino Ramos' },
  { data: '4311981', label: 'Mariana Pimentel' },
  { data: '4312005', label: 'Mariano Moro' },
  { data: '4312054', label: 'Marques de Souza' },
  { data: '4312104', label: 'Mata' },
  { data: '4312138', label: 'Mato Castelhano' },
  { data: '4312153', label: 'Mato Leitão' },
  { data: '4312179', label: 'Mato Queimado' },
  { data: '4312203', label: 'Maximiliano de Almeida' },
  { data: '4312252', label: 'Minas do Leão' },
  { data: '4312302', label: 'Miraguaí' },
  { data: '4312351', label: 'Montauri' },
  { data: '4312377', label: 'Monte Alegre dos Campos' },
  { data: '4312385', label: 'Monte Belo do Sul' },
  { data: '4312401', label: 'Montenegro' },
  { data: '4312427', label: 'Mormaço' },
  { data: '4312443', label: 'Morrinhos do Sul' },
  { data: '4312450', label: 'Morro Redondo' },
  { data: '4312476', label: 'Morro Reuter' },
  { data: '4312500', label: 'Mostardas' },
  { data: '4312609', label: 'Muçum' },
  { data: '4312617', label: 'Muitos Capões' },
  { data: '4312625', label: 'Muliterno' },
  { data: '4312658', label: 'Não-Me-Toque' },
  { data: '4312674', label: 'Nicolau Vergueiro' },
  { data: '4312708', label: 'Nonoai' },
  { data: '4312757', label: 'Nova Alvorada' },
  { data: '4312807', label: 'Nova Araçá' },
  { data: '4312906', label: 'Nova Bassano' },
  { data: '4312955', label: 'Nova Boa Vista' },
  { data: '4313003', label: 'Nova Bréscia' },
  { data: '4313011', label: 'Nova Candelária' },
  { data: '4313037', label: 'Nova Esperança do Sul' },
  { data: '4313060', label: 'Nova Hartz' },
  { data: '4313086', label: 'Nova Pádua' },
  { data: '4313102', label: 'Nova Palma' },
  { data: '4313201', label: 'Nova Petrópolis' },
  { data: '4313300', label: 'Nova Prata' },
  { data: '4313334', label: 'Nova Ramada' },
  { data: '4313359', label: 'Nova Roma do Sul' },
  { data: '4313375', label: 'Nova Santa Rita' },
  { data: '4313490', label: 'Novo Barreiro' },
  { data: '4313391', label: 'Novo Cabrais' },
  { data: '4313409', label: 'Novo Hamburgo' },
  { data: '4313425', label: 'Novo Machado' },
  { data: '4313441', label: 'Novo Tiradentes' },
  { data: '4313466', label: 'Novo Xingu' },
  { data: '4313508', label: 'Osório' },
  { data: '4313607', label: 'Paim Filho' },
  { data: '4313656', label: 'Palmares do Sul' },
  { data: '4313706', label: 'Palmeira das Missões' },
  { data: '4313805', label: 'Palmitinho' },
  { data: '4313904', label: 'Panambi' },
  { data: '4313953', label: 'Pantano Grande' },
  { data: '4314001', label: 'Paraí' },
  { data: '4314027', label: 'Paraíso do Sul' },
  { data: '4314035', label: 'Pareci Novo' },
  { data: '4314050', label: 'Parobé' },
  { data: '4314068', label: 'Passa Sete' },
  { data: '4314076', label: 'Passo do Sobrado' },
  { data: '4314100', label: 'Passo Fundo' },
  { data: '4314134', label: 'Paulo Bento' },
  { data: '4314159', label: 'Paverama' },
  { data: '4314175', label: 'Pedras Altas' },
  { data: '4314209', label: 'Pedro Osório' },
  { data: '4314308', label: 'Pejuçara' },
  { data: '4314407', label: 'Pelotas' },
  { data: '4314423', label: 'Picada Café' },
  { data: '4314456', label: 'Pinhal' },
  { data: '4314464', label: 'Pinhal da Serra' },
  { data: '4314472', label: 'Pinhal Grande' },
  { data: '4314498', label: 'Pinheirinho do Vale' },
  { data: '4314506', label: 'Pinheiro Machado' },
  { data: '4314555', label: 'Pirapó' },
  { data: '4314605', label: 'Piratini' },
  { data: '4314704', label: 'Planalto' },
  { data: '4314753', label: 'Poço das Antas' },
  { data: '4314779', label: 'Pontão' },
  { data: '4314787', label: 'Ponte Preta' },
  { data: '4314803', label: 'Portão' },
  { data: '4314902', label: 'Porto Alegre' },
  { data: '4315008', label: 'Porto Lucena' },
  { data: '4315057', label: 'Porto Mauá' },
  { data: '4315073', label: 'Porto Vera Cruz' },
  { data: '4315107', label: 'Porto Xavier' },
  { data: '4315131', label: 'Pouso Novo' },
  { data: '4315149', label: 'Presidente Lucena' },
  { data: '4315156', label: 'Progresso' },
  { data: '4315172', label: 'Protásio Alves' },
  { data: '4315206', label: 'Putinga' },
  { data: '4315305', label: 'Quaraí' },
  { data: '4315313', label: 'Quatro Irmãos' },
  { data: '4315321', label: 'Quevedos' },
  { data: '4315354', label: 'Quinze de Novembro' },
  { data: '4315404', label: 'Redentora' },
  { data: '4315453', label: 'Relvado' },
  { data: '4315503', label: 'Restinga Seca' },
  { data: '4315552', label: 'Rio dos Índios' },
  { data: '4315602', label: 'Rio Grande' },
  { data: '4315701', label: 'Rio Pardo' },
  { data: '4315750', label: 'Riozinho' },
  { data: '4315800', label: 'Roca Sales' },
  { data: '4315909', label: 'Rodeio Bonito' },
  { data: '4315958', label: 'Rolador' },
  { data: '4316006', label: 'Rolante' },
  { data: '4316105', label: 'Ronda Alta' },
  { data: '4316204', label: 'Rondinha' },
  { data: '4316303', label: 'Roque Gonzales' },
  { data: '4316402', label: 'Rosário do Sul' },
  { data: '4316428', label: 'Sagrada Família' },
  { data: '4316436', label: 'Saldanha Marinho' },
  { data: '4316451', label: 'Salto do Jacuí' },
  { data: '4316477', label: 'Salvador das Missões' },
  { data: '4316501', label: 'Salvador do Sul' },
  { data: '4316600', label: 'Sananduva' },
  { data: '4316709', label: 'Santa Bárbara do Sul' },
  { data: '4316733', label: 'Santa Cecília do Sul' },
  { data: '4316758', label: 'Santa Clara do Sul' },
  { data: '4316808', label: 'Santa Cruz do Sul' },
  { data: '4316972', label: 'Santa Margarida do Sul' },
  { data: '4316907', label: 'Santa Maria' },
  { data: '4316956', label: 'Santa Maria do Herval' },
  { data: '4317202', label: 'Santa Rosa' },
  { data: '4317251', label: 'Santa Tereza' },
  { data: '4317301', label: 'Santa Vitória do Palmar' },
  { data: '4317004', label: 'Santana da Boa Vista' },
  { data: '4317103', label: 'Santana do Livramento' },
  { data: '4317400', label: 'Santiago' },
  { data: '4317509', label: 'Santo Ângelo' },
  { data: '4317608', label: 'Santo Antônio da Patrulha' },
  { data: '4317707', label: 'Santo Antônio das Missões' },
  { data: '4317558', label: 'Santo Antônio do Palma' },
  { data: '4317756', label: 'Santo Antônio do Planalto' },
  { data: '4317806', label: 'Santo Augusto' },
  { data: '4317905', label: 'Santo Cristo' },
  { data: '4317954', label: 'Santo Expedito do Sul' },
  { data: '4318002', label: 'São Borja' },
  { data: '4318051', label: 'São Domingos do Sul' },
  { data: '4318101', label: 'São Francisco de Assis' },
  { data: '4318200', label: 'São Francisco de Paula' },
  { data: '4318309', label: 'São Gabriel' },
  { data: '4318408', label: 'São Jerônimo' },
  { data: '4318424', label: 'São João da Urtiga' },
  { data: '4318432', label: 'São João do Polêsine' },
  { data: '4318440', label: 'São Jorge' },
  { data: '4318457', label: 'São José das Missões' },
  { data: '4318465', label: 'São José do Herval' },
  { data: '4318481', label: 'São José do Hortêncio' },
  { data: '4318499', label: 'São José do Inhacorá' },
  { data: '4318507', label: 'São José do Norte' },
  { data: '4318606', label: 'São José do Ouro' },
  { data: '4318614', label: 'São José do Sul' },
  { data: '4318622', label: 'São José dos Ausentes' },
  { data: '4318705', label: 'São Leopoldo' },
  { data: '4318804', label: 'São Lourenço do Sul' },
  { data: '4318903', label: 'São Luiz Gonzaga' },
  { data: '4319000', label: 'São Marcos' },
  { data: '4319109', label: 'São Martinho' },
  { data: '4319125', label: 'São Martinho da Serra' },
  { data: '4319158', label: 'São Miguel das Missões' },
  { data: '4319208', label: 'São Nicolau' },
  { data: '4319307', label: 'São Paulo das Missões' },
  { data: '4319356', label: 'São Pedro da Serra' },
  { data: '4319364', label: 'São Pedro das Missões' },
  { data: '4319372', label: 'São Pedro do Butiá' },
  { data: '4319406', label: 'São Pedro do Sul' },
  { data: '4319505', label: 'São Sebastião do Caí' },
  { data: '4319604', label: 'São Sepé' },
  { data: '4319703', label: 'São Valentim' },
  { data: '4319711', label: 'São Valentim do Sul' },
  { data: '4319737', label: 'São Valério do Sul' },
  { data: '4319752', label: 'São Vendelino' },
  { data: '4319802', label: 'São Vicente do Sul' },
  { data: '4319901', label: 'Sapiranga' },
  { data: '4320008', label: 'Sapucaia do Sul' },
  { data: '4320107', label: 'Sarandi' },
  { data: '4320206', label: 'Seberi' },
  { data: '4320230', label: 'Sede Nova' },
  { data: '4320263', label: 'Segredo' },
  { data: '4320305', label: 'Selbach' },
  { data: '4320321', label: 'Senador Salgado Filho' },
  { data: '4320354', label: 'Sentinela do Sul' },
  { data: '4320404', label: 'Serafina Corrêa' },
  { data: '4320453', label: 'Sério' },
  { data: '4320503', label: 'Sertão' },
  { data: '4320552', label: 'Sertão Santana' },
  { data: '4320578', label: 'Sete de Setembro' },
  { data: '4320602', label: 'Severiano de Almeida' },
  { data: '4320651', label: 'Silveira Martins' },
  { data: '4320677', label: 'Sinimbu' },
  { data: '4320701', label: 'Sobradinho' },
  { data: '4320800', label: 'Soledade' },
  { data: '4320859', label: 'Tabaí' },
  { data: '4320909', label: 'Tapejara' },
  { data: '4321006', label: 'Tapera' },
  { data: '4321105', label: 'Tapes' },
  { data: '4321204', label: 'Taquara' },
  { data: '4321303', label: 'Taquari' },
  { data: '4321329', label: 'Taquaruçu do Sul' },
  { data: '4321352', label: 'Tavares' },
  { data: '4321402', label: 'Tenente Portela' },
  { data: '4321436', label: 'Terra de Areia' },
  { data: '4321451', label: 'Teutônia' },
  { data: '4321469', label: 'Tio Hugo' },
  { data: '4321477', label: 'Tiradentes do Sul' },
  { data: '4321493', label: 'Toropi' },
  { data: '4321501', label: 'Torres' },
  { data: '4321600', label: 'Tramandaí' },
  { data: '4321626', label: 'Travesseiro' },
  { data: '4321634', label: 'Três Arroios' },
  { data: '4321667', label: 'Três Cachoeiras' },
  { data: '4321709', label: 'Três Coroas' },
  { data: '4321808', label: 'Três de Maio' },
  { data: '4321832', label: 'Três Forquilhas' },
  { data: '4321857', label: 'Três Palmeiras' },
  { data: '4321907', label: 'Três Passos' },
  { data: '4321956', label: 'Trindade do Sul' },
  { data: '4322004', label: 'Triunfo' },
  { data: '4322103', label: 'Tucunduva' },
  { data: '4322152', label: 'Tunas' },
  { data: '4322186', label: 'Tupanci do Sul' },
  { data: '4322202', label: 'Tupanciretã' },
  { data: '4322251', label: 'Tupandi' },
  { data: '4322301', label: 'Tuparendi' },
  { data: '4322327', label: 'Turuçu' },
  { data: '4322343', label: 'Ubiretama' },
  { data: '4322350', label: 'União da Serra' },
  { data: '4322376', label: 'Unistalda' },
  { data: '4322400', label: 'Uruguaiana' },
  { data: '4322509', label: 'Vacaria' },
  { data: '4322533', label: 'Vale do Sol' },
  { data: '4322541', label: 'Vale Real' },
  { data: '4322525', label: 'Vale Verde' },
  { data: '4322558', label: 'Vanini' },
  { data: '4322608', label: 'Venâncio Aires' },
  { data: '4322707', label: 'Vera Cruz' },
  { data: '4322806', label: 'Veranópolis' },
  { data: '4322855', label: 'Vespasiano Correa' },
  { data: '4322905', label: 'Viadutos' },
  { data: '4323002', label: 'Viamão' },
  { data: '4323101', label: 'Vicente Dutra' },
  { data: '4323200', label: 'Victor Graeff' },
  { data: '4323309', label: 'Vila Flores' },
  { data: '4323358', label: 'Vila Lângaro' },
  { data: '4323408', label: 'Vila Maria' },
  { data: '4323457', label: 'Vila Nova do Sul' },
  { data: '4323507', label: 'Vista Alegre' },
  { data: '4323606', label: 'Vista Alegre do Prata' },
  { data: '4323705', label: 'Vista Gaúcha' },
  { data: '4323754', label: 'Vitória das Missões' },
  { data: '4323770', label: 'Westfalia' },
  { data: '4323804', label: 'Xangri-lá' },
];

export default rs;
