import { Col, Row } from 'antd';
import React from 'react';
import { Page, Button, Form, ContentWrapper } from '~/components';
import { Input } from '~/components/forms';

class EditExportTableInsertView extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidUpdate(prevProps) {
    const { action } = this.props;
    const actionChange = action !== prevProps.action;

    if (actionChange && action) {
      if (this.saveApi) {
        this.input.focus();
      }
    }
  }

  render = () => {
    const { onCancel, action, onOk } = this.props;

    return (
      <>
        <Page.Modal
          visible={action}
          title="Salvar Layout de Exportação"
          width={300}
          onCancel={onCancel}
          forceRender
          centered
          keyboard={false}
          destroyOnClose
          footer={
            <div style={{ width: '100%', display: 'flex' }}>
              <div style={{ width: '100%', textAlign: 'left' }}>
                <Button.Save onClick={() => this.saveApi.submitForm()}>Salvar</Button.Save>
              </div>
              <div style={{ width: '100%', textAlign: 'right' }}>
                <Button onClick={onCancel}>Cancelar</Button>
              </div>
            </div>
          }
        >
          <Form getApi={api => (this.saveApi = api)} onSubmit={onOk}>
            <ContentWrapper type="search">
              <Row gutter={4}>
                <Col md={24}>
                  <Input field="nome" label="Nome" required capsLock={false} forwardedRef={ref => (this.input = ref)} />
                </Col>
              </Row>
            </ContentWrapper>
          </Form>
        </Page.Modal>
      </>
    );
  };
}

export default EditExportTableInsertView;
