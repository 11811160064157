import React from 'react';
import classNames from 'classnames';
import { useField } from 'informed';
import { Input, Select, Tooltip } from 'antd';
import { Form } from '~/components/forms';
import { isRequired } from '~/utils';
import { LabelForm } from '../utils';
import './InputNumber/InputNumber.less';
import { types } from './Input/options';

const { Option } = Select;

const SelectCombo = React.memo(({ required, validate, validateOnChange, ...props }) => {
  const { fieldState, fieldApi, render, userProps } = useField({
    validate: validate || (required && isRequired) || (() => undefined),
    validateOnChange: validateOnChange || required || validate,
    ...props,
  });

  const {
    onBlur,
    onChange,
    label,
    initialValue,
    forwardedRef,
    allowClear,
    style = {},
    formItemProps,
    dataSource,
    loading,
    dataIndexString,
    dataIndex = 'data',
    labelIndex = 'label',
    renderIndex = item => item[labelIndex],
    dataSize = '22%',
    labelSize = '78%',
    dataPlaceholder,
    labelPlaceholder,
    mode,
    searchIndex,
    disabledReason,
    tooltipProps,
    tooltip,
    focusDescricao = false,
    forceZero,
    enterCallback,
    ...rest
  } = userProps;

  function handleOnChange(changeValue) {
    fieldApi.setValue(changeValue);

    if (onChange) {
      const objValue = dataSource.find(item => item[dataIndex] === changeValue);
      onChange(changeValue, objValue);
    }
  }

  function handleOnBlurCodigo() {
    fieldApi.setTouched();
    const value = fieldApi.getValue();
    const objValue = dataSource ? dataSource.find(item => item[dataIndex].toString() === (value || '').toString()) : false;

    if (value && (!objValue || objValue.lenght === 0)) {
      fieldApi.setValue(undefined);
    } else if (onChange) {
      onChange('', undefined);
    }

    if (onChange) {
      if (objValue && objValue[dataIndex]) {
        fieldApi.setValue(value);
        onChange(value, objValue);
      }
    }
  }

  function handleOnBlur() {
    fieldApi.setTouched();
    const value = fieldApi.getValue();
    const objValue = dataSource ? dataSource.find(item => item[dataIndex].toString() === (value || '').toString()) : [];

    if (!objValue || objValue.lenght === 0) {
      fieldApi.setValue('');
    }

    if (onBlur) {
      onBlur(value, objValue);
    }
  }

  function onDataChange(changeValue) {
    fieldApi.setValue(
      dataIndexString ? types.integerDot.parser(changeValue.target.value || '') : types.integer.parser(changeValue.target.value || '').toString()
    );
  }

  return render(
    <Form.Item label={<LabelForm label={label} value={fieldState.value} />} error={fieldState.error} required={required} {...formItemProps}>
      <Tooltip title={(rest.disabled && disabledReason) || tooltip} placement="bottom" color="red" {...tooltipProps}>
        <Input.Group compact>
          <Input
            ref={focusDescricao ? null : forwardedRef}
            placeholder={dataPlaceholder}
            onChange={onDataChange}
            field="data-combo"
            value={forceZero && parseInt(fieldState.value, 10) === 0 ? 0 : fieldState.value || initialValue || ''}
            onBlur={handleOnBlurCodigo}
            className={classNames('input-number', 'text-center', 'input-number-nospinner')}
            style={{ width: dataSize, textAlign: 'center', ...style }}
            onPressEnter={() => {
              if (enterCallback) enterCallback();
            }}
            {...rest}
          />
          <Select
            ref={focusDescricao ? forwardedRef : null}
            style={{ width: labelSize, ...style }}
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            placeholder={labelPlaceholder}
            value={
              dataSource &&
              dataSource.find(item => parseInt(item[dataIndex], 10) === parseInt(fieldState.value, 10)) &&
              fieldState.value !== '' &&
              (fieldState.value !== '0' || (parseInt(fieldState.value, 10) === 0 && forceZero))
                ? fieldState.value
                : '' || initialValue
            }
            loading={loading}
            allowClear
            showSearch
            mode="default"
            optionFilterProp={'children'}
            {...rest}
          >
            {dataSource &&
              dataSource.map(item => (
                <Option value={item[dataIndex]} key={item[dataIndex]}>
                  {renderIndex(item)}
                </Option>
              ))}
          </Select>
        </Input.Group>
      </Tooltip>
    </Form.Item>
  );
});

Select.Option = Option;

export { Option };
export default SelectCombo;
