import React from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { GridContent } from '~/components';
import './FooterToolbar.less';

class FooterToolbar extends React.PureComponent {
  getFooterToolbarWidth = () => {
    const { settings, general } = this.props;

    if (general.isMobile || settings.layout === 'topmenu') {
      return '100%';
    }

    return general.isCollapsed ? 'calc(100% - 80px)' : 'calc(100% - 256px)';
  };

  render() {
    const { className, left, center, right, dispatch, type, general, ...rest } = this.props;

    return (
      <div
        className={classNames(type === 'modal' ? 'footer-toolbar-modal' : 'footer-toolbar', className, general.isMobile ? 'classMobile' : '')}
        style={{ width: this.getFooterToolbarWidth() }}
        {...rest}
      >
        <GridContent>
          {left && <div className="footer-toolbar-left">{left}</div>}
          {center && <div className="footer-toolbar-center">{center}</div>}
          {right && <div className="footer-toolbar-right">{right}</div>}
        </GridContent>
      </div>
    );
  }
}

const mapStateToProps = ({ general, settings }) => ({ general, settings });

export default connect(mapStateToProps)(FooterToolbar);
