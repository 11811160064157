import { HttpService } from '~/helpers';
import { UserService } from '~/services';

const endpoint = 'cadastro/motorista';

const formatFilters = filters =>
  filters
    ? [
        { nome_campo: 'a.idt_cliente', valor: UserService.getUser().codigo, tipo: 'int' },
        { nome_campo: 'a.cpf_fidelizado', valor: filters.cpf, tipo: 'texto' },
        { nome_campo: 'b.nome_fantasia', valor: filters.nome, tipo: 'texto' },
      ]
    : [];

const formatValues = values => ({
  ...values,
  usuario: UserService.getUser().login,
});

const MotoristaService = {
  getAll: (avancada, onSuccess, onError) => {
    HttpService.call(endpoint, 'getAll', [formatFilters(avancada)], onSuccess, onError);
  },
  salvarMotorista: (values, onSuccess, onError) => {
    HttpService.call(endpoint, 'salvarMotorista', [formatValues(values)], onSuccess, onError);
  },
  excluir: (idt, onSuccess, onError) => {
    HttpService.call(endpoint, 'excluir', [idt, UserService.getUser().login], onSuccess, onError);
  },
};

export default MotoristaService;
