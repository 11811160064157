import React from 'react';
import { Button as AntdButton, Tooltip } from 'antd';
import { createSelector } from 'reselect';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { LoadingOutlined, QuestionOutlined } from '@ant-design/icons';
import { Search, Insert, Cancel, Save, Update, Default, Print } from './components';
import { mobile } from './options';
import './Button.less';

const { Group } = AntdButton;

const Button = React.memo(
  ({
    children,
    onClick,
    dispatch,
    onMobile = mobile.show.id,
    loading,
    isMobile,
    validate,
    className,
    icon,
    forceLabel,
    block,
    disabledReason,
    tooltip,
    ...rest
  }) => {
    const notMobile = onMobile === mobile.show.id || !isMobile;

    const button = (
      <AntdButton
        onClick={event => {
          if (!validate || validate()) {
            if (onClick) {
              onClick(event);
            }
          }
        }}
        icon={notMobile ? icon : undefined}
        loading={notMobile && loading}
        className={classNames('button', { 'button-loading': !notMobile && loading }, className, block ? 'button-block' : '')}
        {...rest}
      >
        {notMobile || forceLabel ? children : (icon || loading) && (loading ? <LoadingOutlined /> : icon || <QuestionOutlined />)}
      </AntdButton>
    );

    return (rest.disabled && disabledReason) || tooltip ? (
      <Tooltip title={(rest.disabled && disabledReason) || tooltip} color="red" placement="top" className="button-tooltip">
        {button}
      </Tooltip>
    ) : (
      button
    );
  }
);

Button.Search = Search;
Button.Insert = Insert;
Button.Cancel = Cancel;
Button.Save = Save;
Button.Update = Update;
Button.Group = Group;
Button.Default = Default;
Button.Print = Print;

const isMobileSelector = createSelector(
  general => general,
  ({ isMobile }) => isMobile
);

const mapStateToProps = ({ general }) => ({ isMobile: isMobileSelector(general) });

export { Search, Insert, Cancel, Save, Update, Group, Default };
export default connect(mapStateToProps)(Button);
