// Tocantins
const to = [
  { data: '1700251', label: 'Abreulândia' },
  { data: '1700301', label: 'Aguiarnópolis' },
  { data: '1700350', label: 'Aliança do Tocantins' },
  { data: '1700400', label: 'Almas' },
  { data: '1700707', label: 'Alvorada' },
  { data: '1701002', label: 'Ananás' },
  { data: '1701051', label: 'Angico' },
  { data: '1701101', label: 'Aparecida do Rio Negro' },
  { data: '1701309', label: 'Aragominas' },
  { data: '1701903', label: 'Araguacema' },
  { data: '1702000', label: 'Araguaçu' },
  { data: '1702109', label: 'Araguaína' },
  { data: '1702158', label: 'Araguanã' },
  { data: '1702208', label: 'Araguatins' },
  { data: '1702307', label: 'Arapoema' },
  { data: '1702406', label: 'Arraias' },
  { data: '1702554', label: 'Augustinópolis' },
  { data: '1702703', label: 'Aurora do Tocantins' },
  { data: '1702901', label: 'Axixá do Tocantins' },
  { data: '1703008', label: 'Babaçulândia' },
  { data: '1703057', label: 'Bandeirantes do Tocantins' },
  { data: '1703073', label: 'Barra do Ouro' },
  { data: '1703107', label: 'Barrolândia' },
  { data: '1703206', label: 'Bernardo Sayão' },
  { data: '1703305', label: 'Bom Jesus do Tocantins' },
  { data: '1703602', label: 'Brasilândia do Tocantins' },
  { data: '1703701', label: 'Brejinho de Nazaré' },
  { data: '1703800', label: 'Buriti do Tocantins' },
  { data: '1703826', label: 'Cachoeirinha' },
  { data: '1703842', label: 'Campos Lindos' },
  { data: '1703867', label: 'Cariri do Tocantins' },
  { data: '1703883', label: 'Carmolândia' },
  { data: '1703891', label: 'Carrasco Bonito' },
  { data: '1703909', label: 'Caseara' },
  { data: '1704105', label: 'Centenário' },
  { data: '1705102', label: 'Chapada da Natividade' },
  { data: '1704600', label: 'Chapada de Areia' },
  { data: '1705508', label: 'Colinas do Tocantins' },
  { data: '1716703', label: 'Colméia' },
  { data: '1705557', label: 'Combinado' },
  { data: '1705607', label: 'Conceição do Tocantins' },
  { data: '1706001', label: 'Couto de Magalhães' },
  { data: '1706100', label: 'Cristalândia' },
  { data: '1706258', label: 'Crixás do Tocantins' },
  { data: '1706506', label: 'Darcinópolis' },
  { data: '1707009', label: 'Dianópolis' },
  { data: '1707108', label: 'Divinópolis do Tocantins' },
  { data: '1707207', label: 'Dois Irmãos do Tocantins' },
  { data: '1707306', label: 'Dueré' },
  { data: '1707405', label: 'Esperantina' },
  { data: '1707553', label: 'Fátima' },
  { data: '1707652', label: 'Figueirópolis' },
  { data: '1707702', label: 'Filadélfia' },
  { data: '1708205', label: 'Formoso do Araguaia' },
  { data: '1708254', label: 'Fortaleza do Tabocão' },
  { data: '1708304', label: 'Goianorte' },
  { data: '1709005', label: 'Goiatins' },
  { data: '1709302', label: 'Guaraí' },
  { data: '1709500', label: 'Gurupi' },
  { data: '1709807', label: 'Ipueiras' },
  { data: '1710508', label: 'Itacajá' },
  { data: '1710706', label: 'Itaguatins' },
  { data: '1710904', label: 'Itapiratins' },
  { data: '1711100', label: 'Itaporã do Tocantins' },
  { data: '1711506', label: 'Jaú do Tocantins' },
  { data: '1711803', label: 'Juarina' },
  { data: '1711902', label: 'Lagoa da Confusão' },
  { data: '1711951', label: 'Lagoa do Tocantins' },
  { data: '1712009', label: 'Lajeado' },
  { data: '1712157', label: 'Lavandeira' },
  { data: '1712405', label: 'Lizarda' },
  { data: '1712454', label: 'Luzinópolis' },
  { data: '1712504', label: 'Marianópolis do Tocantins' },
  { data: '1712702', label: 'Mateiros' },
  { data: '1712801', label: 'Maurilândia do Tocantins' },
  { data: '1713205', label: 'Miracema do Tocantins' },
  { data: '1713304', label: 'Miranorte' },
  { data: '1713601', label: 'Monte do Carmo' },
  { data: '1713700', label: 'Monte Santo do Tocantins' },
  { data: '1713957', label: 'Muricilândia' },
  { data: '1714203', label: 'Natividade' },
  { data: '1714302', label: 'Nazaré' },
  { data: '1714880', label: 'Nova Olinda' },
  { data: '1715002', label: 'Nova Rosalândia' },
  { data: '1715101', label: 'Novo Acordo' },
  { data: '1715150', label: 'Novo Alegre' },
  { data: '1715259', label: 'Novo Jardim' },
  { data: '1715507', label: 'Oliveira de Fátima' },
  { data: '1721000', label: 'Palmas' },
  { data: '1715705', label: 'Palmeirante' },
  { data: '1713809', label: 'Palmeiras do Tocantins' },
  { data: '1715754', label: 'Palmeirópolis' },
  { data: '1716109', label: 'Paraíso do Tocantins' },
  { data: '1716208', label: 'Paranã' },
  { data: '1716307', label: "Pau D'Arco" },
  { data: '1716505', label: 'Pedro Afonso' },
  { data: '1716604', label: 'Peixe' },
  { data: '1716653', label: 'Pequizeiro' },
  { data: '1717008', label: 'Pindorama do Tocantins' },
  { data: '1717206', label: 'Piraquê' },
  { data: '1717503', label: 'Pium' },
  { data: '1717800', label: 'Ponte Alta do Bom Jesus' },
  { data: '1717909', label: 'Ponte Alta do Tocantins' },
  { data: '1718006', label: 'Porto Alegre do Tocantins' },
  { data: '1718204', label: 'Porto Nacional' },
  { data: '1718303', label: 'Praia Norte' },
  { data: '1718402', label: 'Presidente Kennedy' },
  { data: '1718451', label: 'Pugmil' },
  { data: '1718501', label: 'Recursolândia' },
  { data: '1718550', label: 'Riachinho' },
  { data: '1718659', label: 'Rio da Conceição' },
  { data: '1718709', label: 'Rio dos Bois' },
  { data: '1718758', label: 'Rio Sono' },
  { data: '1718808', label: 'Sampaio' },
  { data: '1718840', label: 'Sandolândia' },
  { data: '1718865', label: 'Santa Fé do Araguaia' },
  { data: '1718881', label: 'Santa Maria do Tocantins' },
  { data: '1718899', label: 'Santa Rita do Tocantins' },
  { data: '1718907', label: 'Santa Rosa do Tocantins' },
  { data: '1719004', label: 'Santa Tereza do Tocantins' },
  { data: '1720002', label: 'Santa Terezinha do Tocantins' },
  { data: '1720101', label: 'São Bento do Tocantins' },
  { data: '1720150', label: 'São Félix do Tocantins' },
  { data: '1720200', label: 'São Miguel do Tocantins' },
  { data: '1720259', label: 'São Salvador do Tocantins' },
  { data: '1720309', label: 'São Sebastião do Tocantins' },
  { data: '1720499', label: 'São Valério da Natividade' },
  { data: '1720655', label: 'Silvanópolis' },
  { data: '1720804', label: 'Sítio Novo do Tocantins' },
  { data: '1720853', label: 'Sucupira' },
  { data: '1720903', label: 'Taguatinga' },
  { data: '1720937', label: 'Taipas do Tocantins' },
  { data: '1720978', label: 'Talismã' },
  { data: '1721109', label: 'Tocantínia' },
  { data: '1721208', label: 'Tocantinópolis' },
  { data: '1721257', label: 'Tupirama' },
  { data: '1721307', label: 'Tupiratins' },
  { data: '1722081', label: 'Wanderlândia' },
  { data: '1722107', label: 'Xambioá' },
];

export default to;
