import React from 'react';
import { Row, Col } from 'antd';
import { Input, Form, Button, Table, FooterToolbar, Page, ContentWrapper, Divider, Loading } from '~/components';
import { ChequeService } from '~/services';

class ChequePage extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      itens: [],
      selectedItem: {},
      isLoadingImagem: false,
      srcImgFrente: '',
      srcImgVerso: '',
    };
  }

  componentDidMount() {
    this.searchInput.focus();
  }

  handleSearch = values => {
    this.setState({ isLoading: true, srcImgFrente: '', srcImgVerso: '' });
    ChequeService.getAll(
      values,
      itens => {
        this.setState({ itens, isLoading: false });
        if (itens.length > 0 && parseInt(itens[0].idtImagem, 10) > 0) {
          this.setState({ isLoadingImagem: true });
          ChequeService.getAllImagem(
            itens[0].idtImagem,
            response => {
              this.setState({
                isLoadingImagem: false,
                srcImgFrente: `data:image/png;base64,${response.front}`,
                srcImgVerso: `data:image/png;base64,${response.back}`,
              });
            },
            () => this.setState({ isLoadingImagem: false })
          );
        }
      },
      () => this.setState({ isLoading: false })
    );
  };

  render() {
    const { tabId } = this.props;
    const { isLoading, itens, selectedItem, isLoadingImagem, srcImgFrente, srcImgVerso } = this.state;

    return (
      <>
        <Page tabId={tabId}>
          <Form getApi={api => (this.searchApi = api)} onSubmit={this.handleSearch}>
            <ContentWrapper type="search">
              <Row gutter={4}>
                <Col md={4} span={12}>
                  <Input field="banco" label="Código do Banco" required disabled={isLoading} forwardedRef={ref => (this.searchInput = ref)} />
                </Col>
                <Col md={4} span={12}>
                  <Input field="Conta" label="Conta" disabled={isLoading} required />
                </Col>
                <Col md={4} span={12}>
                  <Input field="numero" label="Número do Cheque" disabled={isLoading} required />
                </Col>
                <Col md={12} span={12}>
                  <Button.Search loading={isLoading} />
                </Col>
              </Row>
            </ContentWrapper>
          </Form>
          <Table
            dataSource={itens}
            rowKey="idt"
            loading={isLoading}
            record={selectedItem}
            onRecordChange={record => this.setState({ selectedItem: record })}
            description={item => (
              <Row>
                <Col span={16}>
                  <span style={{ fontSize: '0.8rem' }}>{item.cnpj}</span>
                </Col>
                <Col span={8}>
                  <span>{item.numero}</span>
                </Col>
              </Row>
            )}
            height={window.innerHeight - 360}
          >
            <Table.Column title="Banda" dataIndex="banda" width="25%" align="center" />
            <Table.Column title="Número" dataIndex="numero" width="10%" />
            <Table.Column title="Banco" dataIndex="banco" width="5%" />
            <Table.Column title="Agência" dataIndex="agencia" width="5%" />
            <Table.Column title="Conta" dataIndex="conta" width="5%" />
            <Table.Column title="CNPJ" dataIndex="cnpj" width="10%" />
            <Table.Column title="Vencimento" dataIndex="Vencimento" width="10%" />
            <Table.Column title="Valor (R$)" dataIndex="valor" type="currency" width="10%" />
          </Table>
          <Divider>Imagem</Divider>
          <div>
            {isLoadingImagem ? (
              <Loading />
            ) : (
              <>
                <div style={{ height: '30vh', width: '100%' }}>
                  <img width="100%" height="100%" alt="" src={srcImgFrente} />
                </div>
                <div style={{ height: '30vh', width: '100%' }}>
                  <img width="100%" height="100%" alt="" src={srcImgVerso} />
                </div>
              </>
            )}
          </div>
        </Page>
        <FooterToolbar left={<></>} center={<></>} right={<></>} />
      </>
    );
  }
}

export default ChequePage;
