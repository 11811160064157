// Acre
const ac = [
  { data: '1200013', label: 'Acrelândia' },
  { data: '1200054', label: 'Assis Brasil' },
  { data: '1200104', label: 'Brasiléia' },
  { data: '1200138', label: 'Bujari' },
  { data: '1200179', label: 'Capixaba' },
  { data: '1200203', label: 'Cruzeiro do Sul' },
  { data: '1200252', label: 'Epitaciolândia' },
  { data: '1200302', label: 'Feijó' },
  { data: '1200328', label: 'Jordão' },
  { data: '1200336', label: 'Mâncio Lima' },
  { data: '1200344', label: 'Manoel Urbano' },
  { data: '1200351', label: 'Marechal Thaumaturgo' },
  { data: '1200385', label: 'Plácido de Castro' },
  { data: '1200807', label: 'Porto Acre' },
  { data: '1200393', label: 'Porto Walter' },
  { data: '1200401', label: 'Rio Branco' },
  { data: '1200427', label: 'Rodrigues Alves' },
  { data: '1200435', label: 'Santa Rosa do Purus' },
  { data: '1200500', label: 'Sena Madureira' },
  { data: '1200450', label: 'Senador Guiomard' },
  { data: '1200609', label: 'Tarauacá' },
  { data: '1200708', label: 'Xapuri' },
];

export default ac;
