import { Col, Divider, Row } from 'antd';
import React from 'react';
import { Page, Table, Button, ContentWrapper, Statistic } from '~/components';
import { FrequenciaService } from '~/services';
import { decimalValue } from '~/utils';

class FrequenciaPageDetalheView extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      itens: [],
      isLoadingPagamentos: false,
      itensPagamentos: [],
    };
  }

  componentDidUpdate(prevProps) {
    const { action, record } = this.props;
    const actionChange = action !== prevProps.action;

    if (actionChange && action && record.idt) {
      this.forceUpdate(() => {
        this.setState({ isLoading: true, isLoadingPagamentos: true, itens: [], itensPagamentos: [] });

        FrequenciaService.getAllItens(
          record.idt,
          itens => this.setState({ itens, isLoading: false }),
          () => this.setState({ itens: [], isLoading: false })
        );

        FrequenciaService.getAllPagamentos(
          record.idt,
          itensPagamentos => this.setState({ itensPagamentos, isLoadingPagamentos: false }),
          () => this.setState({ itensPagamentos: [], isLoadingPagamentos: false })
        );
      });
    }
  }

  render = () => {
    const { onCancel, action, record } = this.props;
    const { isLoading, itens, isLoadingPagamentos, itensPagamentos } = this.state;

    return (
      <>
        <Page.Modal
          visible={action}
          title={`NFC-e`}
          width={1000}
          onCancel={onCancel}
          forceRender
          centered
          closable
          destroyOnClose
          footer={
            <div style={{ width: '100%', textAlign: 'center' }}>
              <Button key="back" loading={isLoading || isLoadingPagamentos} onClick={onCancel}>
                Fechar
              </Button>
            </div>
          }
        >
          <ContentWrapper type="search">
            <div style={{ width: '100%', textAlign: 'center' }}>
              <span className="label-important">{`${record.nfce}`}</span>
              <span>{`- ${record.operador}`}</span>
            </div>
          </ContentWrapper>
          <Divider>Produtos</Divider>
          <Table dataSource={itens} rowKey="idt" loading={isLoading}>
            <Table.Column width="65%" title="Descrição" dataIndex="descricao" />
            <Table.Column width="10%" title="Quantidade" dataIndex="quantidade" type="decimal" />
            <Table.Column width="10%" title="Unitário" dataIndex="unitario" type="currency" />
            <Table.Column width="15%" title="Valor Total (R$)" dataIndex="valor_total" type="currency" />
          </Table>
          <ContentWrapper type="search">
            <Row>
              <Col md={3} />
              <Col md={4}>
                <Statistic
                  title="Base ICMS"
                  value={decimalValue(
                    itens.reduce((previous, current) => parseFloat(current.base_icms_item) + previous, 0),
                    2
                  )}
                  color="#000099"
                />
              </Col>
              <Col md={4}>
                <Statistic
                  title="Valor ICMS"
                  value={decimalValue(
                    itens.reduce((previous, current) => parseFloat(current.valor_icms_item) + previous, 0),
                    2
                  )}
                  color="#ff0000"
                />
              </Col>
              <Col md={4}>
                <Statistic
                  title="Base ST"
                  value={decimalValue(
                    itens.reduce((previous, current) => parseFloat(current.base_st_item) + previous, 0),
                    2
                  )}
                  color="#000099"
                />
              </Col>
              <Col md={4}>
                <Statistic
                  title="Valor ST"
                  value={decimalValue(
                    itens.reduce((previous, current) => parseFloat(current.valor_st_item) + previous, 0),
                    2
                  )}
                  color="#ff5c33"
                />
              </Col>
              <Col md={4}>
                <Statistic
                  title="Valor Total"
                  value={decimalValue(
                    itens.reduce((previous, current) => parseFloat(current.valor_total) + previous, 0),
                    2
                  )}
                  color="#009900"
                />
              </Col>
            </Row>
          </ContentWrapper>
          <Divider>Pagamento</Divider>
          <Table dataSource={itensPagamentos} rowKey="idt" loading={isLoadingPagamentos}>
            <Table.Column width="15%" title="Forma de Pagamento" dataIndex="forma_pagamento" />
            <Table.Column width="10%" title="Tipo" dataIndex="tipo" />
            <Table.Column width="10%" title="Valor (R$)" dataIndex="valor" type="currency" />
            <Table.Column width="10%" title="Número" dataIndex="numero" align="center" />
            <Table.Column width="10%" title="Vencimento" dataIndex="vencimento" type="date" />
            <Table.Column width="15%" title="CPF/CNPJ" dataIndex="documento" />
            <Table.Column width="30%" title="Nome" dataIndex="nome" />
          </Table>
        </Page.Modal>
      </>
    );
  };
}

export default FrequenciaPageDetalheView;
