import { Col, Row } from 'antd';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import { decimalValue } from '~/utils';
import { ContentWrapper, Form, Select } from '..';
import { execFilter, groupBySelect } from './functionsCharts';

const colors = ['#58afff', '#88d1ea', '#36cbcb', '#82dfbe', '#4ecb73', '#acdf82', '#fbd437', '#eaa674', '#f2637b', '#dc81d2'];

class ComparativoDashboard extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      bars: [],
    };
  }

  componentDidUpdate(prevProps) {
    const { valuesLink } = this.props;
    const valores = this.serializeValues();

    if (valores.linkar === 'S') {
      const refLink = valuesLink[valores.referencia_comparativo] || [];
      const oldRefLink = prevProps.valuesLink[valores.referencia_comparativo] || [];
      if (refLink && oldRefLink.length !== refLink.length) {
        this.searchApi.setValue('comparativos', refLink);
        this.onChangeComparativo(refLink);
      }
    }
  }

  onChangeComparativo = value => {
    const { changeLink } = this.props;

    this.setState({
      bars: value.map(item => ({ key: item, fill: colors[value.findIndex(itemFind => itemFind === item)] || '#d9d9d9' })),
    });

    const valores = this.serializeValues();
    if (valores.linkar === 'S' && changeLink) {
      changeLink(value, valores.referencia_comparativo);
    }
  };

  serializeValues = () => {
    const { record } = this.props;
    return JSON.parse(atob(record.valores));
  };

  render() {
    const { data, record, listTotalizar } = this.props;
    const { bars } = this.state;
    const valores = JSON.parse(atob(record.valores));
    const filtros = JSON.parse(atob(record.filtros));
    const referencia = valores.referencia_comparativo;
    const analise = valores.analise_comparativo;
    const totalizarSelected = listTotalizar.find(item => item.data === valores.valor_comparativo);

    const listComparativo = groupBySelect(data, referencia);

    let dataSourceComparativo = execFilter(data, filtros);
    dataSourceComparativo = _.groupBy(dataSourceComparativo, item => item[analise]);
    dataSourceComparativo = _.map(dataSourceComparativo, item => ({
      name: item[0][analise].length === 10 && item[0][analise].indexOf('-') > 0 ? moment(item[0][analise]).format('DD/MM/YYYY') : item[0][analise],
      value: item[0][analise],
    }));
    dataSourceComparativo = _.orderBy(dataSourceComparativo, item => item.value);

    let obj = {};
    const dataSource = [];
    dataSourceComparativo.forEach(item => {
      obj = {};
      bars.forEach(bar => {
        obj = {
          ...obj,
          [bar.key]:
            totalizarSelected.tipo === 'constant_integer'
              ? parseInt(
                  (data.find(itemFilter => itemFilter[analise] === item.value && itemFilter[referencia] === bar.key) || {})[totalizarSelected.data] || 0,
                  10
                )
              : decimalValue(
                  data
                    .filter(itemFilter => itemFilter[analise] === item.value && itemFilter[referencia] === bar.key)
                    .reduce((previous, current) => parseFloat(current[totalizarSelected.data]) + previous, 0),
                  2
                ),
        };
      });
      dataSource.push({
        ...item,
        ...obj,
      });
    });

    return (
      <>
        <Form getApi={api => (this.searchApi = api)}>
          <ContentWrapper type="search">
            <Row gutter={4}>
              <Col md={24}>
                <Select field="comparativos" label="Comparativo" dataSource={listComparativo} mode="multiple" required onChange={this.onChangeComparativo} />
              </Col>
            </Row>
          </ContentWrapper>
        </Form>
        <BarChart
          width={1150}
          height={300}
          data={dataSource}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          {bars.map(item => (
            <Bar key={item.key} dataKey={item.key} fill={item.fill} />
          ))}
        </BarChart>
      </>
    );
  }
}

export default ComparativoDashboard;
