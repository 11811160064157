import React from 'react';
import { connect } from 'react-redux';
import { RightContent } from '..';
import { BaseMenu, Logo } from '../../..';
import './TopNavigator.less';

class TopNavigator extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      maxWidth: undefined,
    };
  }

  static getDerivedStateFromProps({ settings }) {
    return {
      maxWidth: (settings.contentWidth === 'Fixed' && window.innerWidth > 1200 ? 1200 : window.innerWidth) - 280 - 120 - 40,
    };
  }

  render() {
    const { settings } = this.props;
    const { maxWidth } = this.state;

    return (
      <div className={`top-navigator-head ${settings.theme === 'light' ? 'top-navigator-light' : ''}`}>
        <div className={`top-navigator-main ${settings.contentWidth === 'Fixed' ? 'top-navigator-wide' : ''}`}>
          <div className="top-navigator-left">
            <div className="top-navigator-logo">
              <Logo />
            </div>
            <div style={{ maxWidth }}>
              <BaseMenu className="top-navigator-menu" mode="horizontal" />
            </div>
          </div>
          <RightContent className="top-navigator-right" />
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ settings }) => ({ settings });

export default connect(mapStateToProps)(TopNavigator);
