import { takeEvery, all, fork, put, call } from 'redux-saga/effects';
import { UserService } from '~/services';
import { Creators, Types } from '~/stores/ducks/cadastros/produtos/produtosSubGrupos';

function* fetchProdutosSubGrupos() {
  try {
    const response = yield call(
      () =>
        new Promise((resolve, reject) => {
          UserService.fetchSubGrupoProduto(resolve, reject);
        })
    );

    yield put(Creators.fetchProdutosSubGruposSuccess(response));
  } catch (error) {
    yield put(Creators.fetchProdutosSubGruposFailure(error));
  }
}

const watchProdutosSubGrupos = () =>
  function* watch() {
    yield takeEvery(Types.FETCH_PRODUTOS_SUB_GRUPOS, fetchProdutosSubGrupos);
  };

export default function* produtosSubGrupos() {
  yield all([fork(watchProdutosSubGrupos())]);
}
