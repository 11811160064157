// Mato Grosso
const mt = [
  { data: '5100102', label: 'Acorizal' },
  { data: '5100201', label: 'Água Boa' },
  { data: '5100250', label: 'Alta Floresta' },
  { data: '5100300', label: 'Alto Araguaia' },
  { data: '5100359', label: 'Alto Boa Vista' },
  { data: '5100409', label: 'Alto Garças' },
  { data: '5100508', label: 'Alto Paraguai' },
  { data: '5100607', label: 'Alto Taquari' },
  { data: '5100805', label: 'Apiacás' },
  { data: '5101001', label: 'Araguaiana' },
  { data: '5101209', label: 'Araguainha' },
  { data: '5101258', label: 'Araputanga' },
  { data: '5101308', label: 'Arenápolis' },
  { data: '5101407', label: 'Aripuanã' },
  { data: '5101605', label: 'Barão de Melgaço' },
  { data: '5101704', label: 'Barra do Bugres' },
  { data: '5101803', label: 'Barra do Garças' },
  { data: '5101852', label: 'Bom Jesus do Araguaia' },
  { data: '5101902', label: 'Brasnorte' },
  { data: '5102504', label: 'Cáceres' },
  { data: '5102603', label: 'Campinápolis' },
  { data: '5102637', label: 'Campo Novo do Parecis' },
  { data: '5102678', label: 'Campo Verde' },
  { data: '5102686', label: 'Campos de Júlio' },
  { data: '5102694', label: 'Canabrava do Norte' },
  { data: '5102702', label: 'Canarana' },
  { data: '5102793', label: 'Carlinda' },
  { data: '5102850', label: 'Castanheira' },
  { data: '5103007', label: 'Chapada dos Guimarães' },
  { data: '5103056', label: 'Cláudia' },
  { data: '5103106', label: 'Cocalinho' },
  { data: '5103205', label: 'Colíder' },
  { data: '5103254', label: 'Colniza' },
  { data: '5103304', label: 'Comodoro' },
  { data: '5103353', label: 'Confresa' },
  { data: '5103361', label: "Conquista D'Oeste" },
  { data: '5103379', label: 'Cotriguaçu' },
  { data: '5103403', label: 'Cuiabá' },
  { data: '5103437', label: 'Curvelândia' },
  { data: '5103452', label: 'Denise' },
  { data: '5103502', label: 'Diamantino' },
  { data: '5103601', label: 'Dom Aquino' },
  { data: '5103700', label: 'Feliz Natal' },
  { data: '5103809', label: "Figueirópolis D'Oeste" },
  { data: '5103858', label: 'Gaúcha do Norte' },
  { data: '5103908', label: 'General Carneiro' },
  { data: '5103957', label: "Glória D'Oeste" },
  { data: '5104104', label: 'Guarantã do Norte' },
  { data: '5104203', label: 'Guiratinga' },
  { data: '5104500', label: 'Indiavaí' },
  { data: '5104526', label: 'Ipiranga do Norte' },
  { data: '5104542', label: 'Itanhangá' },
  { data: '5104559', label: 'Itaúba' },
  { data: '5104609', label: 'Itiquira' },
  { data: '5104807', label: 'Jaciara' },
  { data: '5104906', label: 'Jangada' },
  { data: '5105002', label: 'Jauru' },
  { data: '5105101', label: 'Juara' },
  { data: '5105150', label: 'Juína' },
  { data: '5105176', label: 'Juruena' },
  { data: '5105200', label: 'Juscimeira' },
  { data: '5105234', label: "Lambari D'Oeste" },
  { data: '5105259', label: 'Lucas do Rio Verde' },
  { data: '5105309', label: 'Luciára' },
  { data: '5105580', label: 'Marcelândia' },
  { data: '5105606', label: 'Matupá' },
  { data: '5105622', label: "Mirassol d'Oeste" },
  { data: '5105903', label: 'Nobres' },
  { data: '5106000', label: 'Nortelândia' },
  { data: '5106109', label: 'Nossa Senhora do Livramento' },
  { data: '5106158', label: 'Nova Bandeirantes' },
  { data: '5106208', label: 'Nova Brasilândia' },
  { data: '5106216', label: 'Nova Canaã do Norte' },
  { data: '5108808', label: 'Nova Guarita' },
  { data: '5106182', label: 'Nova Lacerda' },
  { data: '5108857', label: 'Nova Marilândia' },
  { data: '5108907', label: 'Nova Maringá' },
  { data: '5108956', label: 'Nova Monte Verde' },
  { data: '5106224', label: 'Nova Mutum' },
  { data: '5106174', label: 'Nova Nazaré' },
  { data: '5106232', label: 'Nova Olímpia' },
  { data: '5106190', label: 'Nova Santa Helena' },
  { data: '5106240', label: 'Nova Ubiratã' },
  { data: '5106257', label: 'Nova Xavantina' },
  { data: '5106273', label: 'Novo Horizonte do Norte' },
  { data: '5106265', label: 'Novo Mundo' },
  { data: '5106315', label: 'Novo Santo Antônio' },
  { data: '5106281', label: 'Novo São Joaquim' },
  { data: '5106299', label: 'Paranaíta' },
  { data: '5106307', label: 'Paranatinga' },
  { data: '5106372', label: 'Pedra Preta' },
  { data: '5106422', label: 'Peixoto de Azevedo' },
  { data: '5106455', label: 'Planalto da Serra' },
  { data: '5106505', label: 'Poconé' },
  { data: '5106653', label: 'Pontal do Araguaia' },
  { data: '5106703', label: 'Ponte Branca' },
  { data: '5106752', label: 'Pontes e Lacerda' },
  { data: '5106778', label: 'Porto Alegre do Norte' },
  { data: '5106802', label: 'Porto dos Gaúchos' },
  { data: '5106828', label: 'Porto Esperidião' },
  { data: '5106851', label: 'Porto Estrela' },
  { data: '5107008', label: 'Poxoréo' },
  { data: '5107040', label: 'Primavera do Leste' },
  { data: '5107065', label: 'Querência' },
  { data: '5107156', label: 'Reserva do Cabaçal' },
  { data: '5107180', label: 'Ribeirão Cascalheira' },
  { data: '5107198', label: 'Ribeirãozinho' },
  { data: '5107206', label: 'Rio Branco' },
  { data: '5107578', label: 'Rondolândia' },
  { data: '5107602', label: 'Rondonópolis' },
  { data: '5107701', label: 'Rosário Oeste' },
  { data: '5107750', label: 'Salto do Céu' },
  { data: '5107248', label: 'Santa Carmem' },
  { data: '5107743', label: 'Santa Cruz do Xingu' },
  { data: '5107768', label: 'Santa Rita do Trivelato' },
  { data: '5107776', label: 'Santa Terezinha' },
  { data: '5107263', label: 'Santo Afonso' },
  { data: '5107792', label: 'Santo Antônio do Leste' },
  { data: '5107800', label: 'Santo Antônio do Leverger' },
  { data: '5107859', label: 'São Félix do Araguaia' },
  { data: '5107297', label: 'São José do Povo' },
  { data: '5107305', label: 'São José do Rio Claro' },
  { data: '5107354', label: 'São José do Xingu' },
  { data: '5107107', label: 'São José dos Quatro Marcos' },
  { data: '5107404', label: 'São Pedro da Cipa' },
  { data: '5107875', label: 'Sapezal' },
  { data: '5107883', label: 'Serra Nova Dourada' },
  { data: '5107909', label: 'Sinop' },
  { data: '5107925', label: 'Sorriso' },
  { data: '5107941', label: 'Tabaporã' },
  { data: '5107958', label: 'Tangará da Serra' },
  { data: '5108006', label: 'Tapurah' },
  { data: '5108055', label: 'Terra Nova do Norte' },
  { data: '5108105', label: 'Tesouro' },
  { data: '5108204', label: 'Torixoréu' },
  { data: '5108303', label: 'União do Sul' },
  { data: '5108352', label: 'Vale de São Domingos' },
  { data: '5108402', label: 'Várzea Grande' },
  { data: '5108501', label: 'Vera' },
  { data: '5105507', label: 'Vila Bela da Santíssima Trindade' },
  { data: '5108600', label: 'Vila Rica' },
];

export default mt;
