import React from 'react';
import { Button } from 'antd';
import { Page, Table } from '~/components';

class SelecionarClienteView extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      record: {},
    };
  }

  handleSave = () => {
    const { onOk } = this.props;
    const { record } = this.state;

    if (onOk) {
      onOk(record);
    }
  };

  handleSelect = record => {
    this.setState({ record });
  };

  render() {
    const { visible, itens } = this.props;
    const { isLoading, record } = this.state;
    const isView = visible;

    return (
      <>
        <Page.Modal
          visible={isView}
          title={`Selecione o Cliente`}
          forceRender
          centered
          closable={!isLoading && isView}
          bodyStyle={{ maxHeight: '600px', overflow: 'auto' }}
          maskClosable={isView}
          destroyOnClose
          footer={
            <div key="divFooter" style={{ textAlign: 'right' }}>
              <Button key="submit" type="primary" disabled={!record.nome} onClick={() => this.handleSave()}>
                Login
              </Button>
            </div>
          }
        >
          <Table
            size="small"
            pagination={{ hideOnSinglePage: true, pageSize: itens.length }}
            footer={null}
            dataSource={itens}
            rowKey="uuid"
            className="table-quadro"
            loading={isLoading}
            onRecordChange={this.handleSelect}
            onRow={{
              onDoubleClick: () => this.handleSave(),
            }}
          >
            <Table.Column title="Código" dataIndex="uuid" onMobile="show" width="10%" align="center" />
            <Table.Column title="Nome" dataIndex="fantasia" onMobile="show" width="90%" />
          </Table>
        </Page.Modal>
      </>
    );
  }
}

export default SelecionarClienteView;
