import { HttpService } from '~/helpers';
import { UserService } from '~/services';

const DashboardService = {
  getRecados_recebidos: (onSuccess, onError) => {
    HttpService.call('sistema/login', 'recados_recebidos', [UserService.getUser().codigo], onSuccess, onError);
  },
  getNote: (onSuccess, onError) => {
    HttpService.call('sistema/note', 'buscar', [UserService.getUser().cnpj], onSuccess, onError);
  },
  getPrecoCombustivel: (onSuccess, onError) => {
    HttpService.call('sistema/dashboard', 'getPrecoCombustivel', [UserService.getUser().codigo], onSuccess, onError);
  },
  saveNote: (text, onSuccess, onError) => {
    HttpService.call('sistema/note', 'salvar', [UserService.getUser().cnpj, text], onSuccess, onError);
  },
};

export default DashboardService;
