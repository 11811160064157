// Espírito Santo
const es = [
  { data: '1600102', label: 'Afonso Cláudio' },
  { data: '1600169', label: 'Água Doce do Norte' },
  { data: '1600136', label: 'Águia Branca' },
  { data: '1600201', label: 'Alegre' },
  { data: '1600300', label: 'Alfredo Chaves' },
  { data: '1600359', label: 'Alto Rio Novo' },
  { data: '1600409', label: 'Anchieta' },
  { data: '1600508', label: 'Apiacá' },
  { data: '1600607', label: 'Aracruz' },
  { data: '1600706', label: 'Atilio Vivacqua' },
  { data: '1600805', label: 'Baixo Guandu' },
  { data: '1600904', label: 'Barra de São Francisco' },
  { data: '1601001', label: 'Boa Esperança' },
  { data: '1601100', label: 'Bom Jesus do Norte' },
  { data: '1601159', label: 'Brejetuba' },
  { data: '1601209', label: 'Cachoeiro de Itapemirim' },
  { data: '1601308', label: 'Cariacica' },
  { data: '1601407', label: 'Castelo' },
  { data: '1601506', label: 'Colatina' },
  { data: '1601605', label: 'Conceição da Barra' },
  { data: '1601704', label: 'Conceição do Castelo' },
  { data: '1601803', label: 'Divino de São Lourenço' },
  { data: '1601902', label: 'Domingos Martins' },
  { data: '1602009', label: 'Dores do Rio Preto' },
  { data: '1602108', label: 'Ecoporanga' },
  { data: '1602207', label: 'Fundão' },
  { data: '1602256', label: 'Governador Lindenberg' },
  { data: '1602306', label: 'Guaçuí' },
  { data: '1602405', label: 'Guarapari' },
  { data: '1602454', label: 'Ibatiba' },
  { data: '1602504', label: 'Ibiraçu' },
  { data: '1602553', label: 'Ibitirama' },
  { data: '1602603', label: 'Iconha' },
  { data: '1602652', label: 'Irupi' },
  { data: '1602702', label: 'Itaguaçu' },
  { data: '1602801', label: 'Itapemirim' },
  { data: '1602900', label: 'Itarana' },
  { data: '1603007', label: 'Iúna' },
  { data: '1603056', label: 'Jaguaré' },
  { data: '1603106', label: 'Jerônimo Monteiro' },
  { data: '1603130', label: 'João Neiva' },
  { data: '1603163', label: 'Laranja da Terra' },
  { data: '1603205', label: 'Linhares' },
  { data: '1603304', label: 'Mantenópolis' },
  { data: '1603320', label: 'Marataízes' },
  { data: '1603346', label: 'Marechal Floriano' },
  { data: '1603353', label: 'Marilândia' },
  { data: '1603403', label: 'Mimoso do Sul' },
  { data: '1603502', label: 'Montanha' },
  { data: '1603601', label: 'Mucurici' },
  { data: '1603700', label: 'Muniz Freire' },
  { data: '1603809', label: 'Muqui' },
  { data: '1603908', label: 'Nova Venécia' },
  { data: '1604005', label: 'Pancas' },
  { data: '1604054', label: 'Pedro Canário' },
  { data: '1604104', label: 'Pinheiros' },
  { data: '1604203', label: 'Piúma' },
  { data: '1604252', label: 'Ponto Belo' },
  { data: '1604302', label: 'Presidente Kennedy' },
  { data: '1604351', label: 'Rio Bananal' },
  { data: '1604401', label: 'Rio Novo do Sul' },
  { data: '1604500', label: 'Santa Leopoldina' },
  { data: '1604559', label: 'Santa Maria de Jetibá' },
  { data: '1604609', label: 'Santa Teresa' },
  { data: '1604658', label: 'São Domingos do Norte' },
  { data: '1604708', label: 'São Gabriel da Palha' },
  { data: '1604807', label: 'São José do Calçado' },
  { data: '1604906', label: 'São Mateus' },
  { data: '1604955', label: 'São Roque do Canaã' },
  { data: '1605002', label: 'Serra' },
  { data: '1605010', label: 'Sooretama' },
  { data: '1605036', label: 'Vargem Alta' },
  { data: '1605069', label: 'Venda Nova do Imigrante' },
  { data: '1605101', label: 'Viana' },
  { data: '1605150', label: 'Vila Pavão' },
  { data: '1605176', label: 'Vila Valério' },
  { data: '1605200', label: 'Vila Velha' },
  { data: '1605309', label: 'Vitória' },
];

export default es;
