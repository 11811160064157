import React from 'react';
import { ToolTwoTone } from '@ant-design/icons';
import EditExportTableView from './EditExportTableView';

class ExportEdit extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      showEditExportar: false,
    };
  }

  render() {
    const { dataSource, rowKey, ...rest } = this.props;
    const { showEditExportar } = this.state;

    return (
      <>
        <EditExportTableView
          action={showEditExportar}
          dataSource={dataSource}
          rowKey={rowKey}
          onCancel={() => this.setState({ showEditExportar: false })}
          {...rest}
        />
        <ToolTwoTone
          twoToneColor="#ff0000"
          title="Editar Exportar"
          disabled={dataSource.length === 0}
          {...rest}
          onClick={() => this.setState({ showEditExportar: true })}
        />
      </>
    );
  }
}

export default ExportEdit;
