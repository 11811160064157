import React from 'react';
import KeyboardEventHandler from 'react-keyboard-event-handler';
import { Input as AntdInput } from 'antd';
import classNames from 'classnames';
import './InputCell.less';
import { onKeyEnterTableCell } from '~/utils';

let change = false;

class InputCell extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      value: props.defaultValue,
    };
  }

  render() {
    const { type, record, field, functionAction, defaultValue, maxLength, enterLinha, capsLock = true, ...rest } = this.props;
    const { value } = this.state;

    return (
      <KeyboardEventHandler
        handleKeys={['enter']}
        onKeyEvent={(key, e) => {
          onKeyEnterTableCell(e, 'number', enterLinha);
        }}
      >
        <AntdInput
          size="small"
          value={value || defaultValue}
          defaultValue={defaultValue}
          maxLength={maxLength || null}
          className={classNames('input-number', { block: true })}
          onChange={event => {
            change = true;
            this.setState({ value: capsLock ? event.target.value.toString().toUpperCase() : event.target.value.toString() });
          }}
          onBlur={() => {
            if (change) {
              change = false;
              if (functionAction) functionAction({ ...record, [field]: value }, field);
            }
          }}
          {...rest}
        />
      </KeyboardEventHandler>
    );
  }
}

export default InputCell;
