import React from 'react';
import moment from 'moment';
import { Card, Col, Row } from 'antd';
import { UserService } from '~/services';
import { Table, Link, Form, RangePicker } from '~/components';
import { dateValue, decimalPlace } from '~/utils';
import './EstoqueTerceiroTableCard.less';

const today = {
  id: 'day',
  title: 'Dia',
  dates: [moment().format('YYYYMMDD'), moment().format('YYYYMMDD')],
};

const lastWeek = {
  id: 'week',
  title: 'Semana',
  dates: [
    moment()
      .subtract(1, 'weeks')
      .format('YYYYMMDD'),
    moment().format('YYYYMMDD'),
  ],
};

const lastMonth = {
  id: 'month',
  title: 'Mês',
  dates: [
    moment()
      .subtract(1, 'months')
      .format('YYYYMMDD'),
    moment().format('YYYYMMDD'),
  ],
};

const lastYear = {
  id: 'year',
  title: 'Ano',
  dates: [
    moment()
      .subtract(1, 'years')
      .format('YYYYMMDD'),
    moment().format('YYYYMMDD'),
  ],
};

const dateRangeButtons = [today, lastWeek, lastMonth, lastYear];

class EstoqueTerceiroTableCard extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      itens: [],
      datesValue: [moment().format('YYYYMMDD'), moment().format('YYYYMMDD')],
    };
  }

  componentDidMount() {
    this.fetch();
  }

  fetch = () => {
    const { datesValue } = this.state;

    this.setState({ isLoading: true });
    UserService.getAllContentores(
      {
        cliente: UserService.getUser().codigo,
        inicial: datesValue[0],
        final: datesValue[1],
      },
      itens => this.setState({ itens, isLoading: false }),
      () => this.setState({ isLoading: false })
    );
  };

  currenteDateClassName = dates => {
    const { datesValue } = this.state;

    return dates[0] === datesValue[0] && dates[1] === datesValue[1] ? 'currentDate' : '';
  };

  handleDates = (dates = []) => {
    this.setState({ isLoading: true, datesValue: dates }, () => this.fetch());
  };

  render() {
    const { isLoading, itens, datesValue } = this.state;

    return (
      <Card
        title="Material Circulante"
        loading={isLoading}
        className="card-pagamentos"
        extra={
          <>
            <div className="extra-card-dashboard">
              {dateRangeButtons.map(({ id, title, dates }) => (
                <Link key={id} onClick={() => this.handleDates(dates)} className={this.currenteDateClassName(dates)}>
                  {title}
                </Link>
              ))}
            </div>
            <Form layout="horizontal" getApi={api => (this.datesApi = api)} style={{ display: 'inline-block' }} onSubmit={this.handleDates}>
              <RangePicker
                field="dates"
                style={{ width: 256 }}
                value={datesValue.dates}
                initialValue={datesValue.dates}
                onChange={this.onDatesChange}
                allowClear={false}
              />
            </Form>
          </>
        }
      >
        <Table
          size="small"
          dataSource={itens}
          rowKey="produto"
          loading={isLoading}
          description={item => (
            <Row>
              <Col span={16}>
                <span style={{ fontSize: '0.8rem' }}>{dateValue(item.vencimento)}</span>
              </Col>
              <Col span={8} style={{ textAlign: 'right' }}>
                <span>{decimalPlace(item.valor, 2)}</span>
              </Col>
            </Row>
          )}
          height={200}
        >
          <Table.Column title="Descrição Produto" dataIndex="descricao" width="50%" />
          <Table.Column title="Anterior" dataIndex="estoque" type="currency" width="15%" />
          <Table.Column title="Entrada" dataIndex="entrada" type="currency" width="10%" />
          <Table.Column title="Saida" dataIndex="saida" type="currency" width="10%" />
          <Table.Column title="Atual" dataIndex="estoque_atual" type="currency" width="15%" />
        </Table>
      </Card>
    );
  }
}

export default EstoqueTerceiroTableCard;
