import React from 'react';
import { withRouter } from 'react-router-dom';
import { Row, Col, Divider, Alert } from 'antd';
import { Form, Input, Button, FooterToolbar, Page, ContentWrapper } from '~/components';
import { actions, tabPanes } from '~/options';
import { UserService } from '~/services';
import { notifySuccess, generateUuid } from '~/utils';

class AlterarSenhaPage extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      action: actions.idle,
      activeTab: tabPanes.table.key,
    };
  }

  componentDidMount() {
    setTimeout(() => this.editInput.focus(), 300);
  }

  handleSave = values => {
    this.setState({ isLoading: true });

    UserService.alterarSenha(values, this.onSave, this.onSave);
  };

  onSave = (error = false) => {
    this.setState({ isLoading: false });

    if (!error) {
      const { name } = this.props;
      const action = {
        perfectPast: ' realizado com sucesso',
      };
      notifySuccess({ action, name });
      this.saveApi.reset();

      const { history } = this.props;
      UserService.logout(() => history.push(`/${process.env.REACT_APP_VERSION}/login`));
    }
  };

  renderErroSenha = (senhaErro, senhaTexto) => {
    if (senhaErro) {
      return (
        <>
          <Divider style={{ marginTop: 15 }}>Erros</Divider>
          <Alert
            message="Senha inválida"
            description={
              <ul style={{ marginBottom: 0 }}>
                {senhaTexto.map(item => (
                  <li key={generateUuid()}>{item}</li>
                ))}
              </ul>
            }
            type="error"
            showIcon
          />
        </>
      );
    }
    return null;
  };

  render() {
    const { tabId, params } = this.props;
    const { activeTab, action, isLoading } = this.state;
    const isIdle = action === actions.idle && !isLoading;
    const values = this.saveApi ? this.saveApi.getValues() : {};
    let senhaErro = false;
    const senhaTexto = [];
    if (values && values.nova_senha) {
      if (!/[0-9]/g.test(values.nova_senha)) {
        senhaTexto.push('A senha deve conter ao menos um número.');
      }
      if (!/[A-Za-z]/g.test(values.nova_senha)) {
        senhaTexto.push('A senha deve conter ao menos uma letra.');
      }
      if (values.nova_senha.length < 8) {
        senhaTexto.push('A senha deve conter ao menos 8 caracteres.');
      }
      if (senhaTexto.length >= 1) {
        senhaErro = true;
      }
    }

    return (
      <>
        <Page
          tabbed
          tabId={tabId}
          closable={isIdle && !(params && params.modal)}
          activeKey={activeTab}
          onChange={activeKey => this.setState({ activeTab: activeKey })}
        >
          <Page.TabPane key={tabPanes.table.key} tab={tabPanes.table.tab} disabled={action !== actions.idle}>
            <Form getApi={api => (this.saveApi = api)} onSubmit={this.handleSave}>
              <ContentWrapper>
                <Row gutter={4}>
                  <Col md={8} span={24}>
                    <Input.Password
                      label="Senha Atual"
                      field="senha_atual"
                      disabled={isLoading}
                      required
                      forwardedRef={ref => (this.editInput = ref)}
                      capsLock={false}
                    />
                  </Col>
                  <Col md={8} span={24}>
                    <Input.Password label="Nova Senha" field="nova_senha" disabled={isLoading} required capsLock={false} onChange={() => this.forceUpdate()} />
                  </Col>
                  <Col md={8} span={24}>
                    <Input.Password label="Confirmar Senha" field="confirmar_senha" disabled={isLoading} required capsLock={false} />
                  </Col>
                </Row>
                {this.renderErroSenha(senhaErro, senhaTexto)}
              </ContentWrapper>
            </Form>
          </Page.TabPane>
        </Page>
        <FooterToolbar
          right={
            <>
              <Button.Save
                disabled={isLoading || senhaErro}
                disabledReason={senhaErro && 'Senha inválida.'}
                loading={isLoading}
                onClick={() => this.saveApi.submitForm()}
              />
            </>
          }
        />
      </>
    );
  }
}

export default withRouter(AlterarSenhaPage);
