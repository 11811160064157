import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import classNames from 'classnames';
import { Tabs as AntdTabs, Modal, Tooltip } from 'antd';
import { CloseOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Title, GridContent, Link } from '~/components';
import { Creators } from '~/stores/ducks/general/tabs';
import { RememberService } from '~/services';
import './Tabs.less';

const Tabs = React.memo(({ tabs, changeTab, settings, resetTabs, removeTab, general }) =>
  tabs.data.length > 0 ? (
    <>
      <Title title={(tabs.data.find(item => item.id === tabs.activeTab) || {}).nome} />
      <AntdTabs
        className={classNames('tabs', { 'tabs-wide': settings.contentWidth === 'Fixed' })}
        activeKey={tabs.activeTab}
        size="small"
        onChange={key => changeTab(key)}
        renderTabBar={({ className, ...rest }, DefaultTabBar) => (
          <DefaultTabBar className={classNames(className, 'tabs-tab-bar', general.isMobile ? 'tabs-mobile' : '')} {...rest} />
        )}
        tabBarExtraContent={
          <Link
            onClick={() =>
              Modal.confirm({
                icon: <ExclamationCircleOutlined twoToneColor={'#ff9900'} />,
                title: 'Fechar',
                centered: true,
                onOk: () => {
                  resetTabs();
                },
                maskClosable: true,
                destroyOnClose: true,
                okText: 'Fechar',
                content: (
                  <>
                    {'Tem certeza de que deseja fechar '}
                    <b>{'todas as abas abertas'}</b>
                    {'? Quaisquer informações não salvas serão perdidas.'}
                  </>
                ),
              })
            }
          >
            <div className="tabs-extra-content">
              <Tooltip placement="bottom" title="Fechar todas as janelas">
                <CloseOutlined className="tabs-extra-content-icon" />
              </Tooltip>
            </div>
          </Link>
        }
      >
        {tabs.data.map(({ id, nome, menuId, params, perfis, chave, chaveMobile, component: Component }) => (
          <AntdTabs.TabPane key={id} tab={nome}>
            <div className={classNames('tabs-content', general.isMobile ? 'ant-tabs-mobile' : '')}>
              <GridContent>
                {Component && (
                  <Component
                    tabId={id}
                    menuId={menuId}
                    name={nome}
                    params={params}
                    removeTab={() => removeTab(id)}
                    {...perfis}
                    {...(RememberService.getRemember()[general.isMobile ? chaveMobile : chave] || {})}
                  />
                )}
              </GridContent>
            </div>
          </AntdTabs.TabPane>
        ))}
      </AntdTabs>
    </>
  ) : null
);

const mapStateToProps = ({ tabs, settings, general }) => ({ tabs, settings, general });
const mapDispatchToProps = dispatch => bindActionCreators(Creators, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Tabs);
