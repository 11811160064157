import React from 'react';
import { Tooltip } from 'antd';
import { openUrl } from '~/utils/general';

const Link = React.memo(({ url, children, title, tooltipProps, style, align, label, onClick = () => url && openUrl(url), className, ...rest }) => (
  <Tooltip title={title || url} destroyTooltipOnHide {...tooltipProps}>
    <a
      className={className || 'link-component'}
      onClick={onClick}
      onKeyPress={onClick}
      role="link"
      style={{ textAlign: align || 'left', width: align === 'center' ? '100%' : '', ...style }}
      {...rest}
    >
      {children || label}
    </a>
  </Tooltip>
));

export default Link;
