// Maranhão
const ma = [
  { data: '2100055', label: 'Açailândia' },
  { data: '2100105', label: 'Afonso Cunha' },
  { data: '2100154', label: 'Água Doce do Maranhão' },
  { data: '2100204', label: 'Alcântara' },
  { data: '2100303', label: 'Aldeias Altas' },
  { data: '2100402', label: 'Altamira do Maranhão' },
  { data: '2100436', label: 'Alto Alegre do Maranhão' },
  { data: '2100477', label: 'Alto Alegre do Pindaré' },
  { data: '2100501', label: 'Alto Parnaíba' },
  { data: '2100550', label: 'Amapá do Maranhão' },
  { data: '2100600', label: 'Amarante do Maranhão' },
  { data: '2100709', label: 'Anajatuba' },
  { data: '2100808', label: 'Anapurus' },
  { data: '2100832', label: 'Apicum-Açu' },
  { data: '2100873', label: 'Araguanã' },
  { data: '2100907', label: 'Araioses' },
  { data: '2100956', label: 'Arame' },
  { data: '2101004', label: 'Arari' },
  { data: '2101103', label: 'Axixá' },
  { data: '2101202', label: 'Bacabal' },
  { data: '2101251', label: 'Bacabeira' },
  { data: '2101301', label: 'Bacuri' },
  { data: '2101350', label: 'Bacurituba' },
  { data: '2101400', label: 'Balsas' },
  { data: '2101509', label: 'Barão de Grajaú' },
  { data: '2101608', label: 'Barra do Corda' },
  { data: '2101707', label: 'Barreirinhas' },
  { data: '2101772', label: 'Bela Vista do Maranhão' },
  { data: '2101731', label: 'Belágua' },
  { data: '2101806', label: 'Benedito Leite' },
  { data: '2101905', label: 'Bequimão' },
  { data: '2101939', label: 'Bernardo do Mearim' },
  { data: '2101970', label: 'Boa Vista do Gurupi' },
  { data: '2102002', label: 'Bom Jardim' },
  { data: '2102036', label: 'Bom Jesus das Selvas' },
  { data: '2102077', label: 'Bom Lugar' },
  { data: '2102101', label: 'Brejo' },
  { data: '2102150', label: 'Brejo de Areia' },
  { data: '2102200', label: 'Buriti' },
  { data: '2102309', label: 'Buriti Bravo' },
  { data: '2102325', label: 'Buriticupu' },
  { data: '2102358', label: 'Buritirana' },
  { data: '2102374', label: 'Cachoeira Grande' },
  { data: '2102408', label: 'Cajapió' },
  { data: '2102507', label: 'Cajari' },
  { data: '2102556', label: 'Campestre do Maranhão' },
  { data: '2102606', label: 'Cândido Mendes' },
  { data: '2102705', label: 'Cantanhede' },
  { data: '2102754', label: 'Capinzal do Norte' },
  { data: '2102804', label: 'Carolina' },
  { data: '2102903', label: 'Carutapera' },
  { data: '2103000', label: 'Caxias' },
  { data: '2103109', label: 'Cedral' },
  { data: '2103125', label: 'Central do Maranhão' },
  { data: '2103158', label: 'Centro do Guilherme' },
  { data: '2103174', label: 'Centro Novo do Maranhão' },
  { data: '2103208', label: 'Chapadinha' },
  { data: '2103257', label: 'Cidelândia' },
  { data: '2103307', label: 'Codó' },
  { data: '2103406', label: 'Coelho Neto' },
  { data: '2103505', label: 'Colinas' },
  { data: '2103554', label: 'Conceição do Lago-Açu' },
  { data: '2103604', label: 'Coroatá' },
  { data: '2103703', label: 'Cururupu' },
  { data: '2103752', label: 'Davinópolis' },
  { data: '2103802', label: 'Dom Pedro' },
  { data: '2103901', label: 'Duque Bacelar' },
  { data: '2104008', label: 'Esperantinópolis' },
  { data: '2104057', label: 'Estreito' },
  { data: '2104073', label: 'Feira Nova do Maranhão' },
  { data: '2104081', label: 'Fernando Falcão' },
  { data: '2104099', label: 'Formosa da Serra Negra' },
  { data: '2104107', label: 'Fortaleza dos Nogueiras' },
  { data: '2104206', label: 'Fortuna' },
  { data: '2104305', label: 'Godofredo Viana' },
  { data: '2104404', label: 'Gonçalves Dias' },
  { data: '2104503', label: 'Governador Archer' },
  { data: '2104552', label: 'Governador Edison Lobão' },
  { data: '2104602', label: 'Governador Eugênio Barros' },
  { data: '2104628', label: 'Governador Luiz Rocha' },
  { data: '2104651', label: 'Governador Newton Bello' },
  { data: '2104677', label: 'Governador Nunes Freire' },
  { data: '2104701', label: 'Graça Aranha' },
  { data: '2104800', label: 'Grajaú' },
  { data: '2104909', label: 'Guimarães' },
  { data: '2105005', label: 'Humberto de Campos' },
  { data: '2105104', label: 'Icatu' },
  { data: '2105153', label: 'Igarapé do Meio' },
  { data: '2105203', label: 'Igarapé Grande' },
  { data: '2105302', label: 'Imperatriz' },
  { data: '2105351', label: 'Itaipava do Grajaú' },
  { data: '2105401', label: 'Itapecuru Mirim' },
  { data: '2105427', label: 'Itinga do Maranhão' },
  { data: '2105450', label: 'Jatobá' },
  { data: '2105476', label: 'Jenipapo dos Vieiras' },
  { data: '2105500', label: 'João Lisboa' },
  { data: '2105609', label: 'Joselândia' },
  { data: '2105658', label: 'Junco do Maranhão' },
  { data: '2105708', label: 'Lago da Pedra' },
  { data: '2105807', label: 'Lago do Junco' },
  { data: '2105948', label: 'Lago dos Rodrigues' },
  { data: '2105906', label: 'Lago Verde' },
  { data: '2105922', label: 'Lagoa do Mato' },
  { data: '2105963', label: 'Lagoa Grande do Maranhão' },
  { data: '2105989', label: 'Lajeado Novo' },
  { data: '2106003', label: 'Lima Campos' },
  { data: '2106102', label: 'Loreto' },
  { data: '2106201', label: 'Luís Domingues' },
  { data: '2106300', label: 'Magalhães de Almeida' },
  { data: '2106326', label: 'Maracaçumé' },
  { data: '2106359', label: 'Marajá do Sena' },
  { data: '2106375', label: 'Maranhãozinho' },
  { data: '2106409', label: 'Mata Roma' },
  { data: '2106508', label: 'Matinha' },
  { data: '2106607', label: 'Matões' },
  { data: '2106631', label: 'Matões do Norte' },
  { data: '2106672', label: 'Milagres do Maranhão' },
  { data: '2106706', label: 'Mirador' },
  { data: '2106755', label: 'Miranda do Norte' },
  { data: '2106805', label: 'Mirinzal' },
  { data: '2106904', label: 'Monção' },
  { data: '2107001', label: 'Montes Altos' },
  { data: '2107100', label: 'Morros' },
  { data: '2107209', label: 'Nina Rodrigues' },
  { data: '2107258', label: 'Nova Colinas' },
  { data: '2107308', label: 'Nova Iorque' },
  { data: '2107357', label: 'Nova Olinda do Maranhão' },
  { data: '2107407', label: "Olho d'Água das Cunhãs" },
  { data: '2107456', label: 'Olinda Nova do Maranhão' },
  { data: '2107506', label: 'Paço do Lumiar' },
  { data: '2107605', label: 'Palmeirândia' },
  { data: '2107704', label: 'Paraibano' },
  { data: '2107803', label: 'Parnarama' },
  { data: '2107902', label: 'Passagem Franca' },
  { data: '2108009', label: 'Pastos Bons' },
  { data: '2108058', label: 'Paulino Neves' },
  { data: '2108108', label: 'Paulo Ramos' },
  { data: '2108207', label: 'Pedreiras' },
  { data: '2108256', label: 'Pedro do Rosário' },
  { data: '2108306', label: 'Penalva' },
  { data: '2108405', label: 'Peri Mirim' },
  { data: '2108454', label: 'Peritoró' },
  { data: '2108504', label: 'Pindaré-Mirim' },
  { data: '2108603', label: 'Pinheiro' },
  { data: '2108702', label: 'Pio XII' },
  { data: '2108801', label: 'Pirapemas' },
  { data: '2108900', label: 'Poção de Pedras' },
  { data: '2109007', label: 'Porto Franco' },
  { data: '2109056', label: 'Porto Rico do Maranhão' },
  { data: '2109106', label: 'Presidente Dutra' },
  { data: '2109205', label: 'Presidente Juscelino' },
  { data: '2109239', label: 'Presidente Médici' },
  { data: '2109270', label: 'Presidente Sarney' },
  { data: '2109304', label: 'Presidente Vargas' },
  { data: '2109403', label: 'Primeira Cruz' },
  { data: '2109452', label: 'Raposa' },
  { data: '2109502', label: 'Riachão' },
  { data: '2109551', label: 'Ribamar Fiquene' },
  { data: '2109601', label: 'Rosário' },
  { data: '2109700', label: 'Sambaíba' },
  { data: '2109759', label: 'Santa Filomena do Maranhão' },
  { data: '2109809', label: 'Santa Helena' },
  { data: '2109908', label: 'Santa Inês' },
  { data: '2110005', label: 'Santa Luzia' },
  { data: '2110039', label: 'Santa Luzia do Paruá' },
  { data: '2110104', label: 'Santa Quitéria do Maranhão' },
  { data: '2110203', label: 'Santa Rita' },
  { data: '2110237', label: 'Santana do Maranhão' },
  { data: '2110278', label: 'Santo Amaro do Maranhão' },
  { data: '2110302', label: 'Santo Antônio dos Lopes' },
  { data: '2110401', label: 'São Benedito do Rio Preto' },
  { data: '2110500', label: 'São Bento' },
  { data: '2110609', label: 'São Bernardo' },
  { data: '2110658', label: 'São Domingos do Azeitão' },
  { data: '2110708', label: 'São Domingos do Maranhão' },
  { data: '2110807', label: 'São Félix de Balsas' },
  { data: '2110856', label: 'São Francisco do Brejão' },
  { data: '2110906', label: 'São Francisco do Maranhão' },
  { data: '2111003', label: 'São João Batista' },
  { data: '2111029', label: 'São João do Carú' },
  { data: '2111052', label: 'São João do Paraíso' },
  { data: '2111078', label: 'São João do Soter' },
  { data: '2111102', label: 'São João dos Patos' },
  { data: '2111201', label: 'São José de Ribamar' },
  { data: '2111250', label: 'São José dos Basílios' },
  { data: '2111300', label: 'São Luís' },
  { data: '2111409', label: 'São Luís Gonzaga do Maranhão' },
  { data: '2111508', label: 'São Mateus do Maranhão' },
  { data: '2111532', label: 'São Pedro da Água Branca' },
  { data: '2111573', label: 'São Pedro dos Crentes' },
  { data: '2111607', label: 'São Raimundo das Mangabeiras' },
  { data: '2111631', label: 'São Raimundo do Doca Bezerra' },
  { data: '2111672', label: 'São Roberto' },
  { data: '2111706', label: 'São Vicente Ferrer' },
  { data: '2111722', label: 'Satubinha' },
  { data: '2111748', label: 'Senador Alexandre Costa' },
  { data: '2111763', label: 'Senador La Rocque' },
  { data: '2111789', label: 'Serrano do Maranhão' },
  { data: '2111805', label: 'Sítio Novo' },
  { data: '2111904', label: 'Sucupira do Norte' },
  { data: '2111953', label: 'Sucupira do Riachão' },
  { data: '2112001', label: 'Tasso Fragoso' },
  { data: '2112100', label: 'Timbiras' },
  { data: '2112209', label: 'Timon' },
  { data: '2112233', label: 'Trizidela do Vale' },
  { data: '2112274', label: 'Tufilândia' },
  { data: '2112308', label: 'Tuntum' },
  { data: '2112407', label: 'Turiaçu' },
  { data: '2112456', label: 'Turilândia' },
  { data: '2112506', label: 'Tutóia' },
  { data: '2112605', label: 'Urbano Santos' },
  { data: '2112704', label: 'Vargem Grande' },
  { data: '2112803', label: 'Viana' },
  { data: '2112852', label: 'Vila Nova dos Martírios' },
  { data: '2112902', label: 'Vitória do Mearim' },
  { data: '2113009', label: 'Vitorino Freire' },
  { data: '2114007', label: 'Zé Doca' },
];

export default ma;
