import React from 'react';
import { execFilter, groupByXY } from './functionsCharts';
import { Bar } from '..';

class BarDashboard extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { data, record, listTotalizar } = this.props;
    const valores = JSON.parse(atob(record.valores));
    const filtros = JSON.parse(atob(record.filtros));
    const totalizarSelected = listTotalizar.find(item => item.data === valores.agrupamento_bar);

    let dataSourceBar = execFilter(data, filtros);
    dataSourceBar = groupByXY(dataSourceBar, valores.analise_bar, valores.agrupamento_bar, false);

    return <Bar height={250} data={dataSourceBar} type={totalizarSelected.tipo ? 'integer' : 'currency'} />;
  }
}

export default BarDashboard;
