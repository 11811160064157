const upsert = (collection, newObj, dataIndex = 'uuid') => {
  const index = collection.findIndex(item => item[dataIndex] === newObj[dataIndex]);
  if (index === -1) return [...collection, newObj];

  const newCollection = collection.slice();
  newCollection[index] = newObj;

  return newCollection;
};

export default upsert;
