import React from 'react';
import { UploadOutlined } from '@ant-design/icons';
import { Upload as AntdUpload } from 'antd';
import { Button } from '~/components';
import './Upload.less';
import { CustomerService } from '~/services';

const Upload = React.memo(({ label, onComplete, diretorio, fileList, beforeUpload, onRemove, ...rest }) => (
  <AntdUpload
    progress={{
      strokeColor: {
        '0%': '#108ee9',
        '100%': '#87d068',
      },
      strokeWidth: 5,
      format: percent => `${parseFloat(percent.toFixed())}%`,
    }}
    onChange={info => {
      if (fileList && info.file.status === 'done' && onComplete) onComplete(info.file.name);
      else if (!fileList && onComplete) onComplete(info.file.name);
    }}
    beforeUpload={beforeUpload}
    onRemove={onRemove}
    action={`${process.env.REACT_APP_URL}/arquivo/upload_react.php?diretorio=${diretorio}&cliente=${CustomerService.getCustomer()
      .nome.replace(/[\s+]/g, '_')
      .toLowerCase()}`}
    {...rest}
  >
    <Button.Default label={label || 'Selecionar o arquivo'} icon={<UploadOutlined />} {...rest} />
  </AntdUpload>
));

export default Upload;
