// Santa Catarina
const sc = [
  { data: '4200051', label: 'Abdon Batista' },
  { data: '4200101', label: 'Abelardo Luz' },
  { data: '4200200', label: 'Agrolândia' },
  { data: '4200309', label: 'Agronômica' },
  { data: '4200408', label: 'Água Doce' },
  { data: '4200507', label: 'Águas de Chapecó' },
  { data: '4200556', label: 'Águas Frias' },
  { data: '4200606', label: 'Águas Mornas' },
  { data: '4200705', label: 'Alfredo Wagner' },
  { data: '4200754', label: 'Alto Bela Vista' },
  { data: '4200804', label: 'Anchieta' },
  { data: '4200903', label: 'Angelina' },
  { data: '4201000', label: 'Anita Garibaldi' },
  { data: '4201109', label: 'Anitápolis' },
  { data: '4201208', label: 'Antônio Carlos' },
  { data: '4201257', label: 'Apiúna' },
  { data: '4201273', label: 'Arabutã' },
  { data: '4201307', label: 'Araquari' },
  { data: '4201406', label: 'Araranguá' },
  { data: '4201505', label: 'Armazém' },
  { data: '4201604', label: 'Arroio Trinta' },
  { data: '4201653', label: 'Arvoredo' },
  { data: '4201703', label: 'Ascurra' },
  { data: '4201802', label: 'Atalanta' },
  { data: '4201901', label: 'Aurora' },
  { data: '4201950', label: 'Balneário Arroio do Silva' },
  { data: '4202057', label: 'Balneário Barra do Sul' },
  { data: '4202008', label: 'Balneário Camboriú' },
  { data: '4202073', label: 'Balneário Gaivota' },
  { data: '4212809', label: 'Balneário Piçarras' },
  { data: '4202081', label: 'Bandeirante' },
  { data: '4202099', label: 'Barra Bonita' },
  { data: '4202107', label: 'Barra Velha' },
  { data: '4202131', label: 'Bela Vista do Toldo' },
  { data: '4202156', label: 'Belmonte' },
  { data: '4202206', label: 'Benedito Novo' },
  { data: '4202305', label: 'Biguaçu' },
  { data: '4202404', label: 'Blumenau' },
  { data: '4202438', label: 'Bocaina do Sul' },
  { data: '4202503', label: 'Bom Jardim da Serra' },
  { data: '4202537', label: 'Bom Jesus' },
  { data: '4202578', label: 'Bom Jesus do Oeste' },
  { data: '4202602', label: 'Bom Retiro' },
  { data: '4202453', label: 'Bombinhas' },
  { data: '4202701', label: 'Botuverá' },
  { data: '4202800', label: 'Braço do Norte' },
  { data: '4202859', label: 'Braço do Trombudo' },
  { data: '4202875', label: 'Brunópolis' },
  { data: '4202909', label: 'Brusque' },
  { data: '4203006', label: 'Caçador' },
  { data: '4203105', label: 'Caibi' },
  { data: '4203154', label: 'Calmon' },
  { data: '4203204', label: 'Camboriú' },
  { data: '4203303', label: 'Campo Alegre' },
  { data: '4203402', label: 'Campo Belo do Sul' },
  { data: '4203501', label: 'Campo Erê' },
  { data: '4203600', label: 'Campos Novos' },
  { data: '4203709', label: 'Canelinha' },
  { data: '4203808', label: 'Canoinhas' },
  { data: '4203253', label: 'Capão Alto' },
  { data: '4203907', label: 'Capinzal' },
  { data: '4203956', label: 'Capivari de Baixo' },
  { data: '4204004', label: 'Catanduvas' },
  { data: '4204103', label: 'Caxambu do Sul' },
  { data: '4204152', label: 'Celso Ramos' },
  { data: '4204178', label: 'Cerro Negro' },
  { data: '4204194', label: 'Chapadão do Lageado' },
  { data: '4204202', label: 'Chapecó' },
  { data: '4204251', label: 'Cocal do Sul' },
  { data: '4204301', label: 'Concórdia' },
  { data: '4204350', label: 'Cordilheira Alta' },
  { data: '4204400', label: 'Coronel Freitas' },
  { data: '4204459', label: 'Coronel Martins' },
  { data: '4204558', label: 'Correia Pinto' },
  { data: '4204509', label: 'Corupá' },
  { data: '4204608', label: 'Criciúma' },
  { data: '4204707', label: 'Cunha Porã' },
  { data: '4204756', label: 'Cunhataí' },
  { data: '4204806', label: 'Curitibanos' },
  { data: '4204905', label: 'Descanso' },
  { data: '4205001', label: 'Dionísio Cerqueira' },
  { data: '4205100', label: 'Dona Emma' },
  { data: '4205159', label: 'Doutor Pedrinho' },
  { data: '4205175', label: 'Entre Rios' },
  { data: '4205191', label: 'Ermo' },
  { data: '4205209', label: 'Erval Velho' },
  { data: '4205308', label: 'Faxinal dos Guedes' },
  { data: '4205357', label: 'Flor do Sertão' },
  { data: '4205407', label: 'Florianópolis' },
  { data: '4205431', label: 'Formosa do Sul' },
  { data: '4205456', label: 'Forquilhinha' },
  { data: '4205506', label: 'Fraiburgo' },
  { data: '4205555', label: 'Frei Rogério' },
  { data: '4205605', label: 'Galvão' },
  { data: '4205704', label: 'Garopaba' },
  { data: '4205803', label: 'Garuva' },
  { data: '4205902', label: 'Gaspar' },
  { data: '4206009', label: 'Governador Celso Ramos' },
  { data: '4206108', label: 'Grão Pará' },
  { data: '4206207', label: 'Gravatal' },
  { data: '4206306', label: 'Guabiruba' },
  { data: '4206405', label: 'Guaraciaba' },
  { data: '4206504', label: 'Guaramirim' },
  { data: '4206603', label: 'Guarujá do Sul' },
  { data: '4206652', label: 'Guatambú' },
  { data: '4206702', label: "Herval d'Oeste" },
  { data: '4206751', label: 'Ibiam' },
  { data: '4206801', label: 'Ibicaré' },
  { data: '4206900', label: 'Ibirama' },
  { data: '4207007', label: 'Içara' },
  { data: '4207106', label: 'Ilhota' },
  { data: '4207205', label: 'Imaruí' },
  { data: '4207304', label: 'Imbituba' },
  { data: '4207403', label: 'Imbuia' },
  { data: '4207502', label: 'Indaial' },
  { data: '4207577', label: 'Iomerê' },
  { data: '4207601', label: 'Ipira' },
  { data: '4207650', label: 'Iporã do Oeste' },
  { data: '4207684', label: 'Ipuaçu' },
  { data: '4207700', label: 'Ipumirim' },
  { data: '4207759', label: 'Iraceminha' },
  { data: '4207809', label: 'Irani' },
  { data: '4207858', label: 'Irati' },
  { data: '4207908', label: 'Irineópolis' },
  { data: '4208005', label: 'Itá' },
  { data: '4208104', label: 'Itaiópolis' },
  { data: '4208203', label: 'Itajaí' },
  { data: '4208302', label: 'Itapema' },
  { data: '4208401', label: 'Itapiranga' },
  { data: '4208450', label: 'Itapoá' },
  { data: '4208500', label: 'Ituporanga' },
  { data: '4208609', label: 'Jaborá' },
  { data: '4208708', label: 'Jacinto Machado' },
  { data: '4208807', label: 'Jaguaruna' },
  { data: '4208906', label: 'Jaraguá do Sul' },
  { data: '4208955', label: 'Jardinópolis' },
  { data: '4209003', label: 'Joaçaba' },
  { data: '4209102', label: 'Joinville' },
  { data: '4209151', label: 'José Boiteux' },
  { data: '4209177', label: 'Jupiá' },
  { data: '4209201', label: 'Lacerdópolis' },
  { data: '4209300', label: 'Lages' },
  { data: '4209409', label: 'Laguna' },
  { data: '4209458', label: 'Lajeado Grande' },
  { data: '4209508', label: 'Laurentino' },
  { data: '4209607', label: 'Lauro Muller' },
  { data: '4209706', label: 'Lebon Régis' },
  { data: '4209805', label: 'Leoberto Leal' },
  { data: '4209854', label: 'Lindóia do Sul' },
  { data: '4209904', label: 'Lontras' },
  { data: '4210001', label: 'Luiz Alves' },
  { data: '4210035', label: 'Luzerna' },
  { data: '4210050', label: 'Macieira' },
  { data: '4210100', label: 'Mafra' },
  { data: '4210209', label: 'Major Gercino' },
  { data: '4210308', label: 'Major Vieira' },
  { data: '4210407', label: 'Maracajá' },
  { data: '4210506', label: 'Maravilha' },
  { data: '4210555', label: 'Marema' },
  { data: '4210605', label: 'Massaranduba' },
  { data: '4210704', label: 'Matos Costa' },
  { data: '4210803', label: 'Meleiro' },
  { data: '4210852', label: 'Mirim Doce' },
  { data: '4210902', label: 'Modelo' },
  { data: '4211009', label: 'Mondaí' },
  { data: '4211058', label: 'Monte Carlo' },
  { data: '4211108', label: 'Monte Castelo' },
  { data: '4211207', label: 'Morro da Fumaça' },
  { data: '4211256', label: 'Morro Grande' },
  { data: '4211306', label: 'Navegantes' },
  { data: '4211405', label: 'Nova Erechim' },
  { data: '4211454', label: 'Nova Itaberaba' },
  { data: '4211504', label: 'Nova Trento' },
  { data: '4211603', label: 'Nova Veneza' },
  { data: '4211652', label: 'Novo Horizonte' },
  { data: '4211702', label: 'Orleans' },
  { data: '4211751', label: 'Otacílio Costa' },
  { data: '4211801', label: 'Ouro' },
  { data: '4211850', label: 'Ouro Verde' },
  { data: '4211876', label: 'Paial' },
  { data: '4211892', label: 'Painel' },
  { data: '4211900', label: 'Palhoça' },
  { data: '4212007', label: 'Palma Sola' },
  { data: '4212056', label: 'Palmeira' },
  { data: '4212106', label: 'Palmitos' },
  { data: '4212205', label: 'Papanduva' },
  { data: '4212239', label: 'Paraíso' },
  { data: '4212254', label: 'Passo de Torres' },
  { data: '4212270', label: 'Passos Maia' },
  { data: '4212304', label: 'Paulo Lopes' },
  { data: '4212403', label: 'Pedras Grandes' },
  { data: '4212502', label: 'Penha' },
  { data: '4212601', label: 'Peritiba' },
  { data: '4212650', label: 'Pescaria Brava' },
  { data: '4212700', label: 'Petrolândia' },
  { data: '4212908', label: 'Pinhalzinho' },
  { data: '4213005', label: 'Pinheiro Preto' },
  { data: '4213104', label: 'Piratuba' },
  { data: '4213153', label: 'Planalto Alegre' },
  { data: '4213203', label: 'Pomerode' },
  { data: '4213302', label: 'Ponte Alta' },
  { data: '4213351', label: 'Ponte Alta do Norte' },
  { data: '4213401', label: 'Ponte Serrada' },
  { data: '4213500', label: 'Porto Belo' },
  { data: '4213609', label: 'Porto União' },
  { data: '4213708', label: 'Pouso Redondo' },
  { data: '4213807', label: 'Praia Grande' },
  { data: '4213906', label: 'Presidente Castello Branco' },
  { data: '4214003', label: 'Presidente Getúlio' },
  { data: '4214102', label: 'Presidente Nereu' },
  { data: '4214151', label: 'Princesa' },
  { data: '4214201', label: 'Quilombo' },
  { data: '4214300', label: 'Rancho Queimado' },
  { data: '4214409', label: 'Rio das Antas' },
  { data: '4214508', label: 'Rio do Campo' },
  { data: '4214607', label: 'Rio do Oeste' },
  { data: '4214805', label: 'Rio do Sul' },
  { data: '4214706', label: 'Rio dos Cedros' },
  { data: '4214904', label: 'Rio Fortuna' },
  { data: '4215000', label: 'Rio Negrinho' },
  { data: '4215059', label: 'Rio Rufino' },
  { data: '4215075', label: 'Riqueza' },
  { data: '4215109', label: 'Rodeio' },
  { data: '4215208', label: 'Romelândia' },
  { data: '4215307', label: 'Salete' },
  { data: '4215356', label: 'Saltinho' },
  { data: '4215406', label: 'Salto Veloso' },
  { data: '4215455', label: 'Sangão' },
  { data: '4215505', label: 'Santa Cecília' },
  { data: '4215554', label: 'Santa Helena' },
  { data: '4215604', label: 'Santa Rosa de Lima' },
  { data: '4215653', label: 'Santa Rosa do Sul' },
  { data: '4215679', label: 'Santa Terezinha' },
  { data: '4215687', label: 'Santa Terezinha do Progresso' },
  { data: '4215695', label: 'Santiago do Sul' },
  { data: '4215703', label: 'Santo Amaro da Imperatriz' },
  { data: '4215802', label: 'São Bento do Sul' },
  { data: '4215752', label: 'São Bernardino' },
  { data: '4215901', label: 'São Bonifácio' },
  { data: '4216008', label: 'São Carlos' },
  { data: '4216057', label: 'São Cristovão do Sul' },
  { data: '4216107', label: 'São Domingos' },
  { data: '4216206', label: 'São Francisco do Sul' },
  { data: '4216305', label: 'São João Batista' },
  { data: '4216354', label: 'São João do Itaperiú' },
  { data: '4216255', label: 'São João do Oeste' },
  { data: '4216404', label: 'São João do Sul' },
  { data: '4216503', label: 'São Joaquim' },
  { data: '4216602', label: 'São José' },
  { data: '4216701', label: 'São José do Cedro' },
  { data: '4216800', label: 'São José do Cerrito' },
  { data: '4216909', label: 'São Lourenço do Oeste' },
  { data: '4217006', label: 'São Ludgero' },
  { data: '4217105', label: 'São Martinho' },
  { data: '4217154', label: 'São Miguel da Boa Vista' },
  { data: '4217204', label: 'São Miguel do Oeste' },
  { data: '4217253', label: 'São Pedro de Alcântara' },
  { data: '4217303', label: 'Saudades' },
  { data: '4217402', label: 'Schroeder' },
  { data: '4217501', label: 'Seara' },
  { data: '4217550', label: 'Serra Alta' },
  { data: '4217600', label: 'Siderópolis' },
  { data: '4217709', label: 'Sombrio' },
  { data: '4217758', label: 'Sul Brasil' },
  { data: '4217808', label: 'Taió' },
  { data: '4217907', label: 'Tangará' },
  { data: '4217956', label: 'Tigrinhos' },
  { data: '4218004', label: 'Tijucas' },
  { data: '4218103', label: 'Timbé do Sul' },
  { data: '4218202', label: 'Timbó' },
  { data: '4218251', label: 'Timbó Grande' },
  { data: '4218301', label: 'Três Barras' },
  { data: '4218350', label: 'Treviso' },
  { data: '4218400', label: 'Treze de Maio' },
  { data: '4218509', label: 'Treze Tílias' },
  { data: '4218608', label: 'Trombudo Central' },
  { data: '4218707', label: 'Tubarão' },
  { data: '4218756', label: 'Tunápolis' },
  { data: '4218806', label: 'Turvo' },
  { data: '4218855', label: 'União do Oeste' },
  { data: '4218905', label: 'Urubici' },
  { data: '4218954', label: 'Urupema' },
  { data: '4219002', label: 'Urussanga' },
  { data: '4219101', label: 'Vargeão' },
  { data: '4219150', label: 'Vargem' },
  { data: '4219176', label: 'Vargem Bonita' },
  { data: '4219200', label: 'Vidal Ramos' },
  { data: '4219309', label: 'Videira' },
  { data: '4219358', label: 'Vitor Meireles' },
  { data: '4219408', label: 'Witmarsum' },
  { data: '4219507', label: 'Xanxerê' },
  { data: '4219606', label: 'Xavantina' },
  { data: '4219705', label: 'Xaxim' },
  { data: '4219853', label: 'Zortéa' },
  { data: '4220000', label: 'Balneário Rincão' },
];

export default sc;
