// Ceará
const ce = [
  { data: '2300101', label: 'Abaiara' },
  { data: '2300150', label: 'Acarape' },
  { data: '2300200', label: 'Acaraú' },
  { data: '2300309', label: 'Acopiara' },
  { data: '2300408', label: 'Aiuaba' },
  { data: '2300507', label: 'Alcântaras' },
  { data: '2300606', label: 'Altaneira' },
  { data: '2300705', label: 'Alto Santo' },
  { data: '2300754', label: 'Amontada' },
  { data: '2300804', label: 'Antonina do Norte' },
  { data: '2300903', label: 'Apuiarés' },
  { data: '2301000', label: 'Aquiraz' },
  { data: '2301109', label: 'Aracati' },
  { data: '2301208', label: 'Aracoiaba' },
  { data: '2301257', label: 'Ararendá' },
  { data: '2301307', label: 'Araripe' },
  { data: '2301406', label: 'Aratuba' },
  { data: '2301505', label: 'Arneiroz' },
  { data: '2301604', label: 'Assaré' },
  { data: '2301703', label: 'Aurora' },
  { data: '2301802', label: 'Baixio' },
  { data: '2301851', label: 'Banabuiú' },
  { data: '2301901', label: 'Barbalha' },
  { data: '2301950', label: 'Barreira' },
  { data: '2302008', label: 'Barro' },
  { data: '2302057', label: 'Barroquinha' },
  { data: '2302107', label: 'Baturité' },
  { data: '2302206', label: 'Beberibe' },
  { data: '2302305', label: 'Bela Cruz' },
  { data: '2302404', label: 'Boa Viagem' },
  { data: '2302503', label: 'Brejo Santo' },
  { data: '2302602', label: 'Camocim' },
  { data: '2302701', label: 'Campos Sales' },
  { data: '2302800', label: 'Canindé' },
  { data: '2302909', label: 'Capistrano' },
  { data: '2303006', label: 'Caridade' },
  { data: '2303105', label: 'Cariré' },
  { data: '2303204', label: 'Caririaçu' },
  { data: '2303303', label: 'Cariús' },
  { data: '2303402', label: 'Carnaubal' },
  { data: '2303501', label: 'Cascavel' },
  { data: '2303600', label: 'Catarina' },
  { data: '2303659', label: 'Catunda' },
  { data: '2303709', label: 'Caucaia' },
  { data: '2303808', label: 'Cedro' },
  { data: '2303907', label: 'Chaval' },
  { data: '2303931', label: 'Choró' },
  { data: '2303956', label: 'Chorozinho' },
  { data: '2304004', label: 'Coreaú' },
  { data: '2304103', label: 'Crateús' },
  { data: '2304202', label: 'Crato' },
  { data: '2304236', label: 'Croatá' },
  { data: '2304251', label: 'Cruz' },
  { data: '2304269', label: 'Deputado Irapuan Pinheiro' },
  { data: '2304277', label: 'Ererê' },
  { data: '2304285', label: 'Eusébio' },
  { data: '2304301', label: 'Farias Brito' },
  { data: '2304350', label: 'Forquilha' },
  { data: '2304400', label: 'Fortaleza' },
  { data: '2304459', label: 'Fortim' },
  { data: '2304509', label: 'Frecheirinha' },
  { data: '2304608', label: 'General Sampaio' },
  { data: '2304657', label: 'Graça' },
  { data: '2304707', label: 'Granja' },
  { data: '2304806', label: 'Granjeiro' },
  { data: '2304905', label: 'Groaíras' },
  { data: '2304954', label: 'Guaiúba' },
  { data: '2305001', label: 'Guaraciaba do Norte' },
  { data: '2305100', label: 'Guaramiranga' },
  { data: '2305209', label: 'Hidrolândia' },
  { data: '2305233', label: 'Horizonte' },
  { data: '2305266', label: 'Ibaretama' },
  { data: '2305308', label: 'Ibiapina' },
  { data: '2305332', label: 'Ibicuitinga' },
  { data: '2305357', label: 'Icapuí' },
  { data: '2305407', label: 'Icó' },
  { data: '2305506', label: 'Iguatu' },
  { data: '2305605', label: 'Independência' },
  { data: '2305654', label: 'Ipaporanga' },
  { data: '2305704', label: 'Ipaumirim' },
  { data: '2305803', label: 'Ipu' },
  { data: '2305902', label: 'Ipueiras' },
  { data: '2306009', label: 'Iracema' },
  { data: '2306108', label: 'Irauçuba' },
  { data: '2306207', label: 'Itaiçaba' },
  { data: '2306256', label: 'Itaitinga' },
  { data: '2306306', label: 'Itapajé**' },
  { data: '2306405', label: 'Itapipoca' },
  { data: '2306504', label: 'Itapiúna' },
  { data: '2306553', label: 'Itarema' },
  { data: '2306603', label: 'Itatira' },
  { data: '2306702', label: 'Jaguaretama' },
  { data: '2306801', label: 'Jaguaribara' },
  { data: '2306900', label: 'Jaguaribe' },
  { data: '2307007', label: 'Jaguaruana' },
  { data: '2307106', label: 'Jardim' },
  { data: '2307205', label: 'Jati' },
  { data: '2307254', label: 'Jijoca de Jericoacoara' },
  { data: '2307304', label: 'Juazeiro do Norte' },
  { data: '2307403', label: 'Jucás' },
  { data: '2307502', label: 'Lavras da Mangabeira' },
  { data: '2307601', label: 'Limoeiro do Norte' },
  { data: '2307635', label: 'Madalena' },
  { data: '2307650', label: 'Maracanaú' },
  { data: '2307700', label: 'Maranguape' },
  { data: '2307809', label: 'Marco' },
  { data: '2307908', label: 'Martinópole' },
  { data: '2308005', label: 'Massapê' },
  { data: '2308104', label: 'Mauriti' },
  { data: '2308203', label: 'Meruoca' },
  { data: '2308302', label: 'Milagres' },
  { data: '2308351', label: 'Milhã' },
  { data: '2308377', label: 'Miraíma' },
  { data: '2308401', label: 'Missão Velha' },
  { data: '2308500', label: 'Mombaça' },
  { data: '2308609', label: 'Monsenhor Tabosa' },
  { data: '2308708', label: 'Morada Nova' },
  { data: '2308807', label: 'Moraújo' },
  { data: '2308906', label: 'Morrinhos' },
  { data: '2309003', label: 'Mucambo' },
  { data: '2309102', label: 'Mulungu' },
  { data: '2309201', label: 'Nova Olinda' },
  { data: '2309300', label: 'Nova Russas' },
  { data: '2309409', label: 'Novo Oriente' },
  { data: '2309458', label: 'Ocara' },
  { data: '2309508', label: 'Orós' },
  { data: '2309607', label: 'Pacajus' },
  { data: '2309706', label: 'Pacatuba' },
  { data: '2309805', label: 'Pacoti' },
  { data: '2309904', label: 'Pacujá' },
  { data: '2310001', label: 'Palhano' },
  { data: '2310100', label: 'Palmácia' },
  { data: '2310209', label: 'Paracuru' },
  { data: '2310258', label: 'Paraipaba' },
  { data: '2310308', label: 'Parambu' },
  { data: '2310407', label: 'Paramoti' },
  { data: '2310506', label: 'Pedra Branca' },
  { data: '2310605', label: 'Penaforte' },
  { data: '2310704', label: 'Pentecoste' },
  { data: '2310803', label: 'Pereiro' },
  { data: '2310852', label: 'Pindoretama' },
  { data: '2310902', label: 'Piquet Carneiro' },
  { data: '2310951', label: 'Pires Ferreira' },
  { data: '2311009', label: 'Poranga' },
  { data: '2311108', label: 'Porteiras' },
  { data: '2311207', label: 'Potengi' },
  { data: '2311231', label: 'Potiretama' },
  { data: '2311264', label: 'Quiterianópolis' },
  { data: '2311306', label: 'Quixadá' },
  { data: '2311355', label: 'Quixelô' },
  { data: '2311405', label: 'Quixeramobim' },
  { data: '2311504', label: 'Quixeré' },
  { data: '2311603', label: 'Redenção' },
  { data: '2311702', label: 'Reriutaba' },
  { data: '2311801', label: 'Russas' },
  { data: '2311900', label: 'Saboeiro' },
  { data: '2311959', label: 'Salitre' },
  { data: '2312205', label: 'Santa Quitéria' },
  { data: '2312007', label: 'Santana do Acaraú' },
  { data: '2312106', label: 'Santana do Cariri' },
  { data: '2312304', label: 'São Benedito' },
  { data: '2312403', label: 'São Gonçalo do Amarante' },
  { data: '2312502', label: 'São João do Jaguaribe' },
  { data: '2312601', label: 'São Luís do Curu' },
  { data: '2312700', label: 'Senador Pompeu' },
  { data: '2312809', label: 'Senador Sá' },
  { data: '2312908', label: 'Sobral' },
  { data: '2313005', label: 'Solonópole' },
  { data: '2313104', label: 'Tabuleiro do Norte' },
  { data: '2313203', label: 'Tamboril' },
  { data: '2313252', label: 'Tarrafas' },
  { data: '2313302', label: 'Tauá' },
  { data: '2313351', label: 'Tejuçuoca' },
  { data: '2313401', label: 'Tianguá' },
  { data: '2313500', label: 'Trairi' },
  { data: '2313559', label: 'Tururu' },
  { data: '2313609', label: 'Ubajara' },
  { data: '2313708', label: 'Umari' },
  { data: '2313757', label: 'Umirim' },
  { data: '2313807', label: 'Uruburetama' },
  { data: '2313906', label: 'Uruoca' },
  { data: '2313955', label: 'Varjota' },
  { data: '2314003', label: 'Várzea Alegre' },
  { data: '2314102', label: 'Viçosa do Ceará' },
];

export default ce;
