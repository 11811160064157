const filterColumns = (dataSource, filters) => {
  let listRetorno = dataSource;
  Object.keys(filters)
    .filter(key => filters[key])
    .forEach(key => {
      const value = filters[key][0];
      if (value === 'menor' || value === 'igual' || value === 'maior') {
        if (value === 'menor') {
          listRetorno = listRetorno.filter(item => parseFloat(item[key]) < 0);
        }
        if (value === 'igual') {
          listRetorno = listRetorno.filter(item => parseFloat(item[key]) === 0);
        }
        if (value === 'maior') {
          listRetorno = listRetorno.filter(item => parseFloat(item[key]) > 0);
        }
      } else {
        listRetorno = listRetorno.filter(item => item[key].indexOf(value.toUpperCase()) > -1);
      }
    });
  return listRetorno;
};

export default filterColumns;
