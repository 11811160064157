import React from 'react';
import classNames from 'classnames';
import { useField } from 'informed';
import { Switch as AntdSwitch } from 'antd';
import { Form } from '~/components/forms';

const Switch = React.memo(props => {
  const { fieldState, fieldApi, render, userProps } = useField({ ...props, initialValue: props.initialValue || 'N' });
  const { onChange, label, className, forwardedRef, block, initialValue, disabledReason, tooltip, tooltipProps, ...rest } = userProps;

  if (!fieldState.value) {
    setTimeout(() => {
      fieldApi.setValue(initialValue || 'N');
    }, 300);
  }

  return render(
    <Form.Item error={fieldState.error} label={label} labelHorizontal>
      <AntdSwitch
        className={classNames(block ? 'ant-checkbox-wrapper-block' : '', className)}
        value={fieldState.value || initialValue || 'N'}
        checked={fieldState.value === 'S'}
        ref={forwardedRef}
        onChange={checked => {
          fieldApi.setValue(checked ? 'S' : 'N');

          if (onChange) {
            onChange(checked);
          }
        }}
        {...rest}
      />
    </Form.Item>
  );
});

export default Switch;
