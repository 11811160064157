import React from 'react';
import { Statistic as StatisticAntd } from 'antd';
import { connect } from 'react-redux';
import { decimalPlace } from '~/utils';

const Statistic = React.memo(({ color, precision = 2, customizado, prefix = 'R$ ', sufix, general, title, value, sizeMobile, ...rest }) =>
  general.isMobile ? (
    <div style={{ width: sizeMobile || '100%' }}>
      <span style={{ color: color || undefined }}>{`${title}: `}</span>
      <span>{decimalPlace(value, precision)}</span>
    </div>
  ) : (
    <StatisticAntd
      valueStyle={{ color }}
      precision={precision}
      title={title}
      value={value}
      prefix={prefix}
      sufix={customizado ? sufix : ''}
      decimalSeparator=","
      groupSeparator="."
      {...rest}
    />
  )
);

const mapStateToProps = ({ general }) => ({ general });
export default connect(mapStateToProps)(Statistic);
