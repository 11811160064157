import React from 'react';
import { createSelector } from 'reselect';
import { connect } from 'react-redux';
import { useField } from 'informed';
import { Select as AntdSelect, Tooltip } from 'antd';
import { Form, SelectMobile } from '~/components/forms';
import { isRequired } from '~/utils';

const { Option } = AntdSelect;

const Select = React.memo(({ required, validate, validateOnChange, ...props }) => {
  const { fieldState, fieldApi, render, userProps } = useField({
    validate: validate || (required && isRequired) || (() => undefined),
    validateOnChange: validateOnChange || required || validate,
    ...props,
  });

  const {
    onChange,
    onBlur,
    label,
    forwardedRef,
    dataSource,
    initialValue,
    dataIndex = 'data',
    labelIndex = 'label',
    renderIndex = item => item[labelIndex],
    allowClear,
    disabledReason,
    formItemProps,
    tooltipProps,
    tooltip,
    forceZero,
    mode,
    renderOption,
    isMobile,
    ...rest
  } = userProps;

  function handleOnChange(changeValue, changeOption) {
    fieldApi.setValue(changeValue || '');

    if (onChange) {
      onChange(changeValue, changeOption);
    }
  }

  function handleOnBlur(event) {
    fieldApi.setTouched();

    if (onBlur) {
      onBlur(event);
    }
  }

  let fieldError = fieldState.error;
  if (isRequired(fieldState.value) && !required) {
    fieldError = undefined;
  }

  return render(
    isMobile ? (
      <SelectMobile {...props} />
    ) : (
      <Form.Item label={label} error={fieldError} required={required} {...formItemProps}>
        <Tooltip title={(rest.disabled && disabledReason) || tooltip} color="red" {...tooltipProps}>
          <AntdSelect
            autoComplete="off"
            value={
              (fieldState.value === '0' && !forceZero) ||
              (mode !== 'multiple' && dataSource && !dataSource.find(item => item[dataIndex] === fieldState.value)) ||
              (mode === 'multiple' && fieldState.value && !dataSource.find(item => fieldState.value.includes(item[dataIndex])))
                ? mode === 'multiple'
                  ? []
                  : ''
                : fieldState.value || initialValue
            }
            ref={forwardedRef}
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            showSearch
            mode={mode}
            allowClear={(allowClear || true) && (mode === 'multiple' || (mode !== 'multiple' && !required))}
            optionFilterProp="children"
            filterOption={(input, option) => option.children && option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            {...rest}
          >
            {dataSource &&
              dataSource.map(item => (
                <Option value={item[dataIndex]} key={item[dataIndex]}>
                  {renderOption ? renderOption(item) : renderIndex(item)}
                </Option>
              ))}
          </AntdSelect>
        </Tooltip>
      </Form.Item>
    )
  );
});

Select.Option = Option;

export { Option };

const isMobileSelector = createSelector(
  general => general,
  ({ isMobile }) => isMobile
);

const mapStateToProps = ({ general }) => ({ isMobile: isMobileSelector(general) });

export default connect(mapStateToProps)(Select);
