// redux
const REDUX_PERSIST_KEY = `redux-persist-${process.env.REACT_APP_NAME}`;
const REDUX_RESET_KEY = `redux-reset-${process.env.REACT_APP_NAME}`;
const REDUX_SECRET_KEY = `redux-secret-${process.env.REACT_APP_NAME}`;

// pubsub
const PUBSUB_SETTINGS = `pubsub-settings-${process.env.REACT_APP_NAME}`;
const PUBSUB_EMPRESAS = `pubsub-empresas-${process.env.REACT_APP_NAME}`;
const PUBSUB_ABOUT = `pubsub-about-${process.env.REACT_APP_NAME}`;
const PUBSUB_RELEASE_NOTE = `pubsub-release-note-${process.env.REACT_APP_NAME}`;

// app
const APP_NAME = 'Portal do Cliente';
const APP_DESCRIPTION = 'MySoft Sistemas';

// company
const COMPANY_NAME = 'Mysoft Sistemas';
const COMPANY_URL = 'mysoftsistemas.com.br';
const COMPANY_EMAIL = 'contato@mysoftsistemas.com.br';
const COMPANY_SUPPORT_EMAIL = 'suporte@mysoftsistemas.com.br';

export default {
  // redux
  REDUX_PERSIST_KEY,
  REDUX_RESET_KEY,
  REDUX_SECRET_KEY,
  // pubsub
  PUBSUB_SETTINGS,
  PUBSUB_EMPRESAS,
  PUBSUB_ABOUT,
  PUBSUB_RELEASE_NOTE,
  // app
  APP_NAME,
  APP_DESCRIPTION,
  // company
  COMPANY_NAME,
  COMPANY_URL,
  COMPANY_EMAIL,
  COMPANY_SUPPORT_EMAIL,
};
