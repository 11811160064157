import React, { useState } from 'react';
import classNames from 'classnames';
import { asField } from 'informed';
import { CheckList, Popup, SearchBar, Space } from 'antd-mobile';
import { Form } from '~/components/forms';
import './SelectMobile.less';

const SelectMobile = React.memo(({ required, fieldState, fieldApi, forwardedRef, ...props }) => {
  const { onChange, label, dataSource, initialValue, disabled, dataIndex = 'data', labelIndex = 'label' } = props;
  const [visible, setVisible] = useState(false);
  const [searchText, setSearchText] = useState('');
  const filteredItems = searchText ? dataSource.filter(item => item[labelIndex].includes(searchText.toUpperCase())) : dataSource;
  const selected = dataSource.find(item => item[dataIndex] === fieldState.value || initialValue);

  return (
    <Form.Item
      error={fieldState.error}
      required={required}
      label={label}
      className={classNames('form-item-select-mobile', disabled ? 'select-mobile-disabled' : '')}
      onClick={() => {
        setSearchText('');
        setVisible(true);
        fieldApi.setTouched();
      }}
    >
      <Space align="center">
        <div>{selected ? selected[labelIndex] : ''}</div>
      </Space>
      <Popup
        visible={visible}
        onMaskClick={() => {
          setVisible(false);
          fieldApi.setTouched();
        }}
        destroyOnClose
      >
        <div className="searchBarContainer">
          <SearchBar
            placeholder="Filtro"
            value={searchText}
            onChange={v => {
              setSearchText(v);
            }}
          />
        </div>
        <div className="checkListContainer">
          <CheckList
            className="myCheckList"
            defaultValue={fieldState.value || initialValue}
            onChange={val => {
              fieldApi.setValue(val[0]);
              const newSelected = dataSource.find(item => item[dataIndex] === val[0]);
              setVisible(false);
              fieldApi.setTouched();
              if (onChange) {
                onChange(newSelected);
              }
            }}
          >
            {filteredItems.map(item => (
              <CheckList.Item key={item[dataIndex]} value={item[dataIndex]}>
                {item[labelIndex]}
              </CheckList.Item>
            ))}
          </CheckList>
        </div>
      </Popup>
    </Form.Item>
  );
});

export default asField(SelectMobile);
