import React from 'react';
import { Row, Col } from 'antd';
import { WhatsAppOutlined } from '@ant-design/icons';
import { Form, Input, Button, Page, Select } from '~/components';
import { UserService } from '~/services';
import { buscaCep, notifySuccess } from '~/utils';
import { cidades, estados, paises } from '~/options';

class AtualizarCadastroView extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
    };
  }

  componentDidUpdate(prevProps) {
    const { action } = this.props;
    const actionChange = action !== prevProps.action;

    if (action && actionChange) {
      if (this.saveApi) {
        this.saveApi.reset();
        this.inputCep.focus();
        this.saveApi.setValues(UserService.getUser());
      }
    }
  }

  handleSave = values => {
    this.setState({ isLoading: true });
    UserService.atualizarCadastro(
      {
        ...values,
        cidade: values.estado !== 'EX' ? cidades[values.estado.toLowerCase()].find(item => item.data === values.codigo_cidade).label : values.cidade,
      },
      this.onSave,
      () => this.setState({ isLoading: false })
    );
  };

  onSave = () => {
    const { onOk } = this.props;
    notifySuccess({ name: 'Cadastro', action: { perfectPast: 'atualizado' } });
    this.setState({ isLoading: false }, () => onOk());
  };

  validaCep = value => {
    if (value.target.value.length === 10) {
      this.setState({ isLoading: true });
      buscaCep(value.target.value, this.onValidaCep, () => this.onValidaCep());
    }
  };

  onValidaCep = (value = {}) => {
    this.setState({ isLoading: false }, () => {
      if (value && value.cep) {
        this.saveApi.setValues({
          cep: value.cep || this.saveApi.getValue('cep'),
          endereco: (value.logradouro || '').replace(/[^A-Za-z0-9 ]/g, '') || undefined,
          bairro: value.bairro || undefined,
          estado: value.uf || this.saveApi.getValue('estado'),
          codigo_cidade: value.ibge || undefined,
          codigo_pais: value.ibge ? '1058' : this.saveApi.getValue('codigo_pais'),
        });
        this.numeroCep.focus();
      } else {
        this.enderecoInput.focus();
      }
    });
  };

  render() {
    const { action, showCancel, onOk } = this.props;
    const { isLoading } = this.state;

    return (
      <Page.Modal
        visible={action}
        title="Atualizar Cadastro"
        width={1000}
        forceRender
        centered
        destroyOnClose
        footer={
          <div style={{ textAlign: 'center' }}>
            {showCancel ? (
              <Button key="cancel" onClick={() => onOk()} loading={isLoading}>
                Cancelar
              </Button>
            ) : null}
            <Button key="submit" type="primary" onClick={() => this.saveApi.submitForm()} loading={isLoading}>
              Salvar
            </Button>
          </div>
        }
      >
        <Form getApi={api => (this.saveApi = api)} onSubmit={this.handleSave}>
          <Row gutter={4}>
            <Col md={4} span={12}>
              <Input
                label="CEP"
                field="cep"
                type="postal"
                onBlur={this.validaCep}
                onChange={event => event.target.value.length === 0 && this.onValidaCep()}
                forwardedRef={ref => (this.inputCep = ref)}
                required
              />
            </Col>
            <Col md={12} span={12}>
              <Input
                label="Endereço"
                field="endereco"
                required
                type="textoAlfaNumerico"
                tooltip="Não são permitidos caracteres especiais."
                forwardedRef={ref => (this.enderecoInput = ref)}
              />
            </Col>
            <Col md={3} span={12}>
              <Input label="Número" field="numero" required forwardedRef={ref => (this.numeroCep = ref)} />
            </Col>
            <Col md={5} span={12}>
              <Input label="Complemento" field="complemento" />
            </Col>
            <Col md={6} span={12}>
              <Input label="Bairro/Distrito" field="bairro" required />
            </Col>
            <Col md={10} span={12}>
              <Select label="Cidade" field="codigo_cidade" dataSource={cidades[(UserService.getUser().estado || '').toLowerCase()] || []} required />
            </Col>
            <Col md={2}>
              <Select label="Estado" field="estado" dataSource={estados} labelIndex="data" />
            </Col>
            <Col md={6} span={12}>
              <Select label="País" field="codigo_pais" dataSource={paises} required />
            </Col>
            <Col md={12} span={24}>
              <Input label="E-mail" field="email" type="email" required />
            </Col>
            <Col md={12} span={24}>
              <Input label="E-mail para o envio de Espelho da Nota Fiscal" field="email_espelho_nota" type="email" />
            </Col>
            <Col md={6} span={12}>
              <Input label="(DDD) Telefone" field="telefone" type="phone" required />
            </Col>
            <Col md={6} span={12}>
              <Input label="(DDD) Celular" field="celular" type="phone" suffix={<WhatsAppOutlined twoToneColor="#bfbfbf" />} required />
            </Col>
            <Col md={6} span={12}>
              <Input label="(DDD) Telefone 2" field="fax" type="phone" allowClear />
            </Col>
          </Row>
        </Form>
      </Page.Modal>
    );
  }
}

export default AtualizarCadastroView;
