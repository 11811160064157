// Pernambuco
const pe = [
  { data: '2600054', label: 'Abreu e Lima' },
  { data: '2600104', label: 'Afogados da Ingazeira' },
  { data: '2600203', label: 'Afrânio' },
  { data: '2600302', label: 'Agrestina' },
  { data: '2600401', label: 'Água Preta' },
  { data: '2600500', label: 'Águas Belas' },
  { data: '2600609', label: 'Alagoinha' },
  { data: '2600708', label: 'Aliança' },
  { data: '2600807', label: 'Altinho' },
  { data: '2600906', label: 'Amaraji' },
  { data: '2601003', label: 'Angelim' },
  { data: '2601052', label: 'Araçoiaba' },
  { data: '2601102', label: 'Araripina' },
  { data: '2601201', label: 'Arcoverde' },
  { data: '2601300', label: 'Barra de Guabiraba' },
  { data: '2601409', label: 'Barreiros' },
  { data: '2601508', label: 'Belém de Maria' },
  { data: '2601607', label: 'Belém de São Francisco' },
  { data: '2601706', label: 'Belo Jardim' },
  { data: '2601805', label: 'Betânia' },
  { data: '2601904', label: 'Bezerros' },
  { data: '2602001', label: 'Bodocó' },
  { data: '2602100', label: 'Bom Conselho' },
  { data: '2602209', label: 'Bom Jardim' },
  { data: '2602308', label: 'Bonito' },
  { data: '2602407', label: 'Brejão' },
  { data: '2602506', label: 'Brejinho' },
  { data: '2602605', label: 'Brejo da Madre de Deus' },
  { data: '2602704', label: 'Buenos Aires' },
  { data: '2602803', label: 'Buíque' },
  { data: '2602902', label: 'Cabo de Santo Agostinho' },
  { data: '2603009', label: 'Cabrobó' },
  { data: '2603108', label: 'Cachoeirinha' },
  { data: '2603207', label: 'Caetés' },
  { data: '2603306', label: 'Calçado' },
  { data: '2603405', label: 'Calumbi' },
  { data: '2603454', label: 'Camaragibe' },
  { data: '2603504', label: 'Camocim de São Félix' },
  { data: '2603603', label: 'Camutanga' },
  { data: '2603702', label: 'Canhotinho' },
  { data: '2603801', label: 'Capoeiras' },
  { data: '2603900', label: 'Carnaíba' },
  { data: '2603926', label: 'Carnaubeira da Penha' },
  { data: '2604007', label: 'Carpina' },
  { data: '2604106', label: 'Caruaru' },
  { data: '2604155', label: 'Casinhas' },
  { data: '2604205', label: 'Catende' },
  { data: '2604304', label: 'Cedro' },
  { data: '2604403', label: 'Chã de Alegria' },
  { data: '2604502', label: 'Chã Grande' },
  { data: '2604601', label: 'Condado' },
  { data: '2604700', label: 'Correntes' },
  { data: '2604809', label: 'Cortês' },
  { data: '2604908', label: 'Cumaru' },
  { data: '2605004', label: 'Cupira' },
  { data: '2605103', label: 'Custódia' },
  { data: '2605152', label: 'Dormentes' },
  { data: '2605202', label: 'Escada' },
  { data: '2605301', label: 'Exu' },
  { data: '2605400', label: 'Feira Nova' },
  { data: '2605459', label: 'Fernando de Noronha' },
  { data: '2605509', label: 'Ferreiros' },
  { data: '2605608', label: 'Flores' },
  { data: '2605707', label: 'Floresta' },
  { data: '2605806', label: 'Frei Miguelinho' },
  { data: '2605905', label: 'Gameleira' },
  { data: '2606002', label: 'Garanhuns' },
  { data: '2606101', label: 'Glória do Goitá' },
  { data: '2606200', label: 'Goiana' },
  { data: '2606309', label: 'Granito' },
  { data: '2606408', label: 'Gravatá' },
  { data: '2606507', label: 'Iati' },
  { data: '2606606', label: 'Ibimirim' },
  { data: '2606705', label: 'Ibirajuba' },
  { data: '2606804', label: 'Igarassu' },
  { data: '2606903', label: 'Iguaraci' },
  { data: '2607604', label: 'Ilha de Itamaracá' },
  { data: '2607000', label: 'Inajá' },
  { data: '2607109', label: 'Ingazeira' },
  { data: '2607208', label: 'Ipojuca' },
  { data: '2607307', label: 'Ipubi' },
  { data: '2607406', label: 'Itacuruba' },
  { data: '2607505', label: 'Itaíba' },
  { data: '2607653', label: 'Itambé' },
  { data: '2607703', label: 'Itapetim' },
  { data: '2607752', label: 'Itapissuma' },
  { data: '2607802', label: 'Itaquitinga' },
  { data: '2607901', label: 'Jaboatão dos Guararapes' },
  { data: '2607950', label: 'Jaqueira' },
  { data: '2608008', label: 'Jataúba' },
  { data: '2608057', label: 'Jatobá' },
  { data: '2608107', label: 'João Alfredo' },
  { data: '2608206', label: 'Joaquim Nabuco' },
  { data: '2608255', label: 'Jucati' },
  { data: '2608305', label: 'Jupi' },
  { data: '2608404', label: 'Jurema' },
  { data: '2608453', label: 'Lagoa do Carro' },
  { data: '2608503', label: 'Lagoa do Itaenga' },
  { data: '2608602', label: 'Lagoa do Ouro' },
  { data: '2608701', label: 'Lagoa dos Gatos' },
  { data: '2608750', label: 'Lagoa Grande' },
  { data: '2608800', label: 'Lajedo' },
  { data: '2608909', label: 'Limoeiro' },
  { data: '2609006', label: 'Macaparana' },
  { data: '2609105', label: 'Machados' },
  { data: '2609154', label: 'Manari' },
  { data: '2609204', label: 'Maraial' },
  { data: '2609303', label: 'Mirandiba' },
  { data: '2614303', label: 'Moreilândia' },
  { data: '2609402', label: 'Moreno' },
  { data: '2609501', label: 'Nazaré da Mata' },
  { data: '2609600', label: 'Olinda' },
  { data: '2609709', label: 'Orobó' },
  { data: '2609808', label: 'Orocó' },
  { data: '2609907', label: 'Ouricuri' },
  { data: '2610004', label: 'Palmares' },
  { data: '2610103', label: 'Palmeirina' },
  { data: '2610202', label: 'Panelas' },
  { data: '2610301', label: 'Paranatama' },
  { data: '2610400', label: 'Parnamirim' },
  { data: '2610509', label: 'Passira' },
  { data: '2610608', label: 'Paudalho' },
  { data: '2610707', label: 'Paulista' },
  { data: '2610806', label: 'Pedra' },
  { data: '2610905', label: 'Pesqueira' },
  { data: '2611002', label: 'Petrolândia' },
  { data: '2611101', label: 'Petrolina' },
  { data: '2611200', label: 'Poção' },
  { data: '2611309', label: 'Pombos' },
  { data: '2611408', label: 'Primavera' },
  { data: '2611507', label: 'Quipapá' },
  { data: '2611533', label: 'Quixaba' },
  { data: '2611606', label: 'Recife' },
  { data: '2611705', label: 'Riacho das Almas' },
  { data: '2611804', label: 'Ribeirão' },
  { data: '2611903', label: 'Rio Formoso' },
  { data: '2612000', label: 'Sairé' },
  { data: '2612109', label: 'Salgadinho' },
  { data: '2612208', label: 'Salgueiro' },
  { data: '2612307', label: 'Saloá' },
  { data: '2612406', label: 'Sanharó' },
  { data: '2612455', label: 'Santa Cruz' },
  { data: '2612471', label: 'Santa Cruz da Baixa Verde' },
  { data: '2612505', label: 'Santa Cruz do Capibaribe' },
  { data: '2612554', label: 'Santa Filomena' },
  { data: '2612604', label: 'Santa Maria da Boa Vista' },
  { data: '2612703', label: 'Santa Maria do Cambucá' },
  { data: '2612802', label: 'Santa Terezinha' },
  { data: '2612901', label: 'São Benedito do Sul' },
  { data: '2613008', label: 'São Bento do Una' },
  { data: '2613107', label: 'São Caitano' },
  { data: '2613206', label: 'São João' },
  { data: '2613305', label: 'São Joaquim do Monte' },
  { data: '2613404', label: 'São José da Coroa Grande' },
  { data: '2613503', label: 'São José do Belmonte' },
  { data: '2613602', label: 'São José do Egito' },
  { data: '2613701', label: 'São Lourenço da Mata' },
  { data: '2613800', label: 'São Vicente Ferrer' },
  { data: '2613909', label: 'Serra Talhada' },
  { data: '2614006', label: 'Serrita' },
  { data: '2614105', label: 'Sertânia' },
  { data: '2614204', label: 'Sirinhaém' },
  { data: '2614402', label: 'Solidão' },
  { data: '2614501', label: 'Surubim' },
  { data: '2614600', label: 'Tabira' },
  { data: '2614709', label: 'Tacaimbó' },
  { data: '2614808', label: 'Tacaratu' },
  { data: '2614857', label: 'Tamandaré' },
  { data: '2615003', label: 'Taquaritinga do Norte' },
  { data: '2615102', label: 'Terezinha' },
  { data: '2615201', label: 'Terra Nova' },
  { data: '2615300', label: 'Timbaúba' },
  { data: '2615409', label: 'Toritama' },
  { data: '2615508', label: 'Tracunhaém' },
  { data: '2615607', label: 'Trindade' },
  { data: '2615706', label: 'Triunfo' },
  { data: '2615805', label: 'Tupanatinga' },
  { data: '2615904', label: 'Tuparetama' },
  { data: '2616001', label: 'Venturosa' },
  { data: '2616100', label: 'Verdejante' },
  { data: '2616183', label: 'Vertente do Lério' },
  { data: '2616209', label: 'Vertentes' },
  { data: '2616308', label: 'Vicência' },
  { data: '2616407', label: 'Vitória de Santo Antão' },
  { data: '2616506', label: 'Xexéu' },
];

export default pe;
