import React from 'react';
import moment from 'moment';
import { Row, Col, Modal } from 'antd';
import classNames from 'classnames';
import { FilePdfTwoTone, FileTextTwoTone, MailTwoTone } from '@ant-design/icons';
import { Input, Form, Button, Table, FooterToolbar, Page, ContentWrapper, DatePicker, Select, Statistic, Link } from '~/components';
import { ContaPagarService, FrequenciaService, RelatorioService, UserService } from '~/services';
import { decimalValue } from '~/utils';
import { colors } from '~/options';

class ContaPagarPage extends React.PureComponent {
  constructor(props) {
    super(props);

    this.rowColor = [
      { title: 'Atrasado', color: colors.volcano, predicate: item => item.situacao === 'ATRASADO' },
      { title: 'Baixado', color: colors.polarGreen, predicate: item => item.situacao === 'BAIXADO' },
      { title: 'Vence hoje', color: colors.sunriseYellow, predicate: item => item.situacao === 'VENCE HOJE' },
    ];

    this.state = {
      isLoading: false,
      itens: [],
      selectedItem: {},
      empresas: [],
    };
  }

  componentDidMount() {
    const { params } = this.props;
    UserService.fetchEmpresas(empresas => this.setState({ empresas }));

    if (params && params.idta) {
      this.searchApi.setValue('data_inicial', params.vencimento);
      this.searchApi.setValue('data_final', params.vencimento);
      this.searchApi.submitForm();
    } else {
      this.searchInput.focus();
    }
  }

  handleSearch = values => {
    if (moment(values.data_final).diff(values.data_inicial, 'days') > 31) {
      Modal.error({
        title: 'Cheques Trocados',
        content: 'Não é possível buscar um período maior que o selecionado',
        centered: true,
      });
      return;
    }

    this.setState({ isLoading: true });

    ContaPagarService.getAll(
      values,
      itens => this.setState({ itens, isLoading: false }, () => this.searchInput.focus()),
      () => this.setState({ isLoading: false })
    );
  };

  imprimirCupom = (record, tipo = 'pdf') => {
    FrequenciaService.consultar_cupom({ ...record, retorno: tipo }, response => {
      if (response.toString().indexOf(' encontrado') === -1) {
        window.open(`${process.env.REACT_APP_URL}/${response.toString()}`, '_blank');
      } else Modal.error({ content: response.toString(), title: 'Consulta Cupom', centered: true });
    });
  };

  imprimirFatura = record => {
    const values = [];
    values.push({ nome_campo: 'codigo', valor: record.numero_fatura });
    values.push({ nome_campo: 'reemissao', valor: 'S' });

    RelatorioService.getChamaRelatorio(values, 'ServletCobranca', 'Reemissão de Carta');
  };

  imprimirNota = record => {
    const values = [];
    values.push({ nome_campo: 'codigo', valor: record.idt_nfe });
    values.push({ nome_campo: 'chave', valor: record.chave_nfe });
    values.push({ nome_campo: 'webnfe3', valor: 'S' });
    values.push({
      nome_campo: 'periodo',
      valor: `20${record.chave_nfe.substr(2, 4)}`,
    });

    RelatorioService.getChamaRelatorio(values, 'ServletDanfe', 'Nota Fiscal');
  };

  imprimirBoleto = record => {
    this.setState({ isLoading: true });
    RelatorioService.getGeraBoleto(
      { boletos: record.idt_boleto },
      () => this.setState({ isLoading: false }),
      () => this.setState({ isLoading: false })
    );
  };

  render() {
    const { tabId } = this.props;
    const { isLoading, itens, selectedItem, empresas } = this.state;

    return (
      <>
        <Page tabId={tabId}>
          <Form getApi={api => (this.searchApi = api)} onSubmit={this.handleSearch}>
            <ContentWrapper type="search">
              <Row gutter={4}>
                <Col md={3} span={12}>
                  <DatePicker field="data_inicial" label="Data Inicial" required today disabled={isLoading} />
                </Col>
                <Col md={3} span={12}>
                  <DatePicker field="data_final" label="Data Final" required today disabled={isLoading} />
                </Col>
                <Col md={3} span={12}>
                  <Input field="placa" label="Placa" disabled={isLoading} type="placa" allowClear forwardedRef={ref => (this.searchInput = ref)} />
                </Col>
                <Col md={3} span={12}>
                  <Select
                    field="status"
                    label="Situação"
                    dataSource={[
                      { label: 'ABERTA', data: '0' },
                      { label: 'BAIXADA', data: '9' },
                    ]}
                    allowClear
                    forceZero
                    disabled={isLoading}
                    initialValue="0"
                  />
                </Col>
                <Col md={9} span={20}>
                  <Select field="origem" label="Empresa" dataSource={empresas} allowClear disabled={isLoading} />
                </Col>
                <Col md={3} span={4}>
                  <Button.Search loading={isLoading} />
                </Col>
              </Row>
            </ContentWrapper>
          </Form>
          <Table
            dataSource={itens}
            rowKey="idta"
            loading={isLoading}
            record={selectedItem}
            rowColor={this.rowColor}
            onRecordChange={record => this.setState({ selectedItem: record })}
            description={item => (
              <Row>
                <Col span={16}>
                  <span style={{ fontSize: '0.8rem' }}>{item.placa}</span>
                </Col>
                <Col span={8}>
                  <span>{item.emissao}</span>
                </Col>
              </Row>
            )}
            height={window.innerHeight - 390}
          >
            <Table.Column title="Nota" dataIndex="nota_nfe" width="8%" align="center" />
            <Table.Column title="Emissão" dataIndex="emissao" type="date" onMobile="show" width="8%" />
            <Table.Column title="Vencimento" dataIndex="vencimento" type="date" onMobile="show" width="8%" />
            <Table.Column title="Empresa" dataIndex="nome_empresa" width="24%" />
            <Table.Column title="Placa" dataIndex="placa" width="8%" align="center" />
            <Table.Column title="Tipo" dataIndex="tipo" width="14%" align="center" />
            <Table.Column title="Valor (R$)" dataIndex="valor" type="currency" width="10%" />
            <Table.Column
              title="Fatura"
              dataIndex="numero_fatura"
              width="10%"
              type="integer"
              render={(text, record) => (
                <Link align="center" onClick={() => this.imprimirFatura(record)}>
                  {text || ''}
                </Link>
              )}
            />
            <Table.Column
              title="Ações"
              dataIndex="actions"
              width="10%"
              align="center"
              exporter={false}
              sorter={false}
              render={(text, record) => (
                <>
                  <FilePdfTwoTone
                    onClick={() => this.imprimirCupom(record)}
                    title="PDF NFC-e"
                    twoToneColor="#cc0000"
                    className={classNames(!record.idt_nfce ? 'icon-disabled' : '', 'icon-table-margin')}
                  />
                  <FileTextTwoTone
                    onClick={() => this.imprimirCupom(record, 'xml')}
                    title="XML NFC-e"
                    twoToneColor="#00cc00"
                    className={classNames(!record.idt_nfce ? 'icon-disabled' : '', 'icon-table-margin')}
                  />
                  <FilePdfTwoTone
                    onClick={() => this.imprimirNota(record)}
                    title="PDF NF-e"
                    twoToneColor="#cc0000"
                    className={classNames(!record.chave_nfe ? 'icon-disabled' : '', 'icon-table-margin')}
                  />
                  <MailTwoTone
                    onClick={() => this.imprimirBoleto(record)}
                    title="Boleto"
                    twoToneColor="#0000ff"
                    className={classNames(record.situacao === 'ABERTO' ? '' : 'icon-disabled', 'icon-table-margin')}
                  />
                </>
              )}
            />
          </Table>
          <ContentWrapper type="header">
            <Row>
              <Col md={9} span={0} />
              <Col md={6} span={24} style={{ textAlign: 'center' }}>
                <Statistic
                  style={{ textAlign: 'center' }}
                  title="Valor Total (R$)"
                  value={decimalValue(
                    itens.reduce((previous, current) => parseFloat(current.valor) + previous, 0),
                    2
                  )}
                  color="#3f8600"
                />
              </Col>
            </Row>
          </ContentWrapper>
        </Page>
        <FooterToolbar left={<></>} center={<></>} right={<></>} />
      </>
    );
  }
}

export default ContaPagarPage;
