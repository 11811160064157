import moment from 'moment';
import _ from 'lodash';
import { decimalValue } from '~/utils';

const validateFilter = (item, filtros) => {
  const chaves = Object.keys(filtros);
  for (let index = 0; index < chaves.length; index += 1) {
    const key = chaves[index];
    if (Array.isArray(filtros[key])) {
      if (filtros[key].length > 0 && !filtros[key].find(itemFind => itemFind === item[key])) {
        return false;
      }
    } else if (filtros[key] && item[key] !== filtros[key]) return false;
  }
  return true;
};

const execFilter = (dataSource, filtros) => {
  return dataSource.filter(item => validateFilter(item, filtros));
};

const groupBySelect = (dataSource, agrupamento) => {
  let newDataSource = [];
  newDataSource = _.groupBy(dataSource, item => item[agrupamento]);
  newDataSource = _.map(newDataSource, item => {
    const value =
      item[0][agrupamento].length === 10 && item[0][agrupamento].indexOf('-') > 0 ? moment(item[0][agrupamento]).format('DD/MM/YYYY') : item[0][agrupamento];
    return { data: value, label: value };
  });

  return newDataSource;
};

const groupByXY = (dataSource, agrupamento, analise, orderValue = true) => {
  let newDataSource = _.groupBy(dataSource, item => item[agrupamento]);
  newDataSource = _.map(newDataSource, item => ({
    x:
      item[0][agrupamento].length === 10 && item[0][agrupamento].indexOf('-') > 0
        ? moment(item[0][agrupamento]).format('DD/MM/YYYY')
        : (item[0][agrupamento] || '').substr(0, 40),
    y:
      analise === '_count_'
        ? item.length
        : decimalValue(
            item.reduce((previous, current) => parseFloat(current[analise]) + previous, 0),
            2
          ),
  }));

  if (orderValue) newDataSource = _.orderBy(newDataSource, item => item.y, 'desc');
  else newDataSource = _.orderBy(newDataSource, item => item.x);

  return newDataSource;
};

export { execFilter, groupBySelect, groupByXY };
