// Paraíba
const pb = [
  { data: '2500106', label: 'Água Branca' },
  { data: '2500205', label: 'Aguiar' },
  { data: '2500304', label: 'Alagoa Grande' },
  { data: '2500403', label: 'Alagoa Nova' },
  { data: '2500502', label: 'Alagoinha' },
  { data: '2500536', label: 'Alcantil' },
  { data: '2500577', label: 'Algodão de Jandaíra' },
  { data: '2500601', label: 'Alhandra' },
  { data: '2500734', label: 'Amparo' },
  { data: '2500775', label: 'Aparecida' },
  { data: '2500809', label: 'Araçagi' },
  { data: '2500908', label: 'Arara' },
  { data: '2501005', label: 'Araruna' },
  { data: '2501104', label: 'Areia' },
  { data: '2501153', label: 'Areia de Baraúnas' },
  { data: '2501203', label: 'Areial' },
  { data: '2501302', label: 'Aroeiras' },
  { data: '2501351', label: 'Assunção' },
  { data: '2501401', label: 'Baía da Traição' },
  { data: '2501500', label: 'Bananeiras' },
  { data: '2501534', label: 'Baraúna' },
  { data: '2501609', label: 'Barra de Santa Rosa' },
  { data: '2501575', label: 'Barra de Santana' },
  { data: '2501708', label: 'Barra de São Miguel' },
  { data: '2501807', label: 'Bayeux' },
  { data: '2501906', label: 'Belém' },
  { data: '2502003', label: 'Belém do Brejo do Cruz' },
  { data: '2502052', label: 'Bernardino Batista' },
  { data: '2502102', label: 'Boa Ventura' },
  { data: '2502151', label: 'Boa Vista' },
  { data: '2502201', label: 'Bom Jesus' },
  { data: '2502300', label: 'Bom Sucesso' },
  { data: '2502409', label: 'Bonito de Santa Fé' },
  { data: '2502508', label: 'Boqueirão' },
  { data: '2502706', label: 'Borborema' },
  { data: '2502805', label: 'Brejo do Cruz' },
  { data: '2502904', label: 'Brejo dos Santos' },
  { data: '2503001', label: 'Caaporã' },
  { data: '2503100', label: 'Cabaceiras' },
  { data: '2503209', label: 'Cabedelo' },
  { data: '2503308', label: 'Cachoeira dos Índios' },
  { data: '2503407', label: 'Cacimba de Areia' },
  { data: '2503506', label: 'Cacimba de Dentro' },
  { data: '2503555', label: 'Cacimbas' },
  { data: '2503605', label: 'Caiçara' },
  { data: '2503704', label: 'Cajazeiras' },
  { data: '2503753', label: 'Cajazeirinhas' },
  { data: '2503803', label: 'Caldas Brandão' },
  { data: '2503902', label: 'Camalaú' },
  { data: '2504009', label: 'Campina Grande' },
  { data: '2504033', label: 'Capim' },
  { data: '2504074', label: 'Caraúbas' },
  { data: '2504108', label: 'Carrapateira' },
  { data: '2504157', label: 'Casserengue' },
  { data: '2504207', label: 'Catingueira' },
  { data: '2504306', label: 'Catolé do Rocha' },
  { data: '2504355', label: 'Caturité' },
  { data: '2504405', label: 'Conceição' },
  { data: '2504504', label: 'Condado' },
  { data: '2504603', label: 'Conde' },
  { data: '2504702', label: 'Congo' },
  { data: '2504801', label: 'Coremas' },
  { data: '2504850', label: 'Coxixola' },
  { data: '2504900', label: 'Cruz do Espírito Santo' },
  { data: '2505006', label: 'Cubati' },
  { data: '2505105', label: 'Cuité' },
  { data: '2505238', label: 'Cuité de Mamanguape' },
  { data: '2505204', label: 'Cuitegi' },
  { data: '2505279', label: 'Curral de Cima' },
  { data: '2505303', label: 'Curral Velho' },
  { data: '2505352', label: 'Damião' },
  { data: '2505402', label: 'Desterro' },
  { data: '2505600', label: 'Diamante' },
  { data: '2505709', label: 'Dona Inês' },
  { data: '2505808', label: 'Duas Estradas' },
  { data: '2505907', label: 'Emas' },
  { data: '2506004', label: 'Esperança' },
  { data: '2506103', label: 'Fagundes' },
  { data: '2506202', label: 'Frei Martinho' },
  { data: '2506251', label: 'Gado Bravo' },
  { data: '2506301', label: 'Guarabira' },
  { data: '2506400', label: 'Gurinhém' },
  { data: '2506509', label: 'Gurjão' },
  { data: '2506608', label: 'Ibiara' },
  { data: '2502607', label: 'Igaracy' },
  { data: '2506707', label: 'Imaculada' },
  { data: '2506806', label: 'Ingá' },
  { data: '2506905', label: 'Itabaiana' },
  { data: '2507002', label: 'Itaporanga' },
  { data: '2507101', label: 'Itapororoca' },
  { data: '2507200', label: 'Itatuba' },
  { data: '2507309', label: 'Jacaraú' },
  { data: '2507408', label: 'Jericó' },
  { data: '2507507', label: 'João Pessoa' },
  { data: '2513653', label: 'Joca Claudino' },
  { data: '2507606', label: 'Juarez Távora' },
  { data: '2507705', label: 'Juazeirinho' },
  { data: '2507804', label: 'Junco do Seridó' },
  { data: '2507903', label: 'Juripiranga' },
  { data: '2508000', label: 'Juru' },
  { data: '2508109', label: 'Lagoa' },
  { data: '2508208', label: 'Lagoa de Dentro' },
  { data: '2508307', label: 'Lagoa Seca' },
  { data: '2508406', label: 'Lastro' },
  { data: '2508505', label: 'Livramento' },
  { data: '2508554', label: 'Logradouro' },
  { data: '2508604', label: 'Lucena' },
  { data: '2508703', label: "Mãe d'Água" },
  { data: '2508802', label: 'Malta' },
  { data: '2508901', label: 'Mamanguape' },
  { data: '2509008', label: 'Manaíra' },
  { data: '2509057', label: 'Marcação' },
  { data: '2509107', label: 'Mari' },
  { data: '2509156', label: 'Marizópolis' },
  { data: '2509206', label: 'Massaranduba' },
  { data: '2509305', label: 'Mataraca' },
  { data: '2509339', label: 'Matinhas' },
  { data: '2509370', label: 'Mato Grosso' },
  { data: '2509396', label: 'Maturéia' },
  { data: '2509404', label: 'Mogeiro' },
  { data: '2509503', label: 'Montadas' },
  { data: '2509602', label: 'Monte Horebe' },
  { data: '2509701', label: 'Monteiro' },
  { data: '2509800', label: 'Mulungu' },
  { data: '2509909', label: 'Natuba' },
  { data: '2510006', label: 'Nazarezinho' },
  { data: '2510105', label: 'Nova Floresta' },
  { data: '2510204', label: 'Nova Olinda' },
  { data: '2510303', label: 'Nova Palmeira' },
  { data: '2510402', label: "Olho d'Água" },
  { data: '2510501', label: 'Olivedos' },
  { data: '2510600', label: 'Ouro Velho' },
  { data: '2510659', label: 'Parari' },
  { data: '2510709', label: 'Passagem' },
  { data: '2510808', label: 'Patos' },
  { data: '2510907', label: 'Paulista' },
  { data: '2511004', label: 'Pedra Branca' },
  { data: '2511103', label: 'Pedra Lavrada' },
  { data: '2511202', label: 'Pedras de Fogo' },
  { data: '2512721', label: 'Pedro Régis' },
  { data: '2511301', label: 'Piancó' },
  { data: '2511400', label: 'Picuí' },
  { data: '2511509', label: 'Pilar' },
  { data: '2511608', label: 'Pilões' },
  { data: '2511707', label: 'Pilõezinhos' },
  { data: '2511806', label: 'Pirpirituba' },
  { data: '2511905', label: 'Pitimbu' },
  { data: '2512002', label: 'Pocinhos' },
  { data: '2512036', label: 'Poço Dantas' },
  { data: '2512077', label: 'Poço de José de Moura' },
  { data: '2512101', label: 'Pombal' },
  { data: '2512200', label: 'Prata' },
  { data: '2512309', label: 'Princesa Isabel' },
  { data: '2512408', label: 'Puxinanã' },
  { data: '2512507', label: 'Queimadas' },
  { data: '2512606', label: 'Quixabá' },
  { data: '2512705', label: 'Remígio' },
  { data: '2512747', label: 'Riachão' },
  { data: '2512754', label: 'Riachão do Bacamarte' },
  { data: '2512762', label: 'Riachão do Poço' },
  { data: '2512788', label: 'Riacho de Santo Antônio' },
  { data: '2512804', label: 'Riacho dos Cavalos' },
  { data: '2512903', label: 'Rio Tinto' },
  { data: '2513000', label: 'Salgadinho' },
  { data: '2513109', label: 'Salgado de São Félix' },
  { data: '2513158', label: 'Santa Cecília' },
  { data: '2513208', label: 'Santa Cruz' },
  { data: '2513307', label: 'Santa Helena' },
  { data: '2513356', label: 'Santa Inês' },
  { data: '2513406', label: 'Santa Luzia' },
  { data: '2513703', label: 'Santa Rita' },
  { data: '2513802', label: 'Santa Teresinha' },
  { data: '2513505', label: 'Santana de Mangueira' },
  { data: '2513604', label: 'Santana dos Garrotes' },
  { data: '2513851', label: 'Santo André' },
  { data: '2513927', label: 'São Bentinho' },
  { data: '2513901', label: 'São Bento' },
  { data: '2513968', label: 'São Domingos de Pombal' },
  { data: '2513943', label: 'São Domingos do Cariri' },
  { data: '2513984', label: 'São Francisco' },
  { data: '2514008', label: 'São João do Cariri' },
  { data: '2500700', label: 'São João do Rio do Peixe' },
  { data: '2514107', label: 'São João do Tigre' },
  { data: '2514206', label: 'São José da Lagoa Tapada' },
  { data: '2514305', label: 'São José de Caiana' },
  { data: '2514404', label: 'São José de Espinharas' },
  { data: '2514503', label: 'São José de Piranhas' },
  { data: '2514552', label: 'São José de Princesa' },
  { data: '2514602', label: 'São José do Bonfim' },
  { data: '2514651', label: 'São José do Brejo do Cruz' },
  { data: '2514701', label: 'São José do Sabugi' },
  { data: '2514800', label: 'São José dos Cordeiros' },
  { data: '2514453', label: 'São José dos Ramos' },
  { data: '2514909', label: 'São Mamede' },
  { data: '2515005', label: 'São Miguel de Taipu' },
  { data: '2515104', label: 'São Sebastião de Lagoa de Roça' },
  { data: '2515203', label: 'São Sebastião do Umbuzeiro' },
  { data: '2515302', label: 'Sapé' },
  { data: '2515401', label: 'Seridó' },
  { data: '2515500', label: 'Serra Branca' },
  { data: '2515609', label: 'Serra da Raiz' },
  { data: '2515708', label: 'Serra Grande' },
  { data: '2515807', label: 'Serra Redonda' },
  { data: '2515906', label: 'Serraria' },
  { data: '2515930', label: 'Sertãozinho' },
  { data: '2515971', label: 'Sobrado' },
  { data: '2516003', label: 'Solânea' },
  { data: '2516102', label: 'Soledade' },
  { data: '2516151', label: 'Sossêgo' },
  { data: '2516201', label: 'Sousa' },
  { data: '2516300', label: 'Sumé' },
  { data: '2516409', label: 'Tacima' },
  { data: '2516508', label: 'Taperoá' },
  { data: '2516607', label: 'Tavares' },
  { data: '2516706', label: 'Teixeira' },
  { data: '2516755', label: 'Tenório' },
  { data: '2516805', label: 'Triunfo' },
  { data: '2516904', label: 'Uiraúna' },
  { data: '2517001', label: 'Umbuzeiro' },
  { data: '2517100', label: 'Várzea' },
  { data: '2517209', label: 'Vieirópolis' },
  { data: '2505501', label: 'Vista Serrana' },
  { data: '2517407', label: 'Zabelê' },
];

export default pb;
