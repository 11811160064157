import { HttpService } from '~/helpers';
import { UserService } from '~/services';

const endpoint = 'financeiro/conta_pagar';

const formatFilters = filters =>
  filters
    ? [
        { nome_campo: 'a.cliente', valor: UserService.getUser().codigo, tipo: 'int' },
        { nome_campo: 'a.origem', valor: filters.origem, tipo: 'int' },
        { nome_campo: 'rc.placa', valor: filters.placa ? filters.placa.replace('-', '') : '', tipo: 'texto' },
        {
          nome_campo: filters.tipo_filtro || 'a.emissao',
          valor: filters.data_inicial && filters.data_final ? `${filters.data_inicial} AND ${filters.data_final}` : '',
          tipo: 'datas',
        },
        {
          nome_campo: filters.tipo_filtro,
          valor: filters.valorExpressao || '',
          tipo: 'expressao',
        },
      ]
    : [];

const ContaPagarService = {
  getAll: (values, onSuccess, onError) => {
    HttpService.call(endpoint, 'getAll', [{ ...values, cliente: UserService.getUser().codigo }, formatFilters(values)], onSuccess, onError);
  },
};

export default ContaPagarService;
