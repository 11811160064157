import React from 'react';
import moment from 'moment';
import { Row, Col, Modal, notification, Tabs } from 'antd';
import { connect } from 'react-redux';
import {
  FooterToolbar,
  Navigator,
  Markdown,
  Page,
  Form,
  ContentWrapper,
  Table,
  Input,
  Button,
  DatePicker,
  IconDelete,
  Status,
  Statistic,
  Select,
  SelectCombo,
  KeyboardNumericView,
} from '~/components';
import { actions, affirmatives, colors, tabPanes } from '~/options';
import { dateValue, decimalPlace, notifySuccess } from '~/utils';
import { CustomerService, PedidoService, UserService, RelatorioService } from '~/services';
import README from './README.md';

const rowColor = [
  { title: 'Alterado', color: colors.sunriseYellow, predicate: item => item.alterado },
  { title: 'Escala de Preços', color: colors.dustRed, predicate: item => item.escalas && item.escalas.length > 0 },
  { title: 'Promoção', color: colors.polarGreen, predicate: item => item.promocao === 'S' },
];

let timeout;

class PedidoPage extends React.PureComponent {
  constructor(props) {
    super(props);

    this.tabelaPreco = '';

    this.state = {
      isLoading: false,
      itens: [],
      selectedItem: {},
      action: actions.idle,
      activeTab: tabPanes.table.key,
      isLoadingProdutos: false,
      itensProdutos: [],
      itensResumo: [],
      itensContratos: [],
      activeTabMobile: 'produtos',
      configKeyboard: undefined,
    };
  }

  componentDidMount() {
    this.searchApi.submitForm();
    setTimeout(() => {
      if (this.searchInput) this.searchInput.focus();
    }, 300);

    PedidoService.getAllContratos(UserService.getUser().codigo, moment().format('YYYY-MM-DD'), '', itensContratos => this.setState({ itensContratos }));
  }

  handleSearch = values => {
    this.setState({ isLoading: true });

    PedidoService.getAll(
      { ...values, cliente: UserService.getUser().codigo, filial: CustomerService.getCustomer().estrutura === 'BENASSI' ? UserService.getUser().filial : '' },
      this.onSearch,
      () => this.onSearch()
    );
  };

  onSearch = (itens = []) => {
    this.setState({ itens, isLoading: false, selectedItem: {} });
  };

  handleSelect = record => {
    this.setState({ selectedItem: record, itensResumo: [], itensProdutos: [] }, () => {
      const { general } = this.props;
      const { isMobile } = general;
      if (isMobile) {
        this.handleSelectShow();
      }
    });
    this.saveApi.reset();
    this.observacaoApi.reset();
  };

  handleSelectShow = record => {
    const { selectedItem } = this.state;

    if (record) {
      this.setState({ selectedItem: record, itensResumo: [], itensProdutos: [] });
    }

    this.saveApi.reset();
    this.observacaoApi.reset();

    if (selectedItem.codigo) {
      this.saveApi.setValues(selectedItem);
      this.observacaoApi.setValues(selectedItem);

      this.setState({ itensResumo: [], activeTab: tabPanes.record.key, isLoadingProdutos: true }, () => {
        this.onChangeData();
      });

      PedidoService.getAllItens(
        selectedItem.codigo,
        itensResumo => {
          this.setState({ itensResumo });
        },
        () => this.setState({ itensResumo: [] })
      );
    }
  };

  handleEdit = action => {
    this.setState({ action, activeTab: tabPanes.record.key }, () => {
      if (this.editInput) this.editInput.focus();
    });

    if (action === actions.insert) {
      this.saveApi.reset();
      this.observacaoApi.reset();
      this.setState({ itensProdutos: [], itensResumo: [] }, () => {
        if (this.contratoInput) this.contratoInput.focus();
      });
      PedidoService.getDataVenda(response => {
        if (response.length > 0 && response[0].data_venda) {
          this.saveApi.setValue('data_pedido', response[0].data_venda);
        }
      });
    }
  };

  onEdit = (cancel = false) => {
    let { selectedItem, activeTab } = this.state;

    if (cancel) {
      this.handleSelect(selectedItem);

      activeTab = tabPanes.table.key;
    } else {
      this.searchApi.submitForm();

      const { action } = this.state;

      if (action === actions.insert) {
        activeTab = tabPanes.table.key;
        selectedItem = {};
      }
    }

    this.setState({ action: actions.idle, selectedItem, activeTab });
  };

  verificarHorarioData = values => {
    this.setState({ isLoading: true });
    PedidoService.verificaDataPedido(UserService.getUser().origem_venda, values.data_pedido, this.onVerificarHorarioData, () => this.onVerificarHorarioData());
  };

  onVerificarHorarioData = response => {
    this.setState({ isLoading: false });
    if (response.resultado === 0) {
      const horaInicio = `${response.hora_inicio.substr(0, 2)}:${response.hora_inicio.substr(2, 2)}`;
      const horaFim = `${response.hora_fim.substr(0, 2)}:${response.hora_fim.substr(2, 2)}`;
      if (CustomerService.getCustomer().database === 'benassi_rs') {
        Modal.info({ content: response.mensagem, title: 'Pedido de Compra', centered: true });
      } else {
        Modal.info({
          content: `Pedidos só poderão ser efetuados até às ${horaInicio} horas ou a partir das ${horaFim} horas.`,
          title: 'Pedido de Compra',
          cnetered: true,
        });
      }
    } else {
      this.handleSave();
    }
  };

  handleSave = () => {
    const values = this.saveApi.getValues();
    const valuesObs = this.observacaoApi.getValues();
    const { action, itensResumo } = this.state;
    let record = {};
    let saveHandler = PedidoService.inserir;

    if (action === actions.update) {
      const { selectedItem } = this.state;
      record = selectedItem;
      saveHandler = PedidoService.alterar;
    }

    this.setState({ isLoading: true });
    saveHandler(
      {
        ...record,
        ...values,
        ...valuesObs,
        cliente: UserService.getUser().codigo,
        filial: UserService.getUser().filial,
        nome_cliente: UserService.getUser().nome,
        vendedor: UserService.getUser().nome,
        usuario: UserService.getUser().nome,
        origem: UserService.getUser().origem_venda,
        total_pedido: itensResumo.reduce((previous, current) => parseFloat(parseFloat(current.preco_venda) * parseFloat(current.quantidade)) + previous, 0),
        itens: itensResumo,
        tabela_preco: this.tabelaPreco,
      },
      this.onSave,
      () => this.onSave('', true)
    );
  };

  onSave = (pedido, error = false) => {
    this.setState({ isLoading: false, activeTab: error ? tabPanes.record.key : tabPanes.table.key });

    if (!error) {
      const { itensResumo } = this.state;
      const values = this.saveApi.getValues();

      Modal.info({
        content: (
          <>
            {`Pedido ${pedido} salvo com sucesso.`}
            <ul>
              <li>{`Data: ${dateValue(values.data_pedido)}`}</li>
              <li>{`Produtos: ${itensResumo.length}`}</li>
              <li>
                {`Valor Total: ${decimalPlace(
                  itensResumo.reduce((previous, current) => parseFloat(parseFloat(current.preco_venda) * parseFloat(current.quantidade)) + previous, 0),
                  2
                )}`}
              </li>
            </ul>
          </>
        ),
        title: 'Pedido de Compra',
        centered: true,
      });

      this.onEdit();
    }
  };

  handleDelete = record => {
    this.setState({ isLoading: true });
    PedidoService.excluir(
      record.codigo,
      () => this.onDelete(),
      () => this.onDelete(true)
    );
  };

  onDelete = (error = false) => {
    this.setState({ isLoading: false });

    if (!error) {
      const { name } = this.props;

      this.searchApi.submitForm();
      notifySuccess({ name, action: actions.remove });
    }
  };

  onBlurData = () => {
    const { itensProdutos, isLoadingProdutos } = this.state;

    if (itensProdutos.length === 0 && !isLoadingProdutos) {
      this.onChangeData();
    }
  };

  onChangeData = () => {
    const { itensContratos } = this.state;
    const values = this.saveApi.getValues();
    const contratoSelected = values.contrato ? itensContratos.find(item => item.data === values.contrato) || {} : {};

    if (itensContratos.length > 0 && !values.contrato) return;
    if (itensContratos.length > 0 && values.contrato && contratoSelected.acesso_portal_contrato && !values.acesso_portal_contrato) return;

    this.setState({ isLoading: true });
    UserService.buscaParametros(
      UserService.getUser().codigo,
      UserService.getUser().filial,
      values.data_pedido,
      values.contrato,
      values.acesso_portal_contrato,
      this.onBuscaParametros,
      () => this.onBuscaParametros()
    );
  };

  onBuscaParametros = (response = []) => {
    this.setState({ isLoading: false });
    const values = this.saveApi.getValues();

    if (response.length > 0) {
      if (!response[0].tabela_precos) {
        Modal.error({
          content: 'Cliente sem tabela de preços cadastrada, não é possivel efetuar pedido.',
          title: 'Pedidos de Compra',
          centered: true,
          onOk: () => this.ordemCompraInput.focus(),
        });
      } else if (!response[0].data) {
        Modal.error({
          content: 'Cliente sem tabela de preços preenchida, não é possivel efetuar pedido.',
          title: 'Pedidos de Compra',
          centered: true,
          onOk: () => this.ordemCompraInput.focus(),
        });
      } else if (
        CustomerService.getCustomer().nome === 'Victos' &&
        response[0].data.replace('-', '').replace('-', '') !== values.data_pedido.replace('-', '').replace('-', '')
      ) {
        Modal.info({
          content: 'Não hà mix para a data selecionada! Escolha outra data!',
          title: 'Pedidos de Compra',
          centered: true,
          onOk: () => this.ordemCompraInput.focus(),
        });
      } else {
        this.buscarDados(response[0].tabela_precos, response[0].data);
      }
    } else {
      Modal.error({ content: 'Cliente ou Tabela de preços não encontrada, não é possivel efetuar pedido.', title: 'Pedidos de Compra', centered: true });
    }
  };

  buscarDados = (tabelaPreco, dataEntrega) => {
    const { itensContratos } = this.state;
    const values = this.saveApi.getValues();
    const contratoSelected =
      itensContratos.find(item => item.data === values.contrato && (item.acesso_portal_contrato || '') === (values.acesso_portal_contrato || '')) || {};
    this.tabelaPreco = tabelaPreco;

    this.setState({ itensProdutos: [], isLoadingProdutos: true });
    PedidoService.getAllProdutos(
      {
        data: dataEntrega || values.data_pedido,
        data_pedido: values.data_pedido,
        tabela: contratoSelected.tabelas_produto_contrato || tabelaPreco || UserService.getUser().tabela,
        filtra_precos: UserService.getUser().filtra_precos_site,
        contrato: contratoSelected.data || '',
        codigo: UserService.getUser().codigo,
        filial: UserService.getUser().filial,
        estado: CustomerService.getCustomer().database,
      },
      '',
      'N',
      this.onGetAllProdutos,
      () => this.onGetAllProdutos()
    );
  };

  onGetAllProdutos = (response = []) => {
    const { action } = this.state;
    const itensEscala = response && response.dados ? response.dados.filter(item => parseFloat(item.quantidade_escala) > 0) : [];

    this.setState(
      {
        itensProdutos:
          response.dados
            .filter(item => !item.quantidade_escala)
            .map(item => ({ ...item, escalas: itensEscala.filter(itemEscala => itemEscala.idt_tabela === item.idt_tabela) })) || [],
        isLoadingProdutos: false,
      },
      () => {
        if (action === actions.idle) {
          this.atualizarListaProdutos();
        }
      }
    );
  };

  atualizarListaProdutos = () => {
    const { itensProdutos, itensResumo } = this.state;
    this.setState({
      itensProdutos: itensProdutos.map(item => {
        const itemResumo = itensResumo.find(itemFindResumo => itemFindResumo.idt_tabela === item.idt_tabela) || {};
        return { ...item, quantidade: itemResumo.quantidade || '', alterado: itemResumo.quantidade };
      }),
    });
  };

  onEditItem = (record, dataIndex) => {
    const { action, itensProdutos } = this.state;

    let newItensProdutos = itensProdutos.map(item =>
      item.idt_tabela === record.idt_tabela
        ? {
            ...record,
            quantidade: record.quantidade ? (record.fracionado !== 'S' ? Math.round(record.quantidade) : record.quantidade) : undefined,
            alterado: (item.quantidade && action === actions.update) || record.quantidade,
          }
        : item
    );
    const escalas =
      (itensProdutos.find(item => item.idt_tabela === record.idt_tabela) && itensProdutos.find(item => item.idt_tabela === record.idt_tabela).escalas) || [];

    if (dataIndex === 'quantidade') {
      const sugestao = parseFloat(record.projecao_real);
      if (sugestao > 0 && parseFloat(UserService.getUser().margem_alteracao_produto) > 0) {
        if (parseFloat(record.quantidade) > sugestao) {
          notification.error({
            message: 'Margem',
            description: `Quantidade não pode ser maior que ${decimalPlace(UserService.getUser().margem_alteracao_produto, 2)}% da Sugestão`,
          });
          this.setState({ itensProdutos });
          return;
        }
      }

      if (escalas.length > 0) {
        const escalaFindItens = escalas
          .filter(item => parseFloat(item.quantidade_escala) <= record.quantidade)
          .sort((a, b) => parseFloat(a.quantidade_escala) - parseFloat(b.quantidade_escala))
          .reverse();
        if (escalaFindItens.length > 0) {
          const escalaFirst = escalaFindItens[0];
          newItensProdutos = newItensProdutos.map(item => (item.idt_tabela === record.idt_tabela ? { ...item, preco_venda: escalaFirst.preco_venda } : item));
        } else {
          newItensProdutos = newItensProdutos.map(item => (item.idt_tabela === record.idt_tabela ? { ...item, preco_venda: item.preco_venda_base } : item));
        }
      }
    }

    this.setState({ itensProdutos: newItensProdutos, itensResumo: newItensProdutos.filter(item => parseFloat(item.quantidade)) });
  };

  imprimir = tipo => {
    const { selectedItem } = this.state;
    const values = [];

    values.push({ nome_campo: 'mostra_preco_pedidos', valor: UserService.getUser().mostra_custos_site === '1' ? 'S' : 'N' });
    values.push({ nome_campo: 'pedido', valor: selectedItem.codigo });
    values.push({ nome_campo: 'exporta_excel', valor: tipo === 'excel' ? 'S' : 'N' });
    values.push({ nome_campo: 'exporta_csv', valor: tipo === 'csv' ? 'S' : 'N' });
    values.push({ nome_campo: 'exporta_pdf', valor: tipo === 'pdf' ? 'S' : 'N' });

    RelatorioService.getChamaRelatorio(values, 'ServletPedidos', 'Resumo do Pedido');
  };

  onFiltraProdutos = () => {
    if (timeout) {
      clearTimeout(timeout);
      timeout = null;
    }
    timeout = setTimeout(() => this.forceUpdate(), 300);
  };

  disabledDate = current => {
    if (UserService.getUser().data_retroativa_portal === 'N') {
      return current && current < moment();
    }
    return false;
  };

  handleSelectProduto = record => {
    const { general } = this.props;
    const { isMobile } = general;
    if (isMobile) {
      this.setState({
        configKeyboard: {
          titulo: record.descricao_cliente,
          type: record.unidade === 'KG' ? 'decimal' : 'integer',
          initialValue: record.quantidade,
          onOk: value => {
            this.setState({ configKeyboard: undefined }, () => {
              this.onEditItem({ ...record, quantidade: value });
            });
          },
        },
      });
    }
  };

  renderProdutos = () => {
    const { produtosGrupos, produtosSubGrupos, produtosGrupoSeparacao, general } = this.props;
    const { action, isLoading, isLoadingProdutos, itensProdutos, itensResumo } = this.state;
    const { isMobile } = general;
    const isEdit = action !== actions.idle && !isLoading;
    const valuesFilter = this.filterApi ? this.filterApi.getValues() : {};
    const itensProdutosFilter = isMobile
      ? itensProdutos.filter(
          item =>
            (valuesFilter.filtro
              ? (item.descricao_cliente && item.descricao_cliente.toLowerCase().indexOf(valuesFilter.filtro.toLowerCase()) > -1) ||
                (item.nome_grupo && item.nome_grupo.toLowerCase().indexOf(valuesFilter.filtro.toLowerCase()) > -1) ||
                (item.nome_subgrupo && item.nome_subgrupo.toLowerCase().indexOf(valuesFilter.filtro.toLowerCase()) > -1)
              : true) && (valuesFilter.promocao ? valuesFilter.promocao === item.promocao : true)
        )
      : itensProdutos.filter(
          item =>
            (valuesFilter.filtro ? item.descricao_cliente && item.descricao_cliente.toLowerCase().indexOf(valuesFilter.filtro.toLowerCase()) > -1 : true) &&
            (valuesFilter.grupo && valuesFilter.grupo.length > 0 ? valuesFilter.grupo.find(itemGrupo => itemGrupo === item.grupo) : true) &&
            (valuesFilter.subgrupo && valuesFilter.subgrupo.length > 0 ? valuesFilter.subgrupo.find(itemGrupo => itemGrupo === item.subgrupo) : true) &&
            (valuesFilter.promocao ? valuesFilter.promocao === item.promocao : true) &&
            (valuesFilter.grupo_separacao && valuesFilter.grupo_separacao.length > 0
              ? valuesFilter.grupo_separacao.find(itemGrupo => itemGrupo === item.grupo_separacao)
              : true)
        );
    const MOSTRA_PRECO = UserService.getUser().mostra_precos_site === '1';
    const MOSTRA_ESTOQUE = UserService.getUser().mostra_estoque_site === 'S';
    const VENDA_SUGESTAO = CustomerService.getCustomer().venda_sugestao === 'S';
    const MOSTRA_CUSTO = UserService.getUser().mostra_custos_site === '1';

    return (
      <>
        <Form getApi={api => (this.filterApi = api)} onSubmit={this.onFiltraProdutos}>
          <ContentWrapper type="search">
            <Row gutter={4}>
              <Col md={6} span={isMobile ? 12 : 3}>
                <Input field="filtro" label="Filtrar Descrição" type="string" disabled={!isEdit} onChange={() => this.filterApi.submitForm()} allowClear />
              </Col>
              <Col md={6} hidden={isMobile}>
                <Select
                  field="grupo"
                  label="Grupo"
                  mode="multiple"
                  onChange={() => {
                    this.filterApi.submitForm();
                    this.filterApi.setValue('subgrupo', []);
                  }}
                  dataSource={produtosGrupos.data}
                  disabled={!isEdit}
                  maxTagCount={1}
                />
              </Col>
              <Col md={5} hidden={isMobile}>
                <Select
                  field="subgrupo"
                  label="Subgrupo"
                  mode="multiple"
                  onChange={() => this.filterApi.submitForm()}
                  dataSource={produtosSubGrupos.data.filter(item => (valuesFilter.grupo || []).find(itemFind => item.grupo === itemFind))}
                  disabled={!isEdit}
                  maxTagCount={1}
                />
              </Col>
              <Col md={5} hidden={isMobile}>
                <Select
                  field="grupo_separacao"
                  label="Grupo de Separação"
                  mode="multiple"
                  onChange={() => this.filterApi.submitForm()}
                  dataSource={produtosGrupoSeparacao.data}
                  disabled={!isEdit}
                  maxTagCount={1}
                />
              </Col>
              <Col md={2} span={isMobile ? 12 : 3}>
                <Select
                  field="promocao"
                  label="Promoção"
                  onChange={() => this.filterApi.submitForm()}
                  dataSource={affirmatives}
                  disabled={!isEdit}
                  allowClear
                />
              </Col>
            </Row>
          </ContentWrapper>
        </Form>
        <Form getApi={api => (this.tableProdutoApi = api)}>
          <Table
            dataSource={itensProdutosFilter}
            rowKey="idt_tabela"
            rowColor={rowColor}
            loading={isLoadingProdutos}
            expandable={{
              expandedRowRender: record =>
                record.escalas.map(itemEscala => (
                  <p key={itemEscala.uuid} style={{ margin: 0 }}>
                    {`Quantidade > ${itemEscala.quantidade_escala}: R$ ${decimalPlace(itemEscala.preco_venda, 2)}`}
                  </p>
                )),
              rowExpandable: record => record.escalas && record.escalas.length > 0,
              columnWidth: '30px',
            }}
            height={window.innerHeight - 455}
            onRecordChange={record => this.handleSelectProduto(record)}
            description={item => (
              <Row>
                <Col span={24}>
                  <span style={{ fontSize: '0.8rem' }}>{item.descricao_cliente}</span>
                </Col>
                <Col span={12}>
                  <span className="icon-blue" hidden={!MOSTRA_CUSTO}>
                    {decimalPlace(item.preco_venda, 2)}
                  </span>
                </Col>
                <Col span={12} align="right">
                  <span className="icon-blue">{decimalPlace(item.quantidade, 3)}</span>
                </Col>
              </Row>
            )}
          >
            <Table.Column width="8%" title="Código" align="right" dataIndex="codigo" />
            <Table.Column width="8%" title="Cliente" align="right" dataIndex="codigo_cliente" />
            <Table.Column
              width={`${(VENDA_SUGESTAO ? 0 : 18) + (MOSTRA_PRECO ? 0 : 24) + 30}%`}
              title="Descrição"
              align="left"
              dataIndex="descricao_cliente"
              onMobile="show"
            />
            <Table.Column width="5%" title="UN" align="center" dataIndex="unidade" onMobile="show" />
            <Table.Column width="6%" title="Peso" align="right" dataIndex="peso" type="decimal" />
            <Table.Column width="10%" title="R$ Venda" align="right" dataIndex="preco_venda" type="currency" hide={!MOSTRA_PRECO} />
            <Table.Column width="12%" title="R$ Venda KG" align="right" dataIndex="preco_venda_kg" type="currency" hide={!MOSTRA_PRECO} />
            <Table.Column
              width="8%"
              title="Qtde."
              align="right"
              dataIndex="quantidade"
              type="decimal"
              disabled={!isEdit}
              editable
              functionAction={this.onEditItem}
              ellipsis
              onMobile="show"
            />
            <Table.Column
              width="8%"
              title="Estoque"
              align="right"
              dataIndex="estoque"
              type="decimal"
              disabled={!isEdit}
              editable
              functionAction={this.onEditItem}
              ellipsis
              onMobile="show"
              hide={!MOSTRA_ESTOQUE}
            />
            {VENDA_SUGESTAO ? (
              <>
                <Table.Column width="10%" title="Vendas Anteriores" align="right" dataIndex="projecao" type="decimal" ellipsis />
                <Table.Column width="8%" title="Sugestão" align="right" dataIndex="projecao_real" />
              </>
            ) : (
              []
            )}
            <Table.Column width="8%" title="R$ Médio" align="right" dataIndex="projecao_valor" type="currency" ellipsis hide={!MOSTRA_PRECO} />
          </Table>
        </Form>
        <ContentWrapper type="search">
          <Row>
            <Col span={isMobile ? 24 : 4} style={{ textAlign: 'center', display: isMobile ? 'flex' : 'grid' }}>
              <Statistic title="Total de Itens" value={itensResumo.length} prefix="" precision={0} color="#cf1322" sizeMobile="50%" />
              {MOSTRA_CUSTO ? (
                <Statistic
                  title="Valor Total"
                  value={
                    itensResumo.reduce(
                      (previous, current) => parseFloat(parseFloat(current.preco_venda || 0) * parseFloat(current.quantidade || 0)) + previous,
                      0
                    ) || 0
                  }
                  color={'#3f8600'}
                  sizeMobile="50%"
                />
              ) : null}
            </Col>
          </Row>
        </ContentWrapper>
      </>
    );
  };

  renderObservacao = () => {
    const { action, isLoading } = this.state;
    const isEdit = action !== actions.idle && !isLoading;

    return (
      <Form getApi={api => (this.observacaoApi = api)}>
        <ContentWrapper type="header" color={action.color}>
          <Row gutter={4}>
            <Col span={24}>
              <Input.TextArea field="observacao" label="Observações" type="string" disabled={!isEdit} autoSize={{ minRows: 5, maxRows: 5 }} />
            </Col>
          </Row>
        </ContentWrapper>
      </Form>
    );
  };

  renderResumo = () => {
    const { itensResumo } = this.state;
    const MOSTRA_CUSTO = UserService.getUser().mostra_custos_site === '1';

    return (
      <>
        <Form getApi={api => (this.tableResumoApi = api)}>
          <Table
            dataSource={itensResumo}
            rowKey="idt_tabela"
            height={window.innerHeight - 400}
            description={item => (
              <Row>
                <Col span={24}>
                  <span style={{ fontSize: '0.8rem' }}>{item.descricao_cliente}</span>
                </Col>
                <Col span={12}>
                  <span className="icon-blue" hidden={!MOSTRA_CUSTO}>
                    {decimalPlace(item.preco_venda, 2)}
                  </span>
                </Col>
                <Col span={12} align="right">
                  <span className="icon-blue">{decimalPlace(item.quantidade, 3)}</span>
                </Col>
              </Row>
            )}
          >
            <Table.Column width="10%" title="Código" align="right" dataIndex="codigo" />
            <Table.Column width="10%" title="Cliente" align="right" dataIndex="codigo_cliente" />
            <Table.Column width={MOSTRA_CUSTO ? '51%' : '59%'} title="Descrição" align="left" dataIndex="descricao_cliente" />
            <Table.Column width="5%" title="UN" align="center" dataIndex="unidade" />
            <Table.Column width="8%" title="Peso" align="right" dataIndex="peso" type="decimal" />
            <Table.Column width="8%" title="R$" align="right" dataIndex="preco_venda" type="currency" hide={!MOSTRA_CUSTO} />
            <Table.Column width="8%" title="Qtde" align="right" dataIndex="quantidade" type="decimal" />
          </Table>
        </Form>
      </>
    );
  };

  renderDesktop = () => {
    return (
      <>
        {this.renderProdutos()}
        {this.renderObservacao()}
        {this.renderResumo()}
      </>
    );
  };

  renderTabMobile = () => {
    const { activeTabMobile } = this.state;

    return (
      <>
        <Tabs size="small" activeKey={activeTabMobile} defaultActiveKey={activeTabMobile} onChange={activeKey => this.setState({ activeTabMobile: activeKey })}>
          <Tabs.TabPane tab="PRODUTOS" key="produtos" forceRender>
            {this.renderProdutos()}
          </Tabs.TabPane>
          <Tabs.TabPane tab="OBS" key="obs" forceRender>
            {this.renderObservacao()}
          </Tabs.TabPane>
          <Tabs.TabPane tab="RESUMO" key="resumo" forceRender>
            {this.renderResumo()}
          </Tabs.TabPane>
        </Tabs>
      </>
    );
  };

  render = () => {
    const { menuId, tabId, general } = this.props;
    const { isLoading, itens, selectedItem, action, activeTab, itensProdutos, itensResumo, isLoadingProdutos, itensContratos, configKeyboard } = this.state;
    const { isMobile } = general;
    const isEdit = action !== actions.idle && !isLoading;
    const isIdle = action === actions.idle && !isLoading;
    const MOSTRA_CUSTO = UserService.getUser().mostra_custos_site === '1';

    return (
      <>
        <KeyboardNumericView config={configKeyboard} />
        <Page
          tabbed
          tabId={tabId}
          closable={isIdle}
          helpContent={<Markdown source={README} />}
          activeKey={activeTab}
          onChange={activeKey => this.setState({ activeTab: activeKey })}
          footer={selectedItem.codigo && !isMobile ? <Status record={selectedItem} /> : null}
        >
          <Page.TabPane key={tabPanes.table.key} tab={tabPanes.table.tab} disabled={action !== actions.idle}>
            <Form getApi={api => (this.searchApi = api)} onSubmit={this.handleSearch}>
              <ContentWrapper type="search">
                <Row gutter={4}>
                  <Col md={3} span={isMobile ? 24 : 3}>
                    <Input field="codigo" label="Código" type="integer" disabled={isLoading} forwardedRef={ref => (this.searchInput = ref)} />
                  </Col>
                  <Col md={3} span={isMobile ? 12 : 3}>
                    <DatePicker field="inicio" label="Período" disabled={isLoading} required today />
                  </Col>
                  <Col md={3} span={isMobile ? 12 : 3}>
                    <DatePicker field="fim" label="Período" disabled={isLoading} required today />
                  </Col>
                  <Col md={3} span={isMobile ? 24 : 3}>
                    <Button.Search disabled={isLoading} style={isMobile ? {} : undefined} />
                  </Col>
                </Row>
              </ContentWrapper>
            </Form>
            <Table
              rowKey="codigo"
              loading={isLoading}
              record={selectedItem}
              onRecordChange={this.handleSelect}
              dataSource={itens}
              onRow={{ onDoubleClick: () => this.handleSelectShow() }}
              dataIndex="codigo"
              height={window.innerHeight - 320}
              description={item => (
                <Row>
                  <Col span={6}>{dateValue(item.data)}</Col>
                  <Col span={1} />
                  <Col span={11}>{item.status}</Col>
                  <Col align="right" span={6}>
                    <span className="icon-red">{decimalPlace(item.valor_bilhete, 2)}</span>
                  </Col>
                </Row>
              )}
            >
              <Table.Column width="15%" title="Código" align="center" dataIndex="codigo" onMobile="show" />
              <Table.Column width="15%" title="Data" align="center" dataIndex="data" type="date" onMobile="show" />
              <Table.Column width="15%" title="Nota" align="center" dataIndex="nota" type="integer" />
              <Table.Column width="15%" title="Total" align="center" dataIndex="valor_bilhete" type="currency" hide={!MOSTRA_CUSTO} />
              <Table.Column width={MOSTRA_CUSTO ? '25%' : '40%'} title="Status" align="center" dataIndex="status" onMobile="show" />
              <Table.Column width="15%" title="Inclusão" align="center" dataIndex="data_inc" type="date" />
              <Table.Column title="Observação" dataIndex="observacao" onDesktop="hide" />
              <Table.Column
                align="center"
                dataIndex="actions"
                width="5%"
                title="Ações"
                exporter={false}
                sorter={false}
                render={(text, record) => (
                  <IconDelete
                    validate={{ value: record.codigo, name: `código: ${record.codigo}`, prefix: 'o' }}
                    prefix=" o registro"
                    description={record.codigo}
                    onClick={() => this.handleDelete(record)}
                  />
                )}
              />
            </Table>
          </Page.TabPane>
          <Page.TabPane key={tabPanes.record.key} tab={tabPanes.record.tab} disabled={action === actions.idle && !selectedItem.codigo}>
            <Form getApi={api => (this.saveApi = api)} onSubmit={this.verificarHorarioData}>
              {({ formState }) => (
                <ContentWrapper type="header" color={action.color}>
                  <Row gutter={4}>
                    <Col md={3} span={isMobile ? 12 : 3}>
                      <DatePicker
                        field="data_pedido"
                        label="Data da Entrega"
                        disabled={action !== actions.insert || isLoadingProdutos || isLoading}
                        onBlur={this.onBlurData}
                        onChange={this.onChangeData}
                        disabledDate={this.disabledDate}
                        forwardedRef={isMobile ? undefined : ref => (this.editInput = ref)}
                        initialValue={moment()
                          .add(1, 'day')
                          .format('YYYYMMDD')}
                        required
                      />
                    </Col>
                    <Col md={3} span={isMobile ? 12 : 3}>
                      <Input
                        field="ordem_compra"
                        label="Ordem de Compra"
                        type="string"
                        disabled={!isEdit}
                        forwardedRef={ref => (this.ordemCompraInput = ref)}
                      />
                    </Col>
                    <Col md={15} span={isMobile ? 24 : 12}>
                      <SelectCombo
                        field="contrato"
                        label="Contrato"
                        disabled={!isEdit}
                        required={itensContratos.length > 0}
                        dataSource={itensContratos}
                        forwardedRef={ref => (this.contratoInput = ref)}
                        onBlur={this.onChangeData}
                      />
                    </Col>
                    <Col md={3} span={isMobile ? 12 : 3}>
                      <Input
                        field="acesso_portal_contrato"
                        label="Chave Contrato"
                        disabled={!isEdit}
                        capsLock={false}
                        required={
                          formState.values.contrato &&
                          parseInt((itensContratos.find(item => item.data === formState.values.contrato) || {}).acesso_portal_contrato || '', 10) > 0
                        }
                        onBlur={this.onChangeData}
                      />
                    </Col>
                  </Row>
                </ContentWrapper>
              )}
            </Form>
            {isMobile ? this.renderTabMobile() : this.renderDesktop()}
          </Page.TabPane>
        </Page>
        <FooterToolbar
          left={
            <>
              <Button.Insert perfilAcess menuId={menuId} disabled={!isIdle} onClick={() => this.handleEdit(actions.insert)} />
              <Button.Update
                perfilAcess
                menuId={menuId}
                disabled={
                  !selectedItem.codigo ||
                  selectedItem.nota ||
                  selectedItem.status !== 'ABERTO' ||
                  !isIdle ||
                  (itensResumo.length === 0 && action === actions.insert) ||
                  itensProdutos.length === 0
                }
                onClick={() => this.handleEdit(actions.update)}
              />
            </>
          }
          center={
            <>
              <Button.Print options onPrint={this.imprimir} disabled={!selectedItem.codigo} />
              <Navigator
                disabled={!isIdle}
                record={selectedItem}
                dataSource={itens}
                dataIndex="codigo"
                onClick={record => this.setState({ selectedItem: record })}
              />
            </>
          }
          right={
            <>
              <Button.Cancel disabled={isIdle || isLoading} onClick={() => this.onEdit(true)} />
              <Button.Save disabled={isIdle || isLoading} loading={action !== actions.idle && isLoading} onClick={() => this.saveApi.submitForm()} />
            </>
          }
        />
      </>
    );
  };
}

const mapStateToProps = ({ produtosGrupos, produtosSubGrupos, produtosGrupoSeparacao, general }) => ({
  produtosGrupos,
  produtosSubGrupos,
  produtosGrupoSeparacao,
  general,
});
export default connect(mapStateToProps)(PedidoPage);
