import React from 'react';
import { Card, Tabs, Divider, Dropdown, Menu } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import classNames from 'classnames';
import { CloseOutlined, MoreOutlined } from '@ant-design/icons';
import { Creators } from '~/stores/ducks/general/tabs';
import { TabPane, Modal, Help, PinPage, ParamPage } from './components';
import './Page.less';

class Page extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      clickFix: 0,
    };
  }

  renderExtra = () => {
    const { clickFix } = this.state;
    const { tabbed, tabId, removeTab, extraHeader, paramPage, general, closable = true } = this.props;

    return (
      <div className={classNames({ 'page-extra-tabbed': tabbed === true })}>
        {extraHeader ? (
          <>
            {extraHeader}
            <Divider type="vertical" />
          </>
        ) : null}
        {paramPage ? (
          <>
            <ParamPage paramPage={paramPage} />
            <Divider type="vertical" />
          </>
        ) : null}
        {general.isMobile ? (
          <Dropdown
            placement="bottomRight"
            className="right-content-action"
            arrow
            trigger="click"
            overlay={
              <Menu
                selectedKeys={[]}
                onClick={({ key }) => {
                  if (key === 'fix') {
                    this.setState({ clickFix: clickFix + 1 });
                  } else if (key === 'close') {
                    removeTab(tabId);
                  }
                }}
              >
                <Menu.Item key="fix">
                  <>
                    <PinPage clickParent={clickFix} />
                    {'Fixar'}
                  </>
                </Menu.Item>
                <Menu.Item key="close">
                  <>
                    <CloseOutlined title="Fechar" className={closable === false ? 'icon-disabled' : ''} onClick={() => removeTab(tabId)} />
                    {'Fechar'}
                  </>
                </Menu.Item>
              </Menu>
            }
          >
            <MoreOutlined style={{ fontSize: '16px' }} />
          </Dropdown>
        ) : (
          <>
            <PinPage />
            <Divider type="vertical" />
            <Help />
            <Divider type="vertical" />
            <CloseOutlined title="Fechar" className={closable === false ? 'icon-disabled' : ''} onClick={() => removeTab(tabId)} />
          </>
        )}
      </div>
    );
  };

  render() {
    const { tabbed, footer, children, showExtra = true, onChange, activeKey, title, className, bodyStyle } = this.props;

    if (!tabbed) {
      return (
        <Card
          bodyStyle={{ ...(bodyStyle || {}) }}
          extra={showExtra && this.renderExtra()}
          className={classNames('card-header-page', className)}
          title={title || ''}
        >
          {children}
          {footer && <div className="page-footer">{footer}</div>}
        </Card>
      );
    }

    return (
      <Page bodyStyle={{ padding: 0, ...(bodyStyle || {}) }} footer={footer} showExtra={false}>
        <Tabs
          className="page-tabs"
          renderTabBar={({ ...tabBarProps }, DefaultTabBar) => <DefaultTabBar className={classNames(className, 'page-tabs-tab-bar')} {...tabBarProps} />}
          tabBarExtraContent={this.renderExtra()}
          onChange={onChange}
          activeKey={activeKey}
        >
          {children}
        </Tabs>
      </Page>
    );
  }
}

Page.TabPane = TabPane;
Page.Modal = Modal;

const mapDispatchToProps = dispatch => bindActionCreators(Creators, dispatch);
const mapStateToProps = ({ general }) => ({ general });
export default connect(mapStateToProps, mapDispatchToProps)(Page);
