import axios from 'axios';
import PubSub from 'pubsub-js';
import crypto from 'crypto-js';
import { UserService, CustomerService, RememberService } from '~/services';
import { notifyError, notifyErrorImage } from '~/utils/services';

const getVars = idtmp => {
  if (!UserService.isLogado()) {
    return {};
  }

  const user = UserService.getUser();
  const customer = CustomerService.getCustomer();

  return {
    usuario: user.nome || '',
    perfil: user.perfil || -1,
    idtmp: idtmp || user.idtmp || -1,
    cliente: customer.nome || '',
    sistema: customer.sistema || '',
    estrutura: customer.estrutura || '',
    origem: customer.origem || -1,
    host: customer.endereco_banco || '',
    banco: customer.database || '',
    estado: customer.estado || '',
    posto_mysaas: customer.posto,
  };
};

const HttpService = {
  call: (file, method, params = {}, onSuccess, onError, config = {}, idtmp = false, ignoreLimit = false) => {
    const options = {
      notifyError: true,
      showNotification: true,
      baseURL: process.env.REACT_APP_URL,
      url: 'link.php',
      ...config,
    };

    axios({
      method: 'post',
      baseURL: options.baseURL,
      url: options.url,
      responseType: 'json',
      responseEncoding: 'utf8',
      params: options.params,
      headers: {
        Authorization: `Bearer ${RememberService.getRemember().token || ''}`,
      },
      data: {
        file,
        method,
        params,
        react: 'S',
        ...getVars(idtmp),
      },
    })
      .then(({ data }) => {
        let decryptData;
        if (process.env.NODE_ENV === 'production' && file && method) {
          const decrypted = crypto.AES.decrypt(data.data, crypto.enc.Utf8.parse(crypto.MD5('g3108f88')), { iv: crypto.enc.Hex.parse(data.iv) });
          decryptData = JSON.parse(crypto.enc.Utf8.stringify(decrypted));
          if (typeof decryptData === 'string' && decryptData.indexOf('"') >= 0) {
            decryptData = JSON.parse(decryptData);
          }
        } else {
          decryptData = data;
        }

        if (process.env.NODE_ENV === 'production' && Array.isArray(decryptData) && decryptData.length > 5000 && !ignoreLimit) {
          setTimeout(() => {
            notifyErrorImage(
              'NUMERO DE REGISTROS EXCEDIDO',
              JSON.stringify({
                file,
                method,
                params,
              })
            );
          }, 300);
        }

        if (onSuccess) {
          onSuccess(decryptData);
        }
      })
      .catch(({ response = { config: {} } }) => {
        const error = {
          payload: {
            url: response.config.url,
            data: response.config.data && JSON.parse(response.config.data),
          },
          response: response.data,
          status: response.status,
          statusText: response.statusText,
          innerHtml: config.innerHtml || false,
        };

        // TOKEN INVÁLIDO
        if (
          response.statusText === 'Internal Server Error' &&
          error.response &&
          (error.response.str.indexOf('Tempo de conexao excedido') > 0 || error.response.str.indexOf('Token de autenticação inválido') > 0)
        ) {
          PubSub.publish('logout_service');
        }

        if (options.notifyError && error.status) {
          notifyError(error, options.showNotification);
        }

        if (onError) {
          onError(error);
        }
      });
  },
};

export { getVars };
export default HttpService;
