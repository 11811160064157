import React from 'react';
import { Row, Col, Divider } from 'antd';
import { Input, Select, Form, Button, Table, FooterToolbar, Navigator, Status, Page, ContentWrapper, IconDelete, IconUpdate, DatePicker } from '~/components';
import { actions, affirmatives, tabPanes, estados, cidades } from '~/options';
import { MotoristaService } from '~/services';
import { buscaCep, notifySuccess } from '~/utils';

class FornecedorTipoPage extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      itens: [],
      selectedItem: {},
      action: actions.idle,
      activeTab: tabPanes.table.key,
    };
  }

  componentDidMount() {
    this.searchApi.submitForm();
  }

  handleSearch = values => {
    this.setState({ isLoading: true });
    MotoristaService.getAll(
      values,
      itens => {
        this.setState({ itens, isLoading: false });
        this.searchInput.focus();
      },
      () => {
        this.setState({ isLoading: false });
      }
    );
  };

  handleSelect = record => {
    this.setState({ selectedItem: record });
    this.saveApi.reset();
    this.saveApi.setValues(record);
  };

  handleEdit = action => {
    this.setState({ action, activeTab: tabPanes.record.key });
    setTimeout(() => this.editInput.focus(), 300);

    if (action === actions.insert) {
      this.saveApi.reset();
    }
  };

  onEdit = (cancel = false) => {
    let { selectedItem, activeTab } = this.state;

    if (cancel) {
      this.handleSelect(selectedItem);

      if (!selectedItem.idt) {
        activeTab = tabPanes.table.key;
      }
    } else {
      this.searchApi.submitForm();

      const { action } = this.state;

      if (action === actions.insert) {
        activeTab = tabPanes.table.key;
        selectedItem = {};
      }
    }

    this.setState({ action: actions.idle, selectedItem, activeTab });
  };

  handleSave = values => {
    const { action } = this.state;
    let record = {};
    let saveHandler = MotoristaService.incluir;

    if (action === actions.update) {
      const { selectedItem } = this.state;
      record = selectedItem;
      saveHandler = MotoristaService.alterar;
    }

    this.setState({ isLoading: true });
    saveHandler(
      { ...record, ...values, base_inss: values.base_inss ? values.base_inss : 0, base_irff: values.base_irff ? values.base_irff : 0 },
      newId => {
        this.setState({ selectedItem: { ...values, idt: newId } }, () => {
          this.onSave();
        });
      },
      () => this.onSave('', true)
    );
  };

  onSave = (error = false) => {
    this.setState({ isLoading: false }, () => {
      if (!error) {
        const { name } = this.props;
        const { action } = this.state;
        this.onEdit();
        notifySuccess({ name, action });
      }
    });
  };

  handleDelete = id => {
    this.setState({ isLoading: true });
    MotoristaService.excluir(
      id,
      () => this.onDelete(),
      () => this.onDelete(true)
    );
  };

  onDelete = (error = false) => {
    this.setState({ isLoading: false });

    if (!error) {
      const { name } = this.props;

      this.searchApi.submitForm();
      notifySuccess({ name, action: actions.remove });
    }
  };

  validaCep = value => {
    if (value.target.value.length === 10) {
      this.setState({ isLoading: true });
      buscaCep(value.target.value, this.onValidaCep, () => this.onValidaCep());
    }
  };

  onValidaCep = (values = {}) => {
    this.saveApi.setValues({
      cep: values.cep || this.saveApi.getValue('cep'),
      endereco: (values.logradouro || '').replace(/[^A-Za-z0-9 ]/g, '') || '',
      bairro: values.bairro || '',
      estado: values.uf || this.saveApi.getValue('estado'),
      codigo_cidade: values.ibge || '',
      codigo_pais: values.ibge ? '1058' : this.saveApi.getValue('codigo_pais'),
    });

    this.setState({ isLoading: false });
    this.numeroEndereco.focus();
  };

  render() {
    const { tabId, menuId } = this.props;
    const { activeTab, selectedItem, action, isLoading, itens } = this.state;
    const isEdit = action !== actions.idle && !isLoading;
    const isIdle = action === actions.idle && !isLoading;

    return (
      <>
        <Page
          tabbed
          tabId={tabId}
          closable={isIdle}
          activeKey={activeTab}
          onChange={activeKey => this.setState({ activeTab: activeKey })}
          footer={action !== actions.insert && selectedItem.idt && <Status record={selectedItem} />}
        >
          <Page.TabPane key={tabPanes.table.key} tab={tabPanes.table.tab} disabled={action !== actions.idle}>
            <Form getApi={api => (this.searchApi = api)} onSubmit={this.handleSearch}>
              <ContentWrapper type="search">
                <Row gutter={4}>
                  <Col md={16} span={10}>
                    <Input label="Nome" field="nome" disabled={isLoading} />
                  </Col>
                  <Col md={5} span={10}>
                    <Input label="CPF" field="cpf" disabled={isLoading} allowClear />
                  </Col>
                  <Col md={3} span={4}>
                    <Button.Search loading={isLoading} />
                  </Col>
                </Row>
              </ContentWrapper>
            </Form>

            <Table
              dataSource={itens}
              rowKey="idt"
              loading={isLoading}
              record={selectedItem}
              onRecordChange={record => this.handleSelect(record)}
              onRow={{ onDoubleClick: () => this.setState({ activeTab: tabPanes.record.key }) }}
              description={item => (
                <Row>
                  <Col span={16}>
                    <span style={{ fontSize: '0.8rem' }}>{item.nome_fantasia}</span>
                  </Col>
                  <Col span={8}>
                    <span>{item.cpf_fidelizado}</span>
                  </Col>
                </Row>
              )}
              height={window.innerHeight - 245}
            >
              <Table.Column width="55%" title="Nome" dataIndex="nome_fantasia" />
              <Table.Column width="20%" title="CPF" dataIndex="cpf_fidelizado" />
              <Table.Column width="10%" title="Estado" dataIndex="estado" />
              <Table.Column width="10%" title="Ativo" dataIndex="ativo" type="affirmative" />
              <Table.Column
                title="Ações"
                dataIndex="actions"
                width="5%"
                align="center"
                exporter={false}
                sorter={false}
                render={(text, record) => (
                  <>
                    <IconUpdate
                      onClick={() => {
                        this.handleSelect(record);
                        this.setState({ activeTab: tabPanes.record.key, action: actions.update });
                      }}
                    />
                    <Divider type="vertical" />
                    <IconDelete
                      validate={{ value: record.idt, name: `código: ${record.idt}`, prefix: 'o' }}
                      disabled={record.data_exc}
                      menuId={menuId}
                      prefix="o registro"
                      description={record.nome}
                      onClick={() => this.handleDelete(record.idt)}
                    />
                  </>
                )}
              />
            </Table>
          </Page.TabPane>

          <Page.TabPane key={tabPanes.record.key} tab={tabPanes.record.tab} disabled={action === actions.idle && !selectedItem.idt}>
            <Form getApi={api => (this.saveApi = api)} onSubmit={this.handleSave}>
              {({ formState }) => (
                <>
                  <ContentWrapper type="header" color={selectedItem.data_exc && action.id === 'idle' ? actions.remove.color : action.color}>
                    <Row gutter={4}>
                      <Col md={3} span={12}>
                        <Input label="Código" field="idt" type="integer" disabled />
                      </Col>
                      <Col md={2} span={12}>
                        <Select label="Ativo" field="ativo" dataSource={affirmatives} disabled={!isEdit} initialValue="S" required />
                      </Col>
                      <Col md={10} span={24}>
                        <Input label="Nome" field="nome_fantasia" disabled={!isEdit} required forwardedRef={ref => (this.editInput = ref)} />
                      </Col>
                      <Col md={5} span={12}>
                        <Input label="CPF" field="cpf_fidelizado" type="cpf" disabled={!isEdit} required allowClear />
                      </Col>
                      <Col md={4} span={12}>
                        <DatePicker label="Data Nascimento" field="data_aniversario" disabled={!isEdit} required />
                      </Col>
                      <Col md={3} span={12}>
                        <Input
                          label="CEP"
                          field="cep"
                          type="postal"
                          disabled={!isEdit}
                          required={formState.values.estado !== 'EX'}
                          onBlur={this.validaCep}
                          onChange={event => event.target.value.length === 0 && this.onValidaCep()}
                          forwardedRef={ref => (this.inputCep = ref)}
                        />
                      </Col>
                      <Col md={10} span={24}>
                        <Input label="Endereço" field="endereco" type="textoAlfaNumerico" disabled={!isEdit} maxLength={100} required />
                      </Col>
                      <Col md={3} span={12}>
                        <Input
                          label="Número"
                          field="numero"
                          disabled={!isEdit}
                          maxLength={10}
                          required={formState.values.estado !== 'EX'}
                          forwardedRef={ref => (this.numeroEndereco = ref)}
                        />
                      </Col>
                      <Col md={3} span={12}>
                        <Input label="Complemento" field="complemento" disabled={!isEdit} maxLength={100} />
                      </Col>
                      <Col md={5} span={18}>
                        <Input label="Bairro/Distrito" field="bairro" required disabled={!isEdit} maxLength={20} />
                      </Col>
                      <Col md={3} span={6}>
                        <Select
                          label="Estado"
                          field="estado"
                          dataSource={estados}
                          onSelect={value => value !== selectedItem.estado && this.saveApi.setValues({ codigo_cidade: '' })}
                          disabled={!isEdit}
                          onChange={() => this.forceUpdate()}
                        />
                      </Col>
                      <Col md={10} span={12}>
                        <Select
                          label="Cidade"
                          field="codigo_cidade"
                          dataSource={(formState.values.estado && cidades[formState.values.estado.toLowerCase()]) || []}
                          disabled={!isEdit || !formState.values.estado || formState.values.estado === 'EX'}
                          disabledReason={
                            isEdit &&
                            (!formState.values.estado || formState.values.estado === 'EX') &&
                            'É funcional quando há um estado selecionado e não for Exterior.'
                          }
                          showSearch
                          required={formState.values.estado !== 'EX' && formState.values.cep}
                        />
                      </Col>
                      <Col md={5} span={12}>
                        <Input label="Telefone" field="telefone1" type="phone" disabled={!isEdit} maxLength={20} required={formState.values.estado !== 'EX'} />
                      </Col>
                      <Col md={6} span={24}>
                        <Input label="Email" field="email" type="email" disabled={!isEdit} maxLength={60} required={formState.values.estado !== 'EX'} />
                      </Col>
                    </Row>
                  </ContentWrapper>
                </>
              )}
            </Form>
          </Page.TabPane>
        </Page>
        <FooterToolbar
          left={
            <>
              <Button.Insert disabled={!isIdle} menuId={menuId} onClick={() => this.handleEdit(actions.insert)} />
              <Button.Update disabled={!selectedItem.idt || selectedItem.data_exc || !isIdle} menuId={menuId} onClick={() => this.handleEdit(actions.update)} />
            </>
          }
          center={
            <Navigator
              disabled={!isIdle}
              record={selectedItem}
              dataSource={itens}
              dataIndex="idt"
              onClick={record => this.setState({ selectedItem: record })}
            />
          }
          right={
            <>
              <Button.Cancel disabled={isIdle || isLoading} onClick={() => this.onEdit(true)} />
              <Button.Save disabled={isIdle || isLoading} loading={action !== actions.idle && isLoading} onClick={() => this.saveApi.submitForm()} />
            </>
          }
        />
      </>
    );
  }
}

export default FornecedorTipoPage;
