import React from 'react';
import moment from 'moment';
import { Row, Col, Modal } from 'antd';
import { Input, Form, Button, Table, FooterToolbar, Page, ContentWrapper, DatePicker, Select, Statistic } from '~/components';
import { ChequeService, UserService } from '~/services';
import { decimalValue } from '~/utils';

class ChequeTrocadoPage extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      itens: [],
      selectedItem: {},
      empresas: [],
    };
  }

  componentDidMount() {
    this.searchInput.focus();
    UserService.fetchEmpresas(empresas => this.setState({ empresas }));
  }

  handleSearch = values => {
    if (moment(values.data_final).diff(values.data_inicial, 'days') > 31) {
      Modal.error({
        title: 'Cheques Trocados',
        content: 'Não é possível buscar um período maior que o selecionado',
        centered: true,
      });
      return;
    }

    this.setState({ isLoading: true });
    ChequeService.getAllTrocados(
      values,
      itens => this.setState({ itens, isLoading: false }),
      () => this.setState({ isLoading: false })
    );
  };

  render() {
    const { tabId } = this.props;
    const { isLoading, itens, selectedItem, empresas } = this.state;

    return (
      <>
        <Page tabId={tabId}>
          <Form getApi={api => (this.searchApi = api)} onSubmit={this.handleSearch}>
            <ContentWrapper type="search">
              <Row gutter={4}>
                <Col md={3} span={12}>
                  <DatePicker field="data_inicial" label="Data Inicial" required today disabled={isLoading} />
                </Col>
                <Col md={3} span={12}>
                  <DatePicker field="data_final" label="Data Final" required today disabled={isLoading} />
                </Col>
                <Col md={3} span={12}>
                  <Input field="placa" label="Placa" disabled={isLoading} type="placa" allowClear forwardedRef={ref => (this.searchInput = ref)} />
                </Col>
                <Col md={12} span={20}>
                  <Select field="origem" label="Empresa" dataSource={empresas} allowClear disabled={isLoading} />
                </Col>
                <Col md={3} span={4}>
                  <Button.Search loading={isLoading} />
                </Col>
              </Row>
            </ContentWrapper>
          </Form>
          <Table
            dataSource={itens}
            rowKey="idt"
            loading={isLoading}
            record={selectedItem}
            onRecordChange={record => this.setState({ selectedItem: record })}
            description={item => (
              <Row>
                <Col span={16}>
                  <span style={{ fontSize: '0.8rem' }}>{item.placa}</span>
                </Col>
                <Col span={8}>
                  <span>{item.emissao}</span>
                </Col>
              </Row>
            )}
            height={window.innerHeight - 390}
          >
            <Table.Column title="Placa" dataIndex="placa" width="7%" align="center" />
            <Table.Column title="Emissão" dataIndex="emissao" type="date" onMobile="show" width="12%" />
            <Table.Column title="Vencimento" dataIndex="vencimento" type="date" onMobile="show" width="10%" />
            <Table.Column title="Documento" dataIndex="documento" width="10%" />
            <Table.Column title="CPF/CNPJ" dataIndex="cpf" width="15%" />
            <Table.Column title="Valor (R$)" dataIndex="valor" type="currency" width="10%" />
          </Table>
          <ContentWrapper type="header">
            <Row>
              <Col md={11} />
              <Col md={13}>
                <Statistic
                  title="Valor Total (R$)"
                  value={decimalValue(
                    itens.reduce((previous, current) => parseFloat(current.valor) + previous, 0),
                    2
                  )}
                  color="#3f8600"
                />
              </Col>
            </Row>
          </ContentWrapper>
        </Page>
        <FooterToolbar left={<></>} center={<></>} right={<></>} />
      </>
    );
  }
}

export default ChequeTrocadoPage;
