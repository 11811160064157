const integer = {
  id: 'integer',
  className: 'text-center',
  precision: 0,
  restrict: value => !/[^0-9]/g.test(value),
  parser: value => value.replace(/[^0-9]/g, ''),
  formatter: value => (parseInt(value, 10) || value === '0' ? value : ''),
};

const currency = {
  id: 'currency',
  className: 'text-right',
  precision: 2,
  restrict: value => /^-?[\d.,]{0,}$/g.test(value),
  parser: value => {
    let retorno = value.replace(/[.](?!$)/g, '');
    if (retorno.indexOf(',') > 0) {
      retorno = retorno.replace(/([,][0-9]{2})(.)$/g, '$1');
    }
    retorno = retorno.replace(/(?!^)-/g, '');
    if ((retorno.match(/[,]/g) || '').length > 1 || ((retorno.match(/[,]/g) || '').length === 1 && (retorno.match(/[.]$/g) || '').length === 1)) {
      retorno = retorno.replace(/^[^-0-9]|[^0-9,.-]/g, '').replace(/[.,]$/g, '');
    } else {
      retorno = retorno.replace(/^[^-0-9]|[^0-9,.-]/g, '').replace(/[.]$/g, ',');
    }
    return retorno.replace(',', '.');
  },
  formatter: value =>
    value
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, '*')
      .replace(/\./g, ',')
      .replace(/\*/g, '.'),
};

const decimal = {
  id: 'decimal',
  className: 'text-right',
  precision: 3,
  restrict: value => /^-?[\d.,]{0,}$/g.test(value),
  parser: value => {
    let retorno = value.replace(/[.](?!$)/g, '');
    if (retorno.indexOf(',') > 0) {
      retorno = retorno.replace(/([,][0-9]{3})(.)$/g, '$1');
    }
    retorno = retorno.replace(/(?!^)-/g, '');
    if ((retorno.match(/[,]/g) || '').length > 1 || ((retorno.match(/[,]/g) || '').length === 1 && (retorno.match(/[.]$/g) || '').length === 1)) {
      retorno = retorno.replace(/^[^-0-9]|[^0-9,.-]/g, '').replace(/[.,]$/g, '');
    } else {
      retorno = retorno.replace(/^[^-0-9]|[^0-9,.-]/g, '').replace(/[.]$/g, ',');
    }
    return retorno.replace(',', '.');
  },
  formatter: value =>
    value
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, '*')
      .replace(/\./g, ',')
      .replace(/\*/g, '.'),
};

const precision = {
  id: 'precision',
  className: 'text-right',
  precision: 4,
  restrict: value => /^-?[\d.,]{0,}$/g.test(value),
  parser: value => {
    let retorno = value.replace(/[.](?!$)/g, '');
    if (retorno.indexOf(',') > 0) {
      retorno = retorno.replace(/([,][0-9]{4})(.)$/g, '$1');
    }
    retorno = retorno.replace(/(?!^)-/g, '');
    if ((retorno.match(/[,]/g) || '').length > 1 || ((retorno.match(/[,]/g) || '').length === 1 && (retorno.match(/[.]$/g) || '').length === 1)) {
      retorno = retorno.replace(/^[^-0-9]|[^0-9,.-]/g, '').replace(/[.,]$/g, '');
    } else {
      retorno = retorno.replace(/^[^-0-9]|[^0-9,.-]/g, '').replace(/[.]$/g, ',');
    }
    return retorno.replace(',', '.');
  },
  formatter: value =>
    value
      .toString()
      .replace(/\B(?=(\d{4})+(?!\d))/g, '')
      .replace(/\./g, ','),
};

export default { integer, currency, decimal, precision };
