import React from 'react';
import { SettingOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Creators } from '~/stores/ducks/general/tabs';
import { MenuService, RememberService } from '~/services';
import ParamPageFormView from './ParamPageFormView';

class ParamPage extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      ifExists: false,
      showView: false,
      selected: MenuService.getByTitle((props.tabs.data.find(item => item.id === props.tabs.activeTab) || {}).nome),
    };
  }

  componentDidMount() {
    const { paramPage } = this.props;

    this.forceUpdate(() => {
      this.setState({ ifExists: !!paramPage });
    });
  }

  onSaveParams = values => {
    const { selected } = this.state;
    const { changeTab, tabs, general } = this.props;
    const remember = RememberService.getRemember() || {};
    const chave = general.isMobile ? selected.chave_mobile : selected.chave;

    RememberService.setRemember({ ...remember, [chave]: { ...remember[chave], ...values } });

    changeTab(tabs.activeTab);
  };

  render() {
    const { ifExists, showView, selected } = this.state;
    const { paramPage } = this.props;

    return ifExists ? (
      <>
        <ParamPageFormView
          action={showView}
          paramPage={paramPage}
          onCancel={() => this.setState({ showView: false })}
          onOk={this.onSaveParams}
          menu={selected}
        />
        <SettingOutlined title={`Parâmetros ${selected.nome}`} onClick={() => this.setState({ showView: true })} />
      </>
    ) : null;
  }
}

const mapDispatchToProps = dispatch => bindActionCreators(Creators, dispatch);
const mapStateToProps = ({ tabs, general }) => ({ tabs, general });
export default connect(mapStateToProps, mapDispatchToProps)(ParamPage);
