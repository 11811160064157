import React from 'react';
import { Button } from 'antd';
import _ from 'lodash';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

class Navigator extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      previousIndex: -1,
      nextIndex: -1,
    };
  }

  componentDidUpdate() {
    this.findIndexes();
  }

  findIndexes = () => {
    const { record, dataSource, dataIndex } = this.props;
    const foundIndex = _.findIndex(dataSource, item => item[dataIndex] === record[dataIndex]);
    let previousIndex = -1;
    let nextIndex = -1;

    if (foundIndex > -1) {
      if (foundIndex !== 0) {
        previousIndex = foundIndex - 1;
      }

      if (foundIndex < dataSource.length - 1) {
        nextIndex = foundIndex + 1;
      }
    }

    this.setState({ previousIndex, nextIndex });
  };

  onClick = index => {
    const { onClick, dataSource } = this.props;

    if (onClick) {
      onClick(dataSource[index]);
    }
  };

  render() {
    const { disabled, isMobile } = this.props;
    const { previousIndex, nextIndex } = this.state;

    return !isMobile ? (
      <>
        <Button.Group>
          <Button disabled={previousIndex === -1 || disabled} onClick={() => this.onClick(previousIndex)}>
            <LeftOutlined />
          </Button>
          <Button disabled={nextIndex === -1 || disabled} onClick={() => this.onClick(nextIndex)}>
            <RightOutlined />
          </Button>
        </Button.Group>
      </>
    ) : null;
  }
}

const isMobileSelector = createSelector(
  general => general,
  ({ isMobile }) => isMobile
);
const mapStateToProps = ({ general }) => ({ isMobile: isMobileSelector(general) });
export default connect(mapStateToProps)(Navigator);
