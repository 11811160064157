import React from 'react';
import axios from 'axios';
import { Layout, Modal } from 'antd';
import { connect } from 'react-redux';
import { Footer } from '~/layouts/components';
import { Header, SiderMenu } from './components';
import './BasicLayout.less';
import { CustomerService, UserService } from '~/services';
import { notifyErrorImage } from '~/utils';
import classNames from 'classnames';

// eslint-disable-next-line import/no-unresolved
const SettingsDrawer = React.lazy(() => import('~/layouts/components/SettingsDrawer'));
// eslint-disable-next-line import/no-unresolved
const BackTop = React.lazy(() => import('~/layouts/components/BackTop'));

let confirmar = false;
let enviandoErro = false;

class BasicLayout extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    this.interval = setInterval(this.verificarVersao, 1000 * 60 * 5);

    // ESCUTAR ERRO DE TODO O SISTEMA
    window.addEventListener('error', event => {
      // CONDIÇÃO PARA NOTIFICAR
      if (
        !enviandoErro &&
        process.env.NODE_ENV === 'production' &&
        event.error.stack.indexOf('Network Error') === -1 &&
        event.error.stack.indexOf('Minified React error') === -1 &&
        event.error.stack.indexOf('Unable to find node on an unmounted component') === -1 &&
        event.error.stack.indexOf('Cannot read property key of undefined') === -1 &&
        event.error.stack.indexOf("Cannot read property 'key' of undefined") === -1 &&
        event.error.stack.indexOf("Cannot read properties of undefined (reading 'key')") === -1 &&
        event.error.stack.indexOf("Cannot read properties of undefined (reading 'idt')") === -1
      ) {
        // CONTROLE PRA NÂO ENVIAR SEGUNDAMENTE
        enviandoErro = true;
        // COPIA HTML COMO IMAGEM PARA ANEXO
        notifyErrorImage(event.error.message, event.error.stack, event.currentTarget.document.title, () => (enviandoErro = false));
      }
    });
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  verificarVersao = () => {
    if (!confirmar && document.URL.indexOf('localhost') === -1) {
      UserService.verifica_versao(response => {
        if (response.versao === 'N') {
          confirmar = true;
          Modal.confirm({
            content: 'Foi encontrada uma nova versão do sistema, Deseja Atualizar?',
            title: 'MySoft - Mysaas',
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk: () => window.location.replace(`${process.env.REACT_APP_URL}`),
            onCancel: () => (confirmar = false),
          });
        }
      });
    }
  };

  enviarLogUsuario = () => {
    axios({
      method: 'get',
      baseURL: ' https://api.ipify.org/?format=json',
      responseType: 'json',
    }).then(({ data }) => {
      UserService.inserir_log_usuario({
        ip: data.ip,
        versao_navegador: window.navigator.appVersion,
        versao_sistema: process.env.REACT_APP_VERSION,
        nome_browser: `${window.navigator.appName} - ${window.navigator.vendor}`,
        resolucao: `${window.screen.availWidth}x${window.screen.availHeight}`,
        memoria: Math.round(performance.memory.usedJSHeapSize / 1048576, 2),
        observacao: `Speed: ${window.navigator.connection.downlink}MB - SO: ${window.navigator.platform} - ${+window.innerWidth}x${window.innerHeight}`,
      });
    });
  };

  getLayoutStyle = () => {
    const { general, settings } = this.props;

    if (settings.fixSiderbar && settings.layout !== 'topmenu' && !general.isMobile) {
      return { paddingLeft: general.isCollapsed ? '80px' : '256px' };
    }

    return null;
  };

  render() {
    const { children, general, settings, tabs } = this.props;

    const customer = CustomerService.getCustomer() || {};

    const estilos = {
      backgroundColor: customer.cor_background || '#ebedf0',
    };

    return (
      <>
        <Layout style={{ minHeight: '100vh' }}>
          {settings.layout === 'topmenu' && !general.isMobile ? null : <SiderMenu />}
          <Layout style={{ minHeight: '100vh', ...this.getLayoutStyle() }}>
            <Header />
            <Layout.Content
              className={classNames('basic-layout-content', general.isMobile ? 'basic-layout-content-mobile' : '')}
              style={{ ...estilos, ...(!settings.fixedHeader ? { paddingTop: 0 } : undefined) }}
            >
              {tabs.data.length > 0 ? (
                children
              ) : (
                <div className="div-background">{customer.logo ? <img src={`data:image/png;base64, ${customer.logo_base64 || ''}`} alt="" /> : null}</div>
              )}
            </Layout.Content>
            {tabs.data.length > 0 ? null : <Footer />}
          </Layout>
        </Layout>
        <React.Suspense fallback={null}>
          <BackTop style={{ bottom: '80px' }} />
        </React.Suspense>
        <React.Suspense fallback={null}>
          <SettingsDrawer />
        </React.Suspense>
      </>
    );
  }
}

const mapStateToProps = ({ general, settings, tabs }) => ({ general, settings, tabs });

export default connect(mapStateToProps)(BasicLayout);
