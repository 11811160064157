// Paraná
const pr = [
  { data: '4100103', label: 'Abatiá' },
  { data: '4100202', label: 'Adrianópolis' },
  { data: '4100301', label: 'Agudos do Sul' },
  { data: '4100400', label: 'Almirante Tamandaré' },
  { data: '4100459', label: 'Altamira do Paraná' },
  { data: '4128625', label: 'Alto Paraíso' },
  { data: '4100608', label: 'Alto Paraná' },
  { data: '4100707', label: 'Alto Piquiri' },
  { data: '4100509', label: 'Altônia' },
  { data: '4100806', label: 'Alvorada do Sul' },
  { data: '4100905', label: 'Amaporã' },
  { data: '4101002', label: 'Ampére' },
  { data: '4101051', label: 'Anahy' },
  { data: '4101101', label: 'Andirá' },
  { data: '4101150', label: 'Ângulo' },
  { data: '4101200', label: 'Antonina' },
  { data: '4101309', label: 'Antônio Olinto' },
  { data: '4101408', label: 'Apucarana' },
  { data: '4101507', label: 'Arapongas' },
  { data: '4101606', label: 'Arapoti' },
  { data: '4101655', label: 'Arapuã' },
  { data: '4101705', label: 'Araruna' },
  { data: '4101804', label: 'Araucária' },
  { data: '4101853', label: 'Ariranha do Ivaí' },
  { data: '4101903', label: 'Assaí' },
  { data: '4102000', label: 'Assis Chateaubriand' },
  { data: '4102109', label: 'Astorga' },
  { data: '4102208', label: 'Atalaia' },
  { data: '4102307', label: 'Balsa Nova' },
  { data: '4102406', label: 'Bandeirantes' },
  { data: '4102505', label: 'Barbosa Ferraz' },
  { data: '4102703', label: 'Barra do Jacaré' },
  { data: '4102604', label: 'Barracão' },
  { data: '4102752', label: 'Bela Vista da Caroba' },
  { data: '4102802', label: 'Bela Vista do Paraíso' },
  { data: '4102901', label: 'Bituruna' },
  { data: '4103008', label: 'Boa Esperança' },
  { data: '4103024', label: 'Boa Esperança do Iguaçu' },
  { data: '4103040', label: 'Boa Ventura de São Roque' },
  { data: '4103057', label: 'Boa Vista da Aparecida' },
  { data: '4103107', label: 'Bocaiúva do Sul' },
  { data: '4103156', label: 'Bom Jesus do Sul' },
  { data: '4103206', label: 'Bom Sucesso' },
  { data: '4103222', label: 'Bom Sucesso do Sul' },
  { data: '4103305', label: 'Borrazópolis' },
  { data: '4103354', label: 'Braganey' },
  { data: '4103370', label: 'Brasilândia do Sul' },
  { data: '4103404', label: 'Cafeara' },
  { data: '4103453', label: 'Cafelândia' },
  { data: '4103479', label: 'Cafezal do Sul' },
  { data: '4103503', label: 'Califórnia' },
  { data: '4103602', label: 'Cambará' },
  { data: '4103701', label: 'Cambé' },
  { data: '4103800', label: 'Cambira' },
  { data: '4103909', label: 'Campina da Lagoa' },
  { data: '4103958', label: 'Campina do Simão' },
  { data: '4104006', label: 'Campina Grande do Sul' },
  { data: '4104055', label: 'Campo Bonito' },
  { data: '4104105', label: 'Campo do Tenente' },
  { data: '4104204', label: 'Campo Largo' },
  { data: '4104253', label: 'Campo Magro' },
  { data: '4104303', label: 'Campo Mourão' },
  { data: '4104402', label: 'Cândido de Abreu' },
  { data: '4104428', label: 'Candói' },
  { data: '4104451', label: 'Cantagalo' },
  { data: '4104501', label: 'Capanema' },
  { data: '4104600', label: 'Capitão Leônidas Marques' },
  { data: '4104659', label: 'Carambeí' },
  { data: '4104709', label: 'Carlópolis' },
  { data: '4104808', label: 'Cascavel' },
  { data: '4104907', label: 'Castro' },
  { data: '4105003', label: 'Catanduvas' },
  { data: '4105102', label: 'Centenário do Sul' },
  { data: '4105201', label: 'Cerro Azul' },
  { data: '4105300', label: 'Céu Azul' },
  { data: '4105409', label: 'Chopinzinho' },
  { data: '4105508', label: 'Cianorte' },
  { data: '4105607', label: 'Cidade Gaúcha' },
  { data: '4105706', label: 'Clevelândia' },
  { data: '4105805', label: 'Colombo' },
  { data: '4105904', label: 'Colorado' },
  { data: '4106001', label: 'Congonhinhas' },
  { data: '4106100', label: 'Conselheiro Mairinck' },
  { data: '4106209', label: 'Contenda' },
  { data: '4106308', label: 'Corbélia' },
  { data: '4106407', label: 'Cornélio Procópio' },
  { data: '4106456', label: 'Coronel Domingos Soares' },
  { data: '4106506', label: 'Coronel Vivida' },
  { data: '4106555', label: 'Corumbataí do Sul' },
  { data: '4106803', label: 'Cruz Machado' },
  { data: '4106571', label: 'Cruzeiro do Iguaçu' },
  { data: '4106605', label: 'Cruzeiro do Oeste' },
  { data: '4106704', label: 'Cruzeiro do Sul' },
  { data: '4106852', label: 'Cruzmaltina' },
  { data: '4106902', label: 'Curitiba' },
  { data: '4107009', label: 'Curiúva' },
  { data: '4107108', label: 'Diamante do Norte' },
  { data: '4107124', label: 'Diamante do Sul' },
  { data: '4107157', label: "Diamante D'Oeste" },
  { data: '4107207', label: 'Dois Vizinhos' },
  { data: '4107256', label: 'Douradina' },
  { data: '4107306', label: 'Doutor Camargo' },
  { data: '4128633', label: 'Doutor Ulysses' },
  { data: '4107405', label: 'Enéas Marques' },
  { data: '4107504', label: 'Engenheiro Beltrão' },
  { data: '4107538', label: 'Entre Rios do Oeste' },
  { data: '4107520', label: 'Esperança Nova' },
  { data: '4107546', label: 'Espigão Alto do Iguaçu' },
  { data: '4107553', label: 'Farol' },
  { data: '4107603', label: 'Faxinal' },
  { data: '4107652', label: 'Fazenda Rio Grande' },
  { data: '4107702', label: 'Fênix' },
  { data: '4107736', label: 'Fernandes Pinheiro' },
  { data: '4107751', label: 'Figueira' },
  { data: '4107850', label: 'Flor da Serra do Sul' },
  { data: '4107801', label: 'Floraí' },
  { data: '4107900', label: 'Floresta' },
  { data: '4108007', label: 'Florestópolis' },
  { data: '4108106', label: 'Flórida' },
  { data: '4108205', label: 'Formosa do Oeste' },
  { data: '4108304', label: 'Foz do Iguaçu' },
  { data: '4108452', label: 'Foz do Jordão' },
  { data: '4108320', label: 'Francisco Alves' },
  { data: '4108403', label: 'Francisco Beltrão' },
  { data: '4108502', label: 'General Carneiro' },
  { data: '4108551', label: 'Godoy Moreira' },
  { data: '4108601', label: 'Goioerê' },
  { data: '4108650', label: 'Goioxim' },
  { data: '4108700', label: 'Grandes Rios' },
  { data: '4108809', label: 'Guaíra' },
  { data: '4108908', label: 'Guairaçá' },
  { data: '4108957', label: 'Guamiranga' },
  { data: '4109005', label: 'Guapirama' },
  { data: '4109104', label: 'Guaporema' },
  { data: '4109203', label: 'Guaraci' },
  { data: '4109302', label: 'Guaraniaçu' },
  { data: '4109401', label: 'Guarapuava' },
  { data: '4109500', label: 'Guaraqueçaba' },
  { data: '4109609', label: 'Guaratuba' },
  { data: '4109658', label: 'Honório Serpa' },
  { data: '4109708', label: 'Ibaiti' },
  { data: '4109757', label: 'Ibema' },
  { data: '4109807', label: 'Ibiporã' },
  { data: '4109906', label: 'Icaraíma' },
  { data: '4110003', label: 'Iguaraçu' },
  { data: '4110052', label: 'Iguatu' },
  { data: '4110078', label: 'Imbaú' },
  { data: '4110102', label: 'Imbituva' },
  { data: '4110201', label: 'Inácio Martins' },
  { data: '4110300', label: 'Inajá' },
  { data: '4110409', label: 'Indianópolis' },
  { data: '4110508', label: 'Ipiranga' },
  { data: '4110607', label: 'Iporã' },
  { data: '4110656', label: 'Iracema do Oeste' },
  { data: '4110706', label: 'Irati' },
  { data: '4110805', label: 'Iretama' },
  { data: '4110904', label: 'Itaguajé' },
  { data: '4110953', label: 'Itaipulândia' },
  { data: '4111001', label: 'Itambaracá' },
  { data: '4111100', label: 'Itambé' },
  { data: '4111209', label: "Itapejara d'Oeste" },
  { data: '4111258', label: 'Itaperuçu' },
  { data: '4111308', label: 'Itaúna do Sul' },
  { data: '4111407', label: 'Ivaí' },
  { data: '4111506', label: 'Ivaiporã' },
  { data: '4111555', label: 'Ivaté' },
  { data: '4111605', label: 'Ivatuba' },
  { data: '4111704', label: 'Jaboti' },
  { data: '4111803', label: 'Jacarezinho' },
  { data: '4111902', label: 'Jaguapitã' },
  { data: '4112009', label: 'Jaguariaíva' },
  { data: '4112108', label: 'Jandaia do Sul' },
  { data: '4112207', label: 'Janiópolis' },
  { data: '4112306', label: 'Japira' },
  { data: '4112405', label: 'Japurá' },
  { data: '4112504', label: 'Jardim Alegre' },
  { data: '4112603', label: 'Jardim Olinda' },
  { data: '4112702', label: 'Jataizinho' },
  { data: '4112751', label: 'Jesuítas' },
  { data: '4112801', label: 'Joaquim Távora' },
  { data: '4112900', label: 'Jundiaí do Sul' },
  { data: '4112959', label: 'Juranda' },
  { data: '4113007', label: 'Jussara' },
  { data: '4113106', label: 'Kaloré' },
  { data: '4113205', label: 'Lapa' },
  { data: '4113254', label: 'Laranjal' },
  { data: '4113304', label: 'Laranjeiras do Sul' },
  { data: '4113403', label: 'Leópolis' },
  { data: '4113429', label: 'Lidianópolis' },
  { data: '4113452', label: 'Lindoeste' },
  { data: '4113502', label: 'Loanda' },
  { data: '4113601', label: 'Lobato' },
  { data: '4113700', label: 'Londrina' },
  { data: '4113734', label: 'Luiziana' },
  { data: '4113759', label: 'Lunardelli' },
  { data: '4113809', label: 'Lupionópolis' },
  { data: '4113908', label: 'Mallet' },
  { data: '4114005', label: 'Mamborê' },
  { data: '4114104', label: 'Mandaguaçu' },
  { data: '4114203', label: 'Mandaguari' },
  { data: '4114302', label: 'Mandirituba' },
  { data: '4114351', label: 'Manfrinópolis' },
  { data: '4114401', label: 'Mangueirinha' },
  { data: '4114500', label: 'Manoel Ribas' },
  { data: '4114609', label: 'Marechal Cândido Rondon' },
  { data: '4114708', label: 'Maria Helena' },
  { data: '4114807', label: 'Marialva' },
  { data: '4114906', label: 'Marilândia do Sul' },
  { data: '4115002', label: 'Marilena' },
  { data: '4115101', label: 'Mariluz' },
  { data: '4115200', label: 'Maringá' },
  { data: '4115309', label: 'Mariópolis' },
  { data: '4115358', label: 'Maripá' },
  { data: '4115408', label: 'Marmeleiro' },
  { data: '4115457', label: 'Marquinho' },
  { data: '4115507', label: 'Marumbi' },
  { data: '4115606', label: 'Matelândia' },
  { data: '4115705', label: 'Matinhos' },
  { data: '4115739', label: 'Mato Rico' },
  { data: '4115754', label: 'Mauá da Serra' },
  { data: '4115804', label: 'Medianeira' },
  { data: '4115853', label: 'Mercedes' },
  { data: '4115903', label: 'Mirador' },
  { data: '4116000', label: 'Miraselva' },
  { data: '4116059', label: 'Missal' },
  { data: '4116109', label: 'Moreira Sales' },
  { data: '4116208', label: 'Morretes' },
  { data: '4116307', label: 'Munhoz de Melo' },
  { data: '4116406', label: 'Nossa Senhora das Graças' },
  { data: '4116505', label: 'Nova Aliança do Ivaí' },
  { data: '4116604', label: 'Nova América da Colina' },
  { data: '4116703', label: 'Nova Aurora' },
  { data: '4116802', label: 'Nova Cantu' },
  { data: '4116901', label: 'Nova Esperança' },
  { data: '4116950', label: 'Nova Esperança do Sudoeste' },
  { data: '4117008', label: 'Nova Fátima' },
  { data: '4117057', label: 'Nova Laranjeiras' },
  { data: '4117107', label: 'Nova Londrina' },
  { data: '4117206', label: 'Nova Olímpia' },
  { data: '4117255', label: 'Nova Prata do Iguaçu' },
  { data: '4117214', label: 'Nova Santa Bárbara' },
  { data: '4117222', label: 'Nova Santa Rosa' },
  { data: '4117271', label: 'Nova Tebas' },
  { data: '4117297', label: 'Novo Itacolomi' },
  { data: '4117305', label: 'Ortigueira' },
  { data: '4117404', label: 'Ourizona' },
  { data: '4117453', label: 'Ouro Verde do Oeste' },
  { data: '4117503', label: 'Paiçandu' },
  { data: '4117602', label: 'Palmas' },
  { data: '4117701', label: 'Palmeira' },
  { data: '4117800', label: 'Palmital' },
  { data: '4117909', label: 'Palotina' },
  { data: '4118006', label: 'Paraíso do Norte' },
  { data: '4118105', label: 'Paranacity' },
  { data: '4118204', label: 'Paranaguá' },
  { data: '4118303', label: 'Paranapoema' },
  { data: '4118402', label: 'Paranavaí' },
  { data: '4118451', label: 'Pato Bragado' },
  { data: '4118501', label: 'Pato Branco' },
  { data: '4118600', label: 'Paula Freitas' },
  { data: '4118709', label: 'Paulo Frontin' },
  { data: '4118808', label: 'Peabiru' },
  { data: '4118857', label: 'Perobal' },
  { data: '4118907', label: 'Pérola' },
  { data: '4119004', label: "Pérola d'Oeste" },
  { data: '4119103', label: 'Piên' },
  { data: '4119152', label: 'Pinhais' },
  { data: '4119251', label: 'Pinhal de São Bento' },
  { data: '4119202', label: 'Pinhalão' },
  { data: '4119301', label: 'Pinhão' },
  { data: '4119400', label: 'Piraí do Sul' },
  { data: '4119509', label: 'Piraquara' },
  { data: '4119608', label: 'Pitanga' },
  { data: '4119657', label: 'Pitangueiras' },
  { data: '4119707', label: 'Planaltina do Paraná' },
  { data: '4119806', label: 'Planalto' },
  { data: '4119905', label: 'Ponta Grossa' },
  { data: '4119954', label: 'Pontal do Paraná' },
  { data: '4120002', label: 'Porecatu' },
  { data: '4120101', label: 'Porto Amazonas' },
  { data: '4120150', label: 'Porto Barreiro' },
  { data: '4120200', label: 'Porto Rico' },
  { data: '4120309', label: 'Porto Vitória' },
  { data: '4120333', label: 'Prado Ferreira' },
  { data: '4120358', label: 'Pranchita' },
  { data: '4120408', label: 'Presidente Castelo Branco' },
  { data: '4120507', label: 'Primeiro de Maio' },
  { data: '4120606', label: 'Prudentópolis' },
  { data: '4120655', label: 'Quarto Centenário' },
  { data: '4120705', label: 'Quatiguá' },
  { data: '4120804', label: 'Quatro Barras' },
  { data: '4120853', label: 'Quatro Pontes' },
  { data: '4120903', label: 'Quedas do Iguaçu' },
  { data: '4121000', label: 'Querência do Norte' },
  { data: '4121109', label: 'Quinta do Sol' },
  { data: '4121208', label: 'Quitandinha' },
  { data: '4121257', label: 'Ramilândia' },
  { data: '4121307', label: 'Rancho Alegre' },
  { data: '4121356', label: "Rancho Alegre D'Oeste" },
  { data: '4121406', label: 'Realeza' },
  { data: '4121505', label: 'Rebouças' },
  { data: '4121604', label: 'Renascença' },
  { data: '4121703', label: 'Reserva' },
  { data: '4121752', label: 'Reserva do Iguaçu' },
  { data: '4121802', label: 'Ribeirão Claro' },
  { data: '4121901', label: 'Ribeirão do Pinhal' },
  { data: '4122008', label: 'Rio Azul' },
  { data: '4122107', label: 'Rio Bom' },
  { data: '4122156', label: 'Rio Bonito do Iguaçu' },
  { data: '4122172', label: 'Rio Branco do Ivaí' },
  { data: '4122206', label: 'Rio Branco do Sul' },
  { data: '4122305', label: 'Rio Negro' },
  { data: '4122404', label: 'Rolândia' },
  { data: '4122503', label: 'Roncador' },
  { data: '4122602', label: 'Rondon' },
  { data: '4122651', label: 'Rosário do Ivaí' },
  { data: '4122701', label: 'Sabáudia' },
  { data: '4122800', label: 'Salgado Filho' },
  { data: '4122909', label: 'Salto do Itararé' },
  { data: '4123006', label: 'Salto do Lontra' },
  { data: '4123105', label: 'Santa Amélia' },
  { data: '4123204', label: 'Santa Cecília do Pavão' },
  { data: '4123303', label: 'Santa Cruz de Monte Castelo' },
  { data: '4123402', label: 'Santa Fé' },
  { data: '4123501', label: 'Santa Helena' },
  { data: '4123600', label: 'Santa Inês' },
  { data: '4123709', label: 'Santa Isabel do Ivaí' },
  { data: '4123808', label: 'Santa Izabel do Oeste' },
  { data: '4123824', label: 'Santa Lúcia' },
  { data: '4123857', label: 'Santa Maria do Oeste' },
  { data: '4123907', label: 'Santa Mariana' },
  { data: '4123956', label: 'Santa Mônica' },
  { data: '4124020', label: 'Santa Tereza do Oeste' },
  { data: '4124053', label: 'Santa Terezinha de Itaipu' },
  { data: '4124004', label: 'Santana do Itararé' },
  { data: '4124103', label: 'Santo Antônio da Platina' },
  { data: '4124202', label: 'Santo Antônio do Caiuá' },
  { data: '4124301', label: 'Santo Antônio do Paraíso' },
  { data: '4124400', label: 'Santo Antônio do Sudoeste' },
  { data: '4124509', label: 'Santo Inácio' },
  { data: '4124608', label: 'São Carlos do Ivaí' },
  { data: '4124707', label: 'São Jerônimo da Serra' },
  { data: '4124806', label: 'São João' },
  { data: '4124905', label: 'São João do Caiuá' },
  { data: '4125001', label: 'São João do Ivaí' },
  { data: '4125100', label: 'São João do Triunfo' },
  { data: '4125308', label: 'São Jorge do Ivaí' },
  { data: '4125357', label: 'São Jorge do Patrocínio' },
  { data: '4125209', label: "São Jorge d'Oeste" },
  { data: '4125407', label: 'São José da Boa Vista' },
  { data: '4125456', label: 'São José das Palmeiras' },
  { data: '4125506', label: 'São José dos Pinhais' },
  { data: '4125555', label: 'São Manoel do Paraná' },
  { data: '4125605', label: 'São Mateus do Sul' },
  { data: '4125704', label: 'São Miguel do Iguaçu' },
  { data: '4125753', label: 'São Pedro do Iguaçu' },
  { data: '4125803', label: 'São Pedro do Ivaí' },
  { data: '4125902', label: 'São Pedro do Paraná' },
  { data: '4126009', label: 'São Sebastião da Amoreira' },
  { data: '4126108', label: 'São Tomé' },
  { data: '4126207', label: 'Sapopema' },
  { data: '4126256', label: 'Sarandi' },
  { data: '4126272', label: 'Saudade do Iguaçu' },
  { data: '4126306', label: 'Sengés' },
  { data: '4126355', label: 'Serranópolis do Iguaçu' },
  { data: '4126405', label: 'Sertaneja' },
  { data: '4126504', label: 'Sertanópolis' },
  { data: '4126603', label: 'Siqueira Campos' },
  { data: '4126652', label: 'Sulina' },
  { data: '4126678', label: 'Tamarana' },
  { data: '4126702', label: 'Tamboara' },
  { data: '4126801', label: 'Tapejara' },
  { data: '4126900', label: 'Tapira' },
  { data: '4127007', label: 'Teixeira Soares' },
  { data: '4127106', label: 'Telêmaco Borba' },
  { data: '4127205', label: 'Terra Boa' },
  { data: '4127304', label: 'Terra Rica' },
  { data: '4127403', label: 'Terra Roxa' },
  { data: '4127502', label: 'Tibagi' },
  { data: '4127601', label: 'Tijucas do Sul' },
  { data: '4127700', label: 'Toledo' },
  { data: '4127809', label: 'Tomazina' },
  { data: '4127858', label: 'Três Barras do Paraná' },
  { data: '4127882', label: 'Tunas do Paraná' },
  { data: '4127908', label: 'Tuneiras do Oeste' },
  { data: '4127957', label: 'Tupãssi' },
  { data: '4127965', label: 'Turvo' },
  { data: '4128005', label: 'Ubiratã' },
  { data: '4128104', label: 'Umuarama' },
  { data: '4128203', label: 'União da Vitória' },
  { data: '4128302', label: 'Uniflor' },
  { data: '4128401', label: 'Uraí' },
  { data: '4128534', label: 'Ventania' },
  { data: '4128559', label: 'Vera Cruz do Oeste' },
  { data: '4128609', label: 'Verê' },
  { data: '4128658', label: 'Virmond' },
  { data: '4128708', label: 'Vitorino' },
  { data: '4128500', label: 'Wenceslau Braz' },
  { data: '4128807', label: 'Xambrê' },
];

export default pr;
