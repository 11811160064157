import React from 'react';
import { CSVLink } from 'react-csv';
import { FileExcelTwoTone } from '@ant-design/icons';
import { colors } from '~/options/general';

class Export extends React.PureComponent {
  getHeaders = () => {
    const { columns } = this.props;
    const headers = [];

    if (columns) {
      columns.forEach(element => {
        if (element.dataIndex && element.exporter !== false) {
          headers.push({ label: element.title, key: element.dataIndex });
        }
      });
    }

    return headers;
  };

  render() {
    const { dataSource, selectedSorter, ...rest } = this.props;

    let newDataSource = dataSource;
    if (selectedSorter && selectedSorter.column) {
      newDataSource = dataSource.sort(selectedSorter.column.sort);
      if (selectedSorter.order === 'descend') {
        newDataSource.reverse();
      }
    }

    return (
      <CSVLink separator=";" data={newDataSource} filename={`${new Date().toISOString()}.csv`} headers={this.getHeaders()}>
        <FileExcelTwoTone twoToneColor={colors.polarGreen.color} title=".csv" disabled={newDataSource.length === 0} {...rest} />
      </CSVLink>
    );
  }
}

export default Export;
