import React from 'react';
import { MenuService } from '~/services';
import { Link } from '../general';

const LabelForm = ({ label, value }) => {
  let menuSelected;
  if (typeof label === 'string') {
    menuSelected = MenuService.getByTitle(label);

    if (!menuSelected.idt) {
      menuSelected = MenuService.getByTitle(`${label}s`);
    }
    if (!menuSelected.idt) {
      menuSelected = MenuService.getByTitle(`${label}es`);
    }
    if (!menuSelected.idt) {
      menuSelected = MenuService.getByTitle(`Cadastro de ${label}`);
    }
    if (!menuSelected.idt) {
      menuSelected = MenuService.getByTitle(`Cadastro de ${label}s`);
    }
    if (!menuSelected.idt) {
      menuSelected = MenuService.getByTitle(`Cadastro de ${label}es`);
    }
    if (!menuSelected.idt && label.indexOf('ão') > 0) {
      menuSelected = MenuService.getByTitle(`${label.replace('ão', 'ões')}`);
    }
  }

  const openMenu = () => {
    MenuService.open(menuSelected, { codigo: value });
  };

  return menuSelected && menuSelected.idt ? <Link label={label} onClick={() => openMenu()} /> : <span>{label}</span>;
};

export default LabelForm;
