import React from 'react';
import { useField } from 'informed';
import { TimePicker as AntdTimePicker } from 'antd';
import classNames from 'classnames';
import { SizeMe } from 'react-sizeme';
import { isRequired, momentValue, backValue } from '~/utils';
import { Form } from '~/components/forms';
import './TimePicker.less';

const TimePicker = React.memo(({ required, validate, validateOnChange, ...props }) => {
  const { fieldState, fieldApi, render, userProps } = useField({
    validate: validate || (required && isRequired) || (() => undefined),
    validateOnChange: validateOnChange || required || validate,
    ...props,
  });
  const {
    onChange,
    onBlur,
    label,
    initialValue,
    forwardedRef,
    allowClear,
    formItemProps,
    className,
    block,
    popupClassName,
    popupStyle,
    locale,
    format = 'HH:mm',
    ...rest
  } = userProps;

  return render(
    <Form.Item label={label} error={fieldState.error} required={required} {...formItemProps}>
      <SizeMe>
        {({ size }) => (
          <AntdTimePicker
            placeholder="00:00"
            block
            format={format}
            value={momentValue(fieldState.value || initialValue, 'hour')}
            ref={forwardedRef}
            className={classNames('time-picker', { block: block === true }, className)}
            popupClassName={classNames('time-picker-popup', { block: block === true }, popupClassName)}
            onChange={(date, timeString) => {
              fieldApi.setValue(backValue(date, 'hour', format));

              if (onChange) {
                onChange(date, timeString);
              }
            }}
            onBlur={event => {
              fieldApi.setTouched();

              if (onBlur) {
                onBlur(event);
              }
            }}
            allowClear={!required && !rest.disabled && allowClear !== false}
            popupStyle={{ ...(block && size.width > 168 && { width: size.width }), ...popupStyle }}
            {...rest}
          />
        )}
      </SizeMe>
    </Form.Item>
  );
});

export default TimePicker;
